import React from 'react';

import PageHeader from 'components/Header/PageHeader';
import AddItem from 'components/Product/AddItem';

const AddProduct = () => {
    return (
        <div className='contentBox'>
            <PageHeader noBorderB pageList={["상점 관리", "상품 등록"]} title={`상품 등록`} />
            <AddItem />
        </div>
    );
};

export default AddProduct;