import React from 'react';

interface Props {
    placeholder?: string;
    className?: string;
    inputOnButton?: string;
    type?: string;
    disabled?: boolean;
    maxLength?:number;
    error?: boolean;
    name?: string;
    value?:string;
    inputValue?:string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

const TextUnitFieldInput = ({ placeholder, onKeyPress, maxLength, name, inputValue, type, disabled, className, inputOnButton, onChange, error }: Props) => {
    return (
        <div className={`relative`}>
            <input name={name} maxLength={maxLength} onKeyPress={onKeyPress} value={inputValue ? inputValue : ""} type={type} disabled={disabled} onChange={onChange} placeholder={placeholder} className={`border ${error ? "border-red" : " border-Gray-40"} Body3 rounded-lg py-2.5 px-3 h-[42px] ${className ? className : "w-full"}`} />
            {inputOnButton &&
                <div className='absolute right-3 top-[14px] text-Gray-60 SubH2'>{inputOnButton}</div>
            }
        </div>
    );
};

export default TextUnitFieldInput;