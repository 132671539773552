import React, { useCallback, useEffect, useState } from "react";
import { ReactComponent as Logo } from "assets/Logo.svg";
import NewStoreWrap from "components/Store/NewStoreWrap";
import NewStoreForm from "components/Store/NewStoreForm";
import Dropdown from "components/Dropdown/Dropdown";
import ButtonFilledTertiary from "components/Button/ButtonFilledTertiary";
import CheckBox from "components/CheckBox/CheckBox";
import ButtonFilledPrimary from "components/Button/ButtonFilledPrimary";
import Footer from "components/Footer/Footer";
import ButtonFilledSecondary from "components/Button/ButtonFilledSecondary";
import DaumAddressSearch from "components/DaumAddressSearch";
import { daumAddress } from "type/daumAddress";
import requestToLoveEgg from "utils/requestToLoveEgg";
import { useCategoryListApi } from "api/store";
import { useNavigate } from "react-router-dom";
import TextFieldInput from "components/Input/TextFieldInput";
import { toast } from "react-toastify";
import ModalPopup from "components/Modal/ModalPopup";
import { CategoryResultData } from "type/store";

declare const window: typeof globalThis & {
  IMP: any;
};

const NewStore = () => {
  const navigate = useNavigate();

  const [selectedCategory, setSelectedCategory] = useState<{
    categoryId: number;
    name: string;
  }>({ categoryId: 0, name: "" });
  const [selectedSecondCategory, setSelectedSecondCategory] = useState<{
    categoryId: number;
    name: string;
  }>({ categoryId: 0, name: "" });
  const [selectedThirdCategory, setSelectedThirdCategory] = useState<{
    categoryId: number;
    name: string;
  }>({ categoryId: 0, name: "" });

  const [companyName, setCompanyName] = useState<string>("");
  const [companyRegNo, setCompanyRegNo] = useState<string>("");
  const [addressDetail, setAddressDetail] = useState<string>("");
  const [CeoName, setCeoName] = useState<string>("");
  const [brandName, setBrandNane] = useState<string>("");
  const [mainUrl, setMainUrl] = useState<string>("");
  const [saleUrl, setSaleUrl] = useState<string>("");
  const [impUid, setImpUid] = useState<string>("");

  const [sellerId, setSellerId] = useState<string>("");
  const [sellerPw, setSellerPw] = useState<string>("");

  const [userName, setUserName] = useState<string>("");
  const [userEmail, setUserEmail] = useState<{ userId: string; add: string }>({
    userId: "",
    add: "",
  });
  const [emailAddress, setEmailAddress] = useState<string>("");
  const [userMobile, setUserMobile] = useState<string>("");

  const [checkList, setCheckList] = useState<{
    agree: boolean;
    agree1: boolean;
    agree2: boolean;
  }>({ agree: false, agree1: false, agree2: false });
  const [passwordView, setPasswordView] = useState<boolean>(false);
  const [userIdCheck, setIdCheck] = useState<boolean>(false);

  const [idValidCheckModal, setIdValidCheckModal] = useState<boolean>(false);
  const [idValidText, setIdValidText] = useState<string>("");

  const [isValid, setIsValid] = useState({
    companyNameError: false,
    companyRegError: false,
    addressError: false,
    ceoError: false,
    categoryError: false,
    sellerIdError: false,
    sellerPwError: false,
    userNameError: false,
    userEmailError: false,
    userMobileError: false,
  });

  const [address, setAddress] = useState<daumAddress>({
    address: "",
    addressEnglish: "",
    addressType: "",
    apartment: "",
    autoJibunAddress: "",
    autoJibunAddressEnglish: "",
    autoRoadAddress: "",
    autoRoadAddressEnglish: "",
    bcode: "",
    bname: "",
    bname1: "",
    bname1English: "",
    bname2: "",
    bname2English: "",
    bnameEnglish: "",
    buildingCode: "",
    buildingName: "",
    hname: "",
    jibunAddress: "",
    jibunAddressEnglish: "",
    noSelected: "",
    postcode: "",
    postcode1: "",
    postcode2: "",
    postcodeSeq: "",
    query: "",
    roadAddress: "",
    roadAddressEnglish: "",
    roadname: "",
    roadnameCode: "",
    roadnameEnglish: "",
    sido: "",
    sidoEnglish: "",
    sigungu: "",
    sigunguCode: "",
    sigunguEnglish: "",
    userLanguageType: "",
    userSelectedType: "",
    zonecode: "",
  });

  const submitValid =
    companyName !== "" &&
    companyRegNo !== "" &&
    address.address !== "" &&
    address.zonecode !== "" &&
    CeoName !== "" &&
    selectedCategory.categoryId !== 0 &&
    sellerId !== "" &&
    sellerPw !== "" &&
    userName !== "" &&
    userEmail.userId !== "" &&
    userEmail.add !== "" &&
    userMobile !== "" &&
    checkList.agree &&
    checkList.agree1 &&
    checkList.agree2 &&
    impUid !== "" &&
    userIdCheck;

  const email = [
    { name: "구글", address: "gmail.com" },
    { name: "네이버", address: "naver.com" },
    { name: "네이버", address: "nate.com" },
    { name: "다음", address: "daum.net" },
    { name: "한메일", address: "hanmail.net" },
    { name: "직접입력", address: "직접입력" },
  ];

  const { data } = useCategoryListApi();

  const { userId, add } = userEmail;

  const marketData = {
    companyName: companyName.trim(),
    companyRegNo: companyRegNo,
    addr: address.address,
    detailAddr: addressDetail.trim(),
    zip: address.zonecode,
    repName: CeoName.trim(),
    mainCategory: selectedCategory.categoryId,
    subCategory1: selectedSecondCategory.categoryId,
    subCategory2: selectedThirdCategory.categoryId,
    brandName: brandName.trim(),
    mainUrl: mainUrl,
    saleUrl: saleUrl,
    id: sellerId,
    password: sellerPw,
    username: userName.trim(),
    email: `${userId + "@" + add}`,
    mobileNo: userMobile.replaceAll("-", ""),
    impUid: impUid,
  };

  const mobileVerify = () => {
    var IMP = window.IMP;
    IMP.init("imp00911443");
    if (IMP) {
      IMP.certification(
        {
          pg: "B010007401",
          phone: userMobile.replaceAll("-", ""),
          name: userName.trim(),
        },
        function (rsp: {
          error_code: null;
          error_msg: null;
          imp_uid: string;
          merchant_uid: string;
          pg_provider: string;
          pg_type: string;
          success: boolean;
        }) {
          // callback
          if (rsp.success) {
            setImpUid(rsp.imp_uid);
            console.log(rsp);
            toast.success("연락처 인증 완료했습니다 :)");
          } else {
            console.log("실패");
            toast.info("연락처 인증 실패했습니다. 다시 인증해 주세요.");
          }
        }
      );
    }
  };

  const handleEmailValue = (selectItem: { name: string; address: string }) => {
    setUserEmail({ ...userEmail, add: "" });
    setEmailAddress(selectItem.address);
    if (selectItem.address !== "직접입력") {
      setUserEmail({ ...userEmail, add: selectItem.address });
    }
  };

  const idCheck = () => {
    requestToLoveEgg({
      method: "POST",
      url: "/v1/check-id",
      data: {
        id: sellerId,
      },
    }).then((res) => {
      if (res.data.ResultCode === 14) {
        setIdValidCheckModal(true);
        setIdCheck(false);
        setIdValidText(
          "이미 있는 아이디입니다 😢\n다른 아이디를 사용해 주세요."
        );
      } else if (res.data.ResultCode === 13) {
        setIdValidCheckModal(true);
        setIdCheck(false);
        setIdValidText(
          `아이디에 '${res.data.ResultData}'는 제외하고 입력해 주세요.`
        );
      } else if (res.data.ResultCode === 10) {
        setIdValidCheckModal(true);
        setIdCheck(false);
        setIdValidText(
          `영어 소문자, 숫자만 사용하여\n5글자 이상, 12글자 이하로 입력해 주세요`
        );
      } else {
        setIdValidCheckModal(true);
        setIdCheck(true);
        setIdValidText("사용 가능한 아이디 입니다.");
      }
    });
  };

  const submit = () => {
    requestToLoveEgg({
      method: "POST",
      url: "/v1/join",
      data: marketData,
    }).then((res) => {
      if (res.data.ResultData === "SUCCESS") {
        navigate("/", { replace: true });
      } else if (res.data.ResultCode === 11) {
        toast.error("비밀번호 패턴 에러");
      }
    });
  };

  const onChangeCompanyName = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const currentName = e.target.value.replace(
        /[^A-Za-zㄱ-ㅎ가-힣\s()]/g,
        ""
      );
      setCompanyName(currentName);
      if (currentName === "") {
        setIsValid({ ...isValid, companyNameError: true });
      } else {
        setIsValid({ ...isValid, companyNameError: false });
      }
    },
    [isValid]
  );

  const onChangeCompanyRegNo = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const currentName = e.target.value;
      setCompanyRegNo(
        currentName
          .replace(/[^0-9]/g, "")
          .replace(/^(\d{0,3})(\d{0,2})(\d{0,5})$/g, "$1-$2-$3")
          .replace(/(\-{1,2})$/g, "")
      );
      if (currentName === "") {
        setIsValid({ ...isValid, companyRegError: true });
      } else {
        setIsValid({ ...isValid, companyRegError: false });
      }
    },
    [isValid]
  );

  const onChangeCeoName = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const currentName = e.target.value.replace(/[^A-Za-zㄱ-ㅎ가-힣\s]/g, "");
      setCeoName(currentName);
      if (currentName === "") {
        setIsValid({ ...isValid, ceoError: true });
      } else {
        setIsValid({ ...isValid, ceoError: false });
      }
    },
    [isValid]
  );

  const onChangeSellerId = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      let sellerIdReg = /^[A-Za-z0-9]{5,12}$/g;
      const currentName = e.target.value.replace(/[^a-z0-9]/g, "");
      setIdCheck(false);
      setSellerId(currentName);
      if (currentName === "") {
        setIsValid({ ...isValid, sellerIdError: true });
      } else if (!sellerIdReg.test(currentName)) {
        setIsValid({ ...isValid, sellerIdError: true });
      } else {
        setIsValid({ ...isValid, sellerIdError: false });
      }
    },
    [isValid]
  );

  const onChangeSellerPw = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      let sellerPwReg =
        /^.*(?=^.{8,}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@#$%^&+=]).*$/;
      const currentName = e.target.value;
      setSellerPw(currentName);
      if (currentName === "") {
        setIsValid({ ...isValid, sellerPwError: true });
      } else if (!sellerPwReg.test(currentName)) {
        setIsValid({ ...isValid, sellerPwError: true });
      } else {
        setIsValid({ ...isValid, sellerPwError: false });
      }
    },
    [isValid]
  );

  const onChangeUserName = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const currentName = e.target.value.replace(/[^A-Za-zㄱ-ㅎ가-힣\s]/g, "");
      setUserName(currentName);
      if (currentName === "") {
        setIsValid({ ...isValid, userNameError: true });
      } else {
        setIsValid({ ...isValid, userNameError: false });
      }
    },
    [isValid]
  );
  const onChangeUserEmail = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const currentName = e.target.value;
      setSellerPw(currentName);
      if (currentName === "") {
        setIsValid({ ...isValid, sellerPwError: true });
      } else {
        setIsValid({ ...isValid, sellerPwError: false });
      }
    },
    [isValid]
  );

  const onChangeUserMobile = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value
        .replace(/[^0-9]/g, "")
        .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3")
        .replace(/(\-{1,2})$/g, "");
      const currentName = value;
      setUserMobile(currentName);
      if (currentName === "") {
        setIsValid({ ...isValid, userMobileError: true });
      } else {
        setIsValid({ ...isValid, userMobileError: false });
      }
    },
    [isValid]
  );
  
  const newCate = [
    ...data?.data?.ResultData !== undefined ? data?.data?.ResultData! as CategoryResultData[] : [],
    { categoryId: 0, name: "선택안함" },
  ];


  return (
    <div>
      {idValidCheckModal && (
        <ModalPopup
          onClick={() => {
            setIdValidCheckModal(false);
          }}
          type={"sm"}
          buttonText={"확인"}
        >
          <div className="text-center whitespace-pre-line Body2 text-Gray-90 break-keep">
            {idValidText}
          </div>
        </ModalPopup>
      )}
      <div className="min-w-[770px] px-[60px] max-w-[1073px] w-full mx-auto pt-[120px]">
        <div className="flex items-center gap-4">
          <Logo width={100} height={81} />
          <div className="text-Gray-100 H1">입점 신청</div>
        </div>
        <div className="p-5 bg-Gray-20 max-w-[626px] my-10 rounded-lg">
          <div className="mb-4 SubH1 text-Gray-100">신청 전 확인해주세요.</div>
          <div className="flex flex-col gap-1 text-Gray-90 Body4">
            <div>· 현재 법인, 과세 사업자인 경우에만 신청 가능합니다.</div>
            <div>
              · 배송/CS/상품등록 등 판매 및 사후관리가 불가능한 업체는 입점
              불가합니다.
            </div>
            <div>· 고객센터와 별도 메일을 통한 문의 및 접수는 불가합니다.</div>
            <div>
              · 입점 결과는 기재하신 담당자 연락처 또는 이메일로 회신드리오니,
              정확한 정보를 입력해주세요.
            </div>
            <div>· 단순 입점 방식에 대한 문의는 답변 드리기 어렵습니다.</div>
          </div>
        </div>

        <NewStoreWrap title="판매자 정보">
          <NewStoreForm required title="회사명">
            <TextFieldInput
              error={isValid.companyNameError}
              onChange={(e) => {
                onChangeCompanyName(e);
              }}
              inputValue={companyName}
              className="w-[476px]"
              placeholder="예시) 주식회사 러브에그"
            />
            <div className="mt-2 text-Blue-70 Body4">
              사업자 등록증의 법인명 또는 상호명을 입력해 주세요.
            </div>
          </NewStoreForm>
          <NewStoreForm
            required
            title="사업자 등록 정보"
            className="flex items-center"
          >
            <TextFieldInput
              maxLength={12}
              inputValue={companyRegNo}
              error={isValid.companyRegError}
              onChange={(e) => {
                onChangeCompanyRegNo(e);
              }}
              className="w-[270px]"
              placeholder="123-45-67890"
            />
          </NewStoreForm>
          <NewStoreForm
            className="flex flex-col items-start gap-2.5"
            required
            title="사업자 소재지"
          >
            <div className="flex items-center gap-2">
              <TextFieldInput
                error={isValid.addressError}
                className="w-[358px]"
                placeholder="우편번호"
                inputValue={address.zonecode}
                disabled
              />
              <DaumAddressSearch setAddres={setAddress}>
                <ButtonFilledSecondary
                  size={"md"}
                  className="h-[52px] w-[108px]"
                  btnText={"우편번호 검색"}
                />
              </DaumAddressSearch>
            </div>
            <TextFieldInput
              error={isValid.addressError}
              inputValue={address.address}
              className="w-[476px]"
              disabled
            />
            <TextFieldInput
              inputValue={addressDetail}
              onChange={(e) => {
                setAddressDetail(
                  e.target.value.replace(/[^A-Za-zㄱ-ㅎ가-힣\s() ,\-/\d]/g, "")
                );
              }}
              className="w-[476px]"
              placeholder=""
            />
          </NewStoreForm>
          <NewStoreForm required title="대표자">
            <TextFieldInput
              error={isValid.ceoError}
              inputValue={CeoName}
              onChange={(e) => {
                onChangeCeoName(e);
              }}
              className="w-[476px]"
              placeholder="대표자 이름 입력"
            />
          </NewStoreForm>
          <NewStoreForm required title="대표 판매 카테고리">
            <Dropdown
              error={isValid.categoryError}
              width="w-[476px]"
              innerText={selectedCategory.name ? selectedCategory.name : "선택"}
            >
              {data?.data.ResultData.map((c, i) => {
                return (
                  <li
                    key={c.categoryId}
                    onClick={() => {
                      setSelectedCategory({
                        categoryId: Number(c.categoryId),
                        name: c.name,
                      });
                      setSelectedSecondCategory({ categoryId: 0, name: "" });
                      setSelectedThirdCategory({ categoryId: 0, name: "" });
                    }}
                    className="w-[476px] py-2.5 px-3 hover:bg-Blue-50"
                  >
                    <div className="max-w-[476px] w-full Body4 text-Gray-80">
                      {c.name}
                    </div>
                  </li>
                );
              })}
            </Dropdown>
          </NewStoreForm>
          {selectedCategory.categoryId !== 0 ? 
            <NewStoreForm title="추가 카테고리 1">
              <Dropdown
                width="w-[476px]"
                innerText={
                  selectedSecondCategory.name
                  ? selectedSecondCategory.name
                  : "선택"
                }
                >
                {newCate?.map((c, i) => {
                  if (c.name !== selectedCategory.name) {
                    return (
                      <li
                      value={c.categoryId}
                      key={c.categoryId}
                      onClick={() => {
                        setSelectedSecondCategory({
                          categoryId: Number(c.categoryId),
                          name: c.name,
                        });
                        setSelectedThirdCategory({
                          categoryId: 0,
                          name: "",
                        });
                      }}
                      className="w-[476px] py-2.5 px-3 hover:bg-Blue-50"
                      >
                        <div className="max-w-[476px] w-full Body4 text-Gray-80">
                          {c.name}
                        </div>
                      </li>
                    );
                  }
                })}
              </Dropdown>
              <div className="mt-2 text-Blue-70 Body4">
              대표 판매 카테고리 외에 추가로 취급하고 있는 상품군이 있다면 추가 카테고리로 등록해 주세요.
            </div>
            </NewStoreForm>
                : ""}
          {selectedCategory.categoryId !== 0 && selectedSecondCategory.categoryId !== 0 ? 
            <NewStoreForm title="추가 카테고리 2">
              <Dropdown
                width="w-[476px]"
                innerText={
                  selectedThirdCategory.name
                  ? selectedThirdCategory.name
                  : "선택"
                }
                >
                {newCate?.map((c, i) => {
                  if (
                    c.name !== selectedCategory.name &&
                    c.name !== selectedSecondCategory.name
                    ) {
                      return (
                        <li
                        value={c.categoryId}
                        key={c.categoryId}
                        onClick={() =>
                          setSelectedThirdCategory({
                            categoryId: Number(c.categoryId),
                            name: c.name,
                          })
                        }
                        className="w-[476px] py-2.5 px-3 hover:bg-Blue-50"
                        >
                        <div className="max-w-[476px] w-full Body4 text-Gray-80">
                          {c.name}
                        </div>
                      </li>
                    );
                  }
                })}
              </Dropdown>
            </NewStoreForm>
        : ""}
          
          <NewStoreForm title="브랜드명">
            <TextFieldInput
              inputValue={brandName}
              onChange={(e) => {
                setBrandNane(
                  e.target.value.replace(/[^A-Za-zㄱ-ㅎ가-힣\s()]/g, "")
                );
              }}
              className="w-[476px]"
              placeholder="예시) 레저에빠지다"
            />
            <div className="mt-2 text-Blue-70 Body4">
              브랜드명은 고객에게 보여지는 이름입니다. 브랜드명을 입력하지 않으면 회사 이름이 노출 됩니다.
            </div>
          </NewStoreForm>
          <NewStoreForm title="공식 홈페이지 URL">
            <TextFieldInput
              inputValue={mainUrl}
              onChange={(e) => {
                setMainUrl(
                  e.target.value.replace(
                    /[^A-Za-zㄱ-ㅎ가-힣0-9/:.\?\\+=&%_ -]/g,
                    ""
                  )
                );
              }}
              className="w-[476px]"
              placeholder="https://"
            />
          </NewStoreForm>
          <NewStoreForm subTitle="(자사몰 제외)" title="대표 판매채널 URL">
            <TextFieldInput
              inputValue={saleUrl}
              onChange={(e) => {
                setSaleUrl(
                  e.target.value.replace(
                    /[^A-Za-zㄱ-ㅎ가-힣0-9/:.\?\\+=&%_ -]/g,
                    ""
                  )
                );
              }}
              className="w-[476px]"
              placeholder="https://"
            />
            <div className="mt-2 text-Blue-70 Body4">
              스마트스토어 같이 저희가 참고할 수 있는 채널이 있다면 알려주세요.
            </div>
          </NewStoreForm>
        </NewStoreWrap>

        <NewStoreWrap title="계정 정보">
          <NewStoreForm title="판매자 로그인 ID" required>
            <div className="flex items-center gap-[10px]">
              <TextFieldInput
                error={isValid.sellerIdError}
                inputValue={sellerId}
                onChange={(e) => {
                  onChangeSellerId(e);
                }}
                className="w-[386px]"
                placeholder="사용할 아이디 입력"
              />
              <ButtonFilledTertiary
                onClick={() => {
                  idCheck();
                }}
                className="!h-[52px] w-full"
                size={"md"}
                btnText={"중복확인"}
              />
            </div>
            <div className="mt-2 whitespace-pre-wrap text-Blue-70 Body4">
              {`5글자 이상의 영어 소문자, 숫자 입력해 주세요. (최대 12글자)\n로그인 ID는 입점 신청 후  변경 불가하니 신중하게 정해주세요.`}
            </div>
          </NewStoreForm>
          <NewStoreForm title="비밀번호" required>
            <TextFieldInput
              error={isValid.sellerPwError}
              onChange={(e) => {
                onChangeSellerPw(e);
              }}
              className="w-[476px]"
              type={passwordView ? "text" : "password"}
              placeholder="비밀번호 입력"
            />
            <div className="whitespace-pre-wrap">
              <div className="my-2 text-Blue-70 Body4">
                영문, 숫자, 특수문자 조합으로 입력해 주세요. (최소 8글자)
              </div>
              <CheckBox
                onChange={() => {
                  setPasswordView((passwordView) => !passwordView);
                }}
                text="비밀번호 보기"
              />
            </div>
          </NewStoreForm>
        </NewStoreWrap>

        <NewStoreWrap title="담당자 정보" noMb>
          <NewStoreForm title="이름" required>
            <TextFieldInput
              error={isValid.userNameError}
              inputValue={userName}
              onChange={(e) => {
                onChangeUserName(e);
              }}
              className="w-[367px]"
              placeholder="이름 입력"
            />
          </NewStoreForm>
          <NewStoreForm title="이메일" required className="flex items-center">
            <TextFieldInput
              error={isValid.userEmailError}
              inputValue={userEmail?.userId}
              onChange={(e) => {
                setUserEmail({
                  ...userEmail,
                  userId: e.target.value.replace(
                    /[^A-Za-z0-9_\-ㄱ-ㅎ가-힣]/g,
                    ""
                  ),
                });
              }}
              className="w-[270px]"
              placeholder="연락받을 이메일"
            />
            <div className="mx-2 Body4 text-Gray-90">@</div>
            {emailAddress === "직접입력" && (
              <TextFieldInput
                error={isValid.userEmailError}
                onChange={(e) => {
                  setUserEmail({ ...userEmail, add: e.target.value });
                }}
                className="w-[175px] mr-2"
                placeholder="직접입력"
              />
            )}
            <Dropdown
              innerText={emailAddress !== "" ? emailAddress : "선택"}
              className="w-[175px]"
            >
              {email.map((k, i) => {
                return (
                  <li
                    key={i}
                    onClick={() => handleEmailValue(k)}
                    className="w-[175px] py-2.5 px-3 hover:bg-Blue-50"
                  >
                    <div className="max-w-[175px] w-full Body4 text-Gray-80">
                      {k.address}
                    </div>
                  </li>
                );
              })}
            </Dropdown>
          </NewStoreForm>
          <NewStoreForm title="연락처" required className="flex items-center">
            <TextFieldInput
              maxLength={13}
              inputValue={userMobile}
              error={isValid.userMobileError}
              onChange={(e) => {
                onChangeUserMobile(e);
              }}
              className="w-[260px]"
              placeholder="010-1234-5678"
            />
            <ButtonFilledTertiary
              onClick={() => {
                if (userMobile.length > 11) {
                  mobileVerify();
                } else {
                  alert("최소 11자 입력해야합니다");
                }
              }}
              className="!h-[52px] ml-4 w-full"
              size={"md"}
              btnText={"인증요청"}
            />
          </NewStoreForm>
        </NewStoreWrap>
        <div className="mt-4 Body4 text-Blue-100">
          ※ 연락받을 수 있는 정보로 입력해 주세요. 담당자 이메일로 심사결과
          알려드립니다.
        </div>
        <div className="py-5 px-6 flex flex-col items-start min-w-[650px] my-10 gap-5 border border-Gray-40 rounded-lg">
          <div className="SubH1 text-Gray-100">약관 동의</div>
          <div className="flex flex-col items-start gap-4">
            <CheckBox
              onChange={(e) => {
                setCheckList({ ...checkList, agree: e.target.checked });
              }}
              text="입점 이후 신청 사실과 다른 정보, 상품 판매 등으로 인한 문제 발생 시 통보없이 판매중지될 수 있으며, 이 후 법적 조치에 동의합니다. (필수)"
            />
            <CheckBox
              onChange={(e) => {
                setCheckList({ ...checkList, agree1: e.target.checked });
              }}
              text="개인정보 수집 동의(필수)"
            />
            <CheckBox
              onChange={(e) => {
                setCheckList({ ...checkList, agree2: e.target.checked });
              }}
              text="판매약관 동의(필수)"
            />
          </div>
          <div className="Body5 text-Gray-80">
            ※ 제출 이후 입점 절차를 더 이상 진행하지 않는 경우, 본 내용은
            14일후에 파기합니다.
          </div>
        </div>
        <ButtonFilledPrimary
          disabled={!submitValid}
          onClick={() => {
            submit();
          }}
          size={"lg"}
          className="w-40 !mx-0"
          btnText={"제출하기"}
        />
      </div>
      <Footer />
    </div>
  );
};

export default NewStore;
