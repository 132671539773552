import React from 'react';

interface Props {
    children?: React.ReactNode;
    title: string;
    noMb?:boolean;
    noGap?:boolean;
    noPy?:boolean;
}

const NewStoreWrap = ({ children, title, noMb, noGap, noPy}: Props) => {
    return (
        <div className={`${noMb ? "" : "mb-[60px]"} min-w-[1020px]`}>
            <div className='w-full py-3 border-b border-Gray-100 SubH1'>{title}</div>
            <div className={`flex flex-col border-b ${noPy ? "" : "py-7"}  border-Gray-40 ${noGap ? "" : "gap-6"}`}>
                {children}
            </div>
        </div>
    );
};

export default NewStoreWrap;