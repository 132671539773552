import React from 'react';
import Checked from 'assets/checked.svg'


interface Props {
    disabled?: boolean;
    className?: string;
    style?: React.CSSProperties;
    text?: string;
    id?: string
    checked?: boolean;
    onClick?: () => void;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}


const CheckBox = ({ className, disabled, text, checked, style, id, onChange }: Props) => {
    return (
        <label htmlFor={id} style={style} className={`flex items-center ${className}`}>
            <input id={id} type="checkbox"
                className={`${disabled ? "!bg-Gray-40 !border-none" : ""} mr-2 appearance-none w-4 h-4 rounded-sm border border-Gray-40 hover:bg-Blue-60 hover:border-Blue-100 checked:border-Blue-100 checked:bg-Blue-100 checked:hover:bg-Blue-100 checked:bg-checked bg-checkBox`}
                style={{
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                }} onChange={onChange} checked={checked} disabled={disabled} />
            <div className={`flex-1 ${checked ? "text-Gray-90" : "text-Gray-80"} Body2 `}>{text}</div>
        </label>
    );
};

export default CheckBox;