import React, { ReactComponentElement } from "react";
interface SecondaryBtnProps {
  size: "md" | "lg";
  icon?: ReactComponentElement<any>;
  btnText: string;
  disabled?: boolean;
  className?: string;
  onClick?: () => void;
}

const ButtonFilledSecondary = ({
  size,
  icon,
  btnText,
  disabled,
  className,
  onClick,
}: SecondaryBtnProps) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`border-none rounded-lg flex items-center justify-center px-3 py-[10px] cursor-pointer
        hover:bg-Blue-60 bg-Blue-50 text-Blue-100  disabled:bg-Blue-50 disabled:text-Blue-60 disabled:cursor-default
        ${
          size === "lg"
            ? "min-h-[48px] h-14 min-w-[160px] SubH1"
            : size === "md"
            ? "min-h-[38px] max-h-[52px] SubH2"
            : ""
        } ${className}`}
    >
      {icon && icon}
      <div className={`${icon && "ml-2"}`}>{btnText}</div>
    </button>
  );
};

export default ButtonFilledSecondary;
