import React, { useState } from "react";

import Dropdown from "components/Dropdown/Dropdown";
import PageHeader from "components/Header/PageHeader";
import Table from "components/Table/Table";
import { RETURNORDER } from "components/Table/TableData";
import { dateFormat } from "utils/dateFormat";
import { ReactComponent as Refresh } from "assets/refresh.svg";
import DatePicker from "react-datepicker";
import { useReturnList } from "api/order/User";
import useDebounce from "hook/useDebounce";
import { useNavigate } from "react-router-dom";
import Pagination from "components/Pagination/Pagination";

const ReturnOrder = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  let pageItemLength = 10;
  const [selectedTr, setSelectedTr] = useState<string[]>([]);
  const [startDate, setStartDate] = useState<null | Date>(null);
  const [endDate, setEndDate] = useState<null | Date>(null);
  const [search, setSearch] = useState<string>("");

  const [selectedDate, setSelectedDate] = useState<{
    startDate: Date | string;
    endDate: Date | string;
  }>({ startDate: "", endDate: "" });

  const [selectedFilter, setSelectedFilter] = useState<{
    name: string;
    type:
      | ""
      | "RETURN_APPLY"
      | "RETURN_CHECK"
      | "RETURN_RECEIVE"
      | "RETURN_COMPLETE";
  }>({ name: "반품 신청일", type: "RETURN_APPLY" });

  const [selectedStatus, setSelectedStatus] = useState<{
    name: string;
    type:
      | ""
      | "RETURN_APPLY"
      | "RETURN_CHECK"
      | "RETURN_RECEIVE"
      | "RETURN_COMPLETE";
  }>({ name: "반품 신청", type: "RETURN_APPLY" });

  const [filter, setFilter] = useState<
    {
      name: string;
      type:
        | "RETURN_APPLY"
        | "RETURN_CHECK"
        | "RETURN_RECEIVE"
        | "RETURN_COMPLETE";
    }[]
  >([{ name: "반품 신청", type: "RETURN_APPLY" }]);

  const debouncedSearchText = useDebounce(search, 500);

  const { data, refetch } = useReturnList({
    status: selectedStatus.type,
    dateType: selectedFilter.type,
    startDate: selectedDate.startDate,
    endDate: selectedDate.endDate,
    search: debouncedSearchText,
    page: currentPage - 1,
    size: pageItemLength,
  });

  const orderStatus: {
    id: number;
    name: string;
    length: number;
    filterList: {
      name: string;
      type:
        | "RETURN_APPLY"
        | "RETURN_CHECK"
        | "RETURN_RECEIVE"
        | "RETURN_COMPLETE";
    }[];
    type:
      | "RETURN_APPLY"
      | "RETURN_CHECK"
      | "RETURN_RECEIVE"
      | "RETURN_COMPLETE";
    rightBorder: boolean;
  }[] = [
    {
      id: 0,
      name: "반품 신청",
      length: data?.data.ResultData.applyCount as number,
      filterList: [{ name: "반품 신청일", type: "RETURN_APPLY" }],
      type: "RETURN_APPLY",
      rightBorder: true,
    },
    {
      id: 1,
      name: "반품 접수",
      length: data?.data.ResultData.checkCount as number,
      filterList: [
        { name: "반품 신청일", type: "RETURN_APPLY" },
        { name: "반품 접수일", type: "RETURN_CHECK" },
      ],
      type: "RETURN_CHECK",
      rightBorder: true,
    },
    {
      id: 2,
      name: "반품 처리",
      length: data?.data.ResultData.receiveCount as number,
      filterList: [
        { name: "반품 신청일", type: "RETURN_APPLY" },
        { name: "반품 접수일", type: "RETURN_CHECK" },
        { name: "입고 완료일", type: "RETURN_RECEIVE" },
      ],
      type: "RETURN_RECEIVE",
      rightBorder: true,
    },
    {
      id: 3,
      name: "반품 완료",
      length: data?.data.ResultData.completeCount as number,
      filterList: [
        { name: "반품 신청일", type: "RETURN_APPLY" },
        { name: "반품 접수일", type: "RETURN_CHECK" },
        { name: "입고 완료일", type: "RETURN_RECEIVE" },
        { name: "반품 완료일", type: "RETURN_COMPLETE" },
      ],
      type: "RETURN_COMPLETE",
      rightBorder: false,
    },
  ];

  return (
    <div className="contentBox">
      <PageHeader pageList={["주문 관리", "반품 관리"]} title={`반품 관리`} />
      <div className="flex items-center h-20 border border-Gray-40 rounded-2xl min-w-[1020px]">
        {orderStatus.map((item) => {
          return (
            <div
              className={`flex items-center justify-center w-full ${
                item.rightBorder && "border-r-2 border-Gray-40"
              }`}
              key={item.id}
            >
              <div
                onClick={() => {
                  setCurrentPage(1);
                  setSelectedTr([]);
                  setSelectedFilter({
                    name: "반품 신청일",
                    type: "RETURN_APPLY",
                  });
                  setSelectedStatus({ name: item.name, type: item.type });
                  setFilter(item.filterList);
                }}
                className={`flex flex-col text-center cursor-pointer`}
              >
                <div
                  className={`Body4 ${
                    selectedStatus.name === item.name &&
                    selectedStatus.type === item.type
                      ? "text-Blue-100"
                      : "text-Gray-80"
                  }`}
                >
                  {item.name}
                </div>
                <div
                  className={`H2 hover:underline ${
                    selectedStatus.name === item.name &&
                    selectedStatus.type === item.type
                      ? "text-Blue-100 underline"
                      : "text-Gray-100"
                  }`}
                >
                  {item.length}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="flex items-center py-10 border-b border-Gray-90 min-w-[1020px]">
        <div className="mr-6 SubH2 text-Gray-90">조회기간</div>
        <div className="flex items-center gap-2">
          <Dropdown
            innerText={selectedFilter.name ? selectedFilter.name : ""}
            width="w-[177px]"
          >
            {filter.map((k, i) => {
              return (
                <li
                  onClick={() => {
                    setCurrentPage(1);
                    setSelectedFilter({ name: k.name, type: k.type });
                    refetch();
                  }}
                  key={i}
                  className="dropdownWrap"
                >
                  <div className="dropdownLi">{k.name}</div>
                </li>
              );
            })}
          </Dropdown>
          <div className="flex items-center">
            <DatePicker
              className="flex items-center px-3 py-2 h-[52px] border border-Gray-40 Body4 text-Gray-90 rounded-lg"
              selected={startDate}
              placeholderText="시작일"
              onChange={(date: Date) => {
                setStartDate(date);
                setEndDate(null);
                setSelectedDate({
                  ...selectedDate,
                  startDate: dateFormat(date).replaceAll(".", ""),
                  endDate: "",
                });
              }}
              selectsStart
              dateFormat="yyyy.MM.dd"
              startDate={startDate}
              endDate={endDate}
            />
            <div className="px-2">~</div>
            <DatePicker
              placeholderText="종료일"
              className="flex items-center px-3 py-2 h-[52px] border border-Gray-40 Body4 text-Gray-90 rounded-lg"
              disabled={!startDate}
              selected={endDate}
              onChange={(date: Date) => {
                setCurrentPage(1);
                setEndDate(date);
                setSelectedDate({
                  ...selectedDate,
                  endDate: dateFormat(date).replaceAll(".", ""),
                });
              }}
              selectsEnd
              dateFormat="yyyy.MM.dd"
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
            />
            {startDate && endDate && (
              <div
                className="flex items-center ml-4 cursor-pointer SubH2"
                onClick={() => {
                  setCurrentPage(1);
                  setSelectedDate({ startDate: "", endDate: "" });
                  setStartDate(null);
                  setEndDate(null);
                  setSelectedFilter({
                    name: "반품 신청일",
                    type: "RETURN_APPLY",
                  });
                }}
              >
                <Refresh className="mr-2" />
                <div>초기화</div>
              </div>
            )}
          </div>
        </div>
      </div>
      {data?.data.ResultData.content.length !== 0 ? (
        <Table COLUMNS={RETURNORDER}>
          {data?.data.ResultData.content.map((r, i) => {
            return (
              <tr
                key={r.claimNo}
                className="last:border-none border-y Body4 border-Gray-40"
              >
                <td className="tableTd">{r.originOrderNo}</td>
                <td
                  onClick={() => {
                    navigate(`/order/return/${r.claimNo}`);
                  }}
                  className="cursor-pointer tableTd text-Blue-100"
                >
                  {r.claimNo}
                </td>
                <td className="tableTd min-w-[320px]">
                  <div className="flex items-center gap-2">
                    <img
                      className="rounded w-11 h-11"
                      src={r.itemImagePath}
                      alt=""
                    />
                    <div>{r.itemTitle}</div>
                  </div>
                </td>
                <td className="tableTd max-w-[120px] h-[72px]">
                  {r.optionStr ? r.optionStr : "-"}
                </td>
                <td className="tableTd h-[72px]">{r.count}</td>
                <td className="tableTd">
                  {r.claimStatus === "RETURN_APPLY"
                    ? "반품신청"
                    : r.claimStatus === "RETURN_CHECK"
                    ? "반품접수"
                    : r.claimStatus === "RETURN_RECEIVE"
                    ? "반품상품입고"
                    : r.claimStatus === "RETURN_COMPLETE"
                    ? "반품완료"
                    : ""}
                </td>
                <td className="tableTd">{r.regDt ? r.regDt : "-"}</td>
                <td className="tableTd">{r.checkDt ? r.checkDt : "-"}</td>
                <td className="tableTd">
                  {r.returnCourierName ? r.returnCourierName : "-"}
                </td>
                <td className="tableTd">
                  {r.returnInvoiceNumber ? r.returnInvoiceNumber : "-"}
                </td>
                <td className="tableTd">
                  {r.reason === "CHANGE_OF_HEART"
                    ? "단순변심"
                    : r.reason === "WRONG_ORDER"
                    ? "잘못주문"
                    : r.reason === "DELIVERY_OMITTED"
                    ? "배송누락"
                    : r.reason === "PRODUCT_DEFECT"
                    ? "상품불량"
                    : ""}
                </td>
                <td className="tableTd">
                  {r.collectionType === "COMPANY" ? "업체수거" : "직접수거"}
                </td>
              </tr>
            );
          })}
        </Table>
      ) : (
        <div className="h-[500px] flex flex-col items-center justify-center Body4">
          해당 상태의 상품이 없어요
        </div>
      )}
      <Pagination
        className="mt-4"
        itemsCount={data?.data.ResultData?.totalElements!}
        pageSize={pageItemLength}
        currPage={currentPage}
        setList={setCurrentPage}
      />
    </div>
  );
};

export default ReturnOrder;
