import React, { useEffect, useState } from "react";

import { useDeliveryCompanyListApi } from "api/deliveryCompany";
import ButtonFilledPrimary from "components/Button/ButtonFilledPrimary";
import ButtonFilledSecondary from "components/Button/ButtonFilledSecondary";
import ButtonLinedTertiary from "components/Button/ButtonLinedTertiary";
import DaumAddressSearch from "components/DaumAddressSearch";
import Dropdown from "components/Dropdown/Dropdown";
import TextFieldInput from "components/Input/TextFieldInput";
import TextLimitHorizontalInput from "components/Input/TextLimitHorizontalInput";
import PopupSelect from "components/Modal/PopupSelect";
import NewStoreForm from "components/Store/NewStoreForm";
import NewStoreWrap from "components/Store/NewStoreWrap";
import { daumAddress } from "type/daumAddress";
import { DeliveryResultData } from "type/store";
import requestToLoveEgg from "utils/requestToLoveEgg";
import { useUserDeliveryInfoApi } from "api/store";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

interface Props {
  DeliveryData?: DeliveryResultData;
  status?: string;
  authority?: string;
}

const DeliveryInfo = ({ authority }: Props) => {
  const params = useParams();
  const [deliveryPop, setDeliveryPop] = useState(false);
  const [returnDeliveryPop, setReturnDeliveryPop] = useState(false);
  const [deliveryValue, setDeliveryValue] = useState<{
    cd: string;
    cdNm: string;
  }>({ cd: "", cdNm: "" });
  const [returnDeliveryValue, setReturnDeliveryValue] = useState<{
    cd: string;
    cdNm: string;
  }>({ cd: "", cdNm: "" });
  const [edit, setEdit] = useState(false);

  const { data: DData, refetch } = useUserDeliveryInfoApi(
    authority === "ROLE_COMMERCE_ADMIN" ? params.id : ""
  );
  const delivery = DData?.data.ResultData as DeliveryResultData;

  const [address, setAddress] = useState<daumAddress>({
    address: "",
    addressEnglish: "",
    addressType: "",
    apartment: "",
    autoJibunAddress: "",
    autoJibunAddressEnglish: "",
    autoRoadAddress: "",
    autoRoadAddressEnglish: "",
    bcode: "",
    bname: "",
    bname1: "",
    bname1English: "",
    bname2: "",
    bname2English: "",
    bnameEnglish: "",
    buildingCode: "",
    buildingName: "",
    hname: "",
    jibunAddress: "",
    jibunAddressEnglish: "",
    noSelected: "",
    postcode: "",
    postcode1: "",
    postcode2: "",
    postcodeSeq: "",
    query: "",
    roadAddress: "",
    roadAddressEnglish: "",
    roadname: "",
    roadnameCode: "",
    roadnameEnglish: "",
    sido: "",
    sidoEnglish: "",
    sigungu: "",
    sigunguCode: "",
    sigunguEnglish: "",
    userLanguageType: "",
    userSelectedType: "",
    zonecode: "",
  });

  const [returnAddress, setreturnAddress] = useState<daumAddress>({
    address: "",
    addressEnglish: "",
    addressType: "",
    apartment: "",
    autoJibunAddress: "",
    autoJibunAddressEnglish: "",
    autoRoadAddress: "",
    autoRoadAddressEnglish: "",
    bcode: "",
    bname: "",
    bname1: "",
    bname1English: "",
    bname2: "",
    bname2English: "",
    bnameEnglish: "",
    buildingCode: "",
    buildingName: "",
    hname: "",
    jibunAddress: "",
    jibunAddressEnglish: "",
    noSelected: "",
    postcode: "",
    postcode1: "",
    postcode2: "",
    postcodeSeq: "",
    query: "",
    roadAddress: "",
    roadAddressEnglish: "",
    roadname: "",
    roadnameCode: "",
    roadnameEnglish: "",
    sido: "",
    sidoEnglish: "",
    sigungu: "",
    sigunguCode: "",
    sigunguEnglish: "",
    userLanguageType: "",
    userSelectedType: "",
    zonecode: "",
  });

  const [deliveryPriceType, setDeliveryPriceType] = useState<{
    name: string;
    type: string;
  }>({ name: "", type: "" });

  const [deliveryData, setDeliveryData] = useState<{
    deliveryCompanyName?: string;
    returnExchangeCompanyName?: string;
    companyId?: number | null;
    deliveryCompanyCd: string;
    deliveryPriceType: string | null;
    freeDeliveryPrice: number | null;
    defaultDeliveryPrice: number | null;
    additionalDeliveryPrice: number | null;
    forwardingPlaceAddr: string;
    forwardingPlaceDetailAddr: string;
    forwardingPlaceZip: string;
    deliveryNotice: string;
    returnExchangeCompanyCd: string;
    returnDeliveryPrice: number;
    additionalReturnDeliveryPrice: number;
    exchangeDeliveryPrice: number;
    additionalExchangeDeliveryPrice: number;
    returnExchangePlaceAddr: string;
    returnExchangePlaceDetailAddr: string;
    returnExchangePlaceZip: string;
    returnExchangeNotice: string;
    returnExchangeRestrictionNotice: string;
  }>({
    companyId: null,
    deliveryCompanyName: "",
    returnExchangeCompanyName: "",
    deliveryCompanyCd: "",
    deliveryPriceType: "",
    freeDeliveryPrice: 0,
    defaultDeliveryPrice: 0,
    additionalDeliveryPrice: 0,
    forwardingPlaceAddr: "",
    forwardingPlaceDetailAddr: "",
    forwardingPlaceZip: "",
    deliveryNotice: "",
    returnExchangeCompanyCd: "",
    returnDeliveryPrice: 0,
    additionalReturnDeliveryPrice: 0,
    exchangeDeliveryPrice: 0,
    additionalExchangeDeliveryPrice: 0,
    returnExchangePlaceAddr: "",
    returnExchangePlaceDetailAddr: "",
    returnExchangePlaceZip: "",
    returnExchangeNotice: "",
    returnExchangeRestrictionNotice: "",
  });

  const validCheck =
    deliveryData.deliveryCompanyCd !== "" &&
    deliveryData.deliveryPriceType !== "" &&
    deliveryData.forwardingPlaceAddr !== "" &&
    deliveryData.forwardingPlaceZip !== "" &&
    deliveryData.returnExchangePlaceAddr !== "" &&
    deliveryData.returnExchangePlaceZip !== "";

  const deliveryAmount = [
    { name: "무료", type: "FREE" },
    { name: "조건부 무료", type: "CONDITIONAL_FREE" },
    { name: "유료", type: "CHARGE" },
  ];

  const { data: deliveryCompanyList } = useDeliveryCompanyListApi();
  const list = deliveryCompanyList?.data.ResultData;

  useEffect(() => {
    setDeliveryValue({
      cd: delivery?.deliveryCompanyCd,
      cdNm: delivery?.deliveryCompanyName as string,
    });
    setReturnDeliveryValue({
      cd: delivery?.returnExchangeCompanyCd,
      cdNm: delivery?.returnExchangeCompanyName as string,
    });
    setDeliveryData({
      ...deliveryData,
      deliveryCompanyName: delivery?.deliveryCompanyName,
      returnExchangeCompanyName: delivery?.returnExchangeCompanyName,
      companyId: authority === "ROLE_COMMERCE_ADMIN" ? Number(params.id) : null,
      deliveryCompanyCd: delivery?.deliveryCompanyCd,
      deliveryPriceType: delivery?.deliveryPriceType,
      freeDeliveryPrice: delivery?.freeDeliveryPrice,
      defaultDeliveryPrice: delivery?.defaultDeliveryPrice,
      additionalDeliveryPrice: delivery?.additionalDeliveryPrice,
      forwardingPlaceAddr: delivery?.forwardingPlaceAddr,
      forwardingPlaceDetailAddr: delivery?.forwardingPlaceDetailAddr,
      forwardingPlaceZip: delivery?.forwardingPlaceZip,
      deliveryNotice:
        delivery?.deliveryNotice !== ""
          ? delivery?.deliveryNotice
          : "기본배송비는 0000원 입니다. 단, 00000원 이상 주문 시 배송비 무료. (제주, 도서, 산간, 오지 일부지역은 0000원)\n평균 배송일은 (결제완료 후) 3일입니다. 도서 산간 지역 및 일부 상품의 경우 다소 늦어질수 있습니다.\n천재지변, 물량 수급 변동 등 예외적인 사유 발생 시 다소 지연될 수 있는 점 양해 부탁드립니다.",
      returnExchangeCompanyCd: delivery?.returnExchangeCompanyCd,
      returnDeliveryPrice: delivery?.returnDeliveryPrice,
      additionalReturnDeliveryPrice: delivery?.additionalReturnDeliveryPrice,
      exchangeDeliveryPrice: delivery?.exchangeDeliveryPrice,
      additionalExchangeDeliveryPrice:
        delivery?.additionalExchangeDeliveryPrice,
      returnExchangePlaceAddr: delivery?.returnExchangePlaceAddr,
      returnExchangePlaceDetailAddr: delivery?.returnExchangePlaceDetailAddr,
      returnExchangePlaceZip: delivery?.returnExchangePlaceZip,
      returnExchangeNotice:
        delivery?.returnExchangeNotice !== ""
          ? delivery?.returnExchangeNotice
          : "반품 시 우선 판매자에게 문의하여 반품사유, 택배사, 배송비, 반품지 주소등을 협의하신 후 반품상품을 발송해주시기 바랍니다.\n교환 또는 반품 주소 : (우편번호) 주소\n전자상거래 등에서의 소비자보호에 관한 법률 등에 의한 청약철회 제한 사유에 해당하는 경우 및 기타 객관적으로 이에 준하는 것으로 인정되는 경우 청약철회가 제한될 수 있습니다.\n단순변심에 의한 상품의 교환/반품은 제품 수령 후 7일 이내까지, 교환/반품 제한사항에 해당하지 않는 경우에만 가능합니다. (교환/반품 비용 고객부담)\n상품의 내용이 표시·광고의 내용과 다른 경우에는 상품을 수령한 날부터 3개월 이내, 그 사실을 안 날 또는 알 수 있었던 날부터 30일 이내에 청약철회 가능합니다. (반품 비용 판매업자 부담)",
      returnExchangeRestrictionNotice:
        delivery?.returnExchangeRestrictionNotice !== ""
          ? delivery?.returnExchangeRestrictionNotice
          : "주문/제작 상품의 경우, 상품의 제작이 이미 진행된 경우\n상품 포장을 개봉하여 사용 또는 설치 완료되어 상품의 가치가 훼손된 경우 (단, 내용 확인을 위한 포장 개봉의 경우는 예외)\n고객의 사용, 시간경과, 일부 소비에 의하여 상품의 가치가 현저히 감소한 경우\n세트상품 일부 사용, 구성품을 분실하였거나 취급 부주의로 인한 파손/고장/오염으로 재판매 불가한 경우\n모니터 해상도의 차이로 인해 색상이나 이미지가 실제와 달라, 고객이 단순 변심으로 교환/반품을 무료로 요청하는 경우\n제조사의 사정 (신모델 출시 등) 및 부품 가격 변동 등에 의해 무료 교환/반품으로 요청하는 경우\n상품의 택(TAG) 제거, 라벨 및 상품 훼손, 구성품 누락으로 상품의 가치가 현저히 감소된 경우 (의류/잡화/수입명품 등)\n신선/냉장/냉동 상품의 단순변심의 경우\n뷰티 상품 이용 시 트러블(알러지, 붉은 반점, 가려움, 따가움)이 발생하는 경우,진료 확인서 및 소견서 등을 증빙하면 환불이 가능 (제반비용 고객부담)\n설치 또는 사용하여 재판매가 어려운 경우 (전자/가전/설치상품 등)\n상품의 시리얼 넘버 유출로 내장된 소프트웨어의 가치가 감소한 경우 (내비게이션, OS시리얼이 적힌 PMP)\n홀로그램 등을 분리, 분실, 훼손하여 상품의 가치가 현저히 감소하여 재판매가 불가할 경우 (노트북, 데스크탑 PC 등)\n상품을 개봉하여 장착한 이후 단순변심인 경우 (자동차 용품 등)\n복제가 가능한 상품의 포장 등을 훼손한 경우 (CD/DVD/GAME/도서 등)",
    });
  }, [DData]);

  const handleDeliveryInfo = () => {
    requestToLoveEgg({
      method: "POST",
      url: `/api/v1/join-apply/delivery`,
      data: deliveryData,
    }).then((res) => {
      if (res.data.ResultCode === 1) {
        toast.success("상품 수정 완료 했습니다 :)");
        setEdit(false);
        refetch();
      }
    });
  };

  useEffect(() => {
    setDeliveryData({
      ...deliveryData,
      forwardingPlaceAddr: address.address,
      forwardingPlaceZip: address.zonecode,
    });
  }, [address]);

  useEffect(() => {
    setDeliveryData({
      ...deliveryData,
      returnExchangePlaceAddr: returnAddress.address,
      returnExchangePlaceZip: returnAddress.zonecode,
    });
  }, [returnAddress]);

  // ROLE_USER
  // ROLE_COMMERCE_ADMIN
  // EXAMINATION
  // WAITING
  // INACTIVE
  // ACTIVE

  return (
    <div>
      {deliveryPop && (
        <PopupSelect
          title="배송사 선택"
          onClickCancelButton={() => {
            setDeliveryValue({ cd: "", cdNm: "" });
          }}
          onClick={() => {
            setDeliveryValue({
              cd: deliveryValue.cd,
              cdNm: deliveryValue.cdNm,
            });
            setDeliveryData({
              ...deliveryData,
              deliveryCompanyCd: deliveryValue.cd,
              deliveryCompanyName: deliveryValue.cdNm,
            });
          }}
          currentDeliveryValue={deliveryValue.cdNm}
          setCloseState={setDeliveryPop}
        >
          <div className="h-[400px] overflow-y-auto border rounded-lg border-Gray-40 flex flex-wrap items-start">
            {list?.map((d) => {
              return (
                <div
                  key={d.cd}
                  onClick={() => {
                    setDeliveryValue({ cd: d.cd, cdNm: d.cdNm });
                  }}
                  className={`${
                    deliveryValue.cdNm === d.cdNm
                      ? "text-Blue-100"
                      : "text-Gray-90"
                  } Body5 w-[25%] h-[48px] px-4 flex flex-col justify-center hover:bg-Blue-50 cursor-pointer`}
                >
                  {d.cdNm}
                </div>
              );
            })}
          </div>
        </PopupSelect>
      )}
      {returnDeliveryPop && (
        <PopupSelect
          title="배송사 선택"
          onClickCancelButton={() => {
            setReturnDeliveryValue({ cd: "", cdNm: "" });
          }}
          onClick={() => {
            setDeliveryData({
              ...deliveryData,
              returnExchangeCompanyCd: returnDeliveryValue.cd,
              returnExchangeCompanyName: returnDeliveryValue.cdNm,
            });
          }}
          currentDeliveryValue={returnDeliveryValue.cdNm}
          setCloseState={setReturnDeliveryPop}
        >
          <div className="h-[400px] overflow-y-auto border rounded-lg border-Gray-40 flex flex-wrap items-start">
            {list?.map((r) => {
              return (
                <div
                  key={r.cd}
                  onClick={() => {
                    setReturnDeliveryValue({ cd: r.cd, cdNm: r.cdNm });
                  }}
                  className={`${
                    returnDeliveryValue.cdNm === r.cdNm
                      ? "text-Blue-100"
                      : "text-Gray-90"
                  } Body5 w-[25%] h-[48px] px-4 flex flex-col justify-center hover:bg-Blue-50 cursor-pointer`}
                >
                  {r.cdNm}
                </div>
              );
            })}
          </div>
        </PopupSelect>
      )}
      <NewStoreWrap title="배송 정보">
        <NewStoreForm itemCenter={!edit} required title="배송사">
          {edit ? (
            <div className="flex items-center">
              {deliveryData.deliveryCompanyName && (
                <div className="mr-4 Body3 text-Gray-90">
                  {deliveryData.deliveryCompanyName}
                </div>
              )}
              <ButtonFilledSecondary
                onClick={() => setDeliveryPop(true)}
                size={"md"}
                className="h-[52px] w-[108px]"
                btnText={`배송사 ${
                  deliveryValue.cdNm !== "" ? "수정" : "검색"
                }`}
              />
            </div>
          ) : !edit && delivery?.deliveryCompanyName === "" ? (
            <ButtonFilledSecondary
              disabled
              className="h-[52px] w-[108px]"
              btnText={`배송사 ${deliveryValue.cdNm !== "" ? "수정" : "검색"}`}
              size={"md"}
            />
          ) : (
            <div className="Body3 text-Gray-90">
              {deliveryData.deliveryCompanyName
                ? deliveryData.deliveryCompanyName
                : "-"}
            </div>
          )}
        </NewStoreForm>
        <NewStoreForm required title="배송비 설정">
          {edit ? (
            <Dropdown
              width="w-[476px]"
              innerText={
                deliveryPriceType?.name !== ""
                  ? deliveryPriceType?.name
                  : deliveryData.deliveryPriceType === "FREE"
                  ? "무료"
                  : deliveryData.deliveryPriceType === "CONDITIONAL_FREE"
                  ? "조건부 무료"
                  : deliveryData.deliveryPriceType === "CHARGE"
                  ? "유료"
                  : "선택"
              }
            >
              {deliveryAmount.map((k, i) => {
                return (
                  <li
                    onClick={() => {
                      k.type === "FREE"
                        ? setDeliveryData({
                            ...deliveryData,
                            freeDeliveryPrice: null,
                            defaultDeliveryPrice: null,
                            additionalDeliveryPrice: null,
                            deliveryPriceType: k.type,
                          })
                        : k.type === "CONDITIONAL_FREE"
                        ? setDeliveryData({
                            ...deliveryData,
                            freeDeliveryPrice: 0,
                            defaultDeliveryPrice: 0,
                            additionalDeliveryPrice: 0,
                            deliveryPriceType: k.type,
                          })
                        : setDeliveryData({
                            ...deliveryData,
                            freeDeliveryPrice: null,
                            defaultDeliveryPrice: 0,
                            additionalDeliveryPrice: 0,
                            deliveryPriceType: k.type,
                          });
                      setDeliveryPriceType({ type: k.type, name: k.name });
                    }}
                    key={i}
                    className="dropdownWrap"
                  >
                    <div className="dropdownLi">{k.name}</div>
                  </li>
                );
              })}
            </Dropdown>
          ) : (
            <TextFieldInput
              className="w-[476px]"
              disabled
              inputValue={
                deliveryData.deliveryPriceType === "FREE"
                  ? "무료"
                  : deliveryData.deliveryPriceType === "CONDITIONAL_FREE"
                  ? "조건부 무료"
                  : deliveryData.deliveryPriceType === "CHARGE"
                  ? "유료"
                  : ""
              }
            />
          )}
        </NewStoreForm>
        {deliveryData.deliveryPriceType === "CONDITIONAL_FREE" ? (
          <>
            <NewStoreForm required title="무료배송 조건">
              <TextFieldInput
                disabled={!edit}
                inputValue={String(deliveryData.freeDeliveryPrice)}
                onChange={(e) => {
                  const value = Number(
                    e.target.value.replace(/[^0-9][0-9]{0,8}/g, "")
                  );
                  setDeliveryData({
                    ...deliveryData,
                    freeDeliveryPrice: value,
                  });
                }}
                inputOnButton="원"
                className="w-[476px]"
                placeholder="금액 입력"
              />
            </NewStoreForm>
          </>
        ) : (
          ""
        )}
        {deliveryData.deliveryPriceType === "CHARGE" ||
        deliveryData.deliveryPriceType === "CONDITIONAL_FREE" ? (
          <>
            <NewStoreForm required title="기본 배송비">
              <TextFieldInput
                disabled={!edit}
                inputValue={String(deliveryData.defaultDeliveryPrice)}
                onChange={(e) => {
                  const value = Number(
                    e.target.value.replace(/[^0-9][0-9]{0,8}/g, "")
                  );
                  setDeliveryData({
                    ...deliveryData,
                    defaultDeliveryPrice: value,
                  });
                }}
                inputOnButton="원"
                className="w-[476px]"
                placeholder="금액 입력"
              />
            </NewStoreForm>
            <NewStoreForm title={"제주/도서 산간\n추가 배송비"}>
              <TextFieldInput
                disabled={!edit}
                inputValue={String(deliveryData.additionalDeliveryPrice)}
                onChange={(e) => {
                  const value = Number(
                    e.target.value.replace(/[^0-9][0-9]{0,8}/g, "")
                  );
                  setDeliveryData({
                    ...deliveryData,
                    additionalDeliveryPrice: value,
                  });
                }}
                inputOnButton="원"
                className="w-[476px]"
                placeholder="금액 입력"
              />
            </NewStoreForm>
          </>
        ) : (
          ""
        )}

        <NewStoreForm
          className="flex flex-col items-start gap-2.5"
          required
          title="출고지"
        >
          <div className="flex items-center gap-2">
            <TextFieldInput
              inputValue={deliveryData.forwardingPlaceZip}
              className="w-[358px]"
              placeholder="우편번호"
              disabled
            />
            {edit && (
              <DaumAddressSearch setAddres={setAddress}>
                <ButtonFilledSecondary
                  size={"md"}
                  className="h-[52px] w-[108px]"
                  btnText={"우편번호 검색"}
                />
              </DaumAddressSearch>
            )}
          </div>
          <TextFieldInput
            inputValue={deliveryData.forwardingPlaceAddr}
            className="w-[476px]"
            disabled
          />
          <TextFieldInput
            disabled={!edit}
            inputValue={deliveryData.forwardingPlaceDetailAddr}
            onChange={(e) => {
              setDeliveryData({
                ...deliveryData,
                forwardingPlaceDetailAddr: e.target.value,
              });
            }}
            className="w-[476px]"
            placeholder=""
          />
        </NewStoreForm>
        <NewStoreForm title="배송안내">
          <TextLimitHorizontalInput
            row={8}
            disabled={!edit}
            currentLength={deliveryData.deliveryNotice.length}
            maxLength={400}
            inputValue={deliveryData.deliveryNotice}
            onChange={(e) => {
              setDeliveryData({
                ...deliveryData,
                deliveryNotice: e.target.value,
              });
            }}
            className="w-[803px]"
          />
          <div className="mt-2 Body4 text-Blue-70">
            기본 배송비 등 배송비 관련 설정 사항들을 입력해 주세요. 그외에
            작성된 메세지는 레빠마켓 기본 정책 사항이므로 임의로 수정하지
            마세요.
          </div>
        </NewStoreForm>
      </NewStoreWrap>
      <NewStoreWrap title="교환/반품 정보">
        <NewStoreForm itemCenter={!edit} required title="교환/반품 택배사">
          {edit ? (
            <div className="flex items-center">
              {deliveryData.returnExchangeCompanyName && (
                <div className="mr-4 Body3 text-Gray-90">
                  {deliveryData.returnExchangeCompanyName}
                </div>
              )}
              <ButtonFilledSecondary
                onClick={() => setReturnDeliveryPop(true)}
                size={"md"}
                className="h-[52px] w-[108px]"
                btnText={`배송사 ${
                  returnDeliveryValue.cdNm !== "" ? "수정" : "검색"
                }`}
              />
            </div>
          ) : !edit && delivery?.deliveryCompanyName === "" ? (
            <ButtonFilledSecondary
              disabled
              className="h-[52px] w-[108px]"
              btnText={`배송사 ${deliveryValue.cdNm !== "" ? "수정" : "검색"}`}
              size={"md"}
            />
          ) : (
            <div className="Body3 text-Gray-90">{returnDeliveryValue.cdNm}</div>
          )}
        </NewStoreForm>
        <NewStoreForm required title="반품 배송비 (편도)">
          <TextFieldInput
            disabled={!edit}
            inputValue={String(deliveryData.returnDeliveryPrice)}
            onChange={(e) => {
              const value = Number(
                e.target.value.replace(/[^0-9][0-9]{0,8}/g, "")
              );
              setDeliveryData({ ...deliveryData, returnDeliveryPrice: value });
            }}
            inputOnButton="원"
            className="w-[476px]"
            placeholder="금액입력"
          />
        </NewStoreForm>
        <NewStoreForm title={"제주/도서 산간\n추가 배송비 (편도)"}>
          <TextFieldInput
            disabled={!edit}
            inputValue={String(deliveryData.additionalReturnDeliveryPrice)}
            onChange={(e) => {
              const value = Number(
                e.target.value.replace(/[^0-9][0-9]{0,8}/g, "")
              );
              setDeliveryData({
                ...deliveryData,
                additionalReturnDeliveryPrice: value,
              });
            }}
            inputOnButton="원"
            className="w-[476px]"
            placeholder="금액입력"
          />
        </NewStoreForm>
        <NewStoreForm required title="교환 배송비 (왕복)">
          <TextFieldInput
            disabled={!edit}
            inputValue={String(deliveryData.exchangeDeliveryPrice)}
            onChange={(e) => {
              const value = Number(
                e.target.value.replace(/[^0-9][0-9]{0,8}/g, "")
              );
              setDeliveryData({
                ...deliveryData,
                exchangeDeliveryPrice: value,
              });
            }}
            inputOnButton="원"
            className="w-[476px]"
            placeholder="금액입력"
          />
        </NewStoreForm>
        <NewStoreForm title={"제주/도서 산간\n추가 배송비 (왕복)"}>
          <TextFieldInput
            disabled={!edit}
            inputValue={String(deliveryData.additionalExchangeDeliveryPrice)}
            onChange={(e) => {
              const value = Number(
                e.target.value.replace(/[^0-9][0-9]{0,8}/g, "")
              );
              setDeliveryData({
                ...deliveryData,
                additionalExchangeDeliveryPrice: value,
              });
            }}
            inputOnButton="원"
            className="w-[476px]"
            placeholder="금액입력"
          />
        </NewStoreForm>
        <NewStoreForm
          className="flex flex-col items-start gap-2.5"
          required
          title="교환/반품지"
        >
          <div className="flex items-center gap-2">
            <TextFieldInput
              inputValue={deliveryData.returnExchangePlaceZip}
              className="w-[358px]"
              placeholder="우편번호"
              disabled
            />
            {edit && (
              <DaumAddressSearch setAddres={setreturnAddress}>
                <ButtonFilledSecondary
                  size={"md"}
                  className="h-[52px] w-[108px]"
                  btnText={"우편번호 검색"}
                />
              </DaumAddressSearch>
            )}
          </div>
          <TextFieldInput
            inputValue={deliveryData.returnExchangePlaceAddr}
            className="w-[476px]"
            disabled
          />
          <TextFieldInput
            disabled={!edit}
            inputValue={deliveryData.returnExchangePlaceDetailAddr}
            onChange={(e) => {
              setDeliveryData({
                ...deliveryData,
                returnExchangePlaceDetailAddr: e.target.value,
              });
            }}
            className="w-[476px]"
            placeholder=""
          />
        </NewStoreForm>
        <NewStoreForm title="교환/반품 안내">
          <TextLimitHorizontalInput
            row={8}
            disabled={!edit}
            currentLength={deliveryData.returnExchangeNotice.length}
            inputValue={deliveryData.returnExchangeNotice}
            className="w-[803px]"
            onChange={(e) => {
              setDeliveryData({
                ...deliveryData,
                returnExchangeNotice: e.target.value,
              });
            }}
            maxLength={500}
          />
          <div className="mt-2 Body4 text-Blue-70">
            교환 또는 반품지 주소, 우편번호 등을 입력해 주세요. 그외에 작성된
            메세지는 레빠마켓 기본 정책 사항이므로 임의로 수정하지 마세요.
          </div>
        </NewStoreForm>
        <NewStoreForm title="교환/반품 제한 사항">
          <TextLimitHorizontalInput
            row={8}
            disabled={!edit}
            currentLength={deliveryData.returnExchangeRestrictionNotice.length}
            maxLength={800}
            className="w-[803px]"
            inputValue={deliveryData.returnExchangeRestrictionNotice}
            onChange={(e) => {
              setDeliveryData({
                ...deliveryData,
                returnExchangeRestrictionNotice: e.target.value,
              });
            }}
          />
          <div className="mt-2 Body4 text-Blue-70">
            교환/반품 관련해서 추가로 전달할 내용이 있다면 입력해 주세요. 그외에
            작성된 메세지는 레빠마켓 기본 정책 사항이므로 임의로 수정하지
            마세요.
          </div>
        </NewStoreForm>
      </NewStoreWrap>
      <div className="fixed bottom-0 left-0 bg-Gray-10/70 backdrop-blur-[12px] z-[102] w-full py-5 border-t border-Gray-40">
        <div className="flex items-center pl-[260px] pr-[160px] justify-between max-w-[1920px] mx-auto min-w-[1440px]">
          <div className="Body2">
            상점 관리 {">"} 상점 설정 {">"} 배송정보
          </div>
          {edit ? (
            <div className="flex items-center gap-4">
              <ButtonLinedTertiary
                onClick={() => {
                  setDeliveryPriceType({ name: "", type: "" });
                  refetch();
                  setEdit(false);
                }}
                size={"lg"}
                btnText={"취소"}
              />
              <ButtonFilledPrimary
                disabled={!validCheck}
                onClick={() => {
                  handleDeliveryInfo();
                }}
                size={"lg"}
                btnText={"수정 완료"}
              />
            </div>
          ) : (
            <div>
              <ButtonLinedTertiary
                onClick={() => setEdit(true)}
                size={"lg"}
                btnText={"배송정보 수정"}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DeliveryInfo;
