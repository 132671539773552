import React, { useState } from "react";

import PageHeader from "components/Header/PageHeader";
import SearchInput from "components/Input/SearchInput";
import { CANCEL_ORDER_FOR_ADMIN } from "components/Table/TableData";
import Table from "components/Table/Table";

import { ReactComponent as Refresh } from "assets/refresh.svg";
import { useCancelListForAdmin } from "api/order/Admin";
import { dateFormat } from "utils/dateFormat";
import useDebounce from "hook/useDebounce";

import DatePicker from "react-datepicker";
import { PriceComma } from "utils/priceComma";
import Pagination from "components/Pagination/Pagination";
import { useStoreList } from "api/store";
import Dropdown from "components/Dropdown/Dropdown";

const CancelOrder = () => {
  const [currentPage, setCurrentPage] = useState(1);
  let pageItemLength = 10;
  const [storeInfo, setStoreInfo] = useState<{
    storeName: string;
    storeId: number;
  }>({
    storeName: "",
    storeId: 0,
  });
  const [startDate, setStartDate] = useState<null | Date>(null);
  const [endDate, setEndDate] = useState<null | Date>(null);
  const [search, setSearch] = useState<string>("");

  const [selectedDate, setSelectedDate] = useState<{
    startData: string;
    endDate: string;
  }>({ startData: "", endDate: "" });

  const debouncedSearchText = useDebounce(search, 500);

  const { data, refetch } = useCancelListForAdmin({
    companyId: storeInfo.storeId,
    search: debouncedSearchText,
    startDate: selectedDate.startData,
    endDate: selectedDate.endDate,
    page: currentPage - 1,
    size: pageItemLength,
  });

  const list = data?.data.ResultData.content;

  const { data: storeList } = useStoreList();

  const newStoreList = [
    { brandName: "전체", commerceName: "전체", companyId: 0 },
    ...(storeList?.data?.ResultData !== undefined
      ? storeList?.data?.ResultData
      : []),
  ];

  return (
    <div className="contentBox">
      <PageHeader
        noBorderB
        pageList={["주문 관리", "취소 관리"]}
        title={`주문 취소 관리`}
      />
      <div className="flex flex-col gap-5 pb-10 border-b border-Gray-40 min-w-[1020px] mt-10">
        <div className="flex items-center">
          <div className="py-2.5 min-w-[132px] text-Gray-90 SubH2 mr-6">
            상점
          </div>
          <Dropdown
            width="w-[311px]"
            innerText={
              storeInfo.storeName !== "" ? storeInfo.storeName : "전체"
            }
          >
            {newStoreList?.map((s, i) => {
              return (
                <li
                  key={i}
                  onClick={() => {
                    setCurrentPage(1)
                    setStoreInfo({
                      storeId: s.companyId,
                      storeName: s.commerceName,
                    });
                  }}
                  className="dropdownWrap"
                >
                  <div className="dropdownLi">{s.commerceName}</div>
                </li>
              );
            })}
          </Dropdown>
        </div>
        <div className="flex items-center">
          <div className="w-[132px] SubH2 mr-6">조회 기간(취소일)</div>
          <div className="flex items-center gap-2">
            <DatePicker
              className="flex items-center px-3 py-2 h-[52px] border border-Gray-40 Body4 text-Gray-90 rounded-lg"
              selected={startDate}
              placeholderText="시작일"
              onChange={(date: Date) => {
                setStartDate(date);
                setEndDate(null);
                setSelectedDate({
                  ...selectedDate,
                  startData: dateFormat(date).replaceAll(".", ""),
                  endDate: ""
                });
              }}
              selectsStart
              dateFormat="yyyy.MM.dd"
              startDate={startDate}
              endDate={endDate}
              />
            <div className="px-2">~</div>
            <DatePicker
              className="flex items-center px-3 py-2 h-[52px] border border-Gray-40 Body4 text-Gray-90 rounded-lg"
              placeholderText="종료일"
              selected={endDate}
              disabled={!startDate}
              onChange={(date: Date) => {
                setCurrentPage(1)
                setEndDate(date);
                setSelectedDate({
                  ...selectedDate,
                  endDate: dateFormat(date).replaceAll(".", ""),
                });
              }}
              selectsEnd
              dateFormat="yyyy.MM.dd"
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
            />
              {(startDate && endDate) &&
            <div
              className="flex items-center ml-4 cursor-pointer SubH2"
              onClick={() => {
                setCurrentPage(1)
                setStartDate(null)
                setEndDate(null)
                setSelectedDate({ startData: "", endDate: "" });
              }}
            >
              <Refresh className="mr-2" />
              <div>초기화</div>
            </div>
              }
          </div>
        </div>
      </div>
      <div className="py-4 mt-10 text-right border-t border-Gray-90 min-w-[1020px]">
        <SearchInput
          inputValue={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          placeholder="주문번호/상품명/송장번호/구매자명 등으로 검색"
          className="w-[350px] h-12"
        />
      </div>
      <Table COLUMNS={CANCEL_ORDER_FOR_ADMIN}>
        {list?.map((item, i) => {
          return (
            <tr
              key={i}
              className="last:border-none border-y Body4 border-Gray-40"
            >
              <td className="tableTd w-[120px]">{item.companyName}</td>
              <td className="break-normal tableTd">{item.cancelDate}</td>
              <td className="tableTd">{item.regDt}</td>
              <td className="tableTd">{item.orderNo}</td>
              <td className="tableTd min-w-[320px]">
                <div className="flex items-center gap-2">
                  <img
                    className="rounded w-11 h-11"
                    src={item.itemImagePath}
                    alt=""
                  />
                  <div className="max-w-[180px] textEll">{item.itemTitle}</div>
                </div>
              </td>
              <td className="tableTd h-[77px] max-w-[160px]">
                {item.optionStr ? item.optionStr : "-"}
              </td>
              <td className="tableTd h-[77px]">{item.count}</td>
              <td className="tableTd h-[77px]">{PriceComma(item.price)}원</td>
              <td className="tableTd h-[77px]">
                {item.name} ({item.nickName})
              </td>
            </tr>
          );
        })}
      </Table>
      <Pagination
        className="mt-4"
        itemsCount={data?.data.ResultData?.totalElements!}
        pageSize={pageItemLength}
        currPage={currentPage}
        setList={setCurrentPage}
      />
    </div>
  );
};

export default CancelOrder;
