import React, { ReactComponentElement } from "react";

interface Props {
  size: "sm" | "lg";
  icon?: ReactComponentElement<any>;
  text: string;
  className?: string;
  onClick?: () => void;
}

const TextBtnTertiary = ({ size, icon, text, onClick, className }: Props) => {
  return (
    <div className={`flex items-center ${className}`}>
      <div>{icon && icon}</div>
      <div
        onClick={onClick}
        className={`cursor-pointer ${
          size === "sm"
            ? "text-Gray-60 hover:text-Gray-80 SubH2"
            : "text-Gray-90 hover:text-Gray-80 SubH1"
        }
        ${icon && size === "sm" ? "ml-1" : icon && size === "lg" ? "ml-2" : ""}
        `}
      >
        {text}
      </div>
    </div>
  );
};

export default TextBtnTertiary;
