import React, { useState } from "react";
import { useReturnDetailForAdmin } from "api/order/Admin";
import ButtonFilledSecondary from "components/Button/ButtonFilledSecondary";
import ButtonLinedTertiary from "components/Button/ButtonLinedTertiary";
import PageHeader from "components/Header/PageHeader";
import NewStoreForm from "components/Store/NewStoreForm";
import NewStoreWrap from "components/Store/NewStoreWrap";
import ProdDetail from "components/Wrap/ProdDetail";

import { ReactComponent as Equal } from "assets/icEqual.svg";
import { ReactComponent as MinusGray } from "assets/MinusGray.svg";
import { ReactComponent as Plus } from "assets/icPlus.svg";
import { ReactComponent as Warning } from "assets/Warning.svg";

import { useNavigate, useParams } from "react-router-dom";
import { PriceComma } from "utils/priceComma";
import PopupImageView from "components/Modal/PopupImageView";
import requestToLoveEgg from "utils/requestToLoveEgg";
import TextLimitHorizontalInput from "components/Input/TextLimitHorizontalInput";
import ModalPopup from "components/Modal/ModalPopup";
import { toast } from "react-toastify";
import Dropdown from "components/Dropdown/Dropdown";
import TextFieldInput from "components/Input/TextFieldInput";
import { useDeliveryCompanyListApi } from "api/deliveryCompany";
import Radio from "components/Radio/Radio";

const AdminReturnOrderDetail = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [reason, setReason] = useState("");
  const [completeModal, setCompleteModal] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const [receiveInvoiceModal, setReceiveInvoiceModal] = useState(false);
  const [receiveModal, setReceiveModal] = useState(false);
  const [imagePop, setImagePop] = useState(false);
  const [receiveinVoiceNo, setReceiveInvoiceNo] = useState("");
  const [selectedReceiveDelivery, setSelectedReceiveDelivery] = useState<{
    cd: string;
    cdNm: string;
  }>({ cd: "", cdNm: "" });

  const { data, refetch } = useReturnDetailForAdmin(params.claimNo as string);

  const { data: deliveryData } = useDeliveryCompanyListApi();

  const deliveryList = deliveryData?.data.ResultData;

  const returnData = data?.data.ResultData;

  const returnCheck = () => {
    requestToLoveEgg({
      method: "POST",
      url: `/api/admin/v1/returns/${params.claimNo}/check`,
    }).then((res) => {
      if (res.data.ResultCode === 1) {
        refetch();
        toast.success(
          "‘반품 접수'로 상태 변경했습니다.\n[반품 관리>반품 접수]에서 확인 가능합니다"
        );
      }
    });
  };

  const receive = () => {
    requestToLoveEgg({
      method: "POST",
      url: `/api/admin/v1/returns/${params.claimNo}/receive`,
    }).then((res) => {
      if (res.data.ResultCode === 1) {
        refetch();
        setReceiveModal(false);
        toast.success(
          "‘반품 처리'로 상태 변경했습니다.\n[반품 관리>반품 처리]에서 확인 가능합니다."
        );
      }
    });
  };

  const returnCancel = () => {
    requestToLoveEgg({
      method: "POST",
      url: `/api/admin/v1/returns/${params.claimNo}/cancel`,
      data: {
        reason: reason,
      },
    }).then((res) => {
      if (res.data.ResultCode === 1) {
        navigate("/admin/order/return", { replace: true });
        toast.success(
          "반품 요청이 취소되었습니다.\n[일반 주문>배송완료] 메뉴에서 확인 가능합니다."
        );
      }
    });
  };

  const invoiceCollect = () => {
    // 입고 송장 번호 입력
    requestToLoveEgg({
      method: "POST",
      url: `/api/admin/v1/claim/${params.claimNo}/collect-info`,
      data: {
        invoiceNumber: receiveinVoiceNo,
        courierName: selectedReceiveDelivery.cdNm,
      },
    }).then((res) => {
      if (res.data.ResultCode === 1) {
        setReceiveInvoiceModal(false);
        toast.success(`택배사, 입고 송장번호 입력 완료되었습니다:)`);
        setReceiveInvoiceNo("");
        setSelectedReceiveDelivery({ cd: "", cdNm: "" });
        refetch();
      }
    });
  };

  const returnComplete = () => {
    requestToLoveEgg({
      method: "POST",
      url: `/api/admin/v1/returns/${params.claimNo}/complete`,
    }).then((res) => {
      if (res.data.ResultCode === 1) {
        toast.success(
          "‘반품 완료'로 상태 변경했습니다.\n[반품 관리>반품 완료]에서 확인 가능합니다."
        );
        setCompleteModal(false);
        refetch();
      }
    });
  };

  return (
    <div className="contentBox">
      {completeModal && (
        <ModalPopup
          type={"md"}
          buttonText={"환불처리"}
          cancalButton
          onClick={() => {
            returnComplete();
          }}
          setCloseState={setCompleteModal}
        >
          <div className="H4">해당 상품을 [환불 처리] 할까요?</div>
          <div className="Body2 text-Gray-80">
            <div className="mt-4">
              <div>
                처리시 <span className="text-Blue-100">반품 완료</span>되어
                되돌릴 수 없습니다.
              </div>
            </div>
          </div>
        </ModalPopup>
      )}
      {receiveInvoiceModal && (
        <ModalPopup
          onClick={() => {
            invoiceCollect();
          }}
          onClickCancelButton={() => {
            setReceiveInvoiceNo("");
            setSelectedReceiveDelivery({ cd: "", cdNm: "" });
          }}
          cancalButton
          setCloseState={setReceiveInvoiceModal}
          type={"md"}
          buttonText={"적용하기"}
        >
          <div className="H4">송장번호 입력</div>
          <div className="my-4 text-Gray-90 Body4">
            CS 접수번호 : {params?.claimNo}
          </div>
          <div className="mt-4 Body2 break-keep">
            송장번호를 입력하면 배송중 단계로 넘어갑니다. 택배사와 송장번호를
            정확하게 입력해 주세요. (구매고객에게는{" "}
            <span className="text-Blue-100">배송중</span>으로 보여집니다.)
          </div>
          <Dropdown
            innerText={
              selectedReceiveDelivery.cdNm
                ? selectedReceiveDelivery.cdNm
                : "선택"
            }
            width="w-full"
            className="mt-6"
          >
            {deliveryList?.map((d, i) => {
              return (
                <li
                  key={i}
                  onClick={() => {
                    setSelectedReceiveDelivery({ cd: d.cd, cdNm: d.cdNm });
                  }}
                  className="dropdownWrap"
                >
                  <div className="dropdownLi">{d.cdNm}</div>
                </li>
              );
            })}
          </Dropdown>
          <TextFieldInput
            inputValue={receiveinVoiceNo}
            onChange={(e) => {
              const { value } = e.target;
              setReceiveInvoiceNo(value.replace(/[^0-9]/g, ""));
            }}
            className="w-full mt-4"
          />
        </ModalPopup>
      )}
      {receiveModal && (
        <ModalPopup
          onClick={() => {
            receive();
          }}
          setCloseState={setReceiveModal}
          type={"md"}
          cancalButton
          buttonText={"입고 완료"}
        >
          <div className="mb-4 H4 text-Gray-100">
            해당 상품을 [입고 완료] 처리할까요?
          </div>
          <div className="whitespace-pre-wrap Body2">
            {"입고 완료 후에는 반품 처리 단계로 넘어갑니다.\n"}
            (구매고객에게 <span className="text-Blue-100">반품처리중</span>으로
            보여집니다.)
          </div>
        </ModalPopup>
      )}
      {cancelModal && (
        <ModalPopup
          setCloseState={setCancelModal}
          disabled={reason === ""}
          onClick={() => {
            returnCancel();
          }}
          type={"md"}
          cancalButton
          buttonText={"반품 취소하기"}
        >
          <div className="H4 text-Gray-100">반품 취소</div>
          <div className="my-4 Body4 text-Gray-90">
            반품 취소 시 고객의 상품 상태는 배송완료로 돌아갑니다. 취소 시
            되돌릴 수 없습니다. 정말 취소하시겠습니까?{" "}
          </div>
          <div className="Body4 text-Gray-90">
            반품 취소를 원하실 경우 사유를 작성해주세요.
          </div>
          <TextLimitHorizontalInput
            className="w-full mb-4 mt-9"
            onChange={(e) => {
              setReason(e.target.value);
            }}
            placeholder="취소 사유를 상세하게 작성해주세요"
            currentLength={reason?.length}
            row={7}
            maxLength={200}
          ></TextLimitHorizontalInput>
          <div className="flex items-center">
            <Warning className="mr-2.5" />
            <div className="flex-1 whitespace-pre-wrap Body5 text-Blue-100">
              {
                "지금 작성하는 취소 사유 내용은 고객에게 보여지는\n내용이니 신중하게 작성해주세요. (10자 이상)"
              }
            </div>
          </div>
        </ModalPopup>
      )}
      {imagePop && (
        <PopupImageView
          setCloseState={setImagePop}
          imageList={returnData?.claimDetail.detailImages}
        />
      )}
      <PageHeader
        noBorderB
        pageList={["주문 관리", "반품 관리", "반품 상세"]}
        title={`반품 상세`}
      />
      <NewStoreWrap noGap title="반품 접수">
        <NewStoreForm itemCenter title="CS 접수 번호">
          <div className="Body3 text-Gray-90">{returnData?.claimNo}</div>
        </NewStoreForm>
        <NewStoreForm itemCenter title="주문번호">
          <div className="Body3 text-Gray-90">{returnData?.originOrderNo}</div>
        </NewStoreForm>
        <div className="my-4 border-b border-Gray-40" />
        <NewStoreForm itemCenter title="반품신청일시">
          <div className="Body3 text-Gray-90">{returnData?.regDt}</div>
        </NewStoreForm>
        <NewStoreForm itemCenter title="주문자 (닉네임)">
          <div className="Body3 text-Gray-90">
            {returnData?.name} ({returnData?.nickName})
          </div>
        </NewStoreForm>
        <NewStoreForm itemCenter title="연락처">
          <div className="Body3 text-Gray-90">{returnData?.mobileNo}</div>
        </NewStoreForm>
      </NewStoreWrap>
      <ProdDetail title={"반품 상품 정보"}>
        <div className="flex items-center gap-1">
          {returnData?.originItem.status === "RETURN_APPLY" ? (
            <>
              <ButtonFilledSecondary
                className="h-[38px]"
                onClick={() => {
                  returnCheck();
                }}
                size={"md"}
                btnText={"반품 접수"}
              />
              <ButtonLinedTertiary
                onClick={() => {
                  setCancelModal(true);
                }}
                className="h-[38px]"
                size={"md"}
                btnText={"반품 취소"}
              />
            </>
          ) : returnData?.originItem.status === "RETURN_CHECK" ? (
            <ButtonFilledSecondary
              className="h-[38px]"
              onClick={() => {
                setReceiveModal(true);
              }}
              size={"md"}
              btnText={"입고 완료"}
            />
          ) : returnData?.originItem.status === "RETURN_RECEIVE" ? (
            <ButtonFilledSecondary
              className="h-[38px]"
              onClick={() => {
                setCompleteModal(true);
              }}
              size={"md"}
              btnText={"환불 처리"}
            />
          ) : (
            ""
          )}
        </div>
      </ProdDetail>
      <div className="overflow-scroll max-h-[600px] min-w-[1020px] visibleScroll mb-[60px]">
        <table className="w-full border-y whitespace-nowrap border-Gray-40">
          <thead>
            <tr>
              <th className="SubH2 py-2.5 bg-Gray-20 border-Gray-20 border-b px-3 text-left">
                상품정보
              </th>
              <th className="SubH2 py-2.5 bg-Gray-20 border-Gray-20 border-b px-3 text-left">
                옵션정보
              </th>
              <th className="SubH2 py-2.5 bg-Gray-20 border-Gray-20 border-b px-3 text-center">
                수량
              </th>
              <th className="SubH2 py-2.5 bg-Gray-20 border-Gray-20 border-b px-3 text-center">
                가격
              </th>
              <th className="SubH2 py-2.5 bg-Gray-20 border-Gray-20 border-b px-3 text-center">
                상태
              </th>
              <th className="SubH2 py-2.5 bg-Gray-20 border-Gray-20 border-b px-3 text-center">
                주문 처리 이력
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="Body4">
              <td className="tableTd w-[838px]">
                <div className="flex items-center gap-2">
                  <img
                    className="rounded w-11 h-11"
                    src={returnData?.originItem?.imgPath}
                    alt=""
                  />
                  <div>{returnData?.originItem.title}</div>
                </div>
              </td>
              <td className="flex tableTd min-h-[78px] flex-wrap w-[160px] break-all">
                {returnData?.originItem.optionGroups.length !== 0
                  ? returnData?.originItem.optionGroups.map((o, i) => {
                      if (
                        returnData?.originItem.optionGroups.length ===
                        i + 1
                      ) {
                        return (
                          <div key={i} className="flex flex-col justify-center">
                            {o.options[0].title}{" "}
                            {o.options[0].addPrice === 0
                              ? ""
                              : `(+ ${PriceComma(o.options[0].addPrice)})`}
                          </div>
                        );
                      } else {
                        return (
                          <div key={i} className="flex flex-col justify-center">
                            {o.options[0].title}{" "}
                            {o.options[0].addPrice === 0
                              ? ""
                              : `(+ ${PriceComma(o.options[0].addPrice)})`}{" "}
                            /{" "}
                          </div>
                        );
                      }
                    })
                  : <div className="tableTd">
                  -
                </div>}
              </td>
              <td className="text-center tableTd">
                {returnData?.originItem.count}
              </td>
              <td className="tableTd">
                {PriceComma(returnData?.originItem.price!)}원
              </td>
              <td className="text-center tableTd">
                {returnData?.originItem.status === "RETURN_APPLY"
                  ? "반품신청"
                  : returnData?.originItem.status === "RETURN_CHECK"
                  ? "반품접수"
                  : returnData?.originItem.status === "RETURN_RECEIVE"
                  ? "반품상품입고"
                  : "반품완료"}
              </td>
              <td className="text-center cursor-pointer tableTd text-Gray-60">
                이력보기
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <NewStoreWrap title={"반품 상세 내역"}>
        <NewStoreForm itemCenter title="반품 사유">
          <div className="Body3 text-Gray-90">
            {returnData?.claimDetail.reason === "CHANGE_OF_HEART"
              ? "상품이 만족스럽지 않음"
              : returnData?.claimDetail.reason === "WRONG_ORDER"
              ? "다른 옵션을 잘못 주문"
              : returnData?.claimDetail.reason === "DELIVERY_OMITTED"
              ? "상품 오배송 또는 구성품 누락"
              : returnData?.claimDetail.reason === "PRODUCT_DEFECT"
              ? "상품 파손 또는 불량"
              : ""}
          </div>
        </NewStoreForm>
        <NewStoreForm title="반품 사유">
          <div className="Body3 text-Gray-90 py-[15px]">
            <div>
              {returnData?.claimDetail.reasonDetail
                ? returnData?.claimDetail.reasonDetail
                : "-"}
            </div>
            {returnData?.claimDetail.detailImages.length !== 0 && (
              <div className="mt-2.5 flex items-center gap-2">
                {returnData?.claimDetail.detailImages.map((image, i) => {
                  return (
                    <img
                      onClick={() => {
                        setImagePop(true);
                      }}
                      className="w-10 h-10 rounded"
                      src={image}
                      key={i}
                      alt=""
                    />
                  );
                })}
              </div>
            )}
          </div>
        </NewStoreForm>
        <NewStoreForm itemCenter title="반품 배송비">
          <div className="Body3 text-Gray-90">
            {returnData?.claimDetail.deliveryPrice! +
              returnData?.claimDetail.additionalDeliveryPrice!}
          </div>
        </NewStoreForm>
      </NewStoreWrap>
      <NewStoreWrap title={"반품 입고 정보"}>
        <NewStoreForm itemCenter title="상품 입고 방법">
          <div className="Body3 text-Gray-90">
            {returnData?.collectionType === "COMPANY" ? "업체수거" : "직접수거"}
          </div>
        </NewStoreForm>
        <NewStoreForm itemCenter title="입고 상품 송장번호">
          <div className="Body3 text-Gray-90">
            {returnData?.originItem.status === "RETURN_CHECK" &&
            returnData?.returnInvoiceCourierName === "" &&
            returnData?.returnInvoiceNumber === "" ? (
              <ButtonLinedTertiary
                onClick={() => {
                  setReceiveInvoiceModal(true);
                }}
                size={"sm"}
                btnText={"송장번호 입력"}
              />
            ) : (
              <>
                <div>{returnData?.returnInvoiceCourierName}</div>
                <div>{returnData?.returnInvoiceNumber}</div>
              </>
            )}
          </div>
        </NewStoreForm>
        <NewStoreForm itemCenter title="교환/반품지">
          <div className="Body3 text-Gray-90">
            <div>
              {returnData?.returnExchangePlaceZip
                ? `[${returnData?.returnExchangePlaceZip}]`
                : ""}
            </div>
            <div>
              {returnData?.returnExchangePlaceAddr}{" "}
              {returnData?.returnExchangePlaceDetailAddr
                ? `,${returnData?.returnExchangePlaceDetailAddr}`
                : ""}
            </div>
          </div>
        </NewStoreForm>
        <div className="my-4 border-b border-Gray-40" />
        <NewStoreForm itemCenter title="수령인 정보">
          <div className="Body3 text-Gray-90">{returnData?.name}</div>
        </NewStoreForm>
        <NewStoreForm itemCenter title="수령인 연락처">
          <div className="Body3 text-Gray-90">{returnData?.mobileNo}</div>
        </NewStoreForm>
        <NewStoreForm itemCenter title="수령인 배송지">
          <div className="Body3 text-Gray-90">
            <div>
              {returnData?.receiverZip ? `[${returnData?.receiverZip}]` : ""}
            </div>
            <div>
              {returnData?.receiverAddress}{" "}
              {returnData?.receiverDetailAddress
                ? `,${returnData?.receiverDetailAddress}`
                : ""}
            </div>
          </div>
        </NewStoreForm>
      </NewStoreWrap>
      <NewStoreWrap title={"판매자 예상 정산 정보"}>
        <div className="flex items-center gap-5">
          <div className="flex-1">
            <div className="Body2 text-Gray-80">상품 판매가 (입고상품)</div>
            <div className="H4 text-Gray-90">
              {PriceComma(returnData?.calculateInfo.price!)}원
            </div>
          </div>
          <MinusGray />
          <div className="flex-1">
            <div className="Body2 text-Gray-80">
              수수료 ({returnData?.calculateInfo.fee}%)
            </div>
            <div className="H4 text-Gray-90">
              {PriceComma(
                Math.round(
                  returnData?.calculateInfo.price! *
                    (returnData?.calculateInfo.fee! * 0.01)
                )
              )}
              원
            </div>
          </div>
          <Plus />
          <div className="flex-1">
            <div className="Body2 text-Gray-80">배송비</div>
            <div className="H4 text-Gray-90">
              {PriceComma(
                returnData?.calculateInfo.deliveryPrice! +
                  returnData?.calculateInfo.additionalDeliveryPrice!
              )}
              원
            </div>
          </div>
          <Equal />
          <div className="flex-1">
            <div className="Body2 text-Gray-80">조정금액</div>
            <div className="H2 text-Blue-100">
              {PriceComma(
                returnData?.calculateInfo.price! -
                  Math.round(
                    returnData?.calculateInfo.price! *
                      (returnData?.calculateInfo.fee! * 0.01)
                  ) +
                  returnData?.calculateInfo.deliveryPrice! +
                  returnData?.calculateInfo.additionalDeliveryPrice!
              )}
              원
            </div>
          </div>
        </div>
      </NewStoreWrap>
    </div>
  );
};

export default AdminReturnOrderDetail;
