import React, { useState } from "react";

import ButtonFilledSecondary from "components/Button/ButtonFilledSecondary";
import ButtonLinedTertiary from "components/Button/ButtonLinedTertiary";
import PageHeader from "components/Header/PageHeader";
import NewStoreForm from "components/Store/NewStoreForm";
import NewStoreWrap from "components/Store/NewStoreWrap";
import ProdDetail from "components/Wrap/ProdDetail";
import { useNavigate, useParams } from "react-router-dom";
import { PriceComma } from "utils/priceComma";

import { ReactComponent as Equal } from "assets/icEqual.svg";
import { ReactComponent as Minus } from "assets/icMinus.svg";
import { ReactComponent as MinusGray } from "assets/MinusGray.svg";
import { ReactComponent as Plus } from "assets/icPlus.svg";
import ButtonFilledPrimary from "components/Button/ButtonFilledPrimary";
import requestToLoveEgg from "utils/requestToLoveEgg";
import ModalPopup from "components/Modal/ModalPopup";
import { useDeliveryCompanyListApi } from "api/deliveryCompany";
import Dropdown from "components/Dropdown/Dropdown";
import TextFieldInput from "components/Input/TextFieldInput";
import { toast } from "react-toastify";
import { useOrderDetailForAdmin } from "api/order/Admin";
import PopupConfirm from "components/Modal/PopupConfirm";

const OrderDetail = () => {
  const navigate = useNavigate();

  const [orderCheck, setOrderCheck] = useState(false);
  const [invoice, setInvoice] = useState(false);
  const [history, setHistory] = useState(false);
  const [historyIteminfo, setHistoryItemInfo] = useState<{
    name: string;
    option: string;
    imgUrl: string;
    status: string;
  }>({
    name: "",
    option: "",
    imgUrl: "",
    status: "",
  });
  const [orderNoList, setOrderNoList] = useState<string[]>([]);
  const [orderNo, setOrderNo] = useState<string>("");
  const [invoiceNo, setInvoiceNo] = useState<string>("");
  const [invoiceText, setInvoiceText] = useState("");
  const [preInvoice, setPreInvoice] = useState({
    courierName: "",
    invoice: "",
  });
  const [selectedDelivery, setSelectedDelivery] = useState<{
    cd: string;
    cdNm: string;
  }>({ cd: "", cdNm: "" });

  const param = useParams();

  const orderId = param.orderId as string;

  const { data, refetch } = useOrderDetailForAdmin(orderId);
  const detailData = data?.data.ResultData;

  const { data: delivery } = useDeliveryCompanyListApi();

  const deliveryList = delivery?.data.ResultData;

  return (
    <div className="contentBox">
      {history && (
        <PopupConfirm
          title="주문 처리 이력"
          onClick={() => {
            setHistoryItemInfo({
              name: "",
              imgUrl: "",
              status: "",
              option: "",
            });
          }}
          setCloseState={setHistory}
        >
          <div className="px-5 py-4 border border-Gray-40 w-[640px] flex flex-col gap-1 rounded mb-10">
            <div className="SubH2 text-Gray-60">
              {historyIteminfo.status === "ORDER_CHECK"
                ? "상품 준비중"
                : historyIteminfo.status === "DELIVERY_READY"
                ? "배송 준비중"
                : historyIteminfo.status === "DELIVERY_IN_PROGRESS"
                ? "배송중"
                : historyIteminfo.status === "DELIVERY_COMPLETE"
                ? "배송완료"
                : ""}
            </div>
            <div className="flex items-center Body4 text-Gray-90">
              <img
                className="w-10 h-10 rounded"
                src={historyIteminfo.imgUrl}
                alt=""
              />
              <div className="ml-2 mr-3 textEll max-w-[392px]">
                {historyIteminfo.name}
              </div>
              <div>{historyIteminfo.option}</div>
            </div>
          </div>
          <div>
            <div className="w-full py-3 border-b border-Gray-100">
              주문 처리 이력
            </div>
            <div className="py-4">
              <NewStoreForm itemCenter title="주문완료">
                <div className="Body3 text-Gray-90">{detailData?.regDt}</div>
              </NewStoreForm>
              <NewStoreForm itemCenter title="발주확인">
                <div className="Body3 text-Gray-90">{detailData?.regDt}</div>
              </NewStoreForm>
              <NewStoreForm itemCenter title="배송시작">
                <div className="Body3 text-Gray-90">{detailData?.regDt}</div>
              </NewStoreForm>
              <NewStoreForm itemCenter title="배송완료">
                <div className="Body3 text-Gray-90">{detailData?.regDt}</div>
              </NewStoreForm>
              <NewStoreForm itemCenter title="교환요청">
                <div className="Body3 text-Gray-90">{detailData?.regDt}</div>
              </NewStoreForm>
              <NewStoreForm itemCenter title="교환접수">
                <div className="Body3 text-Gray-90">{detailData?.regDt}</div>
              </NewStoreForm>
              <NewStoreForm itemCenter title="교환취소">
                <div className="Body3 text-Gray-90">{detailData?.regDt}</div>
              </NewStoreForm>
              <NewStoreForm itemCenter title="구매확정">
                <div className="Body3 text-Gray-90">{detailData?.regDt}</div>
              </NewStoreForm>
            </div>
          </div>
        </PopupConfirm>
      )}
      {invoice && (
        <ModalPopup
          type={"md"}
          onClickCancelButton={() => {
            setInvoiceNo("");
            setOrderNo("");
            setSelectedDelivery({ cd: "", cdNm: "" });
            setPreInvoice({ courierName: "", invoice: "" });
          }}
          onClick={() => {
            requestToLoveEgg({
              method: "POST",
              url: `/api/admin/v1/orders/${orderNo}/invoice-number`,
              data: {
                invoiceNumber: invoiceNo,
                courierName: selectedDelivery.cdNm,
              },
            }).then((res) => {
              if (res.data.ResultCode === 1) {
                refetch();
                setInvoice(false);
                toast.success(
                  `택배사, 송장번호 ${invoiceText} 완료되었습니다:)`
                );
                setInvoiceNo("");
                setSelectedDelivery({ cd: "", cdNm: "" });
              } else {
                alert("에러");
              }
            });
          }}
          cancalButton
          setCloseState={setInvoice}
          buttonText={"적용하기"}
        >
          <div className="H4">송장번호 {invoiceText}</div>
          <div className="mt-4 Body4">주문번호: {orderNo}</div>
          {invoiceText === "수정" ? (
            <>
              <div className="Body4 text-Gray-90">
                <div className="mb-4">
                  기존 송장번호 : {preInvoice.courierName} {preInvoice.invoice}
                </div>
                <div>수정할 택배사와 송장번호를 정확하게 입력해 주세요.</div>
              </div>
            </>
          ) : (
            <div className="mt-4 Body2 break-keep">
              송장번호를 입력하면 배송중 단계로 넘어갑니다. 택배사와 송장번호를
              정확하게 입력해 주세요. (구매고객에게는{" "}
              <span className="text-Blue-100">배송중</span>으로 보여집니다.)
            </div>
          )}
          <Dropdown
            innerText={selectedDelivery.cdNm ? selectedDelivery.cdNm : "선택"}
            width="w-full"
            className="mt-6"
          >
            {deliveryList?.map((d, i) => {
              return (
                <li
                  key={i}
                  onClick={() => {
                    setSelectedDelivery({ cd: d.cd, cdNm: d.cdNm });
                  }}
                  className="dropdownWrap"
                >
                  <div className="dropdownLi">{d.cdNm}</div>
                </li>
              );
            })}
          </Dropdown>
          <TextFieldInput
            inputValue={invoiceNo}
            onChange={(e) => {
              const { value } = e.target;
              setInvoiceNo(value.replace(/[^0-9]/g, ""));
            }}
            className="w-full mt-4"
          />
        </ModalPopup>
      )}
      {orderCheck && (
        <ModalPopup
          onClick={() => {
            requestToLoveEgg({
              method: "POST",
              url: "/api/admin/v1/orders/check",
              data: {
                orderNoList: orderNoList,
              },
            }).then((res) => {
              if (res.data.ResultCode === 1) {
                toast.success(
                  "‘배송 준비중'으로 상태 변경했습니다.\n[배송 준비중]에서 확인 가능합니다."
                );
                setOrderNoList([]);
                setOrderCheck(false);
                refetch();
              } else {
                alert("에러");
              }
            });
          }}
          onClickCancelButton={() => {
            setOrderNoList([]);
          }}
          setCloseState={setOrderCheck}
          type={"md"}
          buttonText={"발주 확인"}
          cancalButton
        >
          <div className="H4">전체 주문을 [발주 확인] 처리할까요?</div>
          <div className="mt-4 break-keep Body2">
            발주 확인 후에는 배송준비중 단계로 넘어갑니다. (구매고객에게{" "}
            <span className="text-Blue-100">배송준비중</span>으로 보여집니다.)
          </div>
        </ModalPopup>
      )}
      <PageHeader
        noBorderB
        pageList={["주문 관리", "일반 주문", "주문상세"]}
        title={`주문 상세 내역`}
      />
      <NewStoreWrap noGap title="주문 정보">
        <NewStoreForm itemCenter title="주문일시">
          <div className="Body3 text-Gray-90">{detailData?.regDt}</div>
        </NewStoreForm>
        <NewStoreForm itemCenter title="주문자 (닉네임)">
          <div className="Body3 text-Gray-90">
            {detailData?.purchaserName} ({detailData?.purchaserNickName})
          </div>
        </NewStoreForm>
        <NewStoreForm itemCenter title="연락처">
          <div className="Body3 text-Gray-90">
            {detailData?.purchaserMobileNo}
          </div>
        </NewStoreForm>
      </NewStoreWrap>
      {detailData?.orders.map((o, i) => {
        return (
          <div key={i}>
            <ProdDetail
              title={`${
                detailData?.orders.length > 1
                  ? `${String.fromCharCode(65 + i)}. `
                  : ""
              }'${o.companyName}' 주문상품`}
            >
              {o.orderStatus === "ORDER_CHECK" ? (
                // 상품준비중
                <div>
                  <ButtonFilledSecondary
                    onClick={() => {
                      deliveryList?.map((d) => {
                        if (d.cdNm === o.courierName) {
                          setSelectedDelivery({ cd: d.cd, cdNm: d.cdNm });
                        }
                      });
                      setOrderNoList([o.orderNo]);
                      setOrderCheck(true);
                    }}
                    size={"md"}
                    btnText={"발주 확인"}
                  />
                  {/* orderNo */}
                </div>
              ) : o.orderStatus === "DELIVERY_READY" ? (
                // 배송준비중
                <div>
                  <ButtonFilledSecondary
                    size={"md"}
                    onClick={() => {
                      setInvoice(true);
                      setOrderNo(o.orderNo);
                      setInvoiceText("입력");
                    }}
                    btnText={`송장번호 일괄 업로드`}
                  />
                </div>
              ) : o.orderStatus === "DELIVERY_IN_PROGRESS" ? (
                // 배송중
                <div className="flex items-center gap-2">
                  <ButtonFilledSecondary
                    size={"md"}
                    onClick={() => {
                      deliveryList?.map((d) => {
                        if (d.cdNm === o.courierName) {
                          setSelectedDelivery({ cd: d.cd, cdNm: d.cdNm });
                        }
                      });
                      setInvoice(true);
                      setOrderNo(o.orderNo);
                      setInvoiceText("수정");
                      setPreInvoice({
                        courierName: o.courierName,
                        invoice: o.invoiceNumber,
                      });
                    }}
                    btnText={"송장번호 일괄 수정"}
                  />
                  <ButtonLinedTertiary size={"md"} btnText={"배송조회"} />
                </div>
              ) : (
                // 배송완료, 교환/반품 있는경우
                <div>
                  <ButtonLinedTertiary size={"md"} btnText={"배송조회"} />
                </div>
              )}
            </ProdDetail>
            <div className="overflow-scroll max-h-[600px] min-w-[1020px] visibleScroll mb-[60px]">
              <table className="w-full border-y whitespace-nowrap border-Gray-40">
                <thead>
                  <tr>
                    <th className="SubH2 py-2.5 bg-Gray-20 border-Gray-20 border-b px-3 text-left">
                      주문번호: {o.orderNo}
                    </th>
                    <th className="SubH2 py-2.5 bg-Gray-20 border-Gray-20 border-b px-3 text-left">
                      옵션정보
                    </th>
                    <th className="SubH2 py-2.5 bg-Gray-20 border-Gray-20 border-b px-3 text-center">
                      수량
                    </th>
                    <th className="SubH2 py-2.5 bg-Gray-20 border-Gray-20 border-b px-3 text-center">
                      가격
                    </th>
                    <th className="SubH2 py-2.5 bg-Gray-20 border-Gray-20 border-b px-3 text-center">
                      상태
                    </th>
                    <th className="SubH2 py-2.5 bg-Gray-20 border-Gray-20 border-b px-3 text-left">
                      배송정보
                    </th>
                    <th className="SubH2 py-2.5 bg-Gray-20 border-Gray-20 border-b px-3 text-center">
                      주문 처리 이력
                    </th>
                    <th className="SubH2 py-2.5 bg-Gray-20 border-Gray-20 border-b px-3 text-left"></th>
                  </tr>
                </thead>
                <tbody>
                  {o.orderItems.map((item, i) => {
                    return (
                      <tr key={item.orderItemId} className="Body4">
                        <td className="tableTd max-w-[648px]">
                          <div className="flex items-center gap-2">
                            <img
                              className="rounded w-11 h-11"
                              src={item.thumbnail}
                              alt=""
                            />
                            <div>{item.title}</div>
                          </div>
                        </td>
                        <td className="tableTd">{item.optionStr ? item.optionStr : "-"}</td>
                        <td className="text-center tableTd">{item.count}</td>
                        <td className="text-center tableTd">
                          {PriceComma(item.price)}원
                        </td>
                        <td className="text-center tableTd">
                          {item.itemStatus === "ORDER_CHECK" ? (
                            <div className="SubH2 text-Gray-80">상품준비중</div>
                          ) : item.itemStatus === "DELIVERY_READY" ? (
                            <div className="SubH2 text-Gray-90">배송준비중</div>
                          ) : item.itemStatus === "DELIVERY_IN_PROGRESS" ? (
                            <div className="SubH2 text-Gray-90">배송중</div>
                          ) : item.itemStatus === "DELIVERY_COMPLETE" ? (
                            <div className="SubH2 text-Blue-100">배송완료</div>
                          ) : item.itemStatus === "CANCEL" ? (
                            <div className="SubH2 text-Red100">취소</div>
                          ) : (
                            ""
                          )}
                        </td>
                        <td className="text-left tableTd text-Gray-90">
                          <div>{item.courierName}</div>
                          <div>
                            {item.invoiceNumber ? item.invoiceNumber : "-"}
                          </div>
                        </td>
                        <td
                          onClick={() => {
                            setHistory(true);
                            setHistoryItemInfo({
                              name: item.title,
                              option: item.optionStr,
                              imgUrl: item.thumbnail,
                              status: item.itemStatus,
                            });
                          }}
                          className="text-center cursor-pointer tableTd text-Gray-60"
                        >
                          이력보기
                        </td>
                        <td className="text-center tableTd text-Gray-60">
                          {item.itemStatus === "ORDER_CHECK" ||
                          item.itemStatus === "DELIVERY_READY" ? (
                            <ButtonLinedTertiary
                              size={"md"}
                              className="mx-auto"
                              btnText={"주문취소"}
                            />
                          ) : (
                            // <ButtonLinedTertiary size={'sm'} btnText={'교환상세'}/>
                            // <ButtonLinedTertiary size={'sm'} btnText={'반품상세'}/>
                            "-"
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        );
      })}
      <NewStoreWrap noGap title="배송 정보">
        <NewStoreForm itemCenter title="수령인 정보">
          <div className="Body3 text-Gray-90">
            {detailData?.deliveryInfo.receiverName}
          </div>
        </NewStoreForm>
        <NewStoreForm itemCenter title="수령인 연락처">
          <div className="Body3 text-Gray-90">
            {detailData?.deliveryInfo.receiverMobileNo}
          </div>
        </NewStoreForm>
        <NewStoreForm itemCenter title="배송지">
          <div className="Body3 text-Gray-90">
            <div>[{detailData?.deliveryInfo.receiverZip}]</div>
            <div>
              {detailData?.deliveryInfo.receiverAddress}
              {detailData?.deliveryInfo.receiverDetailAddress
                ? `, ${detailData?.deliveryInfo.receiverDetailAddress}`
                : ""}
            </div>
          </div>
        </NewStoreForm>
        <NewStoreForm itemCenter title="배송 요청사항">
          <div className="Body3 text-Gray-90">
            {detailData?.deliveryInfo.requestMessage
              ? detailData?.deliveryInfo.requestMessage
              : "-"}
          </div>
        </NewStoreForm>
      </NewStoreWrap>
      <NewStoreWrap noPy noGap title="결제 정보">
        <div>
          <div className="flex items-center">
            <div className="flex items-center justify-between w-1/3 h-20 py-4 pl-5 pr-3">
              <div className="SubH2 text-Gray-90">총 상품 금액</div>
              <div className="flex items-center gap-2">
                <div className="H3 text-Gray-90">
                  {PriceComma(
                    (detailData?.paymentInfo.totalPrice! +
                      detailData?.paymentInfo.deliveryPrice!) as number
                  )}
                  원
                </div>
                <Minus />
              </div>
            </div>
            <div className="flex items-center justify-between w-1/3 h-20 py-4 pl-5 pr-3">
              <div className="SubH2 text-Gray-90">할인 금액</div>
              <div className="flex items-center gap-2">
                <div className="H3 text-Gray-90">
                  {PriceComma(
                    detailData?.paymentInfo.usePoint! +
                      detailData?.paymentInfo.couponPrice!
                  )}
                  원
                </div>
                <Equal />
              </div>
            </div>
            <div className="flex items-center justify-between w-1/3 h-20 py-4 pl-5 pr-3">
              <div className="SubH2 text-Gray-90">총 결제 금액</div>
              <div className="flex items-center gap-2">
                <div className="H3 text-Gray-90">
                  {PriceComma(
                    detailData?.paymentInfo.totalPrice! +
                      detailData?.paymentInfo.deliveryPrice! -
                      (detailData?.paymentInfo.usePoint! +
                        detailData?.paymentInfo.couponPrice!)
                  )}
                  원
                </div>
              </div>
            </div>
          </div>
        </div>
        <div></div>
        <div className="flex items-start bg-Gray-20">
          <div className="w-1/3 h-full p-5 pr-[52px]">
            {detailData?.orders.map((item, i) => {
              return (
                <div
                  className="flex flex-col gap-4 mb-4 last:mb-0"
                  key={item.orderNo}
                >
                  <div className="flex items-center justify-between">
                    <div className="SubH2 text-Gray-60 w-[120px]">
                      {detailData?.orders.length > 1
                        ? `(${String.fromCharCode(65 + i)})`
                        : ""}{" "}
                      상품결제금액
                    </div>
                    <div className="Body3 text-Gray-90">
                      {PriceComma(item.totalPrice)}원
                    </div>
                  </div>
                  <div className="flex items-center justify-between">
                    <div className="SubH2 text-Gray-60 w-[120px]">
                      {detailData?.orders.length > 1
                        ? `(${String.fromCharCode(65 + i)})`
                        : ""}{" "}
                      배송비
                    </div>
                    <div className="Body3 text-Gray-90">
                      {PriceComma(item.deliveryPrice)}원
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="w-1/3 h-full p-5 pr-[52px]">
            <div className="flex flex-col gap-4">
              <div className="flex items-center justify-between">
                <div className="SubH2 text-Gray-60 w-[120px]">포인트</div>
                <div className="Body3 text-Gray-90">
                  {PriceComma(detailData?.paymentInfo.usePoint!)}Lp
                </div>
              </div>
              <div className="flex items-start justify-between">
                <div className="SubH2 text-Gray-60 w-[120px]">쿠폰</div>
                <div className="text-right Body3 text-Gray-90">
                  <div>
                    {PriceComma(detailData?.paymentInfo.couponPrice!)}원
                  </div>
                  <div className="Body5 text-Blue-100">
                    {detailData?.paymentInfo.coupons[0]?.couponName}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-1/3 h-full p-5">
            <div className="flex flex-col gap-4">
              <div className="flex items-start justify-between">
                <div className="SubH2 text-Gray-60 w-[120px]">결제수단</div>
                <div className="flex-1 text-right Body3 text-Gray-90 break-keep">
                  {detailData?.paymentInfo.cardName}{" "}
                  {detailData?.paymentInfo.cardNumber && "("}
                  {detailData?.paymentInfo.cardNumber.substring(0, 4)}
                  {detailData?.paymentInfo.cardNumber && "-"}
                  {detailData?.paymentInfo.cardNumber.substring(4, 6)}
                  {detailData?.paymentInfo.cardNumber && "**-****-****)"}{" "}
                  {detailData?.paymentInfo.cardNumber ? "/" : "-"}{" "}
                  {detailData?.paymentInfo.cardQuota}
                </div>
              </div>
              <div className="flex items-center justify-between">
                <div className="SubH2 text-Gray-60 w-[120px]">
                  적립 예정 포인트
                </div>
                <div className="Body3 text-Gray-90">
                  {PriceComma(detailData?.paymentInfo.savePoint!)}Lp
                </div>
              </div>
            </div>
          </div>
        </div>
      </NewStoreWrap>
      {detailData?.calculateInfo.map((c, i) => {
        return (
          <NewStoreWrap
            key={i}
            title={`${
              detailData?.calculateInfo.length > 1
                ? `${String.fromCharCode(65 + i)}.`
                : ""
            }'${c.companyName}' 예상 정산 정보`}
          >
            <div className="flex items-center gap-5">
              <div className="flex-1">
                <div className="Body2 text-Gray-80">상품 판매가</div>
                <div className="H4 text-Gray-90">
                  {PriceComma(c.totalPrice)}원
                </div>
              </div>
              <MinusGray />
              <div className="flex-1">
                <div className="Body2 text-Gray-80">
                  수수료({c.fee as number}%)
                </div>
                <div className="H4 text-Gray-90">
                  {PriceComma(Math.round(c.totalPrice * (c.fee * 0.01)))}원
                </div>
              </div>
              <Plus />
              <div className="flex-1">
                <div className="Body2 text-Gray-80">배송비</div>
                <div className="H4 text-Gray-90">
                  {PriceComma(c.deliveryPrice)}원
                </div>
              </div>
              <Equal />
              <div className="flex-1">
                <div className="Body2 text-Gray-80">예상 정산금</div>
                <div className="H2 text-Blue-100">
                  {PriceComma(
                    Math.round(
                      c.totalPrice -
                        c.totalPrice * (c.fee * 0.01) +
                        c.deliveryPrice
                    )
                  )}
                  원
                </div>
              </div>
            </div>
          </NewStoreWrap>
        );
      })}
      <div className="fixed bottom-0 left-0 bg-Gray-10/70 backdrop-blur-[12px] z-[102] w-full py-5 border-t border-Gray-40">
        <div className="flex items-center pl-[260px] pr-[160px] justify-between max-w-[1920px] mx-auto min-w-[1440px]">
          <div className="Body2">일반 주문 {">"} 주문 상세</div>
          <ButtonFilledPrimary
            onClick={() => {
              navigate(-1);
            }}
            size={"lg"}
            btnText={"확인"}
          />
        </div>
      </div>
    </div>
  );
};

export default OrderDetail;
