import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Layout from "components/Layout";
import { Route, Routes } from "react-router-dom";
import Login from "pages/Login/Login";
import NewStore from "pages/Store/NewStore";
import AllStore from "pages/Store/Admin/AllStore";
import EntryStore from "pages/Store/Admin/EntryStore";
import StoreInfoHistory from "pages/Store/Admin/StoreInfoHistory";
import CheckStore from "pages/Store/CheckStore";
import CheckingUserRouter from "router/checkingUserRouter";
import AdminRouter from "router/adminRoute";
import GuestRouter from "router/guestRouter";
import RequestStore from "pages/Store/Admin/RequestStore";
import SettingStore from "pages/Store/User/SettingStore";
import UserRouter from "router/userRouter";
import SettingAccount from "pages/Store/User/SettingAccount";
import AllProduct from "pages/Store/User/AllProduct";
import AddProduct from "pages/Store/User/addProduct";
import EditProduct from "pages/Store/User/editProduct";
import GeneralOrder from "pages/Order/User/GeneralOrder";
import GiftOrder from "pages/Order/User/GiftOrder";
import ExchangeOrder from "pages/Order/User/ExchangeOrder";
import ReturnOrder from "pages/Order/User/ReturnOrder";
import CancelOrder from "pages/Order/User/CancelOrder";
import AdminGeneralOrder from "pages/Order/Admin/AdminGeneralOrder";
import AdminCancelOrder from "pages/Order/Admin/AdminCancelOrder";
import AdminExchangeOrder from "pages/Order/Admin/AdminExchangeOrder";
import AdminGiftOrder from "pages/Order/Admin/AdminGiftOrder";
import AdminReturnOrder from "pages/Order/Admin/AdminReturnOrder";
import OrderDetail from "pages/Order/User/OrderDetail";
import AdminOrderDetail from "pages/Order/Admin/AdminOrderDetail";
import StoreDetail from "pages/Store/Admin/StoreDetail";
import AdminAllProduct from "pages/Store/Admin/AdminAllProduct";
import GiftOrderDetail from "pages/Order/Admin/AdminGiftOrderDetail";
import AdminReturnOrderDetail from "pages/Order/Admin/AdminReturnOrderDetail";
import AdminCancelOrderDetail from "pages/Order/Admin/AdminCancelOrderDetail";
import AdminEditProduct from "pages/Store/Admin/AdminEditProduct";
import ReturnOrderDetail from "pages/Order/User/ReturnOrderDetail";
import ExchangeOrderDetail from "pages/Order/User/ExchangeOrderDetail";
import AdminExchangeOrderDetail from "pages/Order/Admin/AdminExchangeOrderDetail";

function App() {
  return (
    <Routes>
      <Route element={<GuestRouter />}>
        <Route path="/" element={<Login />} />
        <Route path="/store/new" element={<NewStore />} />
      </Route>

      <Route element={<CheckingUserRouter />}>
        <Route path="/store/check" element={<CheckStore />} />
      </Route>

      <Route element={<AdminRouter />}>
        <Route element={<Layout />}>
          <Route path="/store/all" element={<AllStore />} />
          <Route path="/store/entry" element={<EntryStore />} />
          <Route path="/store/history" element={<StoreInfoHistory />} />
          <Route path="/store/request/:id" element={<RequestStore />} />
          <Route path="/store/detail/:id" element={<StoreDetail />} />

          <Route path="/admin/product/all" element={<AdminAllProduct />} />
          <Route
            path="/admin/product/:itemCode"
            element={<EditProduct />}
          />

          <Route path="/admin/order" element={<AdminGeneralOrder />} />
          <Route path="/admin/order/gift" element={<AdminGiftOrder />} />
          <Route
            path="/admin/order/exchange"
            element={<AdminExchangeOrder />}
          />
          <Route path="/admin/order/exchange/:claimNo" element={<AdminExchangeOrderDetail />} />
          <Route path="/admin/order/return" element={<AdminReturnOrder />} />
          <Route
            path="/admin/order/return/:claimNo"
            element={<AdminReturnOrderDetail />}
          />
          <Route path="/admin/order/cancel" element={<AdminCancelOrder />} />
          <Route
            path="/admin/order/cancel/:orderId"
            element={<AdminCancelOrderDetail />}
          />
          <Route path="/admin/order/:orderId" element={<AdminOrderDetail />} />
          <Route
            path="/admin/order/gift/:orderId"
            element={<GiftOrderDetail />}
          />
        </Route>
      </Route>

      <Route element={<UserRouter />}>
        <Route element={<Layout />}>
          <Route path="/store/setting" element={<SettingStore />} />
          <Route path="/account/setting" element={<SettingAccount />} />
          <Route path="/product/all" element={<AllProduct />} />
          <Route path="/product/add" element={<AddProduct />} />
          <Route path="/product/:itemCode" element={<EditProduct />} />

          <Route path="/order" element={<GeneralOrder />} />
          <Route path="/order/gift" element={<GiftOrder />} />
          <Route path="/order/exchange" element={<ExchangeOrder />} />
          <Route path="/order/exchange/:claimNo" element={<ExchangeOrderDetail />} />
          <Route path="/order/return" element={<ReturnOrder />} />
          <Route
            path="/order/return/:claimNo"
            element={<ReturnOrderDetail />}
          />
          <Route path="/order/cancel" element={<CancelOrder />} />
          <Route path="/order/:orderNo" element={<OrderDetail />} />
          <Route path="/order/gift/:orderId" element={<GiftOrderDetail />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
