import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as ArrowDown } from "assets/arrowDown.svg";

interface DropdownProps {
  children?: React.ReactNode;
  className?: string;
  width?: string;
  innerText?: string;
  error?: boolean;
  disabled?: boolean;
}

const Dropdown = ({
  children,
  innerText,
  className,
  width,
  error,
  disabled,
}: DropdownProps) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const [showOption, setShowOption] = useState(false);

  useEffect(() => {
    const handleOutsideClose = (e: { target: any }) => {
      if (modalRef.current && !modalRef.current.contains(e.target))
        setShowOption(false);
    };
    document.addEventListener("click", handleOutsideClose);

    return () => document.removeEventListener("click", handleOutsideClose);
  }, [modalRef]);

  return (
    <div
      ref={modalRef}
      onClick={() => {
        if (!disabled) {
          setShowOption((showOption) => !showOption);
        }
      }}
      className={`box-border h-[52px] relative flex items-center justify-between px-3 py-2 border rounded-lg ${
        error ? "border-red" : "border-Gray-40"
      } ${
        disabled ? "border-none bg-Gray-20" : "bg-Gray-10"
      }  Body3 text-Gray-60 cursor-pointer ${className} ${width}`}
    >
      <div
        className={`${
          innerText === "선택" || innerText === "선택안함" || innerText === "--"
            ? "text-Gray-60"
            : "text-Gray-90"
        }`}
      >
        {innerText}
      </div>
      <ArrowDown
        className={`transition-all ${showOption ? "rotate-180" : ""}`}
        width="16"
        height="14"
      />
      {showOption && (
        <div
          className={`absolute z-[101] list-none top-[51px] left-0 max-h-[290px] rounded-lg overflow-y-auto border border-Gray-40 bg-Gray-10 ${width}`}
        >
          {children}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
