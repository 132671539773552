import React, { ReactNode } from 'react';

interface Props {
    children?: ReactNode;
    value?: string;
    id?: string;
    disabled?: boolean;
    checked?: boolean;
    onChange?:(e:React.ChangeEvent<HTMLInputElement>) => void;

}

const Radio = ({ children, value, id, checked, onChange, disabled}: Props) => {

    return (
        <label className='flex items-center cursor-pointer'>
            <input disabled={disabled} checked={checked} onChange={onChange} type="radio" name={value} value={value} id={id} />
            <div className='ml-1 Body2 text-Gray-80'>
                {children}
            </div>
        </label>
    );
};

export default Radio;