import React from "react";
import { useNavigate } from "react-router-dom";
import { Content } from "type/order";
import Table from "./Table";
import { GENERAL_ORDER_DELIVERY_IN_PROGRESS } from "./TableData";

interface Props {
  tableList: Content[];
}

const DeliveryInProgress = ({ tableList }: Props) => {
  const navigate = useNavigate();

  return (
    <>
      {tableList?.length !== 0 ? (
        <Table COLUMNS={GENERAL_ORDER_DELIVERY_IN_PROGRESS}>
          {tableList?.map((item, i) => {
            return (
              <tr key={item.orderNo} className="last:border-none border-y Body4 border-Gray-40">
                <td
                  onClick={() => {
                    navigate(`/order/${item.orderNo}`);
                  }}
                  className="cursor-pointer tableTd text-Blue-100 min-w-[120px]"
                >
                  {item.orderNo}
                </td>

                <td className="tableTd min-w-[100px]">{item.regDt}</td>
                <td className="tableTd min-w-[90px] textEll max-w-[100px]">{item.buyerName}</td>
                <td className="tableTd min-w-[120px]">{item.companyName}</td>
                <td className="min-w-[200px]">
                  {item.orderItemList.map((k, i) => (
                    <div key={i} className="flex items-center tableTd">
                      <img
                        className="w-10 h-10 mr-2 rounded"
                        src={k.itemImagePath}
                        alt=""
                      />
                      <div className="max-w-[180px] textEll">{k.itemTitle}</div>
                    </div>
                  ))}
                </td>
                <td className="max-w-[160px]">
                  {item.orderItemList.map((k, i) => (
                    <div key={i} className="flex items-center h-[72px] tableTd">
                      {k.optionStr ? k.optionStr : "-"}
                    </div>
                  ))}
                </td>
                <td className="min-w-[100px]">
                  {item.orderItemList.map((k, i) => (
                    <div key={i} className="flex items-center tableTd h-[72px]">
                      {k.count}
                    </div>
                  ))}
                </td>
                <td className="tableTd min-w-[100px]">
                  {item.deliveryStartDate ? item.deliveryStartDate : "-"}
                </td>
                <td className="tableTd min-w-[120px]">{item.courierName}</td>
                <td className="tableTd min-w-[120px]">
                  {item.invoiceNumber ? item.invoiceNumber : "-"}
                </td>
                <td className="tableTd min-w-[52px]">{item.receiverName}</td>
                <td className="tableTd min-w-[200px]">{item.receiverAddr}</td>
              </tr>
            );
          })}
        </Table>
      ) : (
        <div className="h-[500px] flex flex-col items-center justify-center Body4">
          배송 중인 주문이 없어요
        </div>
      )}
    </>
  );
};

export default DeliveryInProgress;
