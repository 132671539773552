'use client'
import React, { ReactComponentElement } from "react";

interface PrimaryBtnProps {
  size: "md" | "lg";
  icon?: ReactComponentElement<any>;
  btnText: string;
  disabled?: boolean;
  className?: string;
  onClick?: () => void;
}

const ButtonFilledPrimary = ({
  size,
  icon,
  btnText,
  disabled,
  className,
  onClick,
}: PrimaryBtnProps) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`border-none rounded-lg flex items-center justify-center py-[10px] cursor-pointer
      hover:bg-BlueTint-100 bg-Blue-100 px-3 text-Gray-10 disabled:bg-Blue-60 disabled:text-BlueTint-60 disabled:cursor-default
      ${
        size === "lg"
        ? "min-h-[48px] h-14 min-w-[160px] SubH1"
          : size === "md"
          ? "min-h-[38px] max-h-[52px] SubH2"
          : ""
      } ${className}`}
    >
      {icon && icon}
      <div className={`${icon && "ml-2"}`}>{btnText}</div>
    </button>
  );
};

export default ButtonFilledPrimary;
