import React, { useState } from "react";
import {
  useCategoryListApi,
  useStoreList,
  useStoreProductListForAdmin,
} from "api/store";
import ButtonLinedTertiary from "components/Button/ButtonLinedTertiary";
import Dropdown from "components/Dropdown/Dropdown";
import PageHeader from "components/Header/PageHeader";
import Pagination from "components/Pagination/Pagination";
import Radio from "components/Radio/Radio";
import Table from "components/Table/Table";
import { ALLPRODUCTADMIN } from "components/Table/TableData";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { CategoryResultData } from "type/store";
import { PriceComma } from "utils/priceComma";
import requestToLoveEgg from "utils/requestToLoveEgg";

const AdminAllProduct = () => {
  const navigate = useNavigate();
  const userInfo = JSON.parse(localStorage.getItem("userInfo") as string);

  const [currentPage, setCurrentPage] = useState(1);
  let pageItemLength = 10;
  const [history, setHistory] = useState(false);
  const [storeInfo, setStoreInfo] = useState<{
    storeName: string;
    storeId: number;
  }>({
    storeName: "",
    storeId: 0,
  });
  const [selectedRadio, setSelectedRadio] = useState<{
    type: string;
    name: string;
    id: number;
  }>({ name: "전체", type: "", id: 0 });

  const [selectedCategory, setSelectedCategory] = useState<{
    categoryId: number;
    name: string;
  }>({ name: "", categoryId: 0 });

  const radioList = [
    { id: 0, type: "", name: "전체" },
    { id: 1, type: "ON_SALE", name: "판매중" },
    { id: 2, type: "SUSPENSION_OF_SALE", name: "판매중지" },
    { id: 3, type: "OUT_OF_STOCK", name: "품절" },
    { id: 4, type: "END_OF_SALE", name: "판매종료" },
  ];

  const { data: category } = useCategoryListApi();

  const categoryData =
    category !== undefined
      ? (category?.data.ResultData as CategoryResultData[])
      : [];

  const categotyList = [{ categoryId: 0, name: "전체" }, ...categoryData];

  const { data, refetch } = useStoreProductListForAdmin({
    companyId: storeInfo.storeId,
    categoryId: selectedCategory?.categoryId,
    status: selectedRadio?.type,
    page: currentPage - 1,
    size: pageItemLength,
  });

  const productList = data?.data;

  const { data: storeList } = useStoreList();

  const newStoreList = [
    { brandName: "전체", commerceName: "전체", companyId: 0 },
    ...(storeList?.data?.ResultData !== undefined
      ? storeList?.data?.ResultData
      : []),
  ];

  const copyItem = ({
    itemId,
    companyId,
  }: {
    itemId: string;
    companyId: number;
  }) => {
    requestToLoveEgg({
      method: "POST",
      url: `/api/v1/items/${itemId}/copy`,
      params: {
        companyId: companyId,
      },
    }).then((res) => {
      if (res.data.ResultCode === 1) {
        refetch();
      } else {
        toast.error("알수없는 에러발생 !!");
      }
    });
  };

  const salesStatus = [
    { name: "판매중", color: "#47B50B", status: "ON_SALE" },
    {
      name: "판매중지",
      color: "#FAD619",
      status: "SUSPENSION_OF_SALE",
    },
  ];

  const handleClick = (status: string, itemCode: string, companyId: number) => {
    requestToLoveEgg({
      method: "PUT",
      url: `/api/v1/items/${itemCode}/status/${status}`,
      params: { companyId: companyId },
    }).then((res) => {
      if (res.data.ResultCode === 1) {
        if (status === "ON_SALE") {
          toast.success(
            "`판매중`으로 상품 상태를 변경했습니다 :)\n이제 해당 상품은 구매 가능합니다"
          );
        } else {
          toast.error(
            "`판매중지`로 상품 상태를 변경했습니다 :)\n이제 해당 상품은 구매할 수 없습니다."
          );
        }
        refetch();
      }
    });
  };

  return (
    <div className="contentBox">
      <PageHeader
        noBorderB
        pageList={["상점 관리", "전체 상품"]}
        title={`전체 상품`}
      />
      <div className="flex items-center h-20 border border-Gray-40 rounded-2xl min-w-[1020px]">
        <div className="flex flex-col text-center grow">
          <div className="Body4 text-Gray-80">총 상품수</div>
          <div className="H2">{productList?.ResultData.totalElements}</div>
        </div>
        <div className="h-6 border-r-2 border-Gray-40" />
        <div className="flex flex-col text-center grow">
          <div className="Body4 text-Gray-80">판매중</div>
          <div className="H2">{productList?.ResultData.saleCount}</div>
        </div>
        <div className="h-6 border-r-2 border-Gray-40" />
        <div className="flex flex-col text-center grow">
          <div className="Body4 text-Gray-80">판매 안 함</div>
          <div className="H2">{productList?.ResultData.endOfSaleCount}</div>
        </div>
      </div>
      <div className="flex flex-col justify-between my-10 min-w-[1020px]">
        <div className="flex items-center">
          <div className="py-2.5 min-w-[132px] text-Gray-90 SubH2 mr-6">
            상점
          </div>
          <Dropdown
            width="w-[311px]"
            innerText={
              storeInfo.storeName !== "" ? storeInfo.storeName : "전체"
            }
          >
            {newStoreList?.map((s, i) => {
              return (
                <li
                  key={i}
                  onClick={() => {
                    setCurrentPage(1);
                    setStoreInfo({
                      storeId: s.companyId,
                      storeName: s.commerceName,
                    });
                  }}
                  className="dropdownWrap"
                >
                  <div className="dropdownLi">{s.commerceName}</div>
                </li>
              );
            })}
          </Dropdown>
        </div>
        <div className="flex items-center my-5">
          <div className="py-2.5 min-w-[132px] text-Gray-90 SubH2 mr-6">
            판매 상태
          </div>
          <div className="flex items-center gap-4">
            {radioList.map(({ name, type, id }) => {
              return (
                <Radio
                  value={name}
                  checked={
                    selectedRadio.id === id && selectedRadio.name === name
                  }
                  onChange={(e) => {
                    setCurrentPage(1);
                    setSelectedRadio({
                      name: e.target.value,
                      type: type,
                      id: id,
                    });
                  }}
                  id={String(id)}
                  key={id}
                >
                  {name}
                </Radio>
              );
            })}
          </div>
        </div>
        <div className="flex items-center">
          <div className="py-2.5 min-w-[132px] text-Gray-90 SubH2 mr-6">
            판매 카테고리
          </div>
          <Dropdown
            width="w-[311px]"
            innerText={
              selectedCategory.name !== "" ? selectedCategory.name : "전체"
            }
          >
            {categotyList.map((c) => {
              return (
                <li
                  value={c.categoryId}
                  key={c.categoryId}
                  onClick={() => {
                    setCurrentPage(1);
                    const id = Number(c.categoryId);
                    setSelectedCategory({ categoryId: id, name: c.name });
                  }}
                  className="w-[476px] py-2.5 px-3 hover:bg-Blue-50"
                >
                  <div className="max-w-[476px] w-full Body4 text-Gray-80">
                    {c.name}
                  </div>
                </li>
              );
            })}
          </Dropdown>
        </div>
      </div>
      {productList?.ResultData.content.length !== 0 ? (
        <Table COLUMNS={ALLPRODUCTADMIN}>
          {productList?.ResultData.content.map((p, i) => {
            return (
              <tr key={i} className="Body4">
                <td className="tableTd">{p.companyName}</td>
                <td className="max-w-[100px] tableTd">
                  <div className="w-full textEll">
                    {p.categoryName.split(" ")[0]} <br />
                    {p.categoryName.split(" ")[1] !== undefined && "> "}
                    {p.categoryName.split(" ")[1]}
                  </div>
                </td>
                <td className="hoverContainer">
                  <div>
                    {p.status === "END_OF_SALE" ? (
                      <div className="text-center text-Red100">판매종료</div>
                    ) : p.status === "ON_SALE" ? (
                      <div className="flex items-center justify-center gap-1 cursor-pointer">
                        <div className="w-1.5 h-1.5 rounded-full bg-Green100" />
                        <div>판매중</div>
                      </div>
                    ) : p.status === "SUSPENSION_OF_SALE" ? (
                      <div className="flex items-center justify-center gap-1 cursor-pointer">
                        <div className="w-1.5 h-1.5 rounded-full bg-[#FAD619]" />
                        <div>판매중지</div>
                      </div>
                    ) : p.status === "OUT_OF_STOCK" ? (
                      <div className="flex items-center justify-center gap-1 cursor-pointer">
                        <div className="w-1.5 h-1.5 rounded-full bg-Red100" />
                        <div>품절</div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="absolute top-0 z-50 flex-col items-start hidden gap-2 p-4 border rounded-lg left-2/4 -translate-x-2/4 item bg-Gray-10 border-Gray-90">
                    {salesStatus?.map((item, i) => {
                      return (
                        <div
                          key={i}
                          onClick={() => {
                            handleClick(item.status, p.itemCode, p.companyId);
                          }}
                          className={`flex items-center gap-1 ${
                            item.status !== p.status &&
                            "opacity-[0.6]   :underline"
                          }`}
                        >
                          <div
                            className="rounded-full w-1.5 h-1.5"
                            style={{ backgroundColor: `${item.color}` }}
                          />
                          <div className={`text-Gray-90 Body4 cursor-pointer`}>
                            {item.name}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </td>
                <td
                  onClick={() => {
                    navigate(`/admin/product/${p.itemCode}`, {
                      state: p.companyId,
                    });
                  }}
                  className="underline cursor-pointer tableTd text-Blue-100"
                >
                  {p.itemCode}
                </td>
                <td className="w-full max-w-[498px] tableTd">
                  <div className="flex items-center gap-2">
                    <div className="w-10 h-10 rounded">
                      <img src={p.thumbnail} alt="" />
                    </div>
                    <div className="textEll max-w-[426px]">{p.title}</div>
                  </div>
                </td>
                <td className="text-center cursor-pointer tableTd SubH2 text-Gray-60">
                  보기
                </td>
                <td className="tableTd">{p.stock}</td>
                <td className="text-right tableTd">
                  {p.discountPrice !== 0 ? (
                    <div>{PriceComma(p.price - p.discountPrice)}원</div>
                  ) : (
                    <div>{PriceComma(p.price)}원</div>
                  )}
                  {p.discountPrice !== 0 && (
                    <div className="line-through Caption text-Gray-60">
                      {PriceComma(p.price)}원
                    </div>
                  )}
                </td>
                <td className="text-right tableTd">
                  <div>{p.modDt}</div>
                  <div className="Caption text-Gray-60">{p.regDt}</div>
                </td>
                <td className="text-center cursor-pointer tableTd text-Gray-60">
                  이력보기
                </td>
                <td className="tableTd">
                  <div className="flex items-center gap-2 ">
                    <ButtonLinedTertiary
                      onClick={() => [
                        copyItem({
                          itemId: p.itemCode,
                          companyId: p.companyId,
                        }),
                      ]}
                      size={"sm"}
                      btnText={"복사"}
                    />
                    <ButtonLinedTertiary
                      onClick={() => {
                        navigate(`/admin/product/${p.itemCode}`, {
                          state: p.companyId,
                        });
                      }}
                      size={"sm"}
                      btnText={"수정"}
                    />
                  </div>
                </td>
              </tr>
            );
          })}
        </Table>
      ) : (
        <div className="flex flex-col items-center justify-center w-full h-[500px] Body4 text-Gray-90 min-w-[1020px]">
          {"등록된 상품이 없습니다.\n상품을 등록해 주세요 :)"}
        </div>
      )}
      <Pagination
        className="mt-4"
        itemsCount={productList?.ResultData.totalElements!}
        pageSize={pageItemLength}
        currPage={currentPage}
        setList={setCurrentPage}
      />
    </div>
  );
};

export default AdminAllProduct;
