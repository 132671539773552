import React, { ReactComponentElement } from "react";

interface Props {
  size: "sm" | "lg";
  icon?: ReactComponentElement<any>;
  text: string;
  className?: string;
  onClick?: () => void;
}

const TextBtnPrimary = ({ size, icon, text, onClick, className }: Props) => {
  return (
    <div className={`flex items-center ${className}`}>
      <div>{icon && icon}</div>
      <div
        className={`SubH2 cursor-pointer hover:underline text-Blue-100 hover:text-BlueTint-100 ${
          icon && "ml-2"
        }`}
        onClick={onClick}
      >
        {text}
      </div>
    </div>
  );
};

export default TextBtnPrimary;
