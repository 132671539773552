import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { ReactComponent as Arrow } from 'assets/arrowRight.svg';

interface Props {
    pageList: string[];
    tabList?: { id: number, title: string }[];
    modify?: boolean;
    title: string;
    marketCode?: string;
    companyName?: string;
    status?: string;
    noBorderB?:boolean;
    setCurrnetId?: Dispatch<SetStateAction<any>>
}

const PageHeader = ({ companyName, setCurrnetId, noBorderB, pageList, tabList, title, status, marketCode }: Props) => {
    const [tabNum, setTabNum] = useState<number>(0);
    const page = [
        ...pageList
    ]

    useEffect(() => {
        if(setCurrnetId) {
            setCurrnetId(tabNum);
        }
    },[setCurrnetId, tabNum])
    return (
        <div className={`flex flex-col items-start w-full min-w-[1020px] ${noBorderB ? "" : "mb-10"} `}>
            <div className='flex items-center justify-between w-full'>
                <div className='flex items-center'>
                    {page.map((v, i) => {
                        if (page.length === i + 1) {
                            return (
                                <div key={i} className='flex items-center' >
                                    <div className='SubH3 text-Gray-80'>{v}</div>
                                </div>)
                        } else {
                            return (
                                <div key={i} className='flex items-center' >
                                    <div className='Body5 text-Gray-80'>{v}</div>
                                    <Arrow className="mx-1" />
                                </div>
                            )
                        }
                    })}
                </div>
                {/* <div className='flex items-center gap-1'>
                    <div className='SubH3 text-Gray-80'>
                        최근 수정일
                    </div>
                    <div className='text-Gray-80 Body5'>
                        2023. 10. 06  17:20
                    </div>
                    <div className='mx-1 text-Gray-60 Body5'>
                        /
                    </div>
                    <div className='SubH3 text-Gray-80'>
                        최종 편집자
                    </div>
                    <div className='text-Gray-80 Body5'>
                        최종 편집자
                    </div>
                </div> */}
            </div>
            <div className='flex items-center mt-5 mb-10'>
                <div className='text-Gray-100 H1'>{status !== "REJECTED" && status !== "EXAMINATION" ? companyName : ""} {title}</div>
                {marketCode && <div className='mx-2.5 py-[7px] px-2.5 bg-Gray-20 border border-Gray-40 rounded Body5'>상점코드</div>}
                <div className={`ml-4 py-[7px] px-2.5 Body5 rounded ${status === "EXAMINATION" ? "text-Red100 bg-RedTint90" : status === "WAITING" ? "text-Green100 bg-GreenTint90" : ""}`}>{status === "EXAMINATION" ? "심사중" : status === "WAITING" ? "대기중" : ""}</div>
            </div>
            <div className={`flex items-center w-full ${noBorderB ? "border-none" : "border-b"} border-Gray-40`}>
                {status !== "REJECTED" && status !== "EXAMINATION" ?  tabList && tabList.map((t, idx) => {
                    return (
                        <div key={t.id} onClick={() => { setTabNum(t.id) }} className={`flex flex-col justify-center h-16 px-4 cursor-pointer H4 text-Gray-100 ${tabNum === t.id ? "border-Blue-100 border-b-2" : ""}`}>{t.title}</div>
                    )
                }): ""}
            </div>
        </div>
    );
};

export default PageHeader;