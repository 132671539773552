import ButtonFilledPrimary from 'components/Button/ButtonFilledPrimary';
import ButtonFilledTertiary from 'components/Button/ButtonFilledTertiary';
import React, { Dispatch, SetStateAction, useEffect } from 'react';

interface Props {
    type: "sm" | "md" | "lg",
    cancalButton?: boolean;
    children?: React.ReactNode;
    buttonText: string;
    setCloseState?: Dispatch<SetStateAction<boolean>>
    onClick?:() => void;
    disabled?: boolean;
    onClickCancelButton?: () => void;
}

const ModalPopup = ({ type, cancalButton, disabled, children, buttonText, setCloseState, onClick, onClickCancelButton}: Props) => {

    useEffect(() => {
        document.body.style.cssText = `
              position: fixed; 
              top: -${window.scrollY}px;
              overflow-y: scroll;
              width: 100%;`;
        return () => {
          const scrollY = document.body.style.top;
          document.body.style.cssText = "";
          window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
        };
      }, []);

    return (
        <div className='fixed top-0 left-0 right-0 w-full h-full z-[1000] bg-Gray-100/25'>
            <div className={`fixed p-5 pt-10 border left-2/4 top-2/4 -translate-x-2/4 -translate-y-2/4 flex flex-col gap-9 bg-Gray-10 rounded-2xl border-Gray-40 ${type === "sm" ? "w-80" : type === 'md' ? "w-[376px]" : type === 'lg' ? "w-[436px]" : ""}`}>
                <div>
                    {children}
                </div>
                <div className='flex items-center gap-4'>
                    {cancalButton &&
                        <ButtonFilledTertiary onClick={() => {
                            if(onClickCancelButton) {
                                onClickCancelButton()
                            }
                            setCloseState && setCloseState(false)}} className='w-full' size={'lg'} btnText={'취소'} />
                    }
                    <ButtonFilledPrimary disabled={disabled} onClick={onClick} className='w-full' size={'lg'} btnText={buttonText} />
                </div>
            </div>
        </div>
    );
};

export default ModalPopup;