import React from 'react';

import PageHeader from 'components/Header/PageHeader';
import EditItem from 'components/Product/EditItem';

const EditProduct = () => {
    return (
        <div className='contentBox'>
            <PageHeader noBorderB pageList={["상점 관리", "상품 상세"]} title={`상품 상세`} />
            <EditItem/>
        </div>
    );
};

export default EditProduct;