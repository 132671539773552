import React from 'react';
import { ReactComponent as SearchInputIcon } from 'assets/searchInput.svg';

interface Props {
    placeholder?: string;
    className?: string;
    onChange?: (e:React.ChangeEvent<HTMLInputElement>) => void;
    inputValue?:string;
}

const SearchInput = ({ placeholder, className, onChange, inputValue }: Props) => {
    return (
        <div className='relative'>
            <input defaultValue={inputValue ? inputValue : ""} onChange={onChange} placeholder={placeholder} className={`px-3 pr-[38px] py-2 border rounded-lg border-Gray-40 hover:border-Gray-100 Body4 ${className}`}></input>
            <SearchInputIcon className='absolute top-2/4 -translate-y-2/4 right-3' />
        </div>
    );
};

export default SearchInput;