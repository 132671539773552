import React, { useEffect, useState } from "react";

import { useAllStoreListApi, useCategoryListApi } from "api/store";
import ButtonLinedTertiary from "components/Button/ButtonLinedTertiary";
import PageHeader from "components/Header/PageHeader";
import Table from "components/Table/Table";
import { ALLSTORE } from "components/Table/TableData";
import { useNavigate } from "react-router-dom";
import Radio from "components/Radio/Radio";
import Dropdown from "components/Dropdown/Dropdown";
import { CategoryResultData } from "type/store";
import Pagination from "components/Pagination/Pagination";
import requestToLoveEgg from "utils/requestToLoveEgg";
import { toast } from "react-toastify";

const AllStore = () => {
  const navigate = useNavigate();
  let pageItemLength = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState<{
    categoryId: number;
    name: string;
  }>({ name: "", categoryId: 0 });

  const [selectedRadio, setSelectedRadio] = useState<{
    status: string;
    name: string;
    id: number;
  }>({ name: "전체", status: "", id: 1 });

  const { data: allStore, refetch } = useAllStoreListApi({
    status: selectedRadio.status,
    category: selectedCategory.categoryId,
    page: currentPage - 1,
    size: pageItemLength,
  });
  const store = allStore?.data.ResultData.content;

  const radioList = [
    { name: "전체", status: "", id: 1 },
    { name: "활성", status: "ACTIVE", id: 2 },
    { name: "비활성", status: "INACTIVE", id: 3 },
  ];

  const { data: category } = useCategoryListApi();

  const categoryData =
    category !== undefined
      ? (category?.data.ResultData as CategoryResultData[])
      : [];

  const categotyList = [{ categoryId: 0, name: "전체" }, ...categoryData];

  const storeStatus = [
    { name: "활성", status: "active" },
    { name: "비활성", status: "inactive" },
  ];

  const handleStatusChange = ({
    companyId,
    type,
  }: {
    companyId: number;
    type: string;
  }) => {
    requestToLoveEgg({
      method: "POST",
      url: `/api/admin/v1/join-apply/${companyId}/${type}`,
    }).then((res) => {
      if (res.data.ResultCode === 1) {
        type === "active"
          ? toast.success(
              "`활성’으로 상점 상태를 변경했습니다 :)\n이제 고객에게 해당 상점이 노출됩니다.`"
            )
          : toast.error(
              "‘비활성’으로 상점 상태를 변경했습니다 :)\n이제 고객에게 해당 상점은 보이지 않습니다."
            );
        refetch();
      } else {
        toast.error("알수없는 오류 발생 !!");
      }
    });
  };

  return (
    <div className="contentBox">
      <PageHeader
        noBorderB
        pageList={["상점 관리", "전체 상점"]}
        title={`전체 상점 (${store?.length})`}
      />
      <div className="flex flex-col gap-5 mb-10 ">
        <div className="flex items-center min-w-[1020px]">
          <div className="h-12 flex flex-col justify-center min-w-[132px] text-Gray-90 SubH2 mr-6">
            운영 상태
          </div>
          <div className="flex items-center gap-5">
            {radioList.map((item, i) => {
              return (
                <Radio
                  checked={
                    selectedRadio.id === item.id &&
                    selectedRadio.name === item.name
                  }
                  onChange={(e) => {
                    setCurrentPage(1);
                    setSelectedRadio({
                      name: item.name,
                      status: item.status,
                      id: item.id,
                    });
                  }}
                  key={item.id}
                  id={String(item.id)}
                  value={item.status}
                >
                  {item.name}
                </Radio>
              );
            })}
          </div>
        </div>
        <div className="flex items-center min-w-[1020px]">
          <div className="py-2.5 min-w-[132px] text-Gray-90 SubH2 mr-6">
            판매 카테고리
          </div>
          <Dropdown
            width="w-[311px]"
            innerText={
              selectedCategory.name !== "" ? selectedCategory.name : "전체"
            }
          >
            {categotyList.map((c) => {
              return (
                <li
                  value={c.categoryId}
                  key={c.categoryId}
                  onClick={() => {
                    setCurrentPage(1);
                    const id = Number(c.categoryId);
                    setSelectedCategory({ categoryId: id, name: c.name });
                    setCurrentPage(1);
                  }}
                  className="py-2.5 px-3 hover:bg-Blue-50"
                >
                  <div className="w-full Body4 text-Gray-80">{c.name}</div>
                </li>
              );
            })}
          </Dropdown>
        </div>
      </div>
      {store?.length !== 0 ? (
        <Table COLUMNS={ALLSTORE}>
          {store?.map((item) => {
            return (
              <tr key={item.companyId} className="Body4">
                <td className="whitespace-nowrap text-ellipsis overflow-hidden max-w-[145px] tableTd">
                  {item.categoryName}
                </td>
                <td className="tableTd">{item.companyId}</td>
                <td
                  onClick={() => {
                    navigate(`/store/detail/${item.companyId}`);
                  }}
                  className="underline cursor-pointer tableTd"
                >
                  {item.companyName}
                </td>
                <td className="hoverContainer w-[30px]">
                  <div>
                    {item.status === "ACTIVE" ? (
                      <div className="flex items-center gap-1">
                        <div className="w-1.5 h-1.5 rounded-full bg-Green100" />
                        <div>활성</div>
                      </div>
                    ) : item.status === "INACTIVE" ? (
                      <div className="flex items-center gap-1">
                        <div className="w-1.5 h-1.5 rounded-full bg-Gray-40" />
                        <div>비활성</div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="absolute top-0 z-50 flex-col items-start hidden gap-2 p-4 border rounded-lg left-2/4 -translate-x-2/4 item bg-Gray-10 border-Gray-90">
                    {storeStatus.map((a, i) => {
                      return (
                        <div
                          key={i}
                          onClick={() => {
                            if(a.status !== item.status.toLowerCase()) {
                              handleStatusChange({
                                companyId: item.companyId,
                                type: a.status,
                              });
                            }
                          }}
                          className={`flex items-center gap-1 ${
                            a.status !== item.status.toLowerCase() &&
                            "opacity-[0.6] hover:underline"
                          }`}
                        >
                          <div
                            className={`rounded-full w-1.5 h-1.5 ${
                              a.status === item.status.toLowerCase()
                                ? "bg-Green100"
                                : "bg-Gray-40"
                            }`}
                          />
                          <div className={`text-Gray-90 Body4 cursor-pointer`}>
                            {a.name}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </td>
                <td className="w-full tableTd">
                  {item.address}
                  {item.detailAddress ? `, ${item.detailAddress}` : ""}
                </td>
                <td className="tableTd">{item.name}</td>
                <td className="tableTd">{item.email}</td>
                <td className="tableTd">{item.regDt}</td>
                <td className="tableTd ">
                  <ButtonLinedTertiary
                    className="w-[80px]"
                    size={"sm"}
                    btnText={"사업자 전환"}
                  />
                </td>
              </tr>
            );
          })}
        </Table>
      ) : (
        <div className="flex flex-col items-center justify-center w-full h-[500px] Body4 text-Gray-90">
          입점한 업체가 없어요
        </div>
      )}
      <Pagination
        className="mt-4"
        itemsCount={allStore?.data.ResultData.totalElements!}
        pageSize={pageItemLength}
        currPage={currentPage}
        setList={setCurrentPage}
      />
    </div>
  );
};

export default AllStore;
