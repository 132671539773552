import { useDeliveryCompanyListApi } from 'api/deliveryCompany';
import ButtonFilledPrimary from 'components/Button/ButtonFilledPrimary';
import ButtonLinedTertiary from 'components/Button/ButtonLinedTertiary';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

interface Props {
    setCloseState: Dispatch<SetStateAction<boolean>>
    onClick?: () => void;
    onClickCancelButton?: () => void;
    currentDeliveryValue?: string;
    children?: React.ReactNode;
    buttonText?: string;
    disabled?:boolean;
    title?: string;
}


const PopupSelect = ({ setCloseState, onClickCancelButton, onClick, disabled, children, title, buttonText }: Props) => {

    useEffect(() => {
        document.body.style.cssText = `
          position: fixed; 
          top: -${window.scrollY}px;
          overflow-y: scroll;
          width: 100%;`;
        return () => {
            const scrollY = document.body.style.top;
            document.body.style.cssText = '';
            window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
        };
    }, []);

    return (
        <div className='fixed top-0 left-0 right-0 w-full h-full z-[1000] bg-Gray-100/25'>
            <div className={`fixed border left-2/4 top-2/4 -translate-x-2/4 -translate-y-2/4 flex flex-col justify-between bg-Gray-10 rounded-2xl border-Gray-40 min-h-[428px] min-w-[800px] w-[60%] max-w-[1080px]`}>
                <div className='px-10 text-left py-7 H4 text-Gray-100'>{title}</div>
                <div className='flex-1 px-10'>
                    {children}
                </div>
                <div className='flex items-center justify-center gap-4 p-5 pb-7'>
                    <ButtonLinedTertiary onClick={() => {
                        if (onClickCancelButton) {
                            onClickCancelButton()
                        }                        
                        setCloseState(false);
                    }} className='w-40 !mx-0' size={'lg'} btnText={'취소'} />
                    <ButtonFilledPrimary disabled={disabled} onClick={() => {
                        if (onClick) {
                            onClick()
                        }
                        setCloseState(false)
                    }} className='w-40 !mx-0' size={'lg'} btnText={buttonText ? buttonText : '선택 완료'} />
                </div>
            </div>
        </div >
    );
};

export default PopupSelect;