import { useUserStoreInfoApi } from 'api/store';
import ButtonFilledPrimary from 'components/Button/ButtonFilledPrimary';
import ButtonFilledSecondary from 'components/Button/ButtonFilledSecondary';
import ButtonFilledTertiary from 'components/Button/ButtonFilledTertiary';
import ButtonLinedTertiary from 'components/Button/ButtonLinedTertiary';
import CheckBox from 'components/CheckBox/CheckBox';
import Dropdown from 'components/Dropdown/Dropdown';
import PageHeader from 'components/Header/PageHeader';
import TextFieldInput from 'components/Input/TextFieldInput';
import ModalPopup from 'components/Modal/ModalPopup';
import NewStoreForm from 'components/Store/NewStoreForm';
import NewStoreWrap from 'components/Store/NewStoreWrap';
import React, { useCallback, useEffect, useState } from 'react';
import { NewUserStoreInfoData } from 'type/store';
import requestToLoveEgg from 'utils/requestToLoveEgg';

declare const window: typeof globalThis & {
    IMP: any;
};

const SettingAccount = () => {
    const [edit, setEdit] = useState(false);
    const [pwEdit, setPwEdit] = useState(false);
    const [viewPw, setViewPw] = useState<boolean>(false);

    const [popup, setPopup] = useState(false);
    const [popupText, setPopupText] = useState("");

    const [userEmail, setUserEmail] = useState({ userId: "", email: "" })

    const [accountSetting, setAccountSetting] = useState({
        password: "",
        newPassword: "",
        name: "",
        email: "",
        mobileNo: "",
        impUid: ""
    })

    const { data: SData, refetch } = useUserStoreInfoApi();
    const store = SData?.data.ResultData as NewUserStoreInfoData;


    useEffect(() => {
        setAccountSetting({
            ...accountSetting,
            name: store?.username.trim(),
            email: store?.email.trim(),
            mobileNo: store?.mobileNo,
            impUid: ""
        })
    }, [SData])

    const emailArr = accountSetting?.email?.split("@");

    const mobileVerify = () => {
        var IMP = window.IMP;
        IMP.init("imp00911443");
        if (IMP) {

            IMP.certification({
                pg: 'B010007401'
            }, function (rsp: {
                error_code: null
                error_msg: null
                imp_uid: string;
                merchant_uid: string;
                pg_provider: string;
                pg_type: string;
                success: boolean
            }) { // callback
                if (rsp.success) {
                    setAccountSetting({
                        ...accountSetting,
                        impUid: rsp.imp_uid
                    })
                } else {
                    console.log('실패')
                }
            });
        }
    }

    const email = [
        { name: "구글", address: "gmail.com" },
        { name: "네이버", address: "naver.com" },
        { name: "네이버", address: "nate.com" },
        { name: "다음", address: "daum.net" },
        { name: "한메일", address: "hanmail.net" },
        { name: "직접입력", address: "직접입력" },
    ]

    const handleAccount = () => {
        if (pwEdit) {
            let sellerPwReg = /^.*(?=^.{8,}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@#$%^&+=]).*$/
            if (accountSetting?.password === "" && accountSetting?.newPassword !== "") {
                setPopup(true)
                setPopupText("기존 비밀번호를 입력해 주세요")
            } else if (!sellerPwReg.test(accountSetting?.password) && accountSetting?.newPassword !== "") {
                setPopup(true)
                setPopupText("기존 비밀번호는 영문, 숫자, 특수문자\n조합으로 최소 8글자 이상 입력해 주세요.")
            } else if (!sellerPwReg.test(accountSetting?.newPassword) && accountSetting?.newPassword !== "") {
                setPopup(true)
                setPopupText("새로운 비밀번호는 영문, 숫자, 특수문자\n조합으로 최소 8글자 이상 입력해 주세요.")
            } else {
                requestToLoveEgg({
                    method: "POST",
                    url: "/api/v1/join-apply/account",
                    data: accountSetting
                })
                    .then((res) => {
                        if (res.data.ResultCode === 8) {
                            setPopup(true)
                            setPopupText("기존 비밀번호를 다시 확인해 주세요.")
                        } else {
                            setEdit(false);
                            setPwEdit(false)
                            refetch()
                        }
                    })
            }
        } else if (!pwEdit && edit) {
            if (accountSetting.name === "") {
                setPopup(true)
                setPopupText("이름을 입력해 주세요")
            } else if (emailArr[0] === "" || emailArr[1] === "") {
                setPopup(true)
                setPopupText("이메일 형식을 다시 확인해 주세요")
            } else {
                requestToLoveEgg({
                    method: "POST",
                    url: "/api/v1/join-apply/account",
                    data: accountSetting
                })
                    .then((res) => {
                        setEdit(false);
                        setPwEdit(false)
                        refetch()
                    })
            }
        }
    }

    useEffect(() => {
        setAccountSetting({
            ...accountSetting,
            email: `${userEmail.userId}@${userEmail.email}`,

        })
    }, [userEmail])
    
    return (
        <div className='contentBox'>
            {popup &&
                <ModalPopup onClick={() => { setPopup(false) }} type={'sm'} buttonText={'확인'}>
                    <div className='text-center whitespace-pre-wrap Body2 text-Gray-90'>
                        {popupText}
                    </div>
                </ModalPopup>
            }
            <PageHeader pageList={["상점 관리", "계정 설정"]} title={`계정 설정`} />
            <NewStoreWrap title="계정 정보">
                <NewStoreForm itemCenter title="판매자 로그인 ID">
                    <div className='text-Gray-90 Body3'>{store?.id}</div>
                </NewStoreForm>
                <NewStoreForm required title="판매자 비밀전호">
                    {(edit && !pwEdit) &&
                        <ButtonFilledSecondary onClick={() => { setPwEdit(true) }} className='!mx-0 w-[130px]' size={'lg'} btnText={'비밀번호 변경하기'} />
                    }
                    {(!edit && !pwEdit) &&
                        <TextFieldInput disabled className='w-[476px] mb-2' placeholder={"********"} />
                    }


                    {(edit && pwEdit) &&
                        <>
                            <TextFieldInput disabled={!edit} onChange={(e) => {
                                setAccountSetting({
                                    ...accountSetting,
                                    password: e.target.value
                                })
                            }} className='w-[476px] mb-2' placeholder={"기존 비밀번호 입력"} />
                            <TextFieldInput onChange={(e) => {
                                setAccountSetting({
                                    ...accountSetting,
                                    newPassword: e.target.value
                                })
                            }} disabled={!edit} type={viewPw ? "text" : "password"} className='w-[476px]' placeholder={edit ? "새로운 비밀번호 입력" : "***********"} />
                            <div>
                                <div className='my-2 text-Blue-70 Body4'>
                                    영문, 숫자, 특수문자 조합으로 입력해 주세요. (최소 12글자)
                                </div>
                                <CheckBox onChange={(e) => setViewPw(e.target.checked)} text="비밀번호 보기" />
                            </div>
                        </>
                    }
                </NewStoreForm>
            </NewStoreWrap>
            <NewStoreWrap title="담당자 정보">
                <NewStoreForm title="이름" required >
                    <TextFieldInput onChange={(e) => {
                        setAccountSetting({
                            ...accountSetting,
                            name: e.target.value.replace(/[^A-Za-zㄱ-ㅎ가-힣\s]/g, "")
                        })
                    }} disabled={accountSetting.impUid === ""} inputValue={accountSetting?.name} className='w-[367px]' />
                    {edit &&
                        <div className='mt-2 text-Blue-70 Body4'>
                            이름은 연락처 인증한 후에 수정 가능합니다.
                        </div>
                    }
                </NewStoreForm>
                <NewStoreForm title="이메일" required className='flex items-center'>
                    <TextFieldInput onChange={(e) => {
                        setUserEmail({ email: emailArr[1] ,userId: e.target.value.replace(
                            /[^A-Za-z0-9_\-ㄱ-ㅎ가-힣]/g,
                            ""
                          ) })
                    }} inputValue={emailArr?.[0]} disabled={!edit} className='w-[270px]' />
                    <div className='mx-2 Body4 text-Gray-90'>@</div>
                    <TextFieldInput onChange={(e) => {
                        setUserEmail({ email: e.target.value, userId: emailArr[0] })
                    }} inputValue={emailArr?.[1] !== "직접입력" ? emailArr?.[1] : ""} disabled={!edit} className='w-[175px]' />
                    {edit &&
                        <Dropdown
                            innerText={email.map((k) => {
                                if (k.address === emailArr?.[1]) {
                                    return k.address;
                                }
                            }
                            ) as unknown as string}
                            // innerText={userEmail.email ? userEmail.email : "선택"}
                            className='w-[175px] ml-2' >
                            {email.map((k, i) => {
                                return (
                                    <li
                                        key={i}
                                        onClick={() => setUserEmail({
                                            ...userEmail,
                                            email: k.address
                                        })}
                                        className="w-[175px] py-2.5 px-3 hover:bg-Blue-50"
                                    >
                                        <div className="max-w-[175px] w-full Body4 text-Gray-80">
                                            {k.address}
                                        </div>
                                    </li>
                                )
                            })}
                        </Dropdown>
                    }
                </NewStoreForm>
                <NewStoreForm title="연락처" required className='flex items-center'>
                    <TextFieldInput onChange={(e) => {
                        setAccountSetting({
                            ...accountSetting,
                            mobileNo: e.target.value.replace(/[^0-9]/g, '').replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3").replace(/(\-{1,2})$/g, "")
                        })
                    }} disabled={!edit} inputValue={accountSetting?.mobileNo} maxLength={13} className='w-[270px]' />
                    {edit &&
                        <ButtonFilledTertiary
                            onClick={() => {
                                mobileVerify()
                            }} className='!h-[52px] ml-4 w-full' size={'md'} btnText={'인증요청'} />
                    }
                </NewStoreForm>
            </NewStoreWrap>
            <div className='fixed bottom-0 left-0 bg-Gray-10/70 backdrop-blur-[12px] z-[102] w-full py-5 border-t border-Gray-40'>
                <div className='flex items-center pl-[260px] pr-[160px] justify-between max-w-[1920px] mx-auto min-w-[1440px]'>
                    <div className='Body2'>상점 관리 {">"} 계정 설정  {">"} 수정하기</div>
                    {edit ?
                        <div className='flex items-center gap-4'>
                            <ButtonLinedTertiary onClick={() => {

                                setAccountSetting({
                                    ...accountSetting,
                                    password: "",
                                    newPassword: ""
                                })
                                refetch()
                                setEdit(false)
                                setPwEdit(false)
                            }} size={'lg'} btnText={'취소'} />
                            <ButtonFilledPrimary onClick={() => { handleAccount() }} size={'lg'} btnText={'수정 완료'} />
                        </div>
                        :
                        <div className='flex items-center gap-4'>
                            <ButtonLinedTertiary size={'lg'} onClick={() => { setEdit(true) }} btnText={'수정하기'} />
                        </div>
                    }

                </div>
            </div>
        </div>
    );
};

export default SettingAccount;