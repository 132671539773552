export const PriceComma = (price: string | number) => {
  price = price !== undefined ? price : "";

  const numericPrice = typeof price === "number" ? price : parseFloat(price);
  if (!isNaN(numericPrice)) {
    return numericPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return "";
};


export const AddComma = (price: number | string) => {
  let returnString = price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return returnString;
}
