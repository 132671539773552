import ButtonFilledSecondary from "components/Button/ButtonFilledSecondary";
import ImgUploadBtn from "components/Button/ImgUploadBtn";
import DaumAddressSearch from "components/DaumAddressSearch";
import Dropdown from "components/Dropdown/Dropdown";
import TextFieldInput from "components/Input/TextFieldInput";
import TextLimitHorizontalInput from "components/Input/TextLimitHorizontalInput";
import NewStoreForm from "components/Store/NewStoreForm";
import NewStoreWrap from "components/Store/NewStoreWrap";
import React, { useEffect, useState } from "react";
import { daumAddress } from "type/daumAddress";

import DatePicker from "react-datepicker";
import Radio from "components/Radio/Radio";
import ButtonFilledTertiary from "components/Button/ButtonFilledTertiary";
import ButtonLinedTertiary from "components/Button/ButtonLinedTertiary";
import TextUnitFieldInput from "components/Input/TextUnitFieldInput";
import Toggle from "components/Toggle/Toggle";
import {
  Combinations,
  DeliveryInfo,
  OptionAttributes,
  Options,
  ReturnExchangeInfo,
  ServiceInfo,
  Simples,
  addItem,
} from "type/addItem";
import PopupSelect from "components/Modal/PopupSelect";
import CheckBox from "components/CheckBox/CheckBox";
import { useStoreProductDetail, useUserOperationInfoApi } from "api/store";
import { CategoryListApi, OperationResultData } from "type/store";
import { dateFormat } from "utils/dateFormat";
import { useDeliveryCompanyListApi } from "api/deliveryCompany";

import { ReactComponent as DeleteButton } from "assets/imgDeleteBtn.svg";
import { ReactComponent as Close } from "assets/close.svg";
import { ReactComponent as OptionMinus } from "assets/optionMinus.svg";
import { ReactComponent as OptionPlus } from "assets/optionPlus.svg";
import requestToLoveEgg from "utils/requestToLoveEgg";
import ButtonFilledPrimary from "components/Button/ButtonFilledPrimary";
import { PriceComma } from "utils/priceComma";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DetailImages, StoreProductDetailData, TopImages } from "type/product";
import ModalPopup from "components/Modal/ModalPopup";
import { toast } from "react-toastify";

const AddProduct = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { state } = useLocation();
  const userInfo = JSON.parse(localStorage.getItem("userInfo") as string);
  const authority = userInfo?.authority;

  const [optionInfo, setOptionInfo] = useState({ addPrice: 0, stock: 0 });

  const [deleteModal, setDeleteModal] = useState(false);
  const [buttonDuplication, setButtonDuplication] = useState(false);
  const [edit, setEdit] = useState(false);
  const [changeOptionType, setChangeOptionType] = useState(false);
  const [saleEnd, setSaleEnd] = useState(false);

  const [isStatusBox, setIsStatusBox] = useState<string>("");

  const [deliveryPop, setDeliveryPop] = useState(false);
  const [returnDeliveryPop, setReturnDeliveryPop] = useState(false);
  const [colorModal, setColorModal] = useState(false);

  const [deliveryValue, setDeliveryValue] = useState<{
    cd: string;
    cdNm: string;
  }>({ cd: "", cdNm: "" });
  const [returnDeliveryValue, setReturnDeliveryValue] = useState<{
    cd: string;
    cdNm: string;
  }>({ cd: "", cdNm: "" });

  const [categoryPop, setCategoryPop] = useState(false);
  const [cateList, setCateList] = useState<CategoryListApi>();
  const [cate1List, setCate1List] = useState<CategoryListApi>();
  const [cate2List, setCate2List] = useState<CategoryListApi>();
  const [cate1, setCate1] = useState<{ categoryId: number; name: string }>({
    categoryId: 0,
    name: "",
  });
  const [cate2, setCate2] = useState<{ categoryId: number; name: string }>({
    categoryId: 0,
    name: "",
  });
  const [cate3, setCate3] = useState<{ categoryId: number; name: string }>({
    categoryId: 0,
    name: "",
  });

  const [optionAtt, setOptionAtt] = useState<
    { name: string; value: string; isColor: boolean }[]
  >([{ name: "", value: "", isColor: false }]);

  const [customerCenterStartTimeHour, setCustomerCenterStartTimeHour] =
    useState("");
  const [customerCenterStartTimeMinute, setCustomerCenterStartTimeMinute] =
    useState("");
  const [customerCenterEndTimeHour, setCustomerCenterEndTimeHour] =
    useState("");
  const [customerCenterEndTimeMinute, setCustomerCenterEndTimeMinute] =
    useState("");

  const [breakStartTimeHour, setBreakStartTimeHour] = useState("");
  const [breakStartTimeMinute, setBreakStartTimeMinute] = useState("");
  const [breakEndTimeHour, setBreakEndTimeHour] = useState("");
  const [breakEndTimeMinute, setBreakEndTimeMinute] = useState("");

  const [thumbnail1, setThumbnail1] = useState<File>();
  const [thumbnail2, setThumbnail2] = useState<File>();
  const [thumbnail3, setThumbnail3] = useState<File>();

  const [thum1Pre, setThum1Pre] = useState<string>("");
  const [thum2Pre, setThum2Pre] = useState<string>("");
  const [thum3Pre, setThum3Pre] = useState<string>("");

  const [mainImage, setMainImage] = useState<any>([]);
  const [detailImage, setDetailImage] = useState<any>([]);
  const [mainPre, setMainPre] = useState<any>([]);
  const [detailPre, setDetailPre] = useState<any>([]);

  const [selectedColor, setSelectedColor] = useState<string[]>([]);

  const { data: detailData, refetch: detailRefetch } = useStoreProductDetail(
    params.itemCode as string,
    authority === "ROLE_COMMERCE_ADMIN" ? state : ""
  );
  const { data: OData, refetch: oRefetch } = useUserOperationInfoApi(
    authority === "ROLE_COMMERCE_ADMIN" ? state : ""
  );

  const operation = OData?.data.ResultData as OperationResultData;

  const { data: deliveryCompanyList } = useDeliveryCompanyListApi();

  const detail = detailData?.data.ResultData as StoreProductDetailData;

  const [toggleValue, setToggleValue] = useState({
    sellDate: false,
    purchaseRestrict: false,
    option: false,
    breakTime: false,
  });

  const list = deliveryCompanyList?.data.ResultData;

  const [editData, setEditData] = useState<{
    thum1?: string;
    thum2?: string;
    thum3?: string;
    companyId?: null | number;
    cate1Name: string;
    cate2Name: string;
    cate3Name: string;
    itemId: string;
    categoryId: number;
    title: string;
    productNotice: string;
    manageCode: string;
    description: string;
    saleStartDate: string;
    saleEndDate: string;
    price: number;
    discountPrice: number;
    stock: number;
    purchaseMinQuantity: number;
    purchaseMaxQuantity: number;
    productInformation: string;
    productInformationImageYn: string;
    productInfo: {
      material: string;
      color: string;
      size: string;
      manufacturer: string;
      manufactureCountry: string;
      precautions: string;
      manufactureDate: string;
      qualityAssuranceStandard: string;
      servicePhoneNum: string;
      type: string;
      weight: string;
      expirationDate: string;
      instructions: string;
      vendor: string;
      ingredient: string;
      auditYn?: string;
      warrantyProvideYn?: any;
      productName: string;
      location: string;
      nutritionInfo: string;
      functionalInfo: string;
      amountOfIntake: string;
      sideEffectPossibility: string;
      geneticallyModifiedHealthFunctionalFood: string;
      importedFoodYn?: string;
      reportNumber: string;
      ratedVoltage: string;
      powerConsumption: string;
      sameModelReleaseDate: string;
      kcInfo: string;
      productConfiguration: string;
      detailSpecification: string;
      keySpecification: string;
      navigationUpdateCost: string;
      carName: string;
      certificateExaminationNum: string;
      certifiedInfo: string;
    };
    deliveryInfo: DeliveryInfo;
    returnExchangeInfo: ReturnExchangeInfo;
    serviceInfo: ServiceInfo;
    optionType: string;
    modelName: string;
    brandName: string;
    optionSort: string;
    topImages: TopImages[];
    detailImages: DetailImages[];
    options: Options;
  }>({
    cate1Name: "",
    cate2Name: "",
    cate3Name: "",
    productNotice: "",
    companyId: null,
    itemId: "",
    categoryId: 0,
    title: "",
    manageCode: "",
    description: "",
    saleStartDate: "",
    saleEndDate: "",
    price: 0,
    discountPrice: 0,
    stock: 0,
    purchaseMinQuantity: 0,
    purchaseMaxQuantity: 0,
    productInformation: "",
    productInformationImageYn: "N",
    productInfo: {
      material: "",
      color: "",
      size: "",
      manufacturer: "",
      manufactureCountry: "",
      precautions: "",
      manufactureDate: "",
      qualityAssuranceStandard: "",
      servicePhoneNum: "",
      type: "",
      weight: "",
      expirationDate: "",
      instructions: "",
      vendor: "",
      ingredient: "",
      auditYn: "",
      warrantyProvideYn: "",
      productName: "",
      location: "",
      nutritionInfo: "",
      functionalInfo: "",
      amountOfIntake: "",
      sideEffectPossibility: "",
      geneticallyModifiedHealthFunctionalFood: "",
      importedFoodYn: "",
      reportNumber: "",
      ratedVoltage: "",
      powerConsumption: "",
      sameModelReleaseDate: "",
      kcInfo: "",
      productConfiguration: "",
      detailSpecification: "",
      keySpecification: "",
      navigationUpdateCost: "",
      carName: "",
      certificateExaminationNum: "",
      certifiedInfo: "",
    },
    deliveryInfo: {
      deliveryCompanyCd: "",
      deliveryPriceType: "",
      freeDeliveryPrice: 0,
      defaultDeliveryPrice: 0,
      additionalDeliveryPrice: 0,
      forwardingPlaceAddr: "",
      forwardingPlaceDetailAddr: "",
      forwardingPlaceZip: "",
      deliveryNotice: "",
    },
    returnExchangeInfo: {
      returnExchangeCompanyCd: "",
      returnDeliveryPrice: 0,
      additionalReturnDeliveryPrice: 0,
      exchangeDeliveryPrice: 0,
      additionalExchangeDeliveryPrice: 0,
      returnExchangePlaceAddr: "",
      returnExchangePlaceDetailAddr: "",
      returnExchangePlaceZip: "",
      returnExchangeNotice: "",
      returnExchangeRestrictionNotice: "",
    },
    serviceInfo: {
      customerCenterStartTime: "",
      customerCenterEndTime: "",
      customerCenterNum: "",
      breakStartTime: "",
      breakEndTime: "",
      serviceNotice: "",
    },
    optionType: "NONE",
    modelName: "",
    brandName: "",
    optionSort: "",
    topImages: [],
    detailImages: [],
    options: {
      type: "",
      simples: null,
      combinations: null,
      optionAttributes: [],
    },
  });

  useEffect(() => {
    setEditData({
      ...editData,
      thum1: detail?.thumbnail,
      thum2: detail?.thumbnail4_5,
      thum3: detail?.thumbnail16_9,
      companyId: authority === "ROLE_COMMERCE_ADMIN" ? state : null,
      cate1Name: detail?.categories[0]?.name,
      productNotice: detail?.productNotice,
      cate2Name: detail?.categories[1]?.name,
      cate3Name: detail?.categories[2]?.name,
      itemId: params.itemCode as string,
      categoryId: detail?.categories[detail?.categories.length - 1].categoryId,
      title: detail?.title,
      manageCode: detail?.managedCode,
      description: detail?.description,
      saleStartDate: detail?.saleStartDate,
      saleEndDate: detail?.saleEndDate,
      price: detail?.price,
      discountPrice: detail?.discountPrice,
      stock: detail?.stock,
      purchaseMinQuantity: detail?.purchaseMinQuantity,
      purchaseMaxQuantity: detail?.purchaseMaxQuantity,
      productInformation: detail?.productInformation,
      productInformationImageYn: detail?.productInformationImageYn,
      productInfo: {
        material: detail?.material,
        color: detail?.color,
        size: detail?.size,
        manufacturer: detail?.manufacturer,
        manufactureCountry: detail?.manufactureCountry,
        precautions: detail?.precautions,
        manufactureDate: detail?.manufactureDate,
        qualityAssuranceStandard: detail?.qualityAssuranceStandard,
        servicePhoneNum: detail?.servicePhoneNum,
        type: detail?.type,
        weight: detail?.weight,
        expirationDate: detail?.expirationDate,
        instructions: detail?.instructions,
        vendor: detail?.vendor,
        ingredient: detail?.ingredient,
        auditYn: detail?.auditYn,
        warrantyProvideYn: detail?.warrantyProvideYn,
        productName: detail?.productName,
        location: detail?.location,
        nutritionInfo: detail?.nutritionInfo,
        functionalInfo: detail?.functionalInfo,
        amountOfIntake: detail?.amountOfIntake,
        sideEffectPossibility: detail?.sideEffectPossibility,
        geneticallyModifiedHealthFunctionalFood:
          detail?.geneticallyModifiedHealthFunctionalFood,
        importedFoodYn: detail?.importedFoodYn,
        reportNumber: detail?.reportNumber,
        ratedVoltage: detail?.ratedVoltage,
        powerConsumption: detail?.powerConsumption,
        sameModelReleaseDate: detail?.sameModelReleaseDate,
        kcInfo: detail?.kcInfo,
        productConfiguration: detail?.productConfiguration,
        detailSpecification: detail?.detailSpecification,
        keySpecification: detail?.keySpecification,
        navigationUpdateCost: detail?.navigationUpdateCost,
        carName: detail?.carName,
        certificateExaminationNum: detail?.certificateExaminationNum,
        certifiedInfo: detail?.certifiedInfo,
      },
      deliveryInfo: {
        deliveryCompanyName: detail?.deliveryCompanyName,
        deliveryCompanyCd: detail?.deliveryCompanyCd,
        deliveryPriceType: detail?.deliveryPriceType,
        freeDeliveryPrice: detail?.freeDeliveryPrice,
        defaultDeliveryPrice: detail?.defaultDeliveryPrice,
        additionalDeliveryPrice: detail?.additionalDeliveryPrice,
        forwardingPlaceAddr: detail?.forwardingPlaceAddr,
        forwardingPlaceDetailAddr: detail?.forwardingPlaceDetailAddr,
        forwardingPlaceZip: detail?.forwardingPlaceZip,
        deliveryNotice: detail?.deliveryNotice,
      },
      returnExchangeInfo: {
        returnExchangeCompanyName: detail?.returnExchangeCompanyName,
        returnExchangeCompanyCd: detail?.returnExchangeCompanyCd,
        returnDeliveryPrice: detail?.returnDeliveryPrice,
        additionalReturnDeliveryPrice: detail?.additionalReturnDeliveryPrice,
        exchangeDeliveryPrice: detail?.exchangeDeliveryPrice,
        additionalExchangeDeliveryPrice:
          detail?.additionalExchangeDeliveryPrice,
        returnExchangePlaceAddr: detail?.returnExchangePlaceAddr,
        returnExchangePlaceDetailAddr: detail?.returnExchangePlaceDetailAddr,
        returnExchangePlaceZip: detail?.returnExchangePlaceZip,
        returnExchangeNotice: detail?.returnExchangeNotice,
        returnExchangeRestrictionNotice:
          detail?.returnExchangeRestrictionNotice,
      },
      serviceInfo: {
        customerCenterStartTime: detail?.customerCenterStartTime,
        customerCenterEndTime: detail?.customerCenterEndTime,
        customerCenterNum: detail?.customerCenterNum,
        breakStartTime: detail?.breakStartTime,
        breakEndTime: detail?.breakEndTime,
        serviceNotice: detail?.serviceNotice,
      },
      optionType: detail?.optionType,
      modelName: detail?.modelName,
      brandName: detail?.brandName,
      optionSort: detail?.optionSort,
      topImages: detail?.topImages,
      detailImages: detail?.detailImages,
      options: {
        type: detail?.optionType === "INDEPENDENT" ? "SIMPLE" : "COMBINATION",
        simples: detail?.simples,
        combinations: detail?.combinations,
        optionAttributes: detail?.attributes,
      },
    });
  }, [detailData]);

  const [hour, setHour] = useState<string[]>([]);
  const [minute, setMinute] = useState<string[]>([]);

  const [selectedOption, setSelectedOption] = useState<{
    id: number;
    type: string;
  }>({ id: 0, type: "" });

  const [startDate, setStartDate] = useState<null | Date>(null);
  const [endDate, setEndDate] = useState<null | Date>(null);

  useEffect(() => {
    if (detail?.optionType === "INDEPENDENT") {
      setSelectedOption({
        id: 1,
        type: "단독형",
      });
    } else if (detail?.optionType === "COMBINATION") {
      setSelectedOption({
        id: 2,
        type: "조합형",
      });
    }
  }, [detail]);

  const radioList = [
    {
      id: 1,
      option: "INDEPENDENT",
      type: "단독형",
      desc: [
        "옵션 항목이 서로 독립적",
        "옵션 수량이 상품 수량과 동일함",
        "예시 : 장비선택 > 폴대, 보드, 고글)",
      ],
    },
    {
      id: 2,
      option: "COMBINATION",
      type: "조합형",
      desc: [
        "하위 옵션 항목이 상위 옵션 항목에 종속",
        "옵션별로 가격/수량을 별도로 설정 가능",
        "예시 : 옷 > 화이트 S,M, L / 블랙 S, M, L",
      ],
    },
  ];

  const [address, setAddress] = useState<daumAddress>({
    address: "",
    addressEnglish: "",
    addressType: "",
    apartment: "",
    autoJibunAddress: "",
    autoJibunAddressEnglish: "",
    autoRoadAddress: "",
    autoRoadAddressEnglish: "",
    bcode: "",
    bname: "",
    bname1: "",
    bname1English: "",
    bname2: "",
    bname2English: "",
    bnameEnglish: "",
    buildingCode: "",
    buildingName: "",
    hname: "",
    jibunAddress: "",
    jibunAddressEnglish: "",
    noSelected: "",
    postcode: "",
    postcode1: "",
    postcode2: "",
    postcodeSeq: "",
    query: "",
    roadAddress: "",
    roadAddressEnglish: "",
    roadname: "",
    roadnameCode: "",
    roadnameEnglish: "",
    sido: "",
    sidoEnglish: "",
    sigungu: "",
    sigunguCode: "",
    sigunguEnglish: "",
    userLanguageType: "",
    userSelectedType: "",
    zonecode: "",
  });

  const [returnAddress, setReturnAddress] = useState<daumAddress>({
    address: "",
    addressEnglish: "",
    addressType: "",
    apartment: "",
    autoJibunAddress: "",
    autoJibunAddressEnglish: "",
    autoRoadAddress: "",
    autoRoadAddressEnglish: "",
    bcode: "",
    bname: "",
    bname1: "",
    bname1English: "",
    bname2: "",
    bname2English: "",
    bnameEnglish: "",
    buildingCode: "",
    buildingName: "",
    hname: "",
    jibunAddress: "",
    jibunAddressEnglish: "",
    noSelected: "",
    postcode: "",
    postcode1: "",
    postcode2: "",
    postcodeSeq: "",
    query: "",
    roadAddress: "",
    roadAddressEnglish: "",
    roadname: "",
    roadnameCode: "",
    roadnameEnglish: "",
    sido: "",
    sidoEnglish: "",
    sigungu: "",
    sigunguCode: "",
    sigunguEnglish: "",
    userLanguageType: "",
    userSelectedType: "",
    zonecode: "",
  });

  useEffect(() => {
    let h = [];
    for (let i = 0; i < 24; i++) {
      h.push(String(i).padStart(2, "0"));
    }
    let m = [];
    for (let i = 0; i < 60; i++) {
      m.push(String(i).padStart(2, "0"));
    }
    setHour(h);
    setMinute(m);
  }, []);

  const colorSelectList = [
    {
      id: 0,
      name: "레드",
      checked: false,
      color: "#F64343",
    },
    {
      id: 1,
      name: "오렌지",
      checked: false,
      color: "#F68E43",
    },
    {
      id: 2,
      name: "옐로우",
      checked: false,
      color: "#F6CF43",
    },
    {
      id: 3,
      name: "그린",
      checked: false,
      color: "#72CC47",
    },
    {
      id: 4,
      name: "카키",
      checked: false,
      color: "#5F9633",
    },
    {
      id: 5,
      name: "스카이블루",
      checked: false,
      color: "#B2E4F4",
    },
    {
      id: 6,
      name: "블루",
      checked: false,
      color: "#2B8BE2",
    },
    {
      id: 7,
      name: "네이비",
      checked: false,
      color: "#475885",
    },
    {
      id: 8,
      name: "그레이",
      checked: false,
      color: "#999",
    },
    {
      id: 9,
      name: "차콜",
      checked: false,
      color: "#5F7A93",
    },
    {
      id: 10,
      name: "브라운",
      checked: false,
      color: "#A77254",
    },
    {
      id: 11,
      name: "베이지",
      checked: false,
      color: "#DCBB94",
    },
    {
      id: 12,
      name: "아이보리",
      checked: false,
      color: "#F2E8CC",
    },
    {
      id: 13,
      name: "블랙",
      checked: false,
      color: "#111",
    },
    {
      id: 14,
      name: "화이트",
      checked: false,
      color: "#FFF",
    },
  ];

  const productGroup = [
    { name: "의류", id: 0, type: "CLOTHING" },
    { name: "구두/신발", id: 1, type: "SHOES" },
    { name: "가방", id: 2, type: "BAG" },
    {
      name: "패션잡화(모바/벨트/액세서리 등)",
      type: "FASHION_MISCELLANEOUS_GOODS",
    },
    { name: "화장품", id: 4, type: "COSMETIC" },
    { name: "귀금속/보석/시계류", id: 5, type: "JEWELRY_CLOCK" },
    { name: "건강기능식품", id: 6, type: "HEALTH_FUNCTIONAL_FOOD" },
    { name: "의료기기", id: 7, type: "MEDICAL_DEVICE" },
    { name: "스포츠용품", id: 8, type: "SPORTS_EQUIPMENT" },
    { name: "내비게이션", id: 9, type: "NAVIGATION" },
    { name: "자동차용품(자동차부품/기타 자동차용품 등)", type: "CAR_SUPPLIES" },
    { name: "기타재화", id: 11, type: "OTHER_GOODS" },
  ];

  const itemSort = [
    { name: "등록순", id: 0, type: "CREATE" },
    { name: "가나다순", id: 1, type: "ABC" },
    { name: "낮은 가격순", id: 2, type: "LOW_PRICE" },
    { name: "높은 가격순", id: 3, type: "HIGH_PRICE" },
  ];

  const optionList = [
    { name: "색상", id: 0 },
    { name: "직접입력", id: 1 },
  ];

  const deliveryAmount = [
    { name: "무료", type: "FREE" },
    { name: "조건부 무료", type: "CONDITIONAL_FREE" },
    { name: "유료", type: "CHARGE" },
  ];

  const onChangeThumnail = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: 1 | 4 | 16
  ) => {
    if (e.target.files !== null) {
      let file = e.target.files[0];
      const fileSize = file?.size;

      if (fileSize > 1024 ** 2 * 1) {
        return alert("사이즈가 1mb 보다 큽니다.");
      } else {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          if (type === 1) {
            setThumbnail1(file);
            setThum1Pre(reader.result as string);
          } else if (type === 4) {
            setThumbnail2(file);
            setThum2Pre(reader.result as string);
          } else if (type === 16) {
            setThumbnail3(file);
            setThum3Pre(reader.result as string);
          }
        };
      }
    }
  };

  const onChangeMain = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files !== null) {
      let fileList = e.target.files;

      let file;
      let fileURLs: any[] = [];

      for (let i = 0; i < fileList.length; i++) {
        if (fileList[i]?.size > 1024 ** 2 * 1) {
          return alert(`사이즈가 1mb 보다 큰 파일이 있습니다.`);
        } else if (mainImage?.length > 5) {
          return alert(`최대 5장까지 업로드 가능합니다`);
        } else {
          let reader = new FileReader();
          file = fileList[i];
          reader.readAsDataURL(file);
          reader.onload = () => {
            fileURLs[i] = reader.result;
            setMainImage(fileList);
            setMainPre([...mainImage, ...fileURLs]);
          };
        }
      }
    }
  };

  const onChangeDetail = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files !== null) {
      let fileList = e.target.files;

      console.log(fileList);

      let file;
      let fileURLs: any[] = [];

      for (let i = 0; i < fileList?.length; i++) {
        if (fileList[i].size > 1024 ** 2 * 1) {
          return alert(`사이즈가 1mb 보다 큰 파일이 있습니다.`);
        } else if (detailImage?.length > 10) {
          return alert(`최대 10장까지 업로드 가능합니다`);
        } else {
          let reader = new FileReader();
          file = fileList[i];
          reader.onload = () => {
            fileURLs[i] = reader.result;
            setDetailImage(fileList);
            setDetailPre([...detailImage, ...fileURLs]);
          };
          reader.readAsDataURL(file);
        }
      }
    }
  };

  useEffect(() => {
    requestToLoveEgg({
      method: "GET",
      url: "/v1/categories",
    }).then((res) => {
      setCateList(res.data);
    });
  }, []);

  useEffect(() => {
    if (cate1.categoryId !== 0) {
      requestToLoveEgg({
        method: "GET",
        url: "/v1/categories",
        params: { parentId: cate1.categoryId },
      }).then((res) => {
        setCate1List(res.data);
      })
    }
  }, [cate1.categoryId]);

  useEffect(() => {
    if (cate2.categoryId !== 0) {
      requestToLoveEgg({
        method: "GET",
        url: "/v1/categories",
        params: { parentId: cate2.categoryId },
      }).then((res) => {
        setCate2List(res.data);
      });
    }
  }, [cate2.categoryId]);

  useEffect(() => {
    setEditData({
      ...editData,
      deliveryInfo: {
        ...editData.deliveryInfo,
        forwardingPlaceZip: address.zonecode,
        forwardingPlaceAddr: address.address,
        forwardingPlaceDetailAddr: "",
      },
    });
  }, [address]);

  useEffect(() => {
    setEditData({
      ...editData,
      returnExchangeInfo: {
        ...editData.returnExchangeInfo,
        returnExchangePlaceZip: returnAddress.zonecode,
        returnExchangePlaceAddr: returnAddress.address,
        returnExchangePlaceDetailAddr: "",
      },
    });
  }, [returnAddress]);

  useEffect(() => {
    const startDateObject = new Date(detail?.saleStartDate);
    const startDateString = String(startDateObject);

    const endDateObject = new Date(detail?.saleEndDate);
    const endDateString = String(endDateObject);

    if (
      startDateString !== "Invalid Date" &&
      endDateString !== "Invalid Date"
    ) {
      setStartDate(startDateObject);
      setEndDate(endDateObject);
    }
  }, [detail]);

  const handleEditData = () => {
    setButtonDuplication(true);
    const formData = new FormData();
    formData.append(
      "data",
      new Blob([JSON.stringify(editData)], { type: "application/json" })
    );

    requestToLoveEgg({
      method: "PUT",
      url: "/api/v1/items",
      data: formData,
      headers: {
        "Content-Type": `multipart/form-data`,
      },
    }).then((res) => {
      setButtonDuplication(false);
      if (res.data.ResultCode === 1) {
        setEdit(false);
        if (authority === "ROLE_COMMERCE_ADMIN") {
          navigate(`/admin/product/${res.data.ResultData}`, {
            replace: true,
            state: state,
          });
        } else {
          navigate(`/product/${res.data.ResultData}`, { replace: true });
        }
        setEditData({
          ...editData,
          itemId: res.data.ResultData,
        });
      } else {
        alert("에러");
      }
    });
  };

  const start = editData.serviceInfo.customerCenterStartTime?.split(":");
  const end = editData.serviceInfo.customerCenterEndTime?.split(":");
  const breakStart = editData.serviceInfo.breakStartTime?.split(":");
  const breakEnd = editData.serviceInfo.breakEndTime?.split(":");

  const operationStart = operation?.customerCenterStartTime?.split(":");
  const operationEnd = operation?.customerCenterEndTime?.split(":");
  const operationBreakstart = operation?.breakStartTime?.split(":");
  const operationBreakEnd = operation?.breakEndTime?.split(":");

  useEffect(() => {
    if (
      editData.serviceInfo.breakStartTime &&
      editData.serviceInfo.breakEndTime
    ) {
      setToggleValue({ ...toggleValue, breakTime: true });
    }
  }, [editData.serviceInfo.breakEndTime, editData.serviceInfo.breakStartTime]);

  useEffect(() => {
    setEditData({
      ...editData,
      serviceInfo: {
        ...editData.serviceInfo,
        customerCenterStartTime: `${
          customerCenterStartTimeHour
            ? customerCenterStartTimeHour
            : operationStart?.[0] !== undefined
            ? operationStart?.[0]
            : "00"
        }:${
          customerCenterStartTimeMinute
            ? customerCenterStartTimeMinute
            : operationStart?.[1] !== undefined
            ? operationStart?.[1]
            : "00"
        }`,
      },
    });
  }, [customerCenterStartTimeHour, customerCenterStartTimeMinute]);

  useEffect(() => {
    setEditData({
      ...editData,
      serviceInfo: {
        ...editData.serviceInfo,
        customerCenterEndTime: `${
          customerCenterEndTimeHour
            ? customerCenterEndTimeHour
            : operationEnd?.[0] !== undefined
            ? operationEnd?.[0]
            : "00"
        }:${
          customerCenterEndTimeMinute
            ? customerCenterEndTimeMinute
            : operationEnd?.[1] !== undefined
            ? operationEnd?.[1]
            : "00"
        }`,
      },
    });
  }, [customerCenterEndTimeHour, customerCenterEndTimeMinute]);

  useEffect(() => {
    setEditData({
      ...editData,
      serviceInfo: {
        ...editData.serviceInfo,
        breakStartTime: `${
          breakStartTimeHour
            ? breakStartTimeHour
            : operationBreakstart?.[0] !== undefined
            ? operationBreakstart?.[0]
            : "00"
        }:${
          breakStartTimeMinute
            ? breakStartTimeMinute
            : operationBreakstart?.[1] !== undefined
            ? operationBreakstart?.[1]
            : "00"
        }`,
      },
    });
  }, [breakStartTimeHour, breakStartTimeMinute]);

  useEffect(() => {
    setEditData({
      ...editData,
      serviceInfo: {
        ...editData.serviceInfo,
        breakEndTime: `${
          breakEndTimeHour
            ? breakEndTimeHour
            : operationBreakEnd?.[0] !== undefined
            ? operationBreakEnd?.[0]
            : "00"
        }:${
          breakEndTimeMinute
            ? breakEndTimeMinute
            : operationBreakEnd?.[1] !== undefined
            ? operationBreakEnd?.[1]
            : "00"
        }`,
      },
    });
  }, [breakEndTimeHour, breakEndTimeMinute]);

  const CHANGESIMPLEDATA = (
    data: OptionAttributes[],
    price?: number,
    stock?: number,
    idx?: number
  ) => {
    return data
      .filter((item) => item.name && item.value)
      .map((item: { isColor: any; value: string; name: any }) => {
        const details = item.value.split(",").map((value) => ({
          value,
          managedCode: "",
          price: price ? price : 0,
          optionId: 0,
          stock: stock ? stock : 0,
          status: "ON_SALE",
        }));

        return {
          name: item.name,
          details,
        };
      });
  };

  const CHANGECOMBINATIONDATA = (
    itemData: {
      thum1?: string;
      thum2?: string;
      thum3?: string;
      companyId?: null | number;
      cate1Name: string;
      cate2Name: string;
      cate3Name: string;
      itemId: string;
      categoryId: number;
      title: string;
      productNotice: string;
      manageCode: string;
      description: string;
      saleStartDate: string;
      saleEndDate: string;
      price: number;
      discountPrice: number;
      stock: number;
      purchaseMinQuantity: number;
      purchaseMaxQuantity: number;
      productInformation: string;
      productInformationImageYn: string;
      productInfo: {
        material: string;
        color: string;
        size: string;
        manufacturer: string;
        manufactureCountry: string;
        precautions: string;
        manufactureDate: string;
        qualityAssuranceStandard: string;
        servicePhoneNum: string;
        type: string;
        weight: string;
        expirationDate: string;
        instructions: string;
        vendor: string;
        ingredient: string;
        auditYn?: string;
        warrantyProvideYn?: any;
        productName: string;
        location: string;
        nutritionInfo: string;
        functionalInfo: string;
        amountOfIntake: string;
        sideEffectPossibility: string;
        geneticallyModifiedHealthFunctionalFood: string;
        importedFoodYn?: string;
        reportNumber: string;
        ratedVoltage: string;
        powerConsumption: string;
        sameModelReleaseDate: string;
        kcInfo: string;
        productConfiguration: string;
        detailSpecification: string;
        keySpecification: string;
        navigationUpdateCost: string;
        carName: string;
        certificateExaminationNum: string;
        certifiedInfo: string;
      };
      deliveryInfo: DeliveryInfo;
      returnExchangeInfo: ReturnExchangeInfo;
      serviceInfo: ServiceInfo;
      optionType: string;
      modelName: string;
      brandName: string;
      optionSort: string;
      topImages: TopImages[];
      detailImages: DetailImages[];
      options: Options;
    },
    price?: number,
    stock?: number,
    idx?: number
  ) => {
    const cartesianProduct = (arrays: any[]) =>
      arrays?.reduce(
        (acc: any[], array: any[]) =>
          acc.flatMap((x: string | any[]) =>
            array.map((y: any) => x.concat(y))
          ),
        [[]]
      );

    const optionAttributes = itemData?.options?.optionAttributes;

    if (!optionAttributes || optionAttributes.length === 0) {
      return [];
    }

    const combinations = cartesianProduct(
      optionAttributes.map((item: { value: string }) => item.value.split(","))
    );

    const formattedData = combinations.map((combo: any[]) => ({
      name: combo.map((value: any, index: number) => ({
        key: optionAttributes[index].name,
        value: value,
      })),
      price: price ? price : 0,
      stock: stock ? stock : 0,
      managedCode: "",
      optionId: 0,
      status: "ON_SALE",
    }));

    return formattedData;
  };

  useEffect(() => {
    setToggleValue({
      ...toggleValue,
      sellDate:
        detail?.saleStartDate !== "" && detail?.saleEndDate !== ""
          ? true
          : false,
      purchaseRestrict:
        detail?.purchaseMinQuantity !== 0 && detail?.purchaseMaxQuantity !== 0
          ? true
          : false,
      option: detail?.attributes.length !== 0 ? true : false,
      breakTime:
        detail?.breakStartTime !== "" && detail?.breakEndTime ? true : false,
    });
  }, [detailData]);

  const handleDeleteItem = () => {
    requestToLoveEgg({
      method: "DELETE",
      url: "/api/v1/items",
      data: {
        itemId: params?.itemCode,
        companyId: authority === "ROLE_COMMERCE_ADMIN" ? state : null,
      },
    }).then((res) => {
      if (res.data.ResultCode === 1) {
        if (authority === "ROLE_COMMERCE_ADMIN") {
          navigate("/admin/product/all", { replace: true });
        } else {
          navigate("/product/all", { replace: true });
        }
        toast.error("상품이 삭제되었습니다.");
      }
    });
  };

  const optionStatus = [
    { name: "판매중", color: "#47B50B", status: "ON_SALE" },
    { name: "판매중지", color: "#FAD619", status: "SUSPENSION_OF_SALE" },
    { name: "품절", color: "#EA2C4E", status: "OUT_OF_STOCK" },
  ];

  return (
    <div>
      {deleteModal && (
        <ModalPopup
          onClick={() => {
            handleDeleteItem();
          }}
          type={"md"}
          buttonText={"삭제하기"}
          cancalButton
          setCloseState={setDeleteModal}
        >
          <div className="H4 text-Gray-100">
            해당 상품을 <span className="text-Red100">삭제</span>하시나요?
          </div>
          <div className="mt-4 whitespace-pre-wrap Body2 text-Gray-80">
            {`삭제 시 되돌릴 수 없습니다.\n신중하게 결정해 주세요.`}
          </div>
        </ModalPopup>
      )}
      {changeOptionType && (
        <ModalPopup
          setCloseState={setChangeOptionType}
          cancalButton
          type="md"
          buttonText="변경하기"
        >
          <div className="H4 text-Gray-100">옵션 유형 변경하시겠습니까?</div>
          <div className="mt-4 Body4 text-Gray-60">
            유형 변경시 등록된 옵션 항목 정보가 모두 삭제됩니다.{" "}
          </div>
        </ModalPopup>
      )}
      {saleEnd && (
        <ModalPopup
          setCloseState={setSaleEnd}
          onClick={() => {
            requestToLoveEgg({
              method: "PUT",
              url: `/api/v1/items/${params.itemCode!}/status/END_OF_SALE`,
              params: {
                companyId: authority === "ROLE_COMMERCE_ADMIN" ? state : null,
              },
            }).then((res) => {
              if (res.data.ResultCode === 1) {
                setSaleEnd(false);
                toast.error(
                  "`판매종료`로 상품 상태를 변경했습니다 :)\n이제 해당 상품은 판매할 수 없습니다."
                );
                detailRefetch();
              }
            });
          }}
          buttonText="판매 종료하기"
          cancalButton
          type="md"
        >
          <div className="Body2">
            <div className="H4">
              해당 상품을 <span className="text-Red100">판매종료</span>{" "}
              하시나요?
            </div>
            <div className="mt-4 whitespace-pre-line break-keep">
              {`판매종료 시 해당 상품은 수정이 불가능하며,\n삭제만 가능합니다.\n또한, 다시 판매할 수 없으니 신중하게 결정해 주세요.`}
            </div>
          </div>
        </ModalPopup>
      )}
      {categoryPop && (
        <PopupSelect
          title="카테고리 선택"
          onClick={() => {
            setEditData({
              ...editData,
              cate1Name: cate1.name,
              cate2Name: cate2.name,
              cate3Name: cate3.name,
            });
          }}
          setCloseState={setCategoryPop}
        >
          {cate1.name && (
            <div className="flex items-center justify-between w-full px-4 py-3 border-t border-l border-r rounded-t-lg border-Gray-40">
              <div className="flex items-center gap-2">
                <div>{cate1.name}</div>
                <div>{cate1.name && ">"}</div>
                <div>{cate2.name}</div>
                <div>{cate2.name && ">"}</div>
                <div>{cate3.name}</div>
              </div>
              <Close
                onClick={() => {
                  setCate1({ categoryId: 0, name: "" });
                  setCate2({ categoryId: 0, name: "" });
                  setCate3({ categoryId: 0, name: "" });
                  setCate1List({ ResultCode: 0, ResultData: [] });
                  setEditData({ ...editData, categoryId: 0 });
                  setCate2List({ ResultCode: 0, ResultData: [] });
                }}
                className="cursor-pointer"
              />
            </div>
          )}
          <div className="flex items-start h-[410px]">
            <div className="overflow-y-auto grow w-full h-[410px] max-h-[410px] border-t border-l border-b border-r border-Gray-40">
              {cateList?.ResultData?.map((item, i) => {
                const categoryId = Number(item.categoryId);
                return (
                  <div
                    key={categoryId}
                    onClick={() => {
                      setCate1({ categoryId: categoryId, name: item.name });
                      setCate2({ categoryId: 0, name: "" });
                      setCate3({ categoryId: 0, name: "" });
                      setEditData({ ...editData, categoryId: categoryId });
                      setCate2List({ ResultCode: 0, ResultData: [] });
                    }}
                    className={`${
                      cate1.categoryId === categoryId
                        ? "text-Blue-100"
                        : "text-Gray-90"
                    } px-4 py-4 cursor-pointer Body4 hover:text-Blue-100 hover:bg-Blue-50`}
                  >
                    {item.name}
                  </div>
                );
              })}
            </div>
            <div className="overflow-y-auto grow w-full h-[410px] max-h-[410px] border-t border-b border-r border-Gray-40">
              {cate1List?.ResultData?.map((item, i) => {
                const categoryId = Number(item.categoryId);
                return (
                  <div
                    key={categoryId}
                    onClick={() => {
                      setCate2({ categoryId: categoryId, name: item.name });
                      setEditData({ ...editData, categoryId: categoryId });
                      setCate3({ categoryId: 0, name: "" });
                    }}
                    className={`${
                      cate2.categoryId === categoryId
                        ? "text-Blue-100"
                        : "text-Gray-90"
                    } px-4 py-4 cursor-pointer Body4 hover:text-Blue-100 hover:bg-Blue-50`}
                  >
                    {item.name}
                  </div>
                );
              })}
            </div>
            <div className="overflow-y-auto grow w-full h-[410px] max-h-[410px] border-t border-b border-r border-Gray-40">
              {cate2List?.ResultData?.map((item, i) => {
                const categoryId = Number(item.categoryId);
                return (
                  <div
                    key={categoryId}
                    onClick={() => {
                      setEditData({ ...editData, categoryId: categoryId });
                      setCate3({ categoryId: categoryId, name: item.name });
                    }}
                    className={`${
                      cate3.categoryId === categoryId
                        ? "text-Blue-100"
                        : "text-Gray-90"
                    } px-4 py-4 cursor-pointer Body4 hover:text-Blue-100 hover:bg-Blue-50`}
                  >
                    {item.name}
                  </div>
                );
              })}
            </div>
          </div>
        </PopupSelect>
      )}
      {deliveryPop && (
        <PopupSelect
          title="배송사 선택"
          onClickCancelButton={() => {
            setDeliveryValue({ cd: "", cdNm: "" });
          }}
          onClick={() => {
            setEditData({
              ...editData,
              deliveryInfo: {
                ...editData.deliveryInfo,
                deliveryCompanyName: deliveryValue.cdNm,
                deliveryCompanyCd: deliveryValue.cd,
              },
            });
          }}
          setCloseState={setDeliveryPop}
        >
          <div className="h-[400px] overflow-y-auto border rounded-lg border-Gray-40 flex flex-wrap items-start">
            {list?.map((d) => {
              return (
                <div
                  key={d.cd}
                  onClick={() => {
                    setDeliveryValue({ cd: d.cd, cdNm: d.cdNm });
                  }}
                  className={`${
                    deliveryValue.cd === d.cd ? "text-Blue-100" : "text-Gray-90"
                  } Body5 w-[25%] h-[48px] px-4 flex flex-col justify-center hover:bg-Blue-50 cursor-pointer`}
                >
                  {d.cdNm}
                </div>
              );
            })}
          </div>
        </PopupSelect>
      )}
      {returnDeliveryPop && (
        <PopupSelect
          title="배송사 선택"
          onClickCancelButton={() => {
            setReturnDeliveryValue({ cd: "", cdNm: "" });
          }}
          onClick={() => {
            setEditData({
              ...editData,
              returnExchangeInfo: {
                ...editData.returnExchangeInfo,
                returnExchangeCompanyName: returnDeliveryValue.cdNm,
                returnExchangeCompanyCd: returnDeliveryValue.cd,
              },
            });
          }}
          setCloseState={setReturnDeliveryPop}
        >
          <div className="h-[400px] overflow-y-auto border rounded-lg border-Gray-40 flex flex-wrap items-start">
            {list?.map((r) => {
              return (
                <div
                  key={r.cd}
                  onClick={() => {
                    setReturnDeliveryValue({ cd: r.cd, cdNm: r.cdNm });
                  }}
                  className={`${
                    returnDeliveryValue.cd === r.cd
                      ? "text-Blue-100"
                      : "text-Gray-90"
                  } Body5 w-[25%] h-[48px] px-4 flex flex-col justify-center hover:bg-Blue-50 cursor-pointer`}
                >
                  {r.cdNm}
                </div>
              );
            })}
          </div>
        </PopupSelect>
      )}
      {colorModal && (
        <PopupSelect
          onClick={() => {
            setEditData({
              ...editData,
              options: {
                ...editData.options,
                optionAttributes: [
                  {
                    ...editData.options.optionAttributes[0],
                    value: selectedColor.join(","),
                  },
                  ...editData.options.optionAttributes.slice(1),
                ],
              },
            });
          }}
          title="색상 선택"
          setCloseState={setColorModal}
        >
          <div className="flex flex-wrap item-start gap-x-4 gap-y-7">
            {colorSelectList.map(({ name, checked, color, id }, i) => {
              return (
                <CheckBox
                  key={id}
                  onChange={(e) => {
                    if (selectedColor.includes(name)) {
                      setSelectedColor(selectedColor.filter((x) => x !== name));
                    } else {
                      setSelectedColor([...selectedColor, name]);
                    }
                  }}
                  checked={selectedColor.includes(name)}
                  style={{ borderBottom: `4px solid ${color}` }}
                  className={`w-[114px] py-2`}
                  text={name}
                />
              );
            })}
          </div>
          <div
            className={`w-full mb-5 mt-[60px] px-4 py-3 rounded-lg bg-Gray-20 flex ${
              selectedColor.length !== 0 ? "flex-row" : "flex-col gap-1"
            }`}
          >
            {selectedColor.length !== 0 ? (
              selectedColor.map((name, i) => {
                if (selectedColor.length === i + 1) {
                  return (
                    <div key={i} className="Body4 text-Gray-80">
                      {name}
                    </div>
                  );
                } else {
                  return (
                    <div key={i} className="Body4 text-Gray-80">
                      {name},
                    </div>
                  );
                }
              })
            ) : (
              <>
                <div className="text-Gray-90 SubH2">옵션 값</div>
                <div className="Body4 text-Gray-80">색상을 선택해 주세요</div>
              </>
            )}
          </div>
        </PopupSelect>
      )}
      <NewStoreWrap title="기본 설정">
        {authority === "ROLE_COMMERCE_ADMIN" && (
          <NewStoreForm itemCenter title="상점명">
            <div className="Body3 text-Gray-90">
              {detailData?.data.ResultData.companyName}
            </div>
          </NewStoreForm>
        )}
        <NewStoreForm
          className="flex items-center"
          itemCenter
          required
          title="카테고리"
        >
          {(editData.cate1Name || editData.cate2Name || editData.cate3Name) && (
            <div className="mr-4 Body3 text-Gray-90">
              {editData.cate1Name} {editData.cate2Name && ">"}{" "}
              {editData.cate2Name} {editData.cate3Name && ">"}{" "}
              {editData.cate3Name}
            </div>
          )}
          {edit && (
            <ButtonFilledSecondary
              onClick={() => {
                setCategoryPop(true);
              }}
              size={"md"}
              className="!w-[108px] h-[52px]"
              btnText={`카테고리 선택`}
            />
          )}
        </NewStoreForm>
        <NewStoreForm required title="상품명">
          <TextLimitHorizontalInput
            row={4}
            inputValue={editData.title}
            disabled={!edit}
            currentLength={editData.title?.length}
            onChange={(e) => {
              setEditData({ ...editData, title: e.target.value });
            }}
            className="w-[665px]"
            maxLength={30}
            placeholder="상품명을 입력해 주세요"
          />
          <div className="mt-2 text-Red100 Body4">
            판매 상품과 직접 관련이 없는 상품명은 관리자에 의해 변경될 수
            있습니다.
          </div>
        </NewStoreForm>
        <NewStoreForm required title="상품소개">
          <TextLimitHorizontalInput
            row={4}
            inputValue={editData.description}
            disabled={!edit}
            currentLength={editData.description?.length}
            onChange={(e) => {
              setEditData({ ...editData, description: e.target.value });
            }}
            className="w-[665px]"
            maxLength={50}
            placeholder="상품 소개 글을 입력해 주세요"
          />
          <div className="mt-2 text-Blue-70 Body4">
            상품 상세페이지 내에서 상품명과 함께 보여지는 내용입니다.
          </div>
        </NewStoreForm>
        <NewStoreForm title="상품 관리코드">
          <TextFieldInput
            disabled={!edit}
            inputValue={editData.manageCode}
            onChange={(e) => {
              setEditData({ ...editData, manageCode: e.target.value });
            }}
            className="w-[476px]"
            placeholder="영문, 숫자만 입력 가능"
          />
          <div className="mt-2 text-Blue-70 Body4">
            내부적으로 사용할 상품 관리용 번호(코드)를 입력해 주세요.
          </div>
        </NewStoreForm>
        <NewStoreForm title="상품 유의사항">
          <TextLimitHorizontalInput
            row={4}
            disabled={!edit}
            inputValue={editData.productNotice}
            currentLength={editData.productNotice?.length}
            onChange={(e) => {
              setEditData({ ...editData, productNotice: e.target.value });
            }}
            className="w-[665px]"
            maxLength={300}
            placeholder="상품 관련 고객에게 전달할 공지사항이나 유의사항이 있다면 작성해 주세요"
          />
        </NewStoreForm>
        <NewStoreForm className="w-full" title="판매 기한">
          <div className="flex items-center gap-2 h-[52px]">
            <Toggle
              disabled={!edit}
              id="sellDate"
              isOn={toggleValue.sellDate}
              onChange={(e) => {
                setToggleValue({
                  ...toggleValue,
                  sellDate: !toggleValue.sellDate,
                });
                setStartDate(null);
                setEndDate(null);
                setEditData({
                  ...editData,
                  saleStartDate: "",
                  saleEndDate: "",
                });
              }}
            />
            <div className="SubH2 text-Gray-90">
              {toggleValue.sellDate ? "설정함" : "설정안함"}
            </div>
          </div>
          {toggleValue?.sellDate && (
            <>
              <div className="w-full p-5 bg-Gray-20">
                <div className="flex items-center">
                  <DatePicker
                    className="flex items-center px-3 py-2 h-[52px] border border-Gray-40 Body4 text-Gray-90 rounded-lg"
                    placeholderText="시작일"
                    selected={startDate}
                    minDate={new Date()}
                    disabled={!edit}
                    onChange={(date: Date) => {
                      setStartDate(date);
                      setEndDate(null);
                      setEditData({
                        ...editData,
                        saleStartDate: dateFormat(date),
                        saleEndDate: "",
                      });
                    }}
                    selectsStart
                    dateFormat="yyyy.MM.dd"
                    startDate={startDate}
                    endDate={endDate}
                  />
                  <div className="px-2">~</div>
                  <DatePicker
                    disabled={!startDate || !edit}
                    placeholderText="종료일"
                    className="flex items-center px-3 py-2 h-[52px] border border-Gray-40 Body4 text-Gray-90 rounded-lg"
                    selected={endDate}
                    onChange={(date: Date) => {
                      setEndDate(date);
                      setEditData({
                        ...editData,
                        saleEndDate: dateFormat(date),
                      });
                    }}
                    selectsEnd
                    dateFormat="yyyy.MM.dd"
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                  />
                </div>
              </div>
            </>
          )}
        </NewStoreForm>
      </NewStoreWrap>
      <NewStoreWrap title="가격">
        <NewStoreForm required title="기본 금액">
          <TextFieldInput
            disabled={!edit}
            inputValue={String(editData.price)}
            onChange={(e) => {
              setEditData({
                ...editData,
                price: Number(e.target.value),
                discountPrice: 0,
              });
            }}
            inputOnButton="원"
            className="w-[476px]"
            placeholder="금액 입력"
          />
        </NewStoreForm>
        <NewStoreForm title="할인 금액">
          <TextFieldInput
            disabled={!edit}
            inputValue={String(editData.discountPrice)}
            onChange={(e) => {
              if (editData.price < Number(e.target.value)) {
                setEditData({ ...editData, discountPrice: editData.price });
              } else {
                setEditData({
                  ...editData,
                  discountPrice: Number(e.target.value),
                });
              }
            }}
            inputOnButton="원"
            className="w-[476px]"
            placeholder="금액 입력"
          />
          <div className="mt-2 text-Blue-100 Body4">
            할인율 :{" "}
            {editData.discountPrice !== 0 && editData.price !== 0
              ? ((editData.discountPrice / editData.price) * 100).toFixed(2)
              : 0}
            %
          </div>
        </NewStoreForm>
        <NewStoreForm title="최종 판매 가격" itemCenter>
          <div className="H4 text-Gray-90">
            {PriceComma(editData.price - editData.discountPrice)} 원
          </div>
        </NewStoreForm>
      </NewStoreWrap>
      <NewStoreWrap title="상품 재고">
        <NewStoreForm required title="재고">
          <TextFieldInput
            disabled={!edit}
            inputValue={String(editData.stock)}
            onChange={(e) => {
              setEditData({ ...editData, stock: Number(e.target.value) });
            }}
            className="w-[258px]"
            placeholder="상품 재고를 입력해 주세요"
          />
          <div className="mt-2 Body4 text-Blue-70">
            옵셜 설정{">"} 단독형 옵션 설정 시 각각 옵션 항목에 재고 수량이 공통
            적용됩니다.
          </div>
        </NewStoreForm>
      </NewStoreWrap>
      <NewStoreWrap title="구매 수량 제한 설정">
        <NewStoreForm className="w-full" title="1인당 구매 제한">
          <div className="flex items-center gap-2 h-[52px]">
            <Toggle
              disabled={!edit}
              id="purchaseRestrict"
              isOn={toggleValue.purchaseRestrict}
              onChange={(e) => {
                setToggleValue({
                  ...toggleValue,
                  purchaseRestrict: !toggleValue.purchaseRestrict,
                });
                setEditData({
                  ...editData,
                  purchaseMinQuantity: 0,
                  purchaseMaxQuantity: 0,
                });
              }}
            />
            <div className="SubH2 text-Gray-90">
              {toggleValue.purchaseRestrict ? "설정함" : "설정안함"}
            </div>
          </div>
          {toggleValue.purchaseRestrict && (
            <>
              <div className="mb-2 Body4 text-Blue-70">
                계정 1개당 한 번만 구매 할 수 있도록 제한하는 설정입니다.
              </div>
              <div className="w-full p-5 bg-Gray-20">
                <div className="flex items-center">
                  <TextFieldInput
                    disabled={!edit}
                    inputValue={String(editData.purchaseMinQuantity)}
                    onChange={(e) => {
                      setEditData({
                        ...editData,
                        purchaseMinQuantity: Number(e.target.value),
                      });
                    }}
                    className="w-[157px]"
                    placeholder="최소 수량"
                  />
                  <div className="px-2">~</div>
                  <TextFieldInput
                    disabled={!edit}
                    inputValue={String(editData.purchaseMaxQuantity)}
                    onChange={(e) => {
                      setEditData({
                        ...editData,
                        purchaseMaxQuantity: Number(e.target.value),
                      });
                    }}
                    className="w-[157px]"
                    placeholder="최대 수량"
                  />
                </div>
              </div>
            </>
          )}
        </NewStoreForm>
      </NewStoreWrap>
      <NewStoreWrap title="옵션 설정">
        <NewStoreForm itemCenter title="옵션">
          <div className="flex items-center gap-2 h-[52px]">
            <Toggle
              disabled={!edit}
              id="option"
              isOn={toggleValue.option}
              onChange={(e) =>
                setToggleValue({ ...toggleValue, option: e.target.checked })
              }
            />
            <div className="SubH2 text-Gray-90">
              {toggleValue.option ? "설정함" : "설정안함"}
            </div>
          </div>
        </NewStoreForm>
        {toggleValue.option && (
          <>
            <NewStoreForm required title="옵션 유형">
              <div className="flex items-center gap-7">
                {radioList.map(({ type, id, desc, option }) => {
                  return (
                    <div key={id} className="flex flex-col gap-2.5">
                      <Radio
                        disabled={!edit}
                        value={type}
                        checked={
                          selectedOption.id === id &&
                          selectedOption.type === type
                        }
                        onChange={(e) => {
                          if (id === 1) {
                            setEditData({
                              ...editData,
                              optionType: option,
                              options: { ...editData.options, type: "SIMPLE" },
                            });
                          } else if (id === 2) {
                            setEditData({
                              ...editData,
                              optionType: option,
                              options: {
                                ...editData.options,
                                type: "COMBINATION",
                              },
                            });
                          }
                          setSelectedOption({ type: e.target.value, id: id });
                        }}
                        id={String(id)}
                      >
                        {type}
                      </Radio>
                      <div>
                        {desc.map((d, i) => {
                          if (desc.length === i + 1) {
                            return (
                              <div key={i} className="Body4 text-Blue-70">
                                {d}
                              </div>
                            );
                          } else {
                            return (
                              <div key={i} className="Body4 text-Gray-80">
                                {d}
                              </div>
                            );
                          }
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </NewStoreForm>
            <NewStoreForm required title="옵션 정렬 기준">
              <Dropdown
                disabled={!edit}
                width="w-[258px]"
                innerText={
                  editData.optionSort === "CREATE"
                    ? "등록순"
                    : editData.optionSort === "ABC"
                    ? "가나다순"
                    : editData.optionSort === "LOW_PRICE"
                    ? "낮은 가격순"
                    : editData.optionSort === "HIGH_PRICE"
                    ? "높은 가격순"
                    : "선택"
                }
              >
                {itemSort.map(({ id, name, type }) => {
                  return (
                    <li
                      key={id}
                      onClick={() => {
                        setEditData({ ...editData, optionSort: type });
                      }}
                      className="dropdownWrap"
                    >
                      <div className="dropdownLi">{name}</div>
                    </li>
                  );
                })}
              </Dropdown>
            </NewStoreForm>
            <div className="border-b border-Gray-40" />
            <NewStoreForm
              className="flex flex-col w-full gap-4"
              required
              title="옵션 항목"
            >
              <div className="flex items-center gap-6">
                <div className="flex items-center w-[355px]">
                  <div className="Body4 text-Gray-80 w-[52px]">옵션명</div>
                  <Dropdown
                    disabled={!edit}
                    width="w-[120px]"
                    innerText={
                      editData?.options.optionAttributes[0]?.isColor
                        ? "색상"
                        : "직접입력"
                    }
                  >
                    {optionList.map(({ name, id }, i) => {
                      return (
                        <li
                          key={id}
                          onClick={() => {
                            setSelectedColor([]);
                            setEditData({
                              ...editData,
                              options: {
                                ...editData.options,
                                optionAttributes: [
                                  {
                                    ...editData.options.optionAttributes[0],
                                    name: id === 0 ? "색상" : "",
                                    isColor: id === 0 ? true : false,
                                  },
                                  ...editData.options.optionAttributes.slice(1),
                                ],
                              },
                            });
                          }}
                          className="dropdownWrap"
                        >
                          <div className="dropdownLi">{name}</div>
                        </li>
                      );
                    })}
                  </Dropdown>
                  {editData?.options.optionAttributes[0]?.isColor === false && (
                    <TextFieldInput
                      disabled={!edit}
                      inputValue={editData.options.optionAttributes[0].name}
                      onChange={(e) => {
                        setEditData({
                          ...editData,
                          options: {
                            ...editData.options,
                            optionAttributes: [
                              {
                                ...editData.options.optionAttributes[0],
                                name: e.target.value,
                                isColor: false,
                              },
                              ...editData.options.optionAttributes.slice(1),
                            ],
                          },
                        });
                      }}
                      className="w-[175px] ml-2"
                    />
                  )}
                </div>
                {editData?.options.optionAttributes[0]?.isColor === false ? (
                  <div className="flex items-center gap-4">
                    <div className="Body4 text-Gray-80">옵션값</div>
                    <TextFieldInput
                      disabled={!edit}
                      inputValue={editData.options.optionAttributes[0]?.value}
                      onChange={(e) => {
                        setEditData({
                          ...editData,
                          options: {
                            ...editData.options,
                            optionAttributes: [
                              {
                                ...editData.options.optionAttributes[0],
                                value: e.target.value,
                                // .replace(/(\s*)/g, "")
                              },
                              ...editData.options.optionAttributes.slice(1),
                            ],
                          },
                        });
                      }}
                      className="w-[372px]"
                      placeholder="옵션값은 쉼표(,)로 구분"
                    />
                  </div>
                ) : editData.options.optionAttributes[0]?.isColor === true ? (
                  <div className="flex items-center">
                    <div className="Body4 text-Gray-80 w-[52px]">옵션값</div>
                    <div
                      className={
                        selectedColor.length !== 0
                          ? "w-[248px] flex flex-wrap mr-4"
                          : ""
                      }
                    >
                      {/* {selectedColor.length !== 0 &&
                          selectedColor.map((name, i) => {
                            if (selectedColor.length === i + 1) {
                              return (
                                <div key={i} className="text-Gray-90 Body3">
                                  {name}
                                </div>
                              );
                            } else {
                              return (
                                <div key={i} className="text-Gray-90 Body3">
                                  {name},
                                </div>
                              );
                            }
                          })} */}
                      {editData.options.optionAttributes[0].isColor ===
                        true && (
                        <div className="w-[248px] flex flex-wrap mr-4 Body3 text-Gray-90">
                          {editData.options.optionAttributes[0]?.value}
                        </div>
                      )}
                    </div>
                    <ButtonFilledSecondary
                      size={"md"}
                      disabled={!edit}
                      onClick={() => setColorModal(true)}
                      className="!w-[108px] h-[52px]"
                      btnText={`색상 선택`}
                    />
                  </div>
                ) : (
                  ""
                )}
                {editData.options.optionAttributes?.length < 2 && edit && (
                  <OptionPlus
                    onClick={() => {
                      setEditData({
                        ...editData,
                        options: {
                          ...editData.options,
                          optionAttributes: [
                            ...editData.options.optionAttributes,
                            { name: "", value: "", isColor: false },
                          ],
                        },
                      });
                    }}
                    className="rounded-lg bg-Gray-20 p-1.5 cursor-pointer"
                  />
                )}
              </div>
              {editData.options.optionAttributes[1] && (
                <div className="relative flex items-center gap-6">
                  <div className="flex items-center gap-4">
                    <div className="Body4 text-Gray-80">옵션명</div>
                    <TextFieldInput
                      disabled={!edit}
                      inputValue={editData.options.optionAttributes[1]?.name}
                      onChange={(e) => {
                        setEditData({
                          ...editData,
                          options: {
                            ...editData.options,
                            optionAttributes: [
                              ...editData.options.optionAttributes.slice(0, 1),
                              {
                                ...editData.options.optionAttributes[1],
                                name: e.target.value,
                              },
                              ...editData.options.optionAttributes.slice(2),
                            ],
                          },
                        });
                      }}
                      className="w-[303px]"
                      placeholder="직접입력"
                    />
                  </div>
                  <div className="flex items-center gap-4">
                    <div className="Body4 text-Gray-80">옵션값</div>
                    <TextFieldInput
                      disabled={!edit}
                      inputValue={editData.options.optionAttributes[1]?.value}
                      onChange={(e) => {
                        setEditData({
                          ...editData,
                          options: {
                            ...editData.options,
                            optionAttributes: [
                              ...editData.options.optionAttributes.slice(0, 1),
                              {
                                ...editData.options.optionAttributes[1],
                                value: e.target.value,
                                // .replace(/(\s*)/g, ""),
                              },
                              ...editData.options.optionAttributes.slice(2),
                            ],
                          },
                        });
                      }}
                      className="w-[372px]"
                      placeholder="옵션값은 쉼표(,)로 구분"
                    />
                  </div>
                  {editData.options.optionAttributes.length !== 1 && edit && (
                    <OptionMinus
                      onClick={() => {
                        const updatedOptionAttributes = [
                          ...editData.options.optionAttributes.slice(0, 1),
                          ...editData.options.optionAttributes.slice(2),
                        ];
                        setEditData({
                          ...editData,
                          options: {
                            ...editData.options,
                            optionAttributes: updatedOptionAttributes,
                          },
                        });
                      }}
                      className="rounded-lg bg-Gray-20 p-1.5 cursor-pointer"
                    />
                  )}
                </div>
              )}
              <div>
                <ButtonFilledSecondary
                  onClick={() => {
                    if (editData.options.type === "COMBINATION") {
                      const COMBINATION = CHANGECOMBINATIONDATA(
                        editData as any
                      );
                      setEditData({
                        ...editData,
                        options: {
                          ...editData.options,
                          simples: null,
                          combinations: COMBINATION as Combinations[],
                        },
                      });
                    } else if (editData.options.type === "SIMPLE") {
                      const SIMPLE = CHANGESIMPLEDATA(
                        editData.options?.optionAttributes
                      );
                      setEditData({
                        ...editData,
                        options: {
                          ...editData.options,
                          simples: SIMPLE,
                          combinations: null,
                        },
                      });
                    }
                  }}
                  className="!mx-0"
                  disabled={
                    editData.optionType === "NONE" ||
                    editData.options.optionAttributes.length === 1
                      ? editData.options.optionAttributes[0]?.name === "" ||
                        editData.options.optionAttributes[0]?.value === "" ||
                        !edit
                      : editData.options.optionAttributes[0]?.name === "" ||
                        editData.options.optionAttributes[1]?.value === "" ||
                        editData.options.optionAttributes[1]?.name === "" ||
                        !edit
                  }
                  size={"md"}
                  btnText={"옵션 목록에 적용하기"}
                />
              </div>
            </NewStoreForm>
            <div className="border-b border-Gray-40" />
            <div className="pt-2.5 px-3">
              <div className="SubH2 text-Gray-90">
                옵션 목록 (
                {(editData?.options?.type === "SIMPLE"
                  ? editData?.options?.simples !== null &&
                    editData?.options?.simples[0]?.details.length +
                      (editData?.options?.simples[1]?.details?.length || 0)
                  : editData?.options?.combinations !== null &&
                    editData?.options?.combinations.length) || 0}
                )
              </div>
              <div className="Body4 text-Blue-70 mt-2.5 mb-5 whitespace-pre-wrap">{`・ 재고가 0인 경우 자동으로 품절 상태로 바뀝니다.\n・ 판매 상태가 ‘판매 종료'인 경우 더이상 상태 변경 불가합니다`}</div>
              <div className="flex items-center justify-between">
                <ButtonLinedTertiary
                  className="!mx-0"
                  size={"sm"}
                  btnText={"선택 삭제"}
                />
                <div className="flex items-center gap-2">
                  <TextUnitFieldInput
                    className="w-[200px]"
                    inputValue={
                      optionInfo.addPrice !== 0
                        ? String(optionInfo.addPrice)
                        : ""
                    }
                    disabled={!edit}
                    onChange={(e) => {
                      setOptionInfo({
                        ...optionInfo,
                        addPrice: Number(
                          e.target.value.replace(/[^0-9-]/g, "")
                        ),
                      });
                    }}
                    placeholder="추가 금액 입력"
                  />
                  <TextUnitFieldInput
                    disabled={!edit || editData.optionType === "INDEPENDENT"}
                    inputValue={
                      optionInfo.stock !== 0 ? String(optionInfo.stock) : ""
                    }
                    className="w-[200px]"
                    onChange={(e) =>
                      setOptionInfo({
                        ...optionInfo,
                        stock: Number(e.target.value.replace(/[^0-9-]/g, "")),
                      })
                    }
                    placeholder="재고 수량 입력"
                  />
                  <ButtonFilledTertiary
                    size={"md"}
                    disabled={
                      editData.options.type === "COMBINATION"
                        ? editData.options.combinations?.length === 0
                        : editData.options.simples?.length === 0
                    }
                    onClick={() => {
                      if (editData.options.type === "COMBINATION") {
                        const COMBINATION = CHANGECOMBINATIONDATA(
                          editData,
                          optionInfo.addPrice,
                          optionInfo.stock
                        );
                        setEditData({
                          ...editData,
                          options: {
                            ...editData.options,
                            simples: null,
                            combinations:
                              COMBINATION as unknown as Combinations[],
                          },
                        });
                      } else if (editData.options.type === "SIMPLE") {
                        const SIMPLE = CHANGESIMPLEDATA(
                          editData.options.optionAttributes,
                          optionInfo.addPrice,
                          editData.stock
                        );
                        setEditData({
                          ...editData,
                          options: {
                            ...editData.options,
                            simples: SIMPLE,
                            combinations: null,
                          },
                        });
                      }
                    }}
                    btnText={"선택항목 일괄 적용"}
                  />
                </div>
              </div>
            </div>
            <div className="relative px-3">
              {editData.optionType === "COMBINATION" ? (
                <table className="w-full border border-Gray-40">
                  <tr>
                    <th className="addItemTableTh" rowSpan={2}>
                      <input type="checkbox" name="" id="" />
                    </th>
                    <th className="addItemTableTh h-9" colSpan={2}>
                      옵션명
                    </th>
                    <th rowSpan={2} className="addItemTableTh">
                      추가금액
                    </th>
                    <th rowSpan={2} className="addItemTableTh">
                      재고
                    </th>
                    <th rowSpan={2} className="addItemTableTh">
                      판매상태
                    </th>
                    <th rowSpan={2} className="addItemTableTh">
                      옵션 관리 코드
                    </th>
                  </tr>
                  <tr>
                    <td className="w-[145px] h-9 addItemTableTh">
                      {editData.options.optionAttributes[0]?.name}
                    </td>
                    <td className="w-[145px] h-9 addItemTableTh">
                      {editData.options.optionAttributes[1]?.name}
                    </td>
                  </tr>
                  {editData.options.combinations?.map((c, i) => {
                    return (
                      <tr key={i}>
                        <td className="addItemTableTd">
                          <input type="checkbox" name="" id="" />
                        </td>
                        <td className="addItemTableTd">{c.name[0]?.value}</td>
                        <td className="addItemTableTd">{c.name[1]?.value}</td>
                        <td
                          className={`addItemTableTd ${!edit && "bg-Gray-20"}`}
                        >
                          <input
                            disabled={!edit}
                            className="w-full h-full focus:outline-none"
                            onChange={(e) => {
                              setEditData((pre) => {
                                const newData = [
                                  ...(pre.options
                                    .combinations as Combinations[]),
                                ];
                                newData[i] = {
                                  ...newData[i],
                                  price: Number(e.target.value),
                                };

                                return {
                                  ...pre,
                                  options: {
                                    ...pre.options,
                                    combinations: newData,
                                  },
                                };
                              });
                            }}
                            value={c.price}
                          />
                        </td>
                        <td
                          className={`addItemTableTd ${!edit && "bg-Gray-20"}`}
                        >
                          <input
                            disabled={!edit}
                            onChange={(e) => {
                              setEditData((pre) => {
                                const newStockQuantity = [
                                  ...(pre.options
                                    .combinations as Combinations[]),
                                ];
                                newStockQuantity[i] = {
                                  ...newStockQuantity[i],
                                  stock: Number(e.target.value),
                                };

                                return {
                                  ...pre,
                                  options: {
                                    ...pre.options,
                                    combinations: newStockQuantity,
                                  },
                                };
                              });
                            }}
                            className="w-full h-full focus:outline-none"
                            value={c.stock}
                          />
                        </td>
                        <td
                          className={`addItemTableTd hoverContainer w-[100px]`}
                        >
                          {c.status === "END_OF_SALE" ? (
                            <div className="text-Red100">판매종료</div>
                          ) : c.status === "ON_SALE" ? (
                            <div className="flex items-center justify-center gap-1 cursor-pointer">
                              <div className="w-1.5 h-1.5 rounded-full bg-Green100" />
                              <div>판매중</div>
                            </div>
                          ) : c.status === "SUSPENSION_OF_SALE" ? (
                            <div className="flex items-center justify-center gap-1 cursor-pointer">
                              <div className="w-1.5 h-1.5 rounded-full bg-[#FAD619]" />
                              <div>판매중지</div>
                            </div>
                          ) : c.status === "OUT_OF_STOCK" ? (
                            <div className="flex items-center justify-center gap-1 cursor-pointer">
                              <div className="w-1.5 h-1.5 rounded-full bg-Red100" />
                              <div>품절</div>
                            </div>
                          ) : (
                            ""
                          )}
                          {edit && (
                            <div className="absolute top-[50] z-50 flex-col items-start hidden gap-2 p-4 border rounded-lg left-2/4 -translate-x-2/4 item bg-Gray-10 border-Gray-90">
                              {optionStatus.map((o, optionI) => {
                                return (
                                  <div
                                    key={optionI}
                                    onClick={() => {
                                      if (
                                        o.status !== c.status &&
                                        o.status === "ON_SALE"
                                      ) {
                                        toast.success(
                                          "`판매중’으로 옵션 상태를 변경했습니다 :)\n이제 해당 옵션은 구매 가능합니다."
                                        );
                                      } else if (
                                        o.status !== c.status &&
                                        o.status === "SUSPENSION_OF_SALE"
                                      ) {
                                        toast.error(
                                          "`판매중지’로 옵션 상태를 변경했습니다 :)\n이제 해당 옵션은 구매할 수 없습니다."
                                        );
                                      } else if (
                                        o.status !== c.status &&
                                        o.status === "OUT_OF_STOCK"
                                      ) {
                                        toast.error(
                                          "‘품절’로 옵션 상태를 변경했습니다 :)\n이제 해당 옵션은 구매할 수 없습니다."
                                        );
                                      }
                                      if (o.status !== c.status) {
                                        setEditData((pre) => {
                                          const newOptionsStatus = [
                                            ...(pre.options
                                              .combinations as Combinations[]),
                                          ];
                                          newOptionsStatus[i] = {
                                            ...newOptionsStatus[i],
                                            status: o.status,
                                          };

                                          return {
                                            ...pre,
                                            options: {
                                              ...pre.options,
                                              combinations: newOptionsStatus,
                                            },
                                          };
                                        });
                                      }
                                    }}
                                    className={`flex items-center gap-1 ${
                                      o.status !== c.status &&
                                      "opacity-[0.6] hover:underline"
                                    }`}
                                  >
                                    <div
                                      className="rounded-full w-1.5 h-1.5"
                                      style={{ backgroundColor: `${o.color}` }}
                                    />
                                    <div
                                      className={`text-Gray-90 Body4 cursor-pointer`}
                                    >
                                      {o.name}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          )}
                        </td>
                        <td
                          className={`addItemTableTd ${!edit && "bg-Gray-20"}`}
                        >
                          <input
                            disabled={!edit}
                            className="w-full h-full focus:outline-none"
                            onChange={(e) => {
                              setEditData((pre) => {
                                const newData = [
                                  ...(pre.options
                                    .combinations as Combinations[]),
                                ];
                                newData[i] = {
                                  ...newData[i],
                                  managedCode: e.target.value,
                                };

                                return {
                                  ...pre,
                                  options: {
                                    ...pre.options,
                                    combinations: newData,
                                  },
                                };
                              });
                            }}
                            value={c.managedCode}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </table>
              ) : (
                <table className="w-full border border-Gray-40">
                  <tr>
                    <th className="addItemTableTh">
                      <input type="checkbox" name="" id="" />
                    </th>
                    <th className="addItemTableTh">
                      {editData.options.optionAttributes[0]?.name}
                    </th>
                    <th className="addItemTableTh">추가금액</th>
                    <th className="addItemTableTh">재고</th>
                    <th className="addItemTableTh">판매상태</th>
                    <th className="addItemTableTh">옵션 관리 코드</th>
                  </tr>
                  {editData.options.simples &&
                    editData.options.simples?.map((s, i) => {
                      if (
                        s.name === editData.options.optionAttributes[0]?.name
                      ) {
                        return (
                          <React.Fragment key={i}>
                            {s.details.map((d, idx) => {
                              return (
                                <tr key={idx}>
                                  <td className="addItemTableTd">
                                    <input type="checkbox" name="" id="" />
                                  </td>
                                  <td className="addItemTableTd">{d.value}</td>
                                  <td
                                    className={`addItemTableTd ${
                                      !edit && "bg-Gray-20"
                                    }`}
                                  >
                                    <input
                                      disabled={!edit}
                                      className="w-full h-full focus:outline-none"
                                      onChange={(e) => {
                                        setEditData((pre) => {
                                          const newSimples = [
                                            ...(pre.options
                                              .simples as Simples[]),
                                          ];
                                          const details = [
                                            ...newSimples[i].details,
                                          ];
                                          details[idx] = {
                                            ...details[idx],
                                            price: Number(e.target.value.replace(/[^0-9][0-9]{0,8}/g, "")),
                                          };
                                          newSimples[i] = {
                                            ...newSimples[i],
                                            details,
                                          };

                                          return {
                                            ...pre,
                                            options: {
                                              ...pre.options,
                                              simples: newSimples,
                                            },
                                          };
                                        });
                                      }}
                                      value={d.price}
                                    />
                                  </td>
                                  <td className="addItemTableTd text-Gray-60 !SubH2">
                                    재고동일
                                    {/* <input
                                      disabled={!edit}
                                      className="w-full h-full focus:outline-none"
                                      onChange={(e) => {
                                        setEditData((pre) => {
                                          const newSimples = [
                                            ...(pre.options
                                              .simples as Simples[]),
                                          ];
                                          const details = [
                                            ...newSimples[i].details,
                                          ];
                                          details[idx] = {
                                            ...details[idx],
                                            stock: Number(
                                              e.target.value
                                            ),
                                          };
                                          newSimples[i] = {
                                            ...newSimples[i],
                                            details,
                                          };

                                          return {
                                            ...pre,
                                            options: {
                                              ...pre.options,
                                              simples: newSimples,
                                            },
                                          };
                                        });
                                      }}
                                      value={d.stock}
                                    /> */}
                                  </td>
                                  <td className="addItemTableTd">
                                    <div className="flex items-center justify-center gap-1 cursor-pointer">
                                      <div className="w-1.5 h-1.5 rounded-full bg-Green100" />
                                      <div>판매중</div>
                                    </div>
                                  </td>
                                  {/* <td className="hoverContainer addItemTableTd">
                                    {d.status === "END_OF_SALE" ? (
                                      <div className="text-Red100">
                                        판매종료
                                      </div>
                                    ) : d.status === "ON_SALE" ? (
                                      <div className="flex items-center justify-center gap-1 cursor-pointer">
                                        <div className="w-1.5 h-1.5 rounded-full bg-Green100" />
                                        <div>판매중</div>
                                      </div>
                                    ) : d.status === "SUSPENSION_OF_SALE" ? (
                                      <div className="flex items-center justify-center gap-1 cursor-pointer">
                                        <div className="w-1.5 h-1.5 rounded-full bg-[#FAD619]" />
                                        <div>판매중지</div>
                                      </div>
                                    ) : d.status === "OUT_OF_STOCK" ? (
                                      <div className="flex items-center justify-center gap-1 cursor-pointer">
                                        <div className="w-1.5 h-1.5 rounded-full bg-Red100" />
                                        <div>품절</div>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    <div className="absolute top-0 z-50 flex-col items-start hidden gap-2 p-4 border rounded-lg left-2/4 -translate-x-2/4 item bg-Gray-10 border-Gray-90">
                                      단독형
                                    </div>
                                  </td> */}
                                  <td
                                    className={`addItemTableTd ${
                                      !edit && "bg-Gray-20"
                                    }`}
                                  >
                                    <input
                                      disabled={!edit}
                                      className="w-full h-full focus:outline-none"
                                      onChange={(e) => {
                                        setEditData((pre) => {
                                          const newSimples = [
                                            ...(pre.options
                                              .simples as Simples[]),
                                          ];
                                          const details = [
                                            ...newSimples[i].details,
                                          ];
                                          details[idx] = {
                                            ...details[idx],
                                            managedCode: e.target.value,
                                          };
                                          newSimples[i] = {
                                            ...newSimples[i],
                                            details,
                                          };

                                          return {
                                            ...pre,
                                            options: {
                                              ...pre.options,
                                              simples: newSimples,
                                            },
                                          };
                                        });
                                      }}
                                      value={d.managedCode}
                                    />
                                  </td>
                                </tr>
                              );
                            })}
                          </React.Fragment>
                        );
                      }
                    })}
                  {editData.options.simples &&
                    editData.options.simples.length > 1 && (
                      <tr>
                        <th className="addItemTableTh">
                          <input type="checkbox" name="" id="" />
                        </th>
                        <th className="addItemTableTh">
                          {editData.options.optionAttributes[1]?.name}
                        </th>
                        <th className="addItemTableTh">추가금액</th>
                        <th className="addItemTableTh">재고</th>
                        <th className="addItemTableTh">판매상태</th>
                        <th className="addItemTableTh">옵션 관리 코드</th>
                      </tr>
                    )}
                  {editData.options.simples?.map((s_, i) => {
                    if (
                      s_?.name === editData.options.optionAttributes[1]?.name
                    ) {
                      return (
                        <React.Fragment key={i}>
                          {s_.details.map((d_, di) => {
                            return (
                              <tr key={di}>
                                <td className="addItemTableTd">
                                  <input type="checkbox" name="" id="" />
                                </td>
                                <td className="addItemTableTd">{d_.value}</td>
                                <td
                                  className={`addItemTableTd ${
                                    !edit && "bg-Gray-20"
                                  }`}
                                >
                                  <input
                                    disabled={!edit}
                                    className="w-full h-full focus:outline-none"
                                    onChange={(e) => {
                                      setEditData((pre) => {
                                        const newSimples = [
                                          ...(pre.options.simples as Simples[]),
                                        ];
                                        const details = [
                                          ...newSimples[i].details,
                                        ];
                                        details[di] = {
                                          ...details[di],
                                          price: Number(e.target.value.replace(/[^0-9][0-9]{0,8}/g, "")),
                                        };
                                        newSimples[i] = {
                                          ...newSimples[i],
                                          details,
                                        };

                                        return {
                                          ...pre,
                                          options: {
                                            ...pre.options,
                                            simples: newSimples,
                                          },
                                        };
                                      });
                                    }}
                                    value={d_.price}
                                  />
                                </td>
                                <td className="addItemTableTd text-Gray-60 !SubH2">
                                  재고동일
                                  {/* <input
                                  disabled={!edit}
                                    className="w-full h-full focus:outline-none"
                                    onChange={(e) => {
                                      setEditData((pre) => {
                                        const newSimples = [
                                          ...(pre.options.simples as Simples[]),
                                        ];
                                        const details = [
                                          ...newSimples[i].details,
                                        ];
                                        details[di] = {
                                          ...details[di],
                                          stock: Number(e.target.value),
                                        };
                                        newSimples[i] = {
                                          ...newSimples[i],
                                          details,
                                        };

                                        return {
                                          ...pre,
                                          options: {
                                            ...pre.options,
                                            simples: newSimples,
                                          },
                                        };
                                      });
                                    }}
                                    value={d_.stock}
                                  /> */}
                                </td>
                                <td className="addItemTableTd">
                                  <div className="flex items-center justify-center gap-1 cursor-pointer">
                                    <div className="w-1.5 h-1.5 rounded-full bg-Green100" />
                                    <div>판매중</div>
                                  </div>
                                </td>
                                {/* <td className="hoverContainer addItemTableTd">
                                  <div>
                                    {d_.status === "END_OF_SALE" ? (
                                      <div className="text-Red100">
                                        판매종료
                                      </div>
                                    ) : d_.status === "ON_SALE" ? (
                                      <div className="flex items-center justify-center gap-1 cursor-pointer">
                                        <div className="w-1.5 h-1.5 rounded-full bg-Green100" />
                                        <div>판매중</div>
                                      </div>
                                    ) : d_.status === "SUSPENSION_OF_SALE" ? (
                                      <div className="flex items-center justify-center gap-1 cursor-pointer">
                                        <div className="w-1.5 h-1.5 rounded-full bg-[#FAD619]" />
                                        <div>판매중지</div>
                                      </div>
                                    ) : d_.status === "OUT_OF_STOCK" ? (
                                      <div className="flex items-center justify-center gap-1 cursor-pointer">
                                        <div className="w-1.5 h-1.5 rounded-full bg-Red100" />
                                        <div>품절</div>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <div className="absolute top-0 z-50 flex-col items-start hidden gap-2 p-4 border rounded-lg left-2/4 -translate-x-2/4 item bg-Gray-10 border-Gray-90">
                                    단독형 두번째 테이블 ?
                                  </div>
                                </td> */}
                                <td
                                  className={`addItemTableTd ${
                                    !edit && "bg-Gray-20"
                                  }`}
                                >
                                  <input
                                    disabled={!edit}
                                    className="w-full h-full focus:outline-none"
                                    onChange={(e) => {
                                      setEditData((pre) => {
                                        const newSimples = [
                                          ...(pre.options.simples as Simples[]),
                                        ];
                                        const details = [
                                          ...newSimples[i].details,
                                        ];
                                        details[di] = {
                                          ...details[di],
                                          managedCode: e.target.value,
                                        };
                                        newSimples[i] = {
                                          ...newSimples[i],
                                          details,
                                        };

                                        return {
                                          ...pre,
                                          options: {
                                            ...pre.options,
                                            simples: newSimples,
                                          },
                                        };
                                      });
                                    }}
                                    value={d_.managedCode}
                                  />
                                </td>
                              </tr>
                            );
                          })}
                        </React.Fragment>
                      );
                    }
                  })}
                </table>
              )}
            </div>
          </>
        )}
      </NewStoreWrap>
      <NewStoreWrap title="썸네일 이미지">
        <NewStoreForm className="w-full" required title="1:1 이미지">
          <div className="Body4 text-Gray-80">
            <div>・ 썸네일로 사용되는 대표 이미지입니다.</div>
            <div>
              ・ 이미지 비율이 1:1이 아닌 경우 잘려서 보이니 유의해 주세요.
            </div>
            <div className="text-Blue-100">
              ・ 권장 사이즈 : 750 x 750px (용량 1mb 이하)
            </div>
            <div>・ 사용가능 확장자 : jpg, jpeg, png</div>
          </div>
          <div className="flex items-start gap-2.5 h-[120px] mt-4">
            {edit && (
              <ImgUploadBtn
                currentLength={thumbnail1 ? 1 : 0}
                id="Thumbnail1"
                accept=".jpg, .jpeg, .png"
                onChange={(e) => {
                  onChangeThumnail(e, 1);
                }}
                maxLength="1"
              />
            )}
            <div className="flex items-center !h-full w-full p-4 Body4 bg-Gray-20 min-w-[740px]">
              {editData.thum1 ? (
                <div className="relative">
                  <img
                    className="w-20 h-20 rounded-lg"
                    src={editData.thum1}
                    alt=""
                  />
                  {edit && (
                    <DeleteButton
                      onClick={() => {
                        setThum1Pre("");
                        setThumbnail1(undefined);
                      }}
                      className="absolute cursor-pointer top-2 right-2"
                    />
                  )}
                </div>
              ) : (
                <div className="flex items-center !h-full w-full p-4 Body4 bg-Gray-20 min-w-[740px]">
                  Tip. 상품 리스트에서 보여지는 이미지라서 상품의 첫인상을 남길
                  수 있어요
                </div>
              )}
            </div>
          </div>
        </NewStoreForm>
        <NewStoreForm className="w-full" required title="4:5 이미지">
          <div className="Body4 text-Gray-80">
            <div>・ 썸네일로 사용되는 세로형 이미지입니다.</div>
            <div>
              ・ 이미지 비율이 4:5가 아닌 경우 잘려서 보이니 유의해 주세요.
            </div>
            <div className="text-Blue-100">
              ・ 권장 사이즈 : 320 x 400px (용량 1mb 이하)
            </div>
            <div>・ 사용가능 확장자 : jpg, jpeg, png</div>
          </div>
          <div className="flex items-start gap-2.5 h-[120px] mt-4">
            {edit && (
              <ImgUploadBtn
                currentLength={thumbnail2 ? 1 : 0}
                id="Thumbnail2"
                onChange={(e) => {
                  onChangeThumnail(e, 4);
                }}
                accept=".jpg, .jpeg, .png"
                maxLength="1"
              />
            )}
            <div className="flex items-center !h-full w-full p-4 Body4 bg-Gray-20 min-w-[740px]">
              {editData.thum2 ? (
                <div className="relative">
                  <img
                    className="w-20 h-20 rounded-lg"
                    src={editData.thum2}
                    alt=""
                  />
                  {edit && (
                    <DeleteButton
                      onClick={() => {
                        setThum2Pre("");
                        setThumbnail2(undefined);
                      }}
                      className="absolute cursor-pointer top-2 right-2"
                    />
                  )}
                </div>
              ) : (
                <div className="flex items-center !h-full w-full p-4 Body4 bg-Gray-20 min-w-[740px]">
                  Tip. 썸네일 이미지는 브랜드의 일관성 있는 모습을 보여주기 위해
                  통일시켜주는게 좋아요.
                </div>
              )}
            </div>
          </div>
        </NewStoreForm>
        <NewStoreForm className="w-full" required title="16:9 이미지">
          <div className="Body4 text-Gray-80">
            <div>・ 썸네일로 사용되는 가로형 이미지입니다.</div>
            <div>
              ・ 이미지 비율이 16:9가 아닌 경우 잘려서 보이니 유의해 주세요.
            </div>
            <div className="text-Blue-100">
              ・ 권장 사이즈 : 750 x 422px (용량 1mb 이하)
            </div>
            <div>・ 사용가능 확장자 : jpg, jpeg, png</div>
          </div>
          <div className="flex items-start gap-2.5 h-[120px] mt-4">
            {edit && (
              <ImgUploadBtn
                currentLength={thumbnail3 ? 1 : 0}
                id="Thumbnail3"
                onChange={(e) => {
                  onChangeThumnail(e, 16);
                }}
                accept=".jpg, .jpeg, .png"
                maxLength="1"
              />
            )}
            <div className="flex items-center !h-full w-full p-4 Body4 bg-Gray-20 min-w-[740px]">
              {editData.thum3 ? (
                <div className="relative">
                  <img
                    className="w-20 h-20 rounded-lg"
                    src={editData.thum2}
                    alt=""
                  />
                  {edit && (
                    <DeleteButton
                      onClick={() => {
                        setThum3Pre("");
                        setThumbnail3(undefined);
                      }}
                      className="absolute cursor-pointer top-2 right-2"
                    />
                  )}
                </div>
              ) : (
                <div className="flex items-center !h-full w-full p-4 Body4 bg-Gray-20 min-w-[740px]">
                  Tip. 일관성 있는 모습일수록 차별화된 브랜드 이미지를 만들 수
                  있어요
                </div>
              )}
            </div>
          </div>
        </NewStoreForm>
      </NewStoreWrap>
      <NewStoreWrap title="대표 이미지">
        <NewStoreForm className="w-full" required title="대표 이미지">
          <div className="Body4 text-Gray-80">
            <div>
              ・ 상품 상세페이지 내 최상단에서 보여지는 배너 이미지입니다.
            </div>
            <div>
              ・ 이미지 비율이 1:1이 아닌 경우 잘려서 보이니 유의해 주세요.
            </div>
            <div className="text-Blue-100">
              ・ 권장 사이즈 : 750 x 750px (용량 1mb 이하)
            </div>
            <div>・ 사용가능 확장자 : jpg, jpeg, png, gif</div>
          </div>
          <div className="flex items-start gap-2.5 mt-4">
            {edit && (
              <ImgUploadBtn
                currentLength={editData.topImages?.length}
                id="mainImage"
                multiple
                onChange={(e) => {
                  onChangeMain(e);
                }}
                accept=".jpg, .jpeg, .png, .gif"
                maxLength="5"
              />
            )}
            <div className="flex gap-2.5 flex-wrap items-center w-full Body4 bg-Gray-20 min-w-[740px] p-4 min-h-[120px]">
              {editData.topImages?.length !== 0 ? (
                editData.topImages?.map((item: TopImages, i: any) => {
                  return (
                    <div key={i} className="relative">
                      <img
                        className="w-20 h-20 rounded-lg"
                        src={item.imagePath}
                        alt=""
                      />
                      {edit && (
                        <DeleteButton
                          onClick={() => {
                            const newImages = [...mainImage];
                            const newPreviews = [...mainPre];
                            newImages.splice(i, 1);
                            newPreviews.splice(i, 1);
                            setMainImage(newImages);
                            setMainPre(newPreviews);
                          }}
                          className="absolute cursor-pointer top-2 right-2"
                        />
                      )}
                    </div>
                  );
                })
              ) : (
                <div className="w-full min-w-[740px]">
                  Tip. 대표 이미지가 있으면 고객의 시선을 집중시킬 수 있어요
                </div>
              )}
            </div>
            :
          </div>
        </NewStoreForm>
      </NewStoreWrap>
      <NewStoreWrap title="상세 이미지">
        <NewStoreForm className="w-full" required title="상세 이미지">
          <div className="Body4 text-Gray-80">
            <div>・ 상품 상세페이지에서 보여지는 상품 상세 이미지입니다.</div>
            <div>
              ・ 이미지 비율이 안 맞는 경우 잘려서 보이니 유의해 주세요.
            </div>
            <div className="text-Blue-100">
              ・ 권장 사이즈 : 가로 750px 고정, 세로 2000px 이하 (용량 1mb 이하)
            </div>
            <div>・ 사용가능 확장자 : jpg, jpeg, png, gif</div>
          </div>
          <div className="flex items-start gap-2.5 mt-4">
            {edit && (
              <ImgUploadBtn
                currentLength={editData?.detailImages.length}
                id="detailImage"
                multiple
                onChange={(e) => {
                  onChangeDetail(e);
                }}
                accept=".jpg, .jpeg, .png, .gif"
                maxLength="10"
              />
            )}
            <div className="flex gap-2.5 flex-wrap items-center w-full Body4 bg-Gray-20 min-w-[740px] p-4 min-h-[120px]">
              {editData?.detailImages?.length !== 0 ? (
                editData?.detailImages?.map((item: DetailImages, i: any) => {
                  return (
                    <div key={i} className="relative">
                      <img
                        className="w-20 h-20 rounded-lg"
                        src={item.imagePath}
                        alt=""
                      />
                      {edit && (
                        <DeleteButton
                          onClick={() => {
                            const newImages = [...editData.detailImages];
                            newImages.splice(i, 1);
                            setDetailImage(newImages);
                          }}
                          className="absolute cursor-pointer top-2 right-2"
                        />
                      )}
                    </div>
                  );
                })
              ) : (
                <div className="w-full min-w-[740px]">
                  Tip. 상세이미지는 화면을 가득 채워져서 보여지기 때문에 너무
                  작은 글씨는 지양하는게 좋아요.
                </div>
              )}
            </div>
          </div>
        </NewStoreForm>
      </NewStoreWrap>
      <NewStoreWrap title="상품 정보 고시">
        <NewStoreForm required title="상품군">
          <Dropdown
            width="w-[476px]"
            disabled={!edit}
            innerText={
              editData.productInformation === "CLOTHING"
                ? "의류"
                : editData.productInformation === "SHOES"
                ? "구두/신발"
                : editData.productInformation === "BAG"
                ? "가방"
                : editData.productInformation === "FASHION_MISCELLANEOUS_GOODS"
                ? "패션잡화(모자/벨트/액세서리 등)"
                : editData.productInformation === "COSMETIC"
                ? "화장품"
                : editData.productInformation === "JEWELRY_CLOCK"
                ? "귀금속/보석/시계류"
                : editData.productInformation === "HEALTH_FUNCTIONAL_FOOD"
                ? "건강기능식품"
                : editData.productInformation === "MEDICAL_DEVICE"
                ? "의료기기"
                : editData.productInformation === "SPORTS_EQUIPMENT"
                ? "스포츠용품"
                : editData.productInformation === "NAVIGATION"
                ? "내비게이션"
                : editData.productInformation === "CAR_SUPPLIES"
                ? "자동차용품(자동차부품/기타 자동차용품 등)"
                : editData.productInformation === "OTHER_GOODS"
                ? "기타재화"
                : "선택"
            }
          >
            {productGroup.map(({ name, id, type }, i) => {
              return (
                <li
                  key={i}
                  onClick={() => {
                    setEditData({
                      ...editData,
                      productInformation: type,
                      productInfo: {
                        material: "",
                        color: "",
                        size: "",
                        manufacturer: "",
                        manufactureCountry: "",
                        precautions: "",
                        manufactureDate: "",
                        qualityAssuranceStandard: "",
                        servicePhoneNum: "",
                        type: "",
                        weight: "",
                        keySpecification: "",
                        expirationDate: "",
                        instructions: "",
                        vendor: "",
                        ingredient: "",
                        auditYn: "",
                        productName: "",
                        location: "",
                        nutritionInfo: "",
                        functionalInfo: "",
                        sideEffectPossibility: "",
                        geneticallyModifiedHealthFunctionalFood: "",
                        importedFoodYn: "",
                        kcInfo: "",
                        productConfiguration: "",
                        sameModelReleaseDate: "",
                        detailSpecification: "",
                        certifiedInfo: "",
                        amountOfIntake: "",
                        reportNumber: "",
                        ratedVoltage: "",
                        powerConsumption: "",
                        navigationUpdateCost: "",
                        carName: "",
                        certificateExaminationNum: "",
                      },
                    });
                  }}
                  className="dropdownWrap"
                >
                  <div className="dropdownLi">{name}</div>
                </li>
              );
            })}
          </Dropdown>
          {(editData.productInformation === "CLOTHING" ||
            editData.productInformation === "SHOES" ||
            editData.productInformation === "BAG" ||
            editData.productInformation === "FASHION_MISCELLANEOUS_GOODS" ||
            editData.productInformation === "COSMETIC" ||
            editData.productInformation === "HEALTH_FUNCTIONAL_FOOD" ||
            editData.productInformation === "SPORTS_EQUIPMENT" ||
            editData.productInformation === "OTHER_GOODS") && (
            <CheckBox
              disabled={!edit}
              checked={
                editData.productInformationImageYn === "Y" ? true : false
              }
              onChange={(e) => {
                setEditData({
                  ...editData,
                  productInformationImageYn: e.target.checked ? "Y" : "N",
                });
              }}
              text="상품 상세 이미지 참조로 전체입력"
              className="mt-4"
            />
          )}
        </NewStoreForm>
        {editData.productInformation && (
          <div className="border-b border-Gray-40" />
        )}
        {editData.productInformation === "CLOTHING" ? (
          <>
            <NewStoreForm required title="제품 주소재">
              <TextFieldInput
                disabled={!edit}
                inputValue={editData.productInfo.material}
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    productInfo: {
                      ...editData.productInfo,
                      material: e.target.value,
                    },
                  });
                }}
                className="w-[665px]"
              />
            </NewStoreForm>
            <NewStoreForm required title="색상">
              <TextFieldInput
                disabled={!edit}
                inputValue={editData.productInfo.color}
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    productInfo: {
                      ...editData.productInfo,
                      color: e.target.value,
                    },
                  });
                }}
                className="w-[665px]"
              />
            </NewStoreForm>
            <NewStoreForm required title="치수">
              <TextFieldInput
                disabled={!edit}
                inputValue={editData.productInfo.size}
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    productInfo: {
                      ...editData.productInfo,
                      size: e.target.value,
                    },
                  });
                }}
                className="w-[665px]"
              />
            </NewStoreForm>
            <NewStoreForm required title="제조사">
              <TextFieldInput
                disabled={!edit}
                inputValue={editData.productInfo.manufacturer}
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    productInfo: {
                      ...editData.productInfo,
                      manufacturer: e.target.value,
                    },
                  });
                }}
                className="w-[665px]"
              />
            </NewStoreForm>
            <NewStoreForm required title="제조국">
              <TextFieldInput
                disabled={!edit}
                inputValue={editData.productInfo.manufactureCountry}
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    productInfo: {
                      ...editData.productInfo,
                      manufactureCountry: e.target.value,
                    },
                  });
                }}
                className="w-[665px]"
              />
            </NewStoreForm>
            <NewStoreForm required title={"세탁방법 및 취급시\n주의사항"}>
              <TextFieldInput
                disabled={!edit}
                inputValue={editData.productInfo.precautions}
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    productInfo: {
                      ...editData.productInfo,
                      precautions: e.target.value,
                    },
                  });
                }}
                className="w-[665px]"
              />
            </NewStoreForm>
            <NewStoreForm required title="제조연월">
              <TextFieldInput
                disabled={!edit}
                inputValue={editData.productInfo.manufactureDate}
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    productInfo: {
                      ...editData.productInfo,
                      manufactureDate: e.target.value,
                    },
                  });
                }}
                className="w-[665px]"
              />
            </NewStoreForm>
            <NewStoreForm required title="품질보증기준">
              <TextLimitHorizontalInput
                row={4}
                disabled={!edit}
                currentLength={
                  editData.productInfo.qualityAssuranceStandard?.length
                }
                inputValue={editData.productInfo.qualityAssuranceStandard}
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    productInfo: {
                      ...editData.productInfo,
                      qualityAssuranceStandard: e.target.value,
                    },
                  });
                }}
                className="w-[665px]"
              />
            </NewStoreForm>
            <NewStoreForm required title="A/S 책임자와 전화번호">
              <TextFieldInput
                disabled={!edit}
                inputValue={editData.productInfo.servicePhoneNum}
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    productInfo: {
                      ...editData.productInfo,
                      servicePhoneNum: e.target.value,
                    },
                  });
                }}
                className="w-[665px]"
              />
            </NewStoreForm>
          </>
        ) : editData.productInformation === "SHOES" ? (
          <>
            <NewStoreForm required title="제품 주소재">
              <TextFieldInput
                disabled={!edit}
                inputValue={editData.productInfo.material}
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    productInfo: {
                      ...editData.productInfo,
                      material: e.target.value,
                    },
                  });
                }}
                className="w-[665px]"
              />
            </NewStoreForm>
            <NewStoreForm required title="색상">
              <TextFieldInput
                disabled={!edit}
                inputValue={editData.productInfo.color}
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    productInfo: {
                      ...editData.productInfo,
                      color: e.target.value,
                    },
                  });
                }}
                className="w-[665px]"
              />
            </NewStoreForm>
            <NewStoreForm required title="치수">
              <TextFieldInput
                disabled={!edit}
                inputValue={editData.productInfo.size}
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    productInfo: {
                      ...editData.productInfo,
                      size: e.target.value,
                    },
                  });
                }}
                className="w-[665px]"
              />
            </NewStoreForm>
            <NewStoreForm required title="제조사">
              <TextFieldInput
                disabled={!edit}
                inputValue={editData.productInfo.manufacturer}
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    productInfo: {
                      ...editData.productInfo,
                      manufacturer: e.target.value,
                    },
                  });
                }}
                className="w-[665px]"
              />
            </NewStoreForm>
            <NewStoreForm required title="제조국">
              <TextFieldInput
                disabled={!edit}
                inputValue={editData.productInfo.manufactureCountry}
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    productInfo: {
                      ...editData.productInfo,
                      manufactureCountry: e.target.value,
                    },
                  });
                }}
                className="w-[665px]"
              />
            </NewStoreForm>
            <NewStoreForm required title="취급시 주의사항">
              <TextLimitHorizontalInput
                row={4}
                disabled={!edit}
                currentLength={editData.productInfo.precautions?.length}
                inputValue={editData.productInfo.precautions}
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    productInfo: {
                      ...editData.productInfo,
                      precautions: e.target.value,
                    },
                  });
                }}
                className="w-[665px]"
              />
            </NewStoreForm>
            <NewStoreForm required title="제조연월">
              <TextFieldInput
                disabled={!edit}
                inputValue={editData.productInfo.manufactureDate}
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    productInfo: {
                      ...editData.productInfo,
                      manufactureDate: e.target.value,
                    },
                  });
                }}
                className="w-[665px]"
              />
            </NewStoreForm>
            <NewStoreForm required title="품질보증기준">
              <TextLimitHorizontalInput
                row={4}
                disabled={!edit}
                currentLength={
                  editData.productInfo.qualityAssuranceStandard?.length
                }
                inputValue={editData.productInfo.qualityAssuranceStandard}
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    productInfo: {
                      ...editData.productInfo,
                      qualityAssuranceStandard: e.target.value,
                    },
                  });
                }}
                className="w-[665px]"
              />
            </NewStoreForm>
            <NewStoreForm required title="A/S 책임자와 전화번호">
              <TextFieldInput
                disabled={!edit}
                inputValue={editData.productInfo.servicePhoneNum}
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    productInfo: {
                      ...editData.productInfo,
                      servicePhoneNum: e.target.value,
                    },
                  });
                }}
                className="w-[665px]"
              />
            </NewStoreForm>
          </>
        ) : editData.productInformation === "BAG" ? (
          <>
            <NewStoreForm required title="종류">
              <TextFieldInput
                disabled={!edit}
                inputValue={editData.productInfo.type}
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    productInfo: {
                      ...editData.productInfo,
                      type: e.target.value,
                    },
                  });
                }}
                className="w-[665px]"
              />
            </NewStoreForm>
            <NewStoreForm required title="소재">
              <TextLimitHorizontalInput
                row={4}
                disabled={!edit}
                currentLength={editData.productInfo.material?.length}
                inputValue={editData.productInfo.material}
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    productInfo: {
                      ...editData.productInfo,
                      material: e.target.value,
                    },
                  });
                }}
                className="w-[665px]"
              />
            </NewStoreForm>
            <NewStoreForm required title="색상">
              <TextFieldInput
                disabled={!edit}
                inputValue={editData.productInfo.color}
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    productInfo: {
                      ...editData.productInfo,
                      color: e.target.value,
                    },
                  });
                }}
                className="w-[665px]"
              />
            </NewStoreForm>
            <NewStoreForm required title="크기">
              <TextFieldInput
                disabled={!edit}
                inputValue={editData.productInfo.size}
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    productInfo: {
                      ...editData.productInfo,
                      size: e.target.value,
                    },
                  });
                }}
                className="w-[665px]"
              />
            </NewStoreForm>
            <NewStoreForm required title="제조사">
              <TextFieldInput
                disabled={!edit}
                inputValue={editData.productInfo.manufacturer}
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    productInfo: {
                      ...editData.productInfo,
                      manufacturer: e.target.value,
                    },
                  });
                }}
                className="w-[665px]"
              />
            </NewStoreForm>
            <NewStoreForm required title="제조국">
              <TextFieldInput
                disabled={!edit}
                inputValue={editData.productInfo.manufactureCountry}
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    productInfo: {
                      ...editData.productInfo,
                      manufactureCountry: e.target.value,
                    },
                  });
                }}
                className="w-[665px]"
              />
            </NewStoreForm>
            <NewStoreForm required title="취급시 주의사항">
              <TextLimitHorizontalInput
                row={4}
                disabled={!edit}
                currentLength={editData.productInfo.precautions?.length}
                inputValue={editData.productInfo.precautions}
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    productInfo: {
                      ...editData.productInfo,
                      precautions: e.target.value,
                    },
                  });
                }}
                className="w-[665px]"
              />
            </NewStoreForm>
            <NewStoreForm required title="품질보증기준">
              <TextLimitHorizontalInput
                row={4}
                disabled={!edit}
                currentLength={
                  editData.productInfo.qualityAssuranceStandard?.length
                }
                inputValue={editData.productInfo.qualityAssuranceStandard}
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    productInfo: {
                      ...editData.productInfo,
                      qualityAssuranceStandard: e.target.value,
                    },
                  });
                }}
                className="w-[665px]"
              />
            </NewStoreForm>
            <NewStoreForm required title="A/S 책임자와 전화번호">
              <TextFieldInput
                disabled={!edit}
                inputValue={editData.productInfo.servicePhoneNum}
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    productInfo: {
                      ...editData.productInfo,
                      servicePhoneNum: e.target.value,
                    },
                  });
                }}
                className="w-[665px]"
              />
            </NewStoreForm>
          </>
        ) : editData.productInformation === "FASHION_MISCELLANEOUS_GOODS" ? (
          <>
            <NewStoreForm required title="종류">
              <TextFieldInput
                disabled={!edit}
                inputValue={editData.productInfo.type}
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    productInfo: {
                      ...editData.productInfo,
                      type: e.target.value,
                    },
                  });
                }}
                className="w-[665px]"
              />
            </NewStoreForm>
            <NewStoreForm required title="소재">
              <TextLimitHorizontalInput
                row={4}
                disabled={!edit}
                currentLength={editData.productInfo.material?.length}
                inputValue={editData.productInfo.material}
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    productInfo: {
                      ...editData.productInfo,
                      material: e.target.value,
                    },
                  });
                }}
                className="w-[665px]"
              />
            </NewStoreForm>
            <NewStoreForm required title="치수">
              <TextFieldInput
                disabled={!edit}
                inputValue={editData.productInfo.size}
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    productInfo: {
                      ...editData.productInfo,
                      size: e.target.value,
                    },
                  });
                }}
                className="w-[665px]"
              />
            </NewStoreForm>
            <NewStoreForm required title="제조사">
              <TextFieldInput
                disabled={!edit}
                inputValue={editData.productInfo.manufacturer}
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    productInfo: {
                      ...editData.productInfo,
                      manufacturer: e.target.value,
                    },
                  });
                }}
                className="w-[665px]"
              />
            </NewStoreForm>
            <NewStoreForm required title="제조국">
              <TextFieldInput
                disabled={!edit}
                inputValue={editData.productInfo.manufactureCountry}
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    productInfo: {
                      ...editData.productInfo,
                      manufactureCountry: e.target.value,
                    },
                  });
                }}
                className="w-[665px]"
              />
            </NewStoreForm>
            <NewStoreForm required title="취급시 주의사항">
              <TextLimitHorizontalInput
                row={4}
                disabled={!edit}
                currentLength={editData.productInfo.precautions?.length}
                inputValue={editData.productInfo.precautions}
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    productInfo: {
                      ...editData.productInfo,
                      precautions: e.target.value,
                    },
                  });
                }}
                className="w-[665px]"
              />
            </NewStoreForm>
            <NewStoreForm required title="품질보증기준">
              <TextLimitHorizontalInput
                row={4}
                disabled={!edit}
                currentLength={
                  editData.productInfo.qualityAssuranceStandard?.length
                }
                inputValue={editData.productInfo.qualityAssuranceStandard}
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    productInfo: {
                      ...editData.productInfo,
                      qualityAssuranceStandard: e.target.value,
                    },
                  });
                }}
                className="w-[665px]"
              />
            </NewStoreForm>
            <NewStoreForm required title="A/S 책임자와 전화번호">
              <TextFieldInput
                disabled={!edit}
                inputValue={editData.productInfo.servicePhoneNum}
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    productInfo: {
                      ...editData.productInfo,
                      servicePhoneNum: e.target.value,
                    },
                  });
                }}
                className="w-[665px]"
              />
            </NewStoreForm>
          </>
        ) : editData.productInformation === "COSMETIC" ? (
          <>
            <NewStoreForm required title="내용물의 용량 또는 중량">
              <TextFieldInput
                disabled={!edit}
                inputValue={editData.productInfo.weight}
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    productInfo: {
                      ...editData.productInfo,
                      weight: e.target.value,
                    },
                  });
                }}
                className="w-[665px]"
              />
            </NewStoreForm>
            <NewStoreForm required title="제품 주요사양">
              <TextLimitHorizontalInput
                row={4}
                disabled={!edit}
                currentLength={editData.productInfo.keySpecification?.length}
                inputValue={editData.productInfo.keySpecification}
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    productInfo: {
                      ...editData.productInfo,
                      keySpecification: e.target.value,
                    },
                  });
                }}
                className="w-[665px]"
              />
            </NewStoreForm>
            <NewStoreForm required title={"사용기한 또는 개봉 후\n사용기간"}>
              <TextFieldInput
                disabled={!edit}
                inputValue={editData.productInfo.expirationDate}
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    productInfo: {
                      ...editData.productInfo,
                      expirationDate: e.target.value,
                    },
                  });
                }}
                className="w-[665px]"
              />
            </NewStoreForm>
            <NewStoreForm required title="사용방법">
              <TextLimitHorizontalInput
                row={4}
                disabled={!edit}
                currentLength={editData.productInfo.instructions?.length}
                inputValue={editData.productInfo.instructions}
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    productInfo: {
                      ...editData.productInfo,
                      instructions: e.target.value,
                    },
                  });
                }}
                className="w-[665px]"
              />
            </NewStoreForm>
            <NewStoreForm
              required
              title={
                "화장품제조업자\n화장품책임판매업자 및\n맞춤형 화장품판매업자"
              }
            >
              <TextFieldInput
                disabled={!edit}
                inputValue={editData.productInfo.vendor}
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    productInfo: {
                      ...editData.productInfo,
                      vendor: e.target.value,
                    },
                  });
                }}
                className="w-[665px]"
              />
            </NewStoreForm>
            <NewStoreForm required title="제조국">
              <TextFieldInput
                disabled={!edit}
                inputValue={editData.productInfo.manufactureCountry}
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    productInfo: {
                      ...editData.productInfo,
                      manufactureCountry: e.target.value,
                    },
                  });
                }}
                className="w-[665px]"
              />
            </NewStoreForm>
            <NewStoreForm
              required
              title={"[화장품법]에 따라\n기재・표시하여야 하는\n모든 성분"}
            >
              <TextLimitHorizontalInput
                row={4}
                disabled={!edit}
                currentLength={editData.productInfo.ingredient?.length}
                inputValue={editData.productInfo.ingredient}
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    productInfo: {
                      ...editData.productInfo,
                      ingredient: e.target.value,
                    },
                  });
                }}
                className="w-[665px]"
              />
            </NewStoreForm>
            <NewStoreForm
              required
              title={"기능성 화장품은 식품의약\n품안전처 심사필 유무"}
            >
              <TextFieldInput
                disabled={!edit}
                inputValue={editData.productInfo.auditYn}
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    productInfo: {
                      ...editData.productInfo,
                      auditYn: e.target.value,
                    },
                  });
                }}
                className="w-[665px]"
              />
            </NewStoreForm>
            <NewStoreForm required title="사용할 때의 주의사항">
              <TextLimitHorizontalInput
                row={4}
                disabled={!edit}
                currentLength={editData.productInfo.precautions?.length}
                inputValue={editData.productInfo.precautions}
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    productInfo: {
                      ...editData.productInfo,
                      precautions: e.target.value,
                    },
                  });
                }}
                className="w-[665px]"
              />
            </NewStoreForm>
            <NewStoreForm required title="품질보증기준">
              <TextLimitHorizontalInput
                row={4}
                disabled={!edit}
                currentLength={
                  editData.productInfo.qualityAssuranceStandard?.length
                }
                inputValue={editData.productInfo.qualityAssuranceStandard}
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    productInfo: {
                      ...editData.productInfo,
                      qualityAssuranceStandard: e.target.value,
                    },
                  });
                }}
                className="w-[665px]"
              />
            </NewStoreForm>
            <NewStoreForm required title="A/S 책임자의 전화번호">
              <TextFieldInput
                disabled={!edit}
                inputValue={editData.productInfo.servicePhoneNum}
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    productInfo: {
                      ...editData.productInfo,
                      servicePhoneNum: e.target.value,
                    },
                  });
                }}
                className="w-[665px]"
              />
            </NewStoreForm>
          </>
        ) : editData.productInformation === "HEALTH_FUNCTIONAL_FOOD" ? (
          <>
            <NewStoreForm required title="제품명">
              <TextFieldInput
                disabled={!edit}
                inputValue={editData.productInfo.productName}
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    productInfo: {
                      ...editData.productInfo,
                      productName: e.target.value,
                    },
                  });
                }}
                className="w-[665px]"
              />
            </NewStoreForm>
            <NewStoreForm required title={"제조업소의 명칭과\n소재지"}>
              <TextFieldInput
                disabled={!edit}
                inputValue={editData.productInfo.location}
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    productInfo: {
                      ...editData.productInfo,
                      location: e.target.value,
                    },
                  });
                }}
                className="w-[665px]"
              />
            </NewStoreForm>
            <NewStoreForm required title={"소비기한 및 보관방법"}>
              <TextFieldInput
                disabled={!edit}
                inputValue={editData.productInfo.expirationDate}
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    productInfo: {
                      ...editData.productInfo,
                      expirationDate: e.target.value,
                    },
                  });
                }}
                className="w-[665px]"
              />
            </NewStoreForm>
            <NewStoreForm
              required
              title={"포장단위별 내용물의\n용량(중량), 수량"}
            >
              <TextFieldInput
                disabled={!edit}
                inputValue={editData.productInfo.weight}
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    productInfo: {
                      ...editData.productInfo,
                      weight: e.target.value,
                    },
                  });
                }}
                className="w-[665px]"
              />
            </NewStoreForm>
            <NewStoreForm required title={"원료명 및 함량"}>
              <TextLimitHorizontalInput
                row={4}
                disabled={!edit}
                inputValue={editData.productInfo.material}
                currentLength={editData.productInfo.material?.length}
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    productInfo: {
                      ...editData.productInfo,
                      material: e.target.value,
                    },
                  });
                }}
                className="w-[665px]"
              />
            </NewStoreForm>
            <NewStoreForm required title="영양정보">
              <TextLimitHorizontalInput
                row={4}
                disabled={!edit}
                inputValue={editData.productInfo.nutritionInfo}
                currentLength={editData.productInfo.nutritionInfo?.length}
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    productInfo: {
                      ...editData.productInfo,
                      nutritionInfo: e.target.value,
                    },
                  });
                }}
                className="w-[665px]"
              />
            </NewStoreForm>
            <NewStoreForm required title={"기능정보"}>
              <TextLimitHorizontalInput
                row={4}
                disabled={!edit}
                inputValue={editData.productInfo.functionalInfo}
                currentLength={editData.productInfo.functionalInfo?.length}
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    productInfo: {
                      ...editData.productInfo,
                      functionalInfo: e.target.value,
                    },
                  });
                }}
                className="w-[665px]"
              />
            </NewStoreForm>
            <NewStoreForm
              required
              title={"섭취량, 섭취방법 및\n섭취 시 주의사항 및\n부작용 가능성"}
            >
              <TextLimitHorizontalInput
                row={4}
                disabled={!edit}
                inputValue={editData.productInfo.sideEffectPossibility}
                currentLength={
                  editData.productInfo.sideEffectPossibility?.length
                }
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    productInfo: {
                      ...editData.productInfo,
                      sideEffectPossibility: e.target.value,
                    },
                  });
                }}
                className="w-[665px]"
              />
            </NewStoreForm>
            <NewStoreForm required title={"유전자변형건강기능식품\n표시"}>
              <TextFieldInput
                disabled={!edit}
                inputValue={
                  editData.productInfo.geneticallyModifiedHealthFunctionalFood
                }
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    productInfo: {
                      ...editData.productInfo,
                      geneticallyModifiedHealthFunctionalFood: e.target.value,
                    },
                  });
                }}
                className="w-[665px]"
              />
            </NewStoreForm>
            <NewStoreForm required title="수입식품 여부">
              <TextFieldInput
                disabled={!edit}
                inputValue={editData.productInfo.importedFoodYn}
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    productInfo: {
                      ...editData.productInfo,
                      importedFoodYn: e.target.value,
                    },
                  });
                }}
                className="w-[665px]"
              />
            </NewStoreForm>
            <NewStoreForm required title={"소비자안전을 위한\n주의사항"}>
              <TextFieldInput
                disabled={!edit}
                inputValue={editData.productInfo.precautions}
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    productInfo: {
                      ...editData.productInfo,
                      precautions: e.target.value,
                    },
                  });
                }}
                className="w-[665px]"
              />
            </NewStoreForm>
            <NewStoreForm required title={"소비자상담 관련\n전화번호"}>
              <TextFieldInput
                disabled={!edit}
                inputValue={editData.productInfo.servicePhoneNum}
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    productInfo: {
                      ...editData.productInfo,
                      servicePhoneNum: e.target.value,
                    },
                  });
                }}
                className="w-[665px]"
              />
            </NewStoreForm>
          </>
        ) : editData.productInformation === "SPORTS_EQUIPMENT" ? (
          <>
            <NewStoreForm required title="품명 및 모델명">
              <TextFieldInput
                disabled={!edit}
                inputValue={editData.productInfo.productName}
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    productInfo: {
                      ...editData.productInfo,
                      productName: e.target.value,
                    },
                  });
                }}
                className="w-[665px]"
              />
            </NewStoreForm>
            <NewStoreForm required title={"KC 인증정보"}>
              <TextFieldInput
                disabled={!edit}
                inputValue={editData.productInfo.kcInfo}
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    productInfo: {
                      ...editData.productInfo,
                      kcInfo: e.target.value,
                    },
                  });
                }}
                className="w-[665px]"
              />
            </NewStoreForm>
            <NewStoreForm required title={"크기, 중량"}>
              <TextFieldInput
                disabled={!edit}
                inputValue={editData.productInfo.size}
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    productInfo: {
                      ...editData.productInfo,
                      size: e.target.value,
                    },
                  });
                }}
                className="w-[665px]"
              />
            </NewStoreForm>
            <NewStoreForm required title={"색상"}>
              <TextFieldInput
                disabled={!edit}
                inputValue={editData.productInfo.color}
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    productInfo: {
                      ...editData.productInfo,
                      color: e.target.value,
                    },
                  });
                }}
                className="w-[665px]"
              />
            </NewStoreForm>
            <NewStoreForm required title={"재질"}>
              <TextFieldInput
                disabled={!edit}
                inputValue={editData.productInfo.material}
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    productInfo: {
                      ...editData.productInfo,
                      material: e.target.value,
                    },
                  });
                }}
                className="w-[665px]"
              />
            </NewStoreForm>
            <NewStoreForm required title="제품 구성">
              <TextFieldInput
                disabled={!edit}
                inputValue={editData.productInfo.productConfiguration}
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    productInfo: {
                      ...editData.productInfo,
                      productConfiguration: e.target.value,
                    },
                  });
                }}
                className="w-[665px]"
              />
            </NewStoreForm>
            <NewStoreForm required title={"동일모델의 출시년월"}>
              <TextFieldInput
                disabled={!edit}
                inputValue={editData.productInfo.sameModelReleaseDate}
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    productInfo: {
                      ...editData.productInfo,
                      sameModelReleaseDate: e.target.value,
                    },
                  });
                }}
                className="w-[665px]"
              />
            </NewStoreForm>
            <NewStoreForm required title={"제조사"}>
              <TextFieldInput
                disabled={!edit}
                inputValue={editData.productInfo.manufacturer}
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    productInfo: {
                      ...editData.productInfo,
                      manufacturer: e.target.value,
                    },
                  });
                }}
                className="w-[665px]"
              />
            </NewStoreForm>
            <NewStoreForm required title={"상품별 세부 사양"}>
              <TextLimitHorizontalInput
                row={4}
                disabled={!edit}
                currentLength={editData.productInfo.detailSpecification?.length}
                inputValue={editData.productInfo.detailSpecification}
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    productInfo: {
                      ...editData.productInfo,
                      detailSpecification: e.target.value,
                    },
                  });
                }}
                className="w-[665px]"
              />
            </NewStoreForm>
            <NewStoreForm required title="품질보증기준">
              <TextLimitHorizontalInput
                row={4}
                disabled={!edit}
                currentLength={
                  editData.productInfo.qualityAssuranceStandard?.length
                }
                inputValue={editData.productInfo.qualityAssuranceStandard}
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    productInfo: {
                      ...editData.productInfo,
                      qualityAssuranceStandard: e.target.value,
                    },
                  });
                }}
                className="w-[665px]"
              />
            </NewStoreForm>
            <NewStoreForm required title={"A/S 책임자와 전화번호"}>
              <TextFieldInput
                disabled={!edit}
                inputValue={editData.productInfo.servicePhoneNum}
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    productInfo: {
                      ...editData.productInfo,
                      servicePhoneNum: e.target.value,
                    },
                  });
                }}
                className="w-[665px]"
              />
            </NewStoreForm>
          </>
        ) : editData.productInformation === "OTHER_GOODS" ? (
          <>
            <NewStoreForm required title="품명 및 모델명">
              <TextFieldInput
                disabled={!edit}
                inputValue={editData.productInfo.productName}
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    productInfo: {
                      ...editData.productInfo,
                      productName: e.target.value,
                    },
                  });
                }}
                className="w-[665px]"
              />
            </NewStoreForm>
            <NewStoreForm
              required
              title={
                " 법에 의한 인증・허가\n등을 받았음을 확인할 수\n있는 그에 대한 사항"
              }
            >
              <TextFieldInput
                disabled={!edit}
                inputValue={editData.productInfo.certifiedInfo}
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    productInfo: {
                      ...editData.productInfo,
                      certifiedInfo: e.target.value,
                    },
                  });
                }}
                className="w-[665px]"
              />
            </NewStoreForm>
            <NewStoreForm required title={"제조국 또는 원산지"}>
              <TextFieldInput
                disabled={!edit}
                inputValue={editData.productInfo.manufactureCountry}
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    productInfo: {
                      ...editData.productInfo,
                      manufactureCountry: e.target.value,
                    },
                  });
                }}
                className="w-[665px]"
              />
            </NewStoreForm>
            <NewStoreForm required title={"제조사"}>
              <TextFieldInput
                disabled={!edit}
                inputValue={editData.productInfo.manufacturer}
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    productInfo: {
                      ...editData.productInfo,
                      manufacturer: e.target.value,
                    },
                  });
                }}
                className="w-[665px]"
              />
            </NewStoreForm>
            <NewStoreForm required title={"상품별 세부 사양"}>
              <TextLimitHorizontalInput
                row={4}
                disabled={!edit}
                currentLength={editData.productInfo.detailSpecification?.length}
                inputValue={editData.productInfo.detailSpecification}
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    productInfo: {
                      ...editData.productInfo,
                      detailSpecification: e.target.value,
                    },
                  });
                }}
                className="w-[665px]"
              />
            </NewStoreForm>
            <NewStoreForm required title="품질보증기준">
              <TextLimitHorizontalInput
                row={4}
                disabled={!edit}
                currentLength={
                  editData.productInfo.qualityAssuranceStandard?.length
                }
                inputValue={editData.productInfo.qualityAssuranceStandard}
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    productInfo: {
                      ...editData.productInfo,
                      qualityAssuranceStandard: e.target.value,
                    },
                  });
                }}
                className="w-[665px]"
              />
            </NewStoreForm>
            <NewStoreForm
              required
              title={"A/S 책임자와 전화번호\n또는 소비자상담 관련\n전화번호"}
            >
              <TextFieldInput
                disabled={!edit}
                inputValue={editData.productInfo.servicePhoneNum}
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    productInfo: {
                      ...editData.productInfo,
                      servicePhoneNum: e.target.value,
                    },
                  });
                }}
                className="w-[665px]"
              />
            </NewStoreForm>
          </>
        ) : (
          ""
        )}
      </NewStoreWrap>
      <NewStoreWrap title="상품 주요 정보">
        <NewStoreForm title="모델명">
          <TextFieldInput
            disabled={!edit}
            inputValue={editData.modelName}
            onChange={(e) => {
              setEditData({ ...editData, modelName: e.target.value });
            }}
            className="w-[476px]"
            placeholder="모델명 입력"
          />
        </NewStoreForm>
        <NewStoreForm title="브랜드">
          <TextFieldInput
            disabled={!edit}
            inputValue={editData.brandName}
            onChange={(e) => {
              setEditData({ ...editData, brandName: e.target.value });
            }}
            className="w-[476px]"
            placeholder="브랜드명 입력"
          />
        </NewStoreForm>
      </NewStoreWrap>
      <NewStoreWrap title="배송 설정">
        <NewStoreForm itemCenter required title="배송사">
          <div className="flex items-center">
            <div className="mr-4 Body3 text-Gray-90">
              {editData.deliveryInfo?.deliveryCompanyName}
            </div>
            {/* {edit && (
              <ButtonFilledSecondary
                onClick={() => {
                  setDeliveryPop(true);
                }}
                className="h-[52px] w-[108px]"
                btnText={`배송사 검색`}
                size={"md"}
              />
            )} */}
          </div>
        </NewStoreForm>
        <NewStoreForm required title="배송비 설정">
          <Dropdown
            disabled
            // disabled={!edit}
            innerText={
              editData.deliveryInfo.deliveryPriceType === "FREE"
                ? "무료"
                : editData.deliveryInfo.deliveryPriceType === "CONDITIONAL_FREE"
                ? "조건부 무료"
                : editData.deliveryInfo.deliveryPriceType === "CHARGE"
                ? "유료"
                : "선택"
            }
            width="w-[476px]"
          >
            {deliveryAmount.map((k, i) => {
              return (
                <li
                  onClick={() => {
                    k.type === "FREE"
                      ? setEditData({
                          ...editData,
                          deliveryInfo: {
                            ...editData.deliveryInfo,
                            freeDeliveryPrice: null,
                            defaultDeliveryPrice: null,
                            additionalDeliveryPrice: null,
                            deliveryPriceType: k.type,
                          },
                        })
                      : k.type === "CONDITIONAL_FREE"
                      ? setEditData({
                          ...editData,
                          deliveryInfo: {
                            ...editData.deliveryInfo,
                            freeDeliveryPrice: 0,
                            defaultDeliveryPrice: 0,
                            additionalDeliveryPrice: 0,
                            deliveryPriceType: k.type,
                          },
                        })
                      : setEditData({
                          ...editData,
                          deliveryInfo: {
                            freeDeliveryPrice: null,
                            defaultDeliveryPrice: 0,
                            additionalDeliveryPrice: 0,
                            deliveryPriceType: k.type,
                          },
                        });
                  }}
                  key={i}
                  className="dropdownWrap"
                >
                  <div className="dropdownLi">{k.name}</div>
                </li>
              );
            })}
          </Dropdown>
        </NewStoreForm>
        {editData.deliveryInfo.deliveryPriceType === "CONDITIONAL_FREE" ? (
          <NewStoreForm required title="무료배송 조건">
            <TextFieldInput
              disabled
              // disabled={!edit}
              onChange={(e) =>
                setEditData({
                  ...editData,
                  deliveryInfo: {
                    ...editData.deliveryInfo,
                    freeDeliveryPrice: Number(
                      e.target.value.replace(/[^0-9]/g, "")
                    ),
                  },
                })
              }
              inputValue={String(editData?.deliveryInfo.freeDeliveryPrice)}
              inputOnButton="원"
              className="w-[476px]"
              placeholder="금액 입력"
            />
          </NewStoreForm>
        ) : (
          ""
        )}
        {editData.deliveryInfo.deliveryPriceType === "CHARGE" ||
        editData.deliveryInfo.deliveryPriceType === "CONDITIONAL_FREE" ? (
          <>
            <NewStoreForm required title="기본 배송비">
              <TextFieldInput
                // disabled={!edit}
                disabled
                onChange={(e) =>
                  setEditData({
                    ...editData,
                    deliveryInfo: {
                      ...editData.deliveryInfo,
                      defaultDeliveryPrice: Number(
                        e.target.value.replace(/[^0-9]/g, "")
                      ),
                    },
                  })
                }
                inputValue={String(editData?.deliveryInfo.defaultDeliveryPrice)}
                inputOnButton="원"
                className="w-[476px]"
                placeholder="금액 입력"
              />
            </NewStoreForm>
            <NewStoreForm required title={"제주/도서 산간\n추가 배송비"}>
              <TextFieldInput
                // disabled={!edit}
                disabled
                onChange={(e) =>
                  setEditData({
                    ...editData,
                    deliveryInfo: {
                      ...editData.deliveryInfo,
                      additionalDeliveryPrice: Number(
                        e.target.value.replace(/[^0-9]/g, "")
                      ),
                    },
                  })
                }
                inputValue={String(
                  editData?.deliveryInfo.additionalDeliveryPrice
                )}
                inputOnButton="원"
                className="w-[476px]"
                placeholder="금액 입력"
              />
            </NewStoreForm>
          </>
        ) : (
          ""
        )}
        <NewStoreForm
          className="flex flex-col items-start gap-2.5"
          required
          title="출고지"
        >
          <div className="flex items-center gap-2">
            <TextFieldInput
              inputValue={editData.deliveryInfo.forwardingPlaceZip}
              className="w-[358px]"
              placeholder="우편번호"
              disabled
            />
            {/* {edit && (
              <DaumAddressSearch setAddres={setAddress}>
                <ButtonFilledSecondary
                  size={"md"}
                  className="h-[52px] w-[108px]"
                  btnText={"우편번호 검색"}
                />
              </DaumAddressSearch>
            )} */}
          </div>
          <TextFieldInput
            inputValue={editData.deliveryInfo.forwardingPlaceAddr}
            className="w-[476px]"
            disabled
          />
          <TextFieldInput
            // disabled={!edit}
            disabled
            onChange={(e) => {
              setEditData({
                ...editData,
                deliveryInfo: {
                  ...editData.deliveryInfo,
                  forwardingPlaceDetailAddr: e.target.value,
                },
              });
            }}
            inputValue={
              editData !== undefined
                ? editData?.deliveryInfo.forwardingPlaceDetailAddr
                : ""
            }
            className="w-[476px]"
          />
        </NewStoreForm>
        <NewStoreForm required title="배송 안내">
          <TextLimitHorizontalInput
            row={8}
            // disabled={!edit}
            disabled
            currentLength={editData.deliveryInfo.deliveryNotice?.length}
            inputValue={editData.deliveryInfo.deliveryNotice}
            onChange={(e) => {
              setEditData({
                ...editData,
                deliveryInfo: {
                  ...editData.deliveryInfo,
                  deliveryNotice: e.target.value,
                },
              });
            }}
            height=""
            maxLength={400}
            className="w-[665px]"
          />
        </NewStoreForm>
      </NewStoreWrap>
      <NewStoreWrap title="교환/반품 정보">
        <NewStoreForm itemCenter required title="배송사">
          <div className="flex items-center">
            <div className="mr-4 Body3 text-Gray-90">
              {editData.returnExchangeInfo.returnExchangeCompanyName}
            </div>
            {/* {edit && (
              <ButtonFilledSecondary
                onClick={() => {
                  setReturnDeliveryPop(true);
                }}
                className="h-[52px] w-[108px]"
                btnText={`배송사 검색`}
                size={"md"}
              />
            )} */}
          </div>
        </NewStoreForm>
        <NewStoreForm required title="교환 배송비 (왕복)">
          <TextFieldInput
            // disabled={!edit}
            disabled
            onChange={(e) =>
              setEditData({
                ...editData,
                returnExchangeInfo: {
                  ...editData.returnExchangeInfo,
                  exchangeDeliveryPrice: Number(
                    e.target.value.replace(/[^0-9]/g, "")
                  ),
                },
              })
            }
            inputValue={String(
              editData?.returnExchangeInfo.exchangeDeliveryPrice
            )}
            inputOnButton="원"
            className="w-[476px]"
            placeholder="금액 입력"
          />
        </NewStoreForm>
        <NewStoreForm required title={"제주/도서 산간\n추가 배송비 (왕복)"}>
          <TextFieldInput
            // disabled={!edit}
            disabled
            onChange={(e) =>
              setEditData({
                ...editData,
                returnExchangeInfo: {
                  ...editData.returnExchangeInfo,
                  additionalExchangeDeliveryPrice: Number(
                    e.target.value.replace(/[^0-9]/g, "")
                  ),
                },
              })
            }
            inputValue={String(
              editData?.returnExchangeInfo.additionalExchangeDeliveryPrice
            )}
            inputOnButton="원"
            className="w-[476px]"
            placeholder="금액 입력"
          />
        </NewStoreForm>
        <NewStoreForm required title="반품 배송비 (편도)">
          <TextFieldInput
            // disabled={!edit}
            disabled
            onChange={(e) =>
              setEditData({
                ...editData,
                returnExchangeInfo: {
                  ...editData.returnExchangeInfo,
                  returnDeliveryPrice: Number(
                    e.target.value.replace(/[^0-9]/g, "")
                  ),
                },
              })
            }
            inputValue={String(
              editData?.returnExchangeInfo.returnDeliveryPrice
            )}
            inputOnButton="원"
            className="w-[476px]"
            placeholder="금액 입력"
          />
        </NewStoreForm>
        <NewStoreForm required title={"제주/도서 산간\n추가 배송비 (편도)"}>
          <TextFieldInput
            // disabled={!edit}
            disabled
            onChange={(e) =>
              setEditData({
                ...editData,
                returnExchangeInfo: {
                  ...editData.returnExchangeInfo,
                  additionalReturnDeliveryPrice: Number(
                    e.target.value.replace(/[^0-9]/g, "")
                  ),
                },
              })
            }
            inputValue={String(
              editData?.returnExchangeInfo.additionalReturnDeliveryPrice
            )}
            inputOnButton="원"
            className="w-[476px]"
            placeholder="금액 입력"
          />
        </NewStoreForm>
        <NewStoreForm
          className="flex flex-col items-start gap-2.5"
          required
          title="교환/반품 출고지"
        >
          <div className="flex items-center gap-2">
            <TextFieldInput
              inputValue={editData.returnExchangeInfo.returnExchangePlaceZip}
              className="w-[358px]"
              placeholder="우편번호"
              disabled
            />
            {/* {edit && (
              <DaumAddressSearch setAddres={setReturnAddress}>
                <ButtonFilledSecondary
                  size={"md"}
                  className="h-[52px] w-[108px]"
                  btnText={"우편번호 검색"}
                />
              </DaumAddressSearch>
            )} */}
          </div>
          <TextFieldInput
            inputValue={editData.returnExchangeInfo.returnExchangePlaceAddr}
            className="w-[476px]"
            disabled
          />
          <TextFieldInput
            // disabled={!edit}
            disabled
            onChange={(e) => {
              setEditData({
                ...editData,
                returnExchangeInfo: {
                  ...editData.returnExchangeInfo,
                  returnExchangePlaceDetailAddr: e.target.value,
                },
              });
            }}
            inputValue={
              editData.returnExchangeInfo.returnExchangePlaceDetailAddr
            }
            className="w-[476px]"
          />
        </NewStoreForm>
        <NewStoreForm required title="교환/반품 안내">
          <TextLimitHorizontalInput
            row={8}
            // disabled={!edit}
            disabled
            currentLength={
              editData.returnExchangeInfo.returnExchangeNotice?.length
            }
            onChange={(e) => {
              setEditData({
                ...editData,
                returnExchangeInfo: {
                  ...editData.returnExchangeInfo,
                  returnExchangeNotice: e.target.value,
                },
              });
            }}
            inputValue={editData.returnExchangeInfo.returnExchangeNotice}
            maxLength={500}
            className="w-[665px]"
          />
        </NewStoreForm>
        <NewStoreForm required title="교환/반품 제한 사항">
          <TextLimitHorizontalInput
            row={8}
            // disabled={!edit}
            disabled
            currentLength={
              editData.returnExchangeInfo.returnExchangeRestrictionNotice
                ?.length
            }
            onChange={(e) => {
              setEditData({
                ...editData,
                returnExchangeInfo: {
                  ...editData.returnExchangeInfo,
                  returnExchangeRestrictionNotice: e.target.value,
                },
              });
            }}
            inputValue={
              editData.returnExchangeInfo.returnExchangeRestrictionNotice
            }
            maxLength={800}
            className="w-[665px]"
          />
        </NewStoreForm>
      </NewStoreWrap>
      <NewStoreWrap title="A/S 설정">
        <NewStoreForm required title="고객센터 연락처">
          <TextFieldInput
            // disabled={!edit}
            disabled
            onChange={(e) => {
              setEditData({
                ...editData,
                serviceInfo: {
                  ...editData.serviceInfo,
                  customerCenterNum: e.target.value.replace(
                    /[^0-9-]{0,16}/g,
                    ""
                  ),
                },
              });
            }}
            inputValue={editData.serviceInfo.customerCenterNum}
            className="w-[476px]"
          />
        </NewStoreForm>
        <NewStoreForm
          className="flex items-center gap-2"
          required
          title="고객센터 운영시간"
        >
          <>
            <Dropdown
              // disabled={!edit}
              disabled
              width="w-20"
              innerText={start !== undefined ? start[0] : "--"}
            >
              {hour.map((h, i) => {
                return (
                  <li
                    onClick={() => {
                      setCustomerCenterStartTimeHour(h);
                    }}
                    key={i}
                    className="dropdownWrap"
                  >
                    <div className="dropdownLi">{h}</div>
                  </li>
                );
              })}
            </Dropdown>
            <div>시</div>
            <Dropdown
              // disabled={!edit}
              disabled
              width="w-20"
              innerText={start !== undefined ? start[1] : "--"}
            >
              {minute.map((m, i) => {
                return (
                  <li
                    onClick={() => {
                      setCustomerCenterStartTimeMinute(m);
                    }}
                    key={i}
                    className="dropdownWrap"
                  >
                    <div className="dropdownLi">{m}</div>
                  </li>
                );
              })}
            </Dropdown>
            <div>분</div>
            <div className="mx-2">~</div>
            <Dropdown
              // disabled={!edit}
              disabled
              width="w-20"
              innerText={end !== undefined ? end[0] : "--"}
            >
              {hour.map((h, i) => {
                return (
                  <li
                    onClick={() => {
                      setCustomerCenterEndTimeHour(h);
                    }}
                    key={i}
                    className="dropdownWrap"
                  >
                    <div className="dropdownLi">{h}</div>
                  </li>
                );
              })}
            </Dropdown>
            <div>시</div>
            <Dropdown
              // disabled={!edit}
              disabled
              width="w-20"
              innerText={end !== undefined ? end[1] : "--"}
            >
              {minute.map((m, i) => {
                return (
                  <li
                    onClick={() => {
                      setCustomerCenterEndTimeMinute(m);
                    }}
                    key={i}
                    className="dropdownWrap"
                  >
                    <div className="dropdownLi">{m}</div>
                  </li>
                );
              })}
            </Dropdown>
            <div>분</div>
          </>
        </NewStoreForm>
        <NewStoreForm title="휴게시간">
          <div className="flex items-center gap-2 h-[52px]">
            <Toggle
              // disabled={!edit}
              disabled
              id="breakTime"
              isOn={toggleValue.breakTime}
              onChange={(e) =>
                setToggleValue({ ...toggleValue, breakTime: e.target.checked })
              }
            />
            <div className="SubH2 text-Gray-90">
              {toggleValue.breakTime ? "설정함" : "설정안함"}
            </div>
          </div>
          {toggleValue.breakTime && (
            <div className="flex items-center gap-2">
              <Dropdown
                // disabled={!edit}
                disabled
                width="w-20"
                innerText={breakStart !== undefined ? breakStart[0] : "--"}
              >
                {hour.map((h, i) => {
                  return (
                    <li
                      onClick={() => {
                        setBreakStartTimeHour(h);
                      }}
                      key={i}
                      className="dropdownWrap"
                    >
                      <div className="dropdownLi">{h}</div>
                    </li>
                  );
                })}
              </Dropdown>
              <div>시</div>
              <Dropdown
                // disabled={!edit}
                disabled
                width="w-20"
                innerText={breakStart !== undefined ? breakStart[1] : "--"}
              >
                {minute.map((m, i) => {
                  return (
                    <li
                      onClick={() => {
                        setBreakStartTimeMinute(m);
                      }}
                      key={i}
                      className="dropdownWrap"
                    >
                      <div className="dropdownLi">{m}</div>
                    </li>
                  );
                })}
              </Dropdown>
              <div>분</div>
              <div className="mx-2">~</div>
              <Dropdown
                // disabled={!edit}
                disabled
                width="w-20"
                innerText={breakEnd !== undefined ? breakEnd[0] : "--"}
              >
                {hour.map((h, i) => {
                  return (
                    <li
                      onClick={() => {
                        setBreakEndTimeHour(h);
                      }}
                      key={i}
                      className="dropdownWrap"
                    >
                      <div className="dropdownLi">{h}</div>
                    </li>
                  );
                })}
              </Dropdown>
              <div>시</div>
              <Dropdown
                // disabled={!edit}
                disabled
                width="w-20"
                innerText={breakEnd !== undefined ? breakEnd[1] : "--"}
              >
                {minute.map((m, i) => {
                  return (
                    <li
                      onClick={() => {
                        setBreakEndTimeMinute(m);
                      }}
                      key={i}
                      className="dropdownWrap"
                    >
                      <div className="dropdownLi">{m}</div>
                    </li>
                  );
                })}
              </Dropdown>
              <div>분</div>
            </div>
          )}
        </NewStoreForm>
        <NewStoreForm title="A/S 유의사항">
          <TextLimitHorizontalInput
            row={8}
            // disabled={!edit}
            disabled
            onChange={(e) => {
              setEditData({
                ...editData,
                serviceInfo: {
                  ...editData.serviceInfo,
                  serviceNotice: e.target.value,
                },
              });
            }}
            currentLength={editData.serviceInfo.serviceNotice?.length}
            inputValue={editData.serviceInfo.serviceNotice}
            className="w-[665px]"
            maxLength={300}
          />
        </NewStoreForm>
      </NewStoreWrap>
      <div className="fixed bottom-0 left-0 bg-Gray-10/70 backdrop-blur-[12px] z-[102] w-full py-5 border-t border-Gray-40">
        <div className="flex items-center pl-[260px] pr-[160px] justify-between max-w-[1920px] mx-auto min-w-[1440px]">
          <div className="Body2">
            상점 관리 {">"} 상품관리 {">"} 상품상세
          </div>
          {detail?.status === "END_OF_SALE" ? (
            <ButtonFilledTertiary
              onClick={() => {
                setDeleteModal(true);
              }}
              className="!text-Red100"
              size={"lg"}
              btnText={"상품 삭제"}
            />
          ) : (
            <>
              {edit ? (
                <div className="flex items-center gap-4">
                  <ButtonLinedTertiary
                    onClick={() => {
                      setEdit(false);
                      detailRefetch();
                    }}
                    size={"lg"}
                    btnText={"취소"}
                  />
                  <ButtonFilledPrimary
                    disabled={buttonDuplication}
                    onClick={() => {
                      handleEditData();
                    }}
                    size={"lg"}
                    btnText={"수정 완료"}
                  />
                </div>
              ) : (
                <div className="flex items-center gap-4">
                  <ButtonLinedTertiary
                    onClick={() => {
                      setSaleEnd(true);
                    }}
                    size={"lg"}
                    btnText={"판매 종료하기"}
                  />
                  <ButtonFilledPrimary
                    onClick={() => {
                      setEdit(true);
                    }}
                    size={"lg"}
                    btnText={"상품 수정"}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddProduct;
