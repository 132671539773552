import React from 'react';

interface Props {
    onChange?:(e:React.ChangeEvent<HTMLInputElement>) => void;
    isOn: boolean;
    id: string;
    disabled?: boolean;
}

const Toggle = ({onChange, isOn, id, disabled}:Props) => {
    return (
        <div>
            <label htmlFor={id} className="flex items-center cursor-pointer">
                <div className="relative">
                    <input disabled={disabled} onChange={onChange} type="checkbox" id={id} className="hidden" />
                    <div>
                    <div className={`w-12 ${isOn ? "bg-Gray-90" : "bg-Gray-20"} rounded-full shadow-inner h-7 toggle-line`}></div>
                    <div className={`${isOn ? "translate-x-6 bg-Gray-10" : "translate-x-1 bg-Gray-40"} absolute transition-all w-5 h-5 bg-white rounded-full shadow top-1 toggle-dot`}></div>
                    </div>
                </div>
            </label>
        </div>
    );
};

export default Toggle;