import React from 'react';

interface Props {
    title: string;
    subTitle?: string;
    className?: string;
    required?: boolean;
    children?: React.ReactNode;
    itemCenter?: boolean;
}

const NewStoreForm = ({ title, className, children, required, subTitle, itemCenter}: Props) => {
    return (
        <div className={`flex ${itemCenter ? "items-center" : "items-start"}`}>
            <div className='py-[10px] min-w-[150px] max-w-[150px] h-[52px] w-full pl-3 flex flex-col justify-center'>
                <div className='whitespace-pre-wrap SubH2 text-Gray-90'>{title}{required && <span className='text-red'>*</span>}</div>
                {subTitle &&
                    <div className='SubH2 text-Gray-60'>
                        {subTitle}
                    </div>
                }
            </div>

            <div className={`${className}`}>
                {children}
            </div>
        </div>
    );
};

export default NewStoreForm;