import axios from 'axios';
import { onSilentRefresh } from './onSlientRefresh';

const requestToLoveEgg = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    withCredentials: true,
});

requestToLoveEgg.interceptors.request.use(
    async (config) => {
        const userInfo = localStorage.getItem('userInfo');
        const accessToken =
            userInfo && JSON.parse(userInfo).accessToken
                ? JSON.parse(userInfo).accessToken
                : '';
                
        if (!config.headers['Authorization']) {
            config.headers['Authorization'] = `Bearer ${accessToken}`;
        }
        return config;
    },
    (error) => {
        Promise.reject(error);
    },
);

requestToLoveEgg.interceptors.response.use(
    (response) => {
        return response;
    },
    async function (error) {
        const originalRequest = error.config;

        async function refreshAccessToken() {
            onSilentRefresh();
        }

        if (error.response?.status === 401 && !originalRequest._retry) {
            console.log('토큰 만료');
            originalRequest._retry = true;
            await refreshAccessToken();
            return axios(originalRequest);
        }
        return Promise.reject(error);
    },
);

export default requestToLoveEgg;
