import { useCategoryListApi, useUserStoreInfoApi } from "api/store";
import ButtonFilledPrimary from "components/Button/ButtonFilledPrimary";
import ButtonFilledSecondary from "components/Button/ButtonFilledSecondary";
import ButtonFilledTertiary from "components/Button/ButtonFilledTertiary";
import ButtonLinedTertiary from "components/Button/ButtonLinedTertiary";
import DaumAddressSearch from "components/DaumAddressSearch";
import Dropdown from "components/Dropdown/Dropdown";
import TextFieldInput from "components/Input/TextFieldInput";
import ModalPopup from "components/Modal/ModalPopup";
import NewStoreForm from "components/Store/NewStoreForm";
import NewStoreWrap from "components/Store/NewStoreWrap";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { daumAddress } from "type/daumAddress";
import { CategoryResultData, NewUserStoreInfoData } from "type/store";
import requestToLoveEgg from "utils/requestToLoveEgg";
import TextButtonPrimary from "components/Button/TextBtnPrimary";
import TextBtnTertiary from "components/Button/TextBtnTertiary";
import { update } from "lodash";

interface Props {
  status?: string;
  authority?: string;
}

const StoreInfo = ({ status, authority }: Props) => {
  const params = useParams();
  const navigate = useNavigate();
  const [edit, setEdit] = useState(false);
  const param = useParams();

  const comId = Number(param.id);

  const businessLicenseRef = useRef<HTMLInputElement>(null);
  const mailOrderRef = useRef<HTMLInputElement>(null);
  const passbookRef = useRef<HTMLInputElement>(null);
  const mainRef = useRef<HTMLInputElement>(null);
  const sub1Ref = useRef<HTMLInputElement>(null);
  const sub2Ref = useRef<HTMLInputElement>(null);

  const [cancelmodal, setCancelModal] = useState<boolean>(false);
  const [passModal, setPassModal] = useState<boolean>(false);
  const [modalValue, setModalValue] = useState<string>("");

  const [confirmModal, setConfirmModal] = useState<boolean>(false);

  const [entryModal, setEntryModal] = useState<boolean>(false);

  // const [categortMain, setCategoryMain] = useState({ categoryId: 0, name: "" });
  // const [categortSub1, setCategorySub1] = useState({ categoryId: 0, name: "" });
  // const [categortSub2, setCategorySub2] = useState({ categoryId: 0, name: "" });

  // 사업자 등록증
  const [businessLicenseFile, setBusinessLicenseFile] = useState<File | null>(
    null
  );
  const [bizPreview, setBizPreview] = useState<string>("");
  // 통장 사본
  const [passbookFile, setPassbookFile] = useState<File | null>(null);
  const [passPreview, setPassPreview] = useState<string>("");
  // 통신 판매업
  const [mailOrderFile, setMailOrderFile] = useState<File | null>(null);
  const [mailPreview, setMailPreview] = useState<string>("");
  // 	계약서 파일(통합 관리자만 수정가능)
  const [contractFile, setContractFile] = useState<File | null>(null);

  const [mainCategoryFiles, setMainCategoryFiles] = useState<File[] | any>([]);
  const [mainPreview, setMainPreview] = useState<string[]>([]);
  const [sub1Preview, setSub1Preview] = useState<string[]>([]);
  const [sub2Preview, setSub2Preview] = useState<string[]>([]);
  const [subCategory1Files, setSubCategory1Files] = useState<File[] | any>([]);
  const [subCategory2Files, setSubCategory2Files] = useState<File[] | any>([]);

  const [delMainFileId, setDelMainFileId] = useState<number[]>([]);
  const [delSub1FileId, setDelSub1FileId] = useState<number[]>([]);
  const [delSub2FileId, setDelSub2FileId] = useState<number[]>([]);

  const { data: SData, refetch } = useUserStoreInfoApi(
    authority === "ROLE_COMMERCE_ADMIN" ? params.id : ""
  );
  const store = SData?.data.ResultData as NewUserStoreInfoData;

  useEffect(() => {
    setPassModal(false);
    setCancelModal(false);
  }, [modalValue]);

  const resultRequest = (type: string) => {
    requestToLoveEgg({
      method: "POST",
      url: `/api/admin/v1/join-apply/${comId}/${type}`,
    }).then((res) => {});
  };

  const [storeData, setStoreData] = useState<{
    companyId: number;
    companyName: string;
    companyRegNo: string;
    addr: string;
    detailAddr: string;
    zip: string;
    repName: string;
    mainCategory: number;
    mainName: string;
    subCategory1: number;
    sub1Name: string;
    subCategory2: number;
    sub2Name: string;
    brandName: string;
    mainUrl: string;
    saleUrl: string;
    accountName: string;
    accountNo: string;
    fee: number;
    mainCategoryBusinessImages: { id: number; imagePath: string }[];
    subCategory1BusinessImages: { id: number; imagePath: string }[];
    subCategory2BusinessImages: { id: number; imagePath: string }[];
    businessLicensePath: string;
    mailOrderBusinessPath: string;
    passbookPath: string;
    contractPath: string;
    delBusinessLicenseFile: string;
    delMailOrderFile: string;
    delPassbookFile: string;
    delContractFile: string;
    delMainCategoryIds: string;
    delSubCategory1Ids: string;
    delSubCategory2Ids: string;
  }>({
    companyId: 0,
    companyName: "",
    companyRegNo: "",
    addr: "",
    detailAddr: "",
    zip: "",
    repName: "",
    mainCategory: 0,
    mainName: "",
    subCategory1: 0,
    sub1Name: "",
    subCategory2: 0,
    sub2Name: "",
    brandName: "",
    mainUrl: "",
    saleUrl: "",
    accountName: "",
    accountNo: "",
    fee: 5,
    mainCategoryBusinessImages: [],
    subCategory1BusinessImages: [],
    subCategory2BusinessImages: [],
    businessLicensePath: "",
    mailOrderBusinessPath: "",
    passbookPath: "",
    contractPath: "",
    delBusinessLicenseFile: "",
    delMailOrderFile: "",
    delPassbookFile: "",
    delContractFile: "",
    delMainCategoryIds: "",
    delSubCategory1Ids: "",
    delSubCategory2Ids: "",
  });

  const [address, setAddress] = useState<daumAddress>({
    address: "",
    addressEnglish: "",
    addressType: "",
    apartment: "",
    autoJibunAddress: "",
    autoJibunAddressEnglish: "",
    autoRoadAddress: "",
    autoRoadAddressEnglish: "",
    bcode: "",
    bname: "",
    bname1: "",
    bname1English: "",
    bname2: "",
    bname2English: "",
    bnameEnglish: "",
    buildingCode: "",
    buildingName: "",
    hname: "",
    jibunAddress: "",
    jibunAddressEnglish: "",
    noSelected: "",
    postcode: "",
    postcode1: "",
    postcode2: "",
    postcodeSeq: "",
    query: "",
    roadAddress: "",
    roadAddressEnglish: "",
    roadname: "",
    roadnameCode: "",
    roadnameEnglish: "",
    sido: "",
    sidoEnglish: "",
    sigungu: "",
    sigunguCode: "",
    sigunguEnglish: "",
    userLanguageType: "",
    userSelectedType: "",
    zonecode: "",
  });

  const { data: category } = useCategoryListApi();
  const data = store;

  useEffect(() => {
    return setStoreData({
      ...storeData,
      companyId: comId ? comId : 0,
      companyName: store?.companyName.trim(),
      companyRegNo: store?.companyRegNo,
      addr: store?.address,
      detailAddr: store?.detailAddress.trim(),
      zip: store?.zip,
      businessLicensePath: store?.businessLicensePath,
      mailOrderBusinessPath: store?.mailOrderBusinessPath,
      passbookPath: store?.passbookPath,
      contractPath: store?.contractPath,
      repName: store?.reqName.trim(),
      mainCategory: store?.mainCategoryId,
      mainName: store?.mainCategoryName,
      subCategory1: store?.subCategory1Id,
      sub1Name: store?.subCategoryName1,
      subCategory2: store?.subCategory2Id,
      sub2Name: store?.subCategoryName2,
      brandName: store?.brandName.trim(),
      mainUrl: store?.mainUrl,
      saleUrl: store?.saleUrl,
      // 정산계좌
      accountName: store?.accountName,
      accountNo: store?.accountNo,
      mainCategoryBusinessImages: store?.mainCategoryBusinessImages,
      subCategory1BusinessImages: store?.subCategory1BusinessImages,
      subCategory2BusinessImages: store?.subCategory2BusinessImages,
      // 수수료 (통합관리자)
      fee: 5,
      // 사업자 등록증 Y/N
      delBusinessLicenseFile: "N",
      // 통신 판매업 Y/N
      delMailOrderFile: "N",
      // 통장 사본 Y, N
      delPassbookFile: "N",
      // 계약서 Y, N  (통합관리자)
      delContractFile: "",
      delMainCategoryIds: "",
      delSubCategory1Ids: "",
      delSubCategory2Ids: "",
    });
  }, [SData]);

  useEffect(() => {
    setStoreData({
      ...storeData,
      addr: address.address,
      zip: address.zonecode,
      detailAddr: "",
    });
  }, [address]);

  const handleStoreInfo = () => {
    setStoreData({...storeData, delMailOrderFile: delMainFileId.join(",")});

    const formData = new FormData();
    formData.append(
      "data",
      new Blob([JSON.stringify(storeData)], { type: "application/json" })
    );

    formData.append("businessLicenseFile", businessLicenseFile as File);
    formData.append("passbookFile", passbookFile as File);
    formData.append("mailOrderFile", mailOrderFile as File);
    formData.append("contractFile", contractFile as File);
    for (let i = 0; i < mainCategoryFiles.length; i++) {
      formData.append("mainCategoryFiles", mainCategoryFiles[i]);
    }
    for (let i = 0; i < subCategory1Files.length; i++) {
      formData.append("subCategory1Files", subCategory1Files[i]);
    }
    for (let i = 0; i < subCategory2Files.length; i++) {
      formData.append("subCategory2Files", subCategory2Files[i]);
    }

    requestToLoveEgg({
      method: "POST",
      url: `/api/v1/join-apply`,
      data: formData,
      headers: {
        "Content-Type": `multipart/form-data`,
      },
    }).then((res) => {
      if (res.data.ResultCode === 1) {
        toast.success("상품 수정 완료 했습니다 :)");
        setMainPreview([]);
        setSub1Preview([]);
        setSub2Preview([]);
        setMainCategoryFiles([]);
        setSubCategory1Files([]);
        setSubCategory2Files([]);
        setDelMainFileId([]);
        setDelSub1FileId([]);
        setDelSub2FileId([]);
        setBusinessLicenseFile(null);
        setMailOrderFile(null);
        setPassbookFile(null);
        setEdit(false);
        refetch();
      }
    });
  };

  const confirmEntry = () => {
    if (status !== "WAITING") return;
    requestToLoveEgg({
      method: "POST",
      url: `/api/admin/v1/join-apply/${comId}/accept`,
    }).then((res) => {
      if (res.data.ResultData === "SUCCESS") {
        setEntryModal(false);
        setConfirmModal(true);
        setModalValue("CONFIRM");
      }
    });
  };

  const categoryFileInfo = [
    {
      name: "여성패션",
      id: 35,
      docs: [
        {
          desc: "정품확인이 필수인 브랜드를 판매하는 경우",
          fileName:
            "[수입신고필증(갑,을지)또는 브랜드제조업자와의 공급거래계약서]",
        },
      ],
    },
    {
      name: "남성패션",
      id: 128,
      docs: [
        {
          desc: "정품확인이 필수인 브랜드를 판매하는 경우",
          fileName:
            "[수입신고필증(갑,을지)또는 브랜드제조업자와의 공급거래계약서]",
        },
      ],
    },
    {
      name: "뷰티",
      id: 211,
      docs: [
        {
          desc: "직접 수입 또는 제조한 화장품을 판매하거나 OEM방식으로 제조한 화장품을 판매하거나 해외구매대행으로 화장품을 판매하는 경우",
          fileName: "[화장품제조판매업 등록증]",
        },
        {
          desc: "정품확인이 필수인 브랜드를 판매하는 경우",
          fileName:
            "[수입신고필증(갑,을지)또는 브랜드제조업자와의 공급거래계약서]",
        },
      ],
    },
    {
      name: "식품",
      id: 284,
      docs: [
        {
          desc: "직접 제조•가공한 식품을 판매하는 경우",
          fileName: "[식품제조가공업영업등록증]",
        },
        {
          desc: "식품 등을 소분 판매하거나, OEM 방식으로 제조•가공한 식품을 판매하거나, 직접 수입한 식품을 판매하는 경우",
          fileName: "[식품소분판매업영업신고증]",
        },
        {
          desc: "식육 또는 포장육, 식육부산물, 우유, 식용란을 전문적으로 판매하거나, OEM방식으로 가공 또는 포장 처리된 축산물을 판매하거나, 직접 수입한 축산물을 판매하는 경우",
          fileName: "[축산물판매업 신고필증]",
        },
        { desc: "전통주를 판매하는 경우", fileName: "[주류통신판매승인서]" },
      ],
    },
    {
      name: "헬스/건강식품",
      id: 359,
      docs: [
        {
          desc: "건강기능식품을 판매하는 경우",
          fileName: "[건강기능식품판매업 영업신고증]",
        },
      ],
    },
    {
      name: "스포츠레저",
      id: 479,
      docs: [
        {
          desc: "정품확인이 필수인 브랜드를 판매하는 경우",
          fileName:
            "[수입신고필증(갑,을지)또는 브랜드제조업자와의 공급거래계약서]",
        },
      ],
    },
    {
      name: "건강/의료용품",
      id: 656,
      docs: [
        {
          desc: "의료기기를 판매하는 경우",
          fileName: "[의료기기판매업 신고증]",
        },
        {
          desc: "정품확인이 필수인 브랜드를 판매하는 경우",
          fileName:
            "[수입신고필증(갑,을지)또는 브랜드제조업자와의 공급거래계약서]",
        },
      ],
    },
    {
      name: "반려동물용품",
      id: 740,
      docs: [
        {
          desc: "동물용 의료기기를 판매하는 경우",
          fileName: "[동물용 의료기기판매업 신고증]",
        },
        {
          desc: "정품확인이 필수인 브랜드를 판매하는 경우",
          fileName:
            "[수입신고필증(갑,을지)또는 브랜드제조업자와의 공급거래계약서]",
        },
      ],
    },
    {
      name: "자동차용품",
      id: 752,
      docs: [
        {
          desc: "정품확인이 필수인 브랜드를 판매하는 경우",
          fileName:
            "[수입신고필증(갑,을지)또는 브랜드제조업자와의 공급거래계약서]",
        },
      ],
    },
    {
      name: "도서/음반/DVD",
      id: 761,
      docs: [
        {
          desc: "정품확인이 필수인 브랜드를 판매하는 경우",
          fileName:
            "[수입신고필증(갑,을지)또는 브랜드제조업자와의 공급거래계약서]",
        },
      ],
    },
    {
      name: "취미/완구/악기",
      id: 786,
      docs: [
        {
          desc: "정품확인이 필수인 브랜드를 판매하는 경우",
          fileName:
            "[수입신고필증(갑,을지)또는 브랜드제조업자와의 공급거래계약서]",
        },
      ],
    },
    {
      name: "문구/오피스",
      id: 810,
      docs: [
        {
          desc: "정품확인이 필수인 브랜드를 판매하는 경우",
          fileName:
            "[수입신고필증(갑,을지)또는 브랜드제조업자와의 공급거래계약서]",
        },
      ],
    },
    {
      name: "가전",
      id: 829,
      docs: [
        {
          desc: "정품확인이 필수인 브랜드를 판매하는 경우",
          fileName:
            "[수입신고필증(갑,을지)또는 브랜드제조업자와의 공급거래계약서]",
        },
      ],
    },
    {
      name: "디지털",
      id: 841,
      docs: [
        {
          desc: "통신사 가입상품을 판매하는 경우",
          fileName: "[통신사와의 계약서 또는 공식대리점과의 위탁 계약서]",
        },
        {
          desc: "정품확인이 필수인 브랜드를 판매하는 경우",
          fileName:
            "[수입신고필증(갑,을지)또는 브랜드제조업자와의 공급거래계약서]",
        },
      ],
    },
    {
      name: "컴퓨터",
      id: 850,
      docs: [
        {
          desc: "정품확인이 필수인 브랜드를 판매하는 경우",
          fileName:
            "[수입신고필증(갑,을지)또는 브랜드제조업자와의 공급거래계약서]",
        },
      ],
    },
    {
      name: "가구/인테리어",
      id: 860,
      docs: [
        {
          desc: "정품확인이 필수인 브랜드를 판매하는 경우",
          fileName:
            "[수입신고필증(갑,을지)또는 브랜드제조업자와의 공급거래계약서]",
        },
      ],
    },
    {
      name: "주방/생활용품",
      id: 876,
      docs: [
        {
          desc: "정품확인이 필수인 브랜드를 판매하는 경우",
          fileName:
            "[수입신고필증(갑,을지)또는 브랜드제조업자와의 공급거래계약서]",
        },
      ],
    },
    {
      name: "출산/유아동",
      id: 885,
      docs: [
        {
          desc: "정품확인이 필수인 브랜드를 판매하는 경우",
          fileName:
            "[수입신고필증(갑,을지)또는 브랜드제조업자와의 공급거래계약서]",
        },
      ],
    },
  ];
  const mainCategoryFileList = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files !== null) {
      let fileList = e.target.files;
      const flies = [...mainCategoryFiles, ...fileList];

      if (flies?.length + storeData.mainCategoryBusinessImages.length > 5) {
        alert("최대 5개 업로드 가능합니다.");
      } else {
        for (let i = 0; i < fileList?.length; i++) {
          if (fileList[i].size > 1024 ** 2 * 2) {
            return alert(`사이즈가 1mb 보다 큰 파일이 있습니다.`);
          } else {
            setMainCategoryFiles(flies);
            const mainPreviewItem = URL.createObjectURL(fileList[i]);
            setMainPreview((mainPreview) => [...mainPreview, mainPreviewItem]);
          }
        }
      }
    }
  };

  const sub1CategoryFileList = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files !== null) {
      let fileList = e.target.files;
      const flies = [...subCategory1Files, ...fileList];
      console.log(flies);
      if (flies?.length > 5) {
        alert("최대 5개 업로드 가능합니다.");
      } else {
        for (let i = 0; i < fileList?.length; i++) {
          if (fileList[i].size > 1024 ** 2 * 2) {
            return alert(`사이즈가 1mb 보다 큰 파일이 있습니다.`);
          } else {
            setSubCategory1Files(flies);
            const sub1PreviewItem = URL.createObjectURL(fileList[i]);
            setSub1Preview((sub1Preview) => [...sub1Preview, sub1PreviewItem]);
          }
        }
      }
    }
  };
  const sub2CategoryFileList = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files !== null) {
      let fileList = e.target.files;
      const flies = [...subCategory2Files, ...fileList];
      if (flies?.length > 5) {
        alert("최대 5개 업로드 가능합니다.");
      } else {
        for (let i = 0; i < fileList?.length; i++) {
          if (fileList[i].size > 1024 ** 2 * 2) {
            return alert(`사이즈가 1mb 보다 큰 파일이 있습니다.`);
          } else {
            setSubCategory2Files(flies);
            const sub2PreviewItem = URL.createObjectURL(fileList[i]);
            setSub2Preview((sub2Preview) => [...sub2Preview, sub2PreviewItem]);
          }
        }
      }
    }
  };

  const newCate = [
    ...(category?.data?.ResultData !== undefined
      ? (category?.data?.ResultData! as CategoryResultData[])
      : []),
    { categoryId: 0, name: "선택안함" },
  ];

  console.log("a" + ",b")

  console.log(
    "mainCategoryBusinessImages",
    storeData.mainCategoryBusinessImages
  );
  console.log(
    "subCategory1BusinessImages",
    storeData.subCategory1BusinessImages
  );
  console.log(
    "subCategory2BusinessImages",
    storeData.subCategory2BusinessImages
  );
  console.log("mainCategoryFiles", mainCategoryFiles);
  console.log("mainPreview", mainPreview);

  console.log("delMainFileId", delMainFileId)

  const mainCategoryImageId = storeData.mainCategoryBusinessImages.map(
    ({ id }) => id
  );
  const sub1CategoryImageId = storeData.subCategory1BusinessImages.map(
    ({ id }) => id
  );
  const sub2CategoryImageId = storeData.subCategory2BusinessImages.map(
    ({ id }) => id
  );

  console.log(storeData);

  return (
    <div>
      {confirmModal && (
        <ModalPopup
          type={"lg"}
          buttonText={"확인"}
          onClick={() => {
            setModalValue("");
            setConfirmModal(false);
            navigate("/store/entry", { replace: true });
          }}
          setCloseState={() => {}}
        >
          {modalValue === "REJECT" ? (
            <div>
              <div className="H4">
                "{data?.companyName}" 입점 신청이{" "}
                <span className="text-Blue-100">거절</span>되었습니다.
              </div>
              <div className="mt-4 Body2 break-keep">
                <span className="text-Blue-100">{data?.email}</span>에게 입점
                신청 거절 사유에 대해 안내해 주세요.
              </div>
            </div>
          ) : modalValue === "ACCEPT" ? (
            <div>
              <div className="H4">
                "{data?.companyName}" 입점 신청이{" "}
                <span className="text-Blue-100">승인</span>되었습니다.
              </div>
              <div className="mt-4 Body2 break-keep">
                <span className="text-Blue-100">{data?.email}</span>와 계약
                사항을 마무리 해주세요. 판매 전 필수 입력 사항을 입력해야 정식
                승인 및 상품 등록이 가능합니다.
              </div>
            </div>
          ) : modalValue === "CONFIRM" ? (
            <div>
              <div className="H4">
                "{data?.companyName}" 입점이{" "}
                <span className="text-Blue-100">승인</span>되었습니다.
              </div>
              <div className="mt-4 whitespace-pre-wrap Body2 break-keep">
                {
                  "승인 이후 배송 및 운영 정보 입력상태를 확인해주세요.\n배송 및 운영설정의 필수 정보가 있어야 상점 활성화 가능합니다."
                }
              </div>
            </div>
          ) : (
            ""
          )}
        </ModalPopup>
      )}
      {cancelmodal && (
        <ModalPopup
          onClick={() => {
            setConfirmModal(true);
            resultRequest("reject");
            setModalValue("REJECT");
          }}
          setCloseState={setCancelModal}
          type={"md"}
          cancalButton
          buttonText={"입점거부"}
        >
          <div className="H4">입점거부</div>
          <div className="mt-4 Body2 break-keep">
            `{data?.companyName}`의 입점요청을 거절할까요? 거절 시 입점요청서는
            1주일 후 삭제됩니다.
          </div>
        </ModalPopup>
      )}
      {passModal && (
        <ModalPopup
          onClick={() => {
            setConfirmModal(true);
            resultRequest("pass");
            setModalValue("ACCEPT");
          }}
          setCloseState={setPassModal}
          type={"md"}
          cancalButton
          buttonText={"입점요청 승인"}
        >
          <div className="H4">입점 1차 승인</div>
          <div className="mt-4 Body2 break-keep text-Gray-80">
            <span className="SubH1">`{data?.companyName}`</span>의 입점요청을{" "}
            <span className="text-Blue-100">승인</span>할까요? 승인 후 해당
            업체와 정식 입점 절차를 진행해야 합니다.
          </div>
        </ModalPopup>
      )}
      {entryModal && (
        <ModalPopup
          onClick={() => {
            confirmEntry();
          }}
          setCloseState={setEntryModal}
          type={"md"}
          cancalButton
          buttonText={"입점 최종승인"}
        >
          <div className="H4">
            "{data?.companyName}"입점을{" "}
            <span className="text-Blue-100">최종승인</span>하시겠습니까?
          </div>
          <div className="mt-4 Body2 break-keep">
            승인시 해당 업체는 <span className="SubH1">‘전체 상점'</span>{" "}
            메뉴에서 비활성상태로 확인할 수 있습니다.
          </div>
        </ModalPopup>
      )}
      <NewStoreWrap title="판매자 정보">
        <NewStoreForm required title="회사명">
          <TextFieldInput
            disabled={!edit}
            onChange={(e) => {
              setStoreData({
                ...storeData,
                companyName: e.target.value.replace(
                  /[^A-Za-zㄱ-ㅎ가-힣\s()]/g,
                  ""
                ),
              });
            }}
            inputValue={storeData?.companyName}
            className="w-[476px]"
            placeholder="예시) 주식회사 러브에그"
          />
        </NewStoreForm>
        <NewStoreForm required title="사업자 등록 정보">
          <TextFieldInput
            disabled={!edit}
            maxLength={12}
            onChange={(e) => {
              setStoreData({
                ...storeData,
                companyRegNo: e.target.value
                  .replace(/[^0-9]/g, "")
                  .replace(/^(\d{0,3})(\d{0,2})(\d{0,5})$/g, "$1-$2-$3")
                  .replace(/(\-{1,2})$/g, ""),
              });
            }}
            inputValue={edit ? storeData.companyRegNo : data?.companyRegNo}
            className="w-[476px]"
            placeholder="123-45-67890"
          />
          {edit ? (
            <>
              {businessLicenseFile !== null ? (
                <div className="flex items-center gap-2">
                  <TextButtonPrimary
                    onClick={() => {
                      window.open(`${bizPreview}`);
                    }}
                    className="mt-2"
                    size={"lg"}
                    text={"첨부파일 보기"}
                  ></TextButtonPrimary>
                  <TextBtnTertiary
                    onClick={() => {
                      setBusinessLicenseFile(null);
                      setStoreData({
                        ...storeData,
                        businessLicensePath: "",
                        delBusinessLicenseFile: "Y",
                      });
                      setBizPreview("");
                    }}
                    className="mt-2"
                    size={"sm"}
                    text={"삭제하기"}
                  ></TextBtnTertiary>
                </div>
              ) : storeData?.businessLicensePath !== "" ? (
                <div className="flex items-center gap-2">
                  <TextButtonPrimary
                    onClick={() => {
                      window.open(`${storeData?.businessLicensePath}`);
                    }}
                    className="mt-2"
                    size={"lg"}
                    text={"첨부파일 보기"}
                  ></TextButtonPrimary>
                  <TextBtnTertiary
                    onClick={() => {
                      setStoreData({
                        ...storeData,
                        businessLicensePath: "",
                        delBusinessLicenseFile: "Y",
                      });
                    }}
                    className="mt-2"
                    size={"sm"}
                    text={"삭제하기"}
                  ></TextBtnTertiary>
                </div>
              ) : (
                <div>
                  <ButtonFilledTertiary
                    className="!mx-0 my-2"
                    size={"md"}
                    btnText={"사업자 등록증 첨부"}
                    onClick={() => {
                      businessLicenseRef.current?.click();
                    }}
                  />
                  <input
                    ref={businessLicenseRef}
                    accept=".pdf"
                    onChange={(e) => {
                      if (e.target.files) {
                        setBusinessLicenseFile(e.target.files[0]);
                        const preview = URL.createObjectURL(e.target.files[0]);
                        setBizPreview(preview);
                      }
                    }}
                    type="file"
                    className="hidden"
                  />
                  <div className="text-Red100 Body4">
                    사업자 등록증을 등록해 주세요. (PDF 파일만 첨부 가능합니다)
                  </div>
                </div>
              )}
            </>
          ) : !edit && storeData?.businessLicensePath ? (
            <TextButtonPrimary
              onClick={() => {
                window.open(`${storeData?.businessLicensePath}`);
              }}
              className="mt-2"
              size={"lg"}
              text={"첨부파일 보기"}
            ></TextButtonPrimary>
          ) : (
            ""
          )}
        </NewStoreForm>
        <NewStoreForm
          className="flex flex-col items-start gap-2.5"
          required
          title="사업자 소재지"
        >
          <div className="flex items-center gap-2">
            <TextFieldInput
              inputValue={storeData.zip}
              className="w-[358px]"
              placeholder="우편번호"
              disabled
            />
            {edit && (
              <DaumAddressSearch setAddres={setAddress}>
                <ButtonFilledSecondary
                  size={"md"}
                  className="h-[52px] w-[108px]"
                  btnText={"우편번호 검색"}
                />
              </DaumAddressSearch>
            )}
          </div>
          <TextFieldInput
            inputValue={edit ? storeData.addr : data?.address}
            className="w-[476px]"
            disabled
          />
          <TextFieldInput
            onChange={(e) => {
              setStoreData({
                ...storeData,
                detailAddr: e.target.value.replace(
                  /[^A-Za-zㄱ-ㅎ가-힣\s() ,\-/\d]/g,
                  ""
                ),
              });
            }}
            disabled={!edit}
            inputValue={
              edit ? storeData.detailAddr : data?.detailAddress.trim()
            }
            className="w-[476px]"
            placeholder=""
          />
        </NewStoreForm>
        <NewStoreForm itemCenter required title="통신 판매업 등록">
          {edit ? (
            <div className="w-[476px]">
              {mailOrderFile !== null ? (
                <div className="flex items-center gap-2">
                  <TextButtonPrimary
                    onClick={() => {
                      window.open(`${mailPreview}`);
                    }}
                    size={"lg"}
                    text={"첨부파일 보기"}
                  ></TextButtonPrimary>
                  <TextBtnTertiary
                    onClick={() => {
                      setStoreData({
                        ...storeData,
                        mailOrderBusinessPath: "",
                        delMailOrderFile: "Y",
                      });
                      setMailPreview("");
                      setMailOrderFile(null);
                    }}
                    size={"sm"}
                    text={"삭제하기"}
                  ></TextBtnTertiary>
                </div>
              ) : storeData?.mailOrderBusinessPath !== "" ? (
                <div className="flex items-center gap-2">
                  <TextButtonPrimary
                    onClick={() => {
                      window.open(`${storeData?.mailOrderBusinessPath}`);
                    }}
                    size={"lg"}
                    text={"첨부파일 보기"}
                  ></TextButtonPrimary>
                  <TextBtnTertiary
                    onClick={() => {
                      setStoreData({
                        ...storeData,
                        mailOrderBusinessPath: "",
                        delMailOrderFile: "Y",
                      });
                    }}
                    size={"sm"}
                    text={"삭제하기"}
                  ></TextBtnTertiary>
                </div>
              ) : (
                <div>
                  <ButtonFilledTertiary
                    className="!mx-0 mb-2"
                    size={"md"}
                    btnText={"통신판매업 신고증 첨부"}
                    onClick={() => {
                      mailOrderRef.current?.click();
                    }}
                  />
                  <input
                    ref={mailOrderRef}
                    accept=".pdf"
                    onChange={(e) => {
                      if (e.target.files) {
                        setMailOrderFile(e.target.files[0]);
                        const preview = URL.createObjectURL(e.target.files[0]);
                        setMailPreview(preview);
                      }
                    }}
                    type="file"
                    className="hidden"
                  />
                  <div className="text-Red100 Body4">
                    신고증을 등록해 주세요. (PDF 파일만 첨부 가능합니다)
                  </div>
                </div>
              )}
            </div>
          ) : !edit && storeData?.mailOrderBusinessPath ? (
            <TextButtonPrimary
              onClick={() => {
                window.open(`${storeData?.mailOrderBusinessPath}`);
              }}
              size={"lg"}
              text={"첨부파일 보기"}
            ></TextButtonPrimary>
          ) : (
            <div className="text-Red100 Body4">통신판매업 신고증 미등록</div>
          )}
        </NewStoreForm>
        <NewStoreForm required title="대표자">
          <TextFieldInput
            disabled={!edit}
            inputValue={storeData.repName}
            onChange={(e) => {
              setStoreData({
                ...storeData,
                repName: e.target.value.replace(/[^A-Za-zㄱ-ㅎ가-힣\s]/g, ""),
              });
            }}
            className="w-[476px]"
            placeholder=""
          />
        </NewStoreForm>
        <NewStoreForm required title="대표 카테고리">
          {edit ? (
            <Dropdown
              width="w-[476px]"
              innerText={storeData.mainName ? storeData.mainName : "선택"}
            >
              {category?.data.ResultData.map((c) => {
                return (
                  <li
                    value={c.categoryId}
                    key={c.categoryId}
                    onClick={() => {
                      const id = Number(c.categoryId);
                      // setCategoryMain({ categoryId: id, name: c.name });
                      setMainPreview([]);
                      setSub1Preview([]);
                      setSub2Preview([]);
                      setStoreData({
                        ...storeData,
                        mainCategoryBusinessImages: [],
                        subCategory1BusinessImages: [],
                        subCategory2BusinessImages: [],
                        delMainCategoryIds: mainCategoryImageId.join(","),
                        delSubCategory1Ids: sub1CategoryImageId.join(","),
                        delSubCategory2Ids: sub2CategoryImageId.join(","),
                        mainCategory: id,
                        mainName: c.name,
                        sub1Name: "",
                        subCategory1: 0,
                        sub2Name: "",
                        subCategory2: 0,
                      });
                    }}
                    className="w-[476px] py-2.5 px-3 hover:bg-Blue-50"
                  >
                    <div className="max-w-[476px] w-full Body4 text-Gray-80">
                      {c.name}
                    </div>
                  </li>
                );
              })}
            </Dropdown>
          ) : (
            <TextFieldInput
              disabled
              inputValue={storeData.mainName}
              className="w-[476px]"
              placeholder=""
            />
          )}
          <div className="my-2 SubH2 text-Red100">
            다음에 해당하는 경우 해당 서류를 첨부해 주세요. (PDF 파일만 첨부
            가능합니다)
          </div>
          <div className="bg-Blue-50 w-[476px] rounded-lg">
            {categoryFileInfo?.map((item) => {
              if (item.name === storeData.mainName) {
                return (
                  <div
                    key={item.id}
                    className="flex flex-col items-start gap-6 px-4 py-5"
                  >
                    {item.docs?.map((subItem, i) => {
                      return (
                        <div key={i} className="SubH2">
                          <div>{subItem.desc}</div>
                          <div className="text-BlueTint-100">
                            {subItem.fileName}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              }
            })}
          </div>
          {edit ? (
            <>
              {storeData?.mainCategoryBusinessImages.length !== 0 && (
                <div className="mt-2 mb-1 Body5 text-Gray-90">
                  기존 업로드 된 파일
                </div>
              )}
              <div className="flex flex-col gap-2 ml-3">
                {storeData?.mainCategoryBusinessImages.map((item, i) => {
                  return (
                    <div key={i} className="flex items-center gap-2">
                      <div className="w-[83px]">
                        <TextButtonPrimary
                          onClick={() => {
                            window.open(`${item.imagePath}`);
                          }}
                          size={"lg"}
                          text={`첨부파일${i + 1} 보기`}
                        />
                      </div>
                      <TextBtnTertiary
                        onClick={() => {
                          setDelMainFileId((delMainFileId) => [...delMainFileId, item.id]);
                          const updateFile =
                            storeData.mainCategoryBusinessImages.filter(
                              (file) => file.id !== item.id
                            );
                          setStoreData((storeData) => ({
                            ...storeData,
                            mainCategoryBusinessImages: updateFile
                          }));
                        }}
                        size={"sm"}
                        text={"삭제하기"}
                      ></TextBtnTertiary>
                    </div>
                  );
                })}
              </div>
              {mainPreview.length !== 0 && (
                <div className="mt-2 mb-1 Body5 text-Gray-90">추가 업로드</div>
              )}
              <div className="flex flex-col gap-2 ml-3">
                {mainPreview?.map((item, i) => {
                  return (
                    <div key={i} className="flex items-center gap-2">
                      <div className="w-[83px]">
                        <TextButtonPrimary
                          onClick={() => {
                            window.open(`${item}`);
                          }}
                          size={"lg"}
                          text={`첨부파일${i + 1} 보기`}
                        />
                      </div>
                      <TextBtnTertiary
                        onClick={() => {
                          const newImages = [...mainCategoryFiles];
                          const newPreviews = [...mainPreview];
                          newImages.splice(i, 1);
                          newPreviews.splice(i, 1);
                          setMainCategoryFiles(newImages);
                          setMainPreview(newPreviews);
                        }}
                        size={"sm"}
                        text={"삭제하기"}
                      ></TextBtnTertiary>
                    </div>
                  );
                })}
              </div>
              {storeData?.mainCategoryBusinessImages.length +
                mainCategoryFiles.length <
                5 && (
                <>
                  <ButtonLinedTertiary
                    onClick={() => {
                      mainRef.current?.click();
                    }}
                    className="mt-4"
                    size={"md"}
                    btnText={"서류 첨부"}
                  />
                  <input
                    ref={mainRef}
                    multiple
                    onChange={(e) => {
                      mainCategoryFileList(e);
                    }}
                    type="file"
                    accept=".pdf"
                    className="hidden"
                  ></input>
                </>
              )}
            </>
          ) : storeData?.mainCategoryBusinessImages.length !== 0 ? (
            <div className="flex flex-col gap-2 my-2">
              {storeData?.mainCategoryBusinessImages.map((item, i) => {
                return (
                  <div key={item.id} className="flex items-center gap-2">
                    <TextButtonPrimary
                      onClick={() => {
                        window.open(`${item.imagePath}`);
                      }}
                      size={"lg"}
                      text={`첨부파일${i + 1} 보기`}
                    ></TextButtonPrimary>
                  </div>
                );
              })}
            </div>
          ) : (
            ""
          )}
        </NewStoreForm>
        <NewStoreForm title="추가 카테고리 1">
          {!edit ? (
            <TextFieldInput
              disabled
              inputValue={storeData.sub1Name}
              className="w-[476px]"
              placeholder=""
            />
          ) : (
            <Dropdown
              disabled={!storeData.mainCategory}
              width="w-[476px]"
              innerText={storeData.sub1Name ? storeData.sub1Name : "선택"}
            >
              {newCate?.map((c) => {
                if (c.name !== storeData.mainName) {
                  return (
                    <li
                      value={c.categoryId}
                      key={c.categoryId}
                      onClick={() => {
                        const id = Number(c.categoryId);
                        // setCategorySub1({ categoryId: id, name: c.name });
                        setSub1Preview([]);
                        setSub2Preview([]);
                        setStoreData({
                          ...storeData,
                          subCategory1BusinessImages: [],
                          subCategory2BusinessImages: [],
                          delSubCategory1Ids: sub1CategoryImageId.join(","),
                          delSubCategory2Ids: sub2CategoryImageId.join(","),
                          subCategory1: id,
                          sub1Name: c.name,
                          sub2Name: "",
                          subCategory2: 0,
                        });
                      }}
                      className="w-[476px] py-2.5 px-3 hover:bg-Blue-50"
                    >
                      <div className="max-w-[476px] w-full Body4 text-Gray-80">
                        {c.name}
                      </div>
                    </li>
                  );
                }
              })}
            </Dropdown>
          )}
          {storeData.sub1Name && (
            <div className="my-2 SubH2 text-Red100">
              다음에 해당하는 경우 해당 서류를 첨부해 주세요. (PDF 파일만 첨부
              가능합니다)
            </div>
          )}
          <div className="bg-Blue-50 w-[476px] rounded-lg">
            {categoryFileInfo?.map((item, idx) => {
              if (item.name === storeData.sub1Name) {
                return (
                  <div
                    key={item.id}
                    className="flex flex-col items-start gap-6 px-4 py-5"
                  >
                    {item.docs?.map((subItem, i) => {
                      return (
                        <div key={i} className="SubH2">
                          <div>{subItem?.desc}</div>
                          <div className="text-BlueTint-100">
                            {subItem?.fileName}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              }
            })}
          </div>
          <div className="flex flex-col gap-2 my-2">
            {sub1Preview.map((item, i) => {
              return (
                <div key={item} className="flex items-center gap-2">
                  <TextButtonPrimary
                    onClick={() => {
                      window.open(`${item}`);
                    }}
                    size={"lg"}
                    text={`첨부파일${i + 1} 보기`}
                  ></TextButtonPrimary>
                  <TextBtnTertiary
                    onClick={() => {
                      const newImages = [...subCategory1Files];
                      const newPreviews = [...sub1Preview];
                      newImages.splice(i, 1);
                      newPreviews.splice(i, 1);
                      setSubCategory1Files(newImages);
                      setSub1Preview(newPreviews);
                    }}
                    size={"sm"}
                    text={"삭제하기"}
                  ></TextBtnTertiary>
                </div>
              );
            })}
          </div>
          {edit && storeData.sub1Name && sub1Preview.length < 5 ? (
            <>
              <ButtonLinedTertiary
                onClick={() => {
                  sub1Ref.current?.click();
                }}
                className="mt-4"
                size={"md"}
                btnText={"서류 첨부"}
              />
              <input
                ref={sub1Ref}
                multiple
                onChange={(e) => {
                  sub1CategoryFileList(e);
                }}
                type="file"
                accept=".pdf"
                className="hidden"
              ></input>
            </>
          ) : storeData?.subCategory1BusinessImages.length !== 0 ? (
            <div className="flex flex-col gap-2">
              {storeData?.subCategory1BusinessImages.map((item, i) => {
                return (
                  <div key={item.id} className="flex items-center gap-2">
                    <TextButtonPrimary
                      onClick={() => {
                        window.open(`${item.imagePath}`);
                      }}
                      size={"lg"}
                      text={`첨부파일${i + 1} 보기`}
                    ></TextButtonPrimary>
                  </div>
                );
              })}
            </div>
          ) : (
            ""
          )}
        </NewStoreForm>
        <NewStoreForm title="추가 카테고리 2">
          {!edit ? (
            <TextFieldInput
              disabled
              inputValue={storeData.sub2Name}
              className="w-[476px]"
              placeholder=""
            />
          ) : (
            <Dropdown
              width="w-[476px]"
              disabled={!storeData.subCategory1}
              innerText={storeData.sub2Name ? storeData.sub2Name : "선택"}
            >
              {newCate.map((c) => {
                if (
                  c.name !== storeData.mainName &&
                  c.name !== storeData?.sub1Name
                ) {
                  return (
                    <li
                      value={c.categoryId}
                      key={c.categoryId}
                      onClick={() => {
                        const id = Number(c.categoryId);
                        // setCategorySub2({ categoryId: id, name: c.name });
                        setStoreData({
                          ...storeData,
                          subCategory2: id,
                          sub2Name: c.name,
                        });
                      }}
                      className="w-[476px] py-2.5 px-3 hover:bg-Blue-50"
                    >
                      <div className="max-w-[476px] w-full Body4 text-Gray-80">
                        {c.name}
                      </div>
                    </li>
                  );
                }
              })}
            </Dropdown>
          )}
          {storeData.sub2Name && (
            <div className="my-2 SubH2 text-Red100">
              다음에 해당하는 경우 해당 서류를 첨부해 주세요. (PDF 파일만 첨부
              가능합니다)
            </div>
          )}
          <div className="bg-Blue-50 w-[476px] rounded-lg">
            {categoryFileInfo.map((item, idx) => {
              if (item.name === storeData.sub2Name) {
                return (
                  <div
                    key={item.id}
                    className="flex flex-col items-start gap-6 px-4 py-5"
                  >
                    {item.docs?.map((subItem, i) => {
                      return (
                        <div key={i} className="SubH2">
                          <div>{subItem.desc}</div>
                          <div className="text-BlueTint-100">
                            {subItem.fileName}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              }
            })}
          </div>
          <div className="flex flex-col gap-2 my-2">
            {sub2Preview.map((item, i) => {
              return (
                <div key={item} className="flex items-center gap-2">
                  <TextButtonPrimary
                    onClick={() => {
                      window.open(`${item}`);
                    }}
                    size={"lg"}
                    text={`첨부파일${i + 1} 보기`}
                  ></TextButtonPrimary>
                  <TextBtnTertiary
                    onClick={() => {
                      const newImages = [...subCategory2Files];
                      const newPreviews = [...sub2Preview];
                      newImages.splice(i, 1);
                      newPreviews.splice(i, 1);
                      setSubCategory2Files(newImages);
                      setSub2Preview(newPreviews);
                    }}
                    size={"sm"}
                    text={"삭제하기"}
                  ></TextBtnTertiary>
                </div>
              );
            })}
          </div>
          {edit && storeData.sub2Name && sub2Preview.length < 5 ? (
            <>
              <ButtonLinedTertiary
                onClick={() => {
                  sub2Ref.current?.click();
                }}
                className="mt-4"
                size={"md"}
                btnText={"서류 첨부"}
              />
              <input
                ref={sub2Ref}
                multiple
                onChange={(e) => {
                  sub2CategoryFileList(e);
                }}
                type="file"
                accept=".pdf"
                className="hidden"
              ></input>
            </>
          ) : storeData?.subCategory2BusinessImages.length !== 0 ? (
            <div className="flex flex-col gap-2">
              {storeData?.subCategory2BusinessImages.map((item, i) => {
                return (
                  <div key={item.id} className="flex items-center gap-2">
                    <TextButtonPrimary
                      onClick={() => {
                        window.open(`${item.imagePath}`);
                      }}
                      size={"lg"}
                      text={`첨부파일${i + 1} 보기`}
                    ></TextButtonPrimary>
                  </div>
                );
              })}
            </div>
          ) : (
            ""
          )}
        </NewStoreForm>
        <NewStoreForm title="브랜드명">
          <TextFieldInput
            onChange={(e) => {
              setStoreData({
                ...storeData,
                brandName: e.target.value.replace(
                  /[^A-Za-zㄱ-ㅎ가-힣\s()]/g,
                  ""
                ),
              });
            }}
            disabled={!edit}
            inputValue={storeData.brandName}
            className="w-[476px]"
            placeholder="예시) 레저에빠지다"
          />
        </NewStoreForm>
        <NewStoreForm itemCenter title="공식 홈페이지 URL">
          {edit ? (
            <TextFieldInput
              onChange={(e) => {
                setStoreData({
                  ...storeData,
                  mainUrl: e.target.value.replace(
                    /[^A-Za-zㄱ-ㅎ가-힣0-9/:.\?\\+=&%_ -]/g,
                    ""
                  ),
                });
              }}
              inputValue={storeData.mainUrl}
              className="w-[476px]"
              placeholder=""
            />
          ) : (
            <div
              className={`${
                storeData.mainUrl && "underline text-Blue-100 cursor-pointer"
              } Body3`}
              onClick={() => {
                if (storeData.mainUrl.startsWith("https://")) {
                  window.open(`${storeData.mainUrl}`);
                } else if (storeData.mainUrl.startsWith("http://")) {
                  window.open(`${storeData.mainUrl}`);
                } else {
                  window.open(`https://${storeData.mainUrl}`);
                }
              }}
            >
              {storeData.mainUrl ? storeData.mainUrl : "-"}
            </div>
          )}
        </NewStoreForm>
        <NewStoreForm
          itemCenter
          title="대표 판매채널 URL"
          subTitle="(자사몰 제외)"
        >
          {edit ? (
            <TextFieldInput
              onChange={(e) => {
                setStoreData({
                  ...storeData,
                  saleUrl: e.target.value.replace(
                    /[^A-Za-zㄱ-ㅎ가-힣0-9/:.\?\\+=&%_ -]/g,
                    ""
                  ),
                });
              }}
              inputValue={storeData.saleUrl}
              className="w-[476px]"
              placeholder=""
            />
          ) : (
            <div
              className={`${
                storeData.saleUrl && "underline text-Blue-100 cursor-pointer"
              } Body3`}
              onClick={() => {
                if (storeData.saleUrl.startsWith("https://")) {
                  window.open(`${storeData.saleUrl}`);
                } else if (storeData.saleUrl.startsWith("http://")) {
                  window.open(`${storeData.saleUrl}`);
                } else {
                  window.open(`https://${storeData.saleUrl}`);
                }
              }}
            >
              {storeData.saleUrl ? storeData.saleUrl : "- "}
            </div>
          )}
        </NewStoreForm>
      </NewStoreWrap>
      <NewStoreWrap title="계정 정보">
        <NewStoreForm itemCenter title="판매자 로그인 ID">
          <div className="Body3 text-Gray-90">{data?.id}</div>
        </NewStoreForm>
      </NewStoreWrap>
      <NewStoreWrap title="담당자 정보">
        <NewStoreForm itemCenter title="이름">
          <div className="Body3 text-Gray-90">{data?.username}</div>
        </NewStoreForm>
        <NewStoreForm itemCenter title="이메일">
          <div className="Body3 text-Gray-90">{data?.email}</div>
        </NewStoreForm>
        <NewStoreForm itemCenter title="연락처">
          <div className="Body3 text-Gray-90">
            {data?.mobileNo.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3")}
          </div>
        </NewStoreForm>
      </NewStoreWrap>
      <NewStoreWrap title="정산 정보">
        <NewStoreForm title="정산 계좌">
          <div className="flex items-center gap-2">
            <TextFieldInput
              inputValue={storeData?.accountName}
              onChange={(e) => {
                setStoreData({
                  ...storeData,
                  accountName: e.target.value.replace(
                    /[^A-Za-zㄱ-ㅎ가-힣-]/g,
                    ""
                  ),
                });
              }}
              disabled={!edit}
              className="w-[177px]"
              placeholder="은행명 입력"
            />
            <TextFieldInput
              inputValue={storeData?.accountNo}
              onChange={(e) => {
                setStoreData({
                  ...storeData,
                  accountNo: e.target.value.replace(/[^0-9-]/g, ""),
                });
              }}
              disabled={!edit}
              className="w-[258px]"
              placeholder="계좌번호 입력 ('_'없이 숫자만 입력)"
            />
          </div>
          {edit ? (
            <div>
              {passbookFile !== null ? (
                <div className="flex items-center gap-2">
                  <TextButtonPrimary
                    onClick={() => {
                      window.open(`${passPreview}`);
                    }}
                    className="mt-2"
                    size={"lg"}
                    text={"첨부파일 보기"}
                  ></TextButtonPrimary>
                  <TextBtnTertiary
                    onClick={() => {
                      setStoreData({
                        ...storeData,
                        passbookPath: "",
                        delPassbookFile: "Y",
                      });
                      setPassbookFile(null);
                      setPassPreview("");
                    }}
                    className="mt-2"
                    size={"sm"}
                    text={"삭제하기"}
                  ></TextBtnTertiary>
                </div>
              ) : storeData?.passbookPath !== "" ? (
                <div className="flex items-center gap-2">
                  <TextButtonPrimary
                    onClick={() => {
                      window.open(`${storeData?.passbookPath}`);
                    }}
                    className="mt-2"
                    size={"lg"}
                    text={"첨부파일 보기"}
                  ></TextButtonPrimary>
                  <TextBtnTertiary
                    onClick={() => {
                      setStoreData({
                        ...storeData,
                        passbookPath: "",
                        delPassbookFile: "Y",
                      });
                    }}
                    className="mt-2"
                    size={"sm"}
                    text={"삭제하기"}
                  ></TextBtnTertiary>
                </div>
              ) : (
                <div>
                  <ButtonFilledTertiary
                    className="my-2"
                    size={"md"}
                    btnText={"통장사본 첨부"}
                    onClick={() => {
                      passbookRef.current?.click();
                    }}
                  />
                  <input
                    ref={passbookRef}
                    onChange={(e) => {
                      if (e.target.files) {
                        setPassbookFile(e.target.files[0]);
                        const preview = URL.createObjectURL(e.target.files[0]);
                        setPassPreview(preview);
                      }
                    }}
                    accept=".pdf"
                    type="file"
                    className="hidden"
                  />
                  <div className="text-Red100 Body4">
                    통장사본을 등록해 주세요. (PDF 파일만 첨부 가능합니다)
                  </div>
                </div>
              )}
            </div>
          ) : !edit && storeData?.passbookPath ? (
            <TextButtonPrimary
              onClick={() => {
                window.open(`${storeData?.passbookPath}`);
              }}
              className="mt-2"
              size={"lg"}
              text={"첨부파일 보기"}
            ></TextButtonPrimary>
          ) : (
            <div className="mt-2 Body4 text-red">통장사본 미등록</div>
          )}
        </NewStoreForm>
        <NewStoreForm
          itemCenter
          title="정산 방법"
          className="flex items-center Body4"
        >
          <div className="text-Gray-90">
            월 정산 <span className="text-Gray-60">매월 5일</span>
          </div>
          <div className="ml-2 text-Gray-60">(휴일인 경우 전일 지급)</div>
        </NewStoreForm>
        <NewStoreForm
          itemCenter
          title="정산 기준 시점"
          className="flex items-center"
        >
          <div className="Body4 text-Gray-90">구매확정 시</div>
        </NewStoreForm>
      </NewStoreWrap>
      <NewStoreWrap title="계약 정보">
        <NewStoreForm
          itemCenter
          title="입점 신청일"
          className="flex items-center"
        >
          <div className="Body4 text-Gray-90">{store?.regDt}</div>
        </NewStoreForm>
        <NewStoreForm title="계약서" itemCenter>
          {edit && authority === "ROLE_COMMERCE_ADMIN" ? (
            <div className="Body4 text-Gray-90">
              <ButtonFilledTertiary
                className="w-full"
                size={"md"}
                btnText={"전자계약서 첨부"}
              />
              <div className="mt-2 Body4 text-red">계약서를 등록해 주세요</div>
            </div>
          ) : (
            <div className=" Body4 text-red">전자계약 계약서 미등록</div>
          )}
        </NewStoreForm>
        <NewStoreForm title="수수료" itemCenter className="flex items-center">
          <div className="SubH5 text-Gray-90">5%</div>
          {edit && authority === "ROLE_COMMERCE_ADMIN" && (
            <ButtonLinedTertiary
              className="w-full ml-4"
              size={"sm"}
              btnText={"수수료 변경"}
            />
          )}
        </NewStoreForm>
      </NewStoreWrap>
      <div className="fixed bottom-0 left-0 bg-Gray-10/70 backdrop-blur-[12px] z-[102] w-full py-5 border-t border-Gray-40">
        <div className="flex items-center pl-[260px] pr-[160px] justify-between max-w-[1920px] mx-auto min-w-[1440px]">
          {edit && authority === "ROLE_COMMERCE_ADMIN" ? (
            <div className="flex items-center">
              <div className="Body2">
                상점 관리 {">"} 상점 설정 {">"} 기본정보
              </div>
              <ButtonLinedTertiary
                onClick={() => {
                  navigate("/store/entry");
                }}
                className="ml-2"
                size={"sm"}
                btnText={"목록으로"}
              />
            </div>
          ) : (
            <div className="Body2">
              상점 관리 {">"} 상점 설정 {">"} 기본정보
            </div>
          )}
          {status === "EXAMINATION" && authority === "ROLE_COMMERCE_ADMIN" ? (
            <div className="flex items-center gap-4">
              <ButtonLinedTertiary
                className="!text-Red100"
                onClick={() => {
                  setCancelModal(true);
                }}
                size={"lg"}
                btnText={"신청거부"}
              />
              <ButtonFilledPrimary
                size={"lg"}
                onClick={() => {
                  setPassModal(true);
                }}
                btnText={"입점 승인"}
              />
            </div>
          ) : status === "REJECTED" && authority === "ROLE_COMMERCE_ADMIN" ? (
            <ButtonLinedTertiary
              className="!mx-0"
              onClick={() => {}}
              size={"lg"}
              btnText={"요청서 삭제"}
            />
          ) : edit && status !== "EXAMINAITON" ? (
            <div className="flex items-center gap-4">
              <ButtonLinedTertiary
                onClick={() => {
                  setBusinessLicenseFile(null);
                  setPassbookFile(null);
                  setMailOrderFile(null);
                  refetch();
                  setMainCategoryFiles([]);
                  setSubCategory1Files([]);
                  setSubCategory2Files([]);
                  setMainPreview([]);
                  setSub1Preview([]);
                  setSub2Preview([]);
                  setDelMainFileId([]);
                  setDelSub1FileId([]);
                  setDelSub2FileId([]);
                  setEdit(false);
                }}
                size={"lg"}
                btnText={"취소"}
              />
              <ButtonFilledPrimary
                onClick={() => {
                  handleStoreInfo();
                }}
                size={"lg"}
                btnText={"수정 완료"}
              />
            </div>
          ) : (
            <div className="flex items-center">
              <ButtonLinedTertiary
                onClick={() => setEdit(true)}
                size={"lg"}
                btnText={"기본정보 수정"}
              />
              {status === "WAITING" && authority === "ROLE_COMMERCE_ADMIN" && (
                <ButtonFilledPrimary
                  onClick={() => {
                    setEntryModal(true);
                    setModalValue("CONFIRM");
                  }}
                  className="ml-4"
                  size={"lg"}
                  btnText={"입점 최종승인"}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default StoreInfo;
