import React, { Dispatch } from "react";

import { useDaumPostcodePopup } from "react-daum-postcode";
import { daumAddress } from "type/daumAddress";

interface Props {
  children: React.ReactNode;
  setAddres: Dispatch<daumAddress>;
}

const DaumAddressSearch = ({ children, setAddres }: Props) => {
  const CURRENT_URL =
    "https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js";
  const open = useDaumPostcodePopup(CURRENT_URL);
  const handleComplete = (data: any) => {
    setAddres(data)
  };
  const handleClick = () => {
    open({ onComplete: handleComplete });
  };
  return (
    <div onClick={handleClick}>
      {children}
    </div>
  );
};

export default DaumAddressSearch;
