import { AxiosResponse } from "axios";
import { UseQueryResult, useQuery } from "react-query";
import {
  GeneralOrderListProps,
  GeneralOrderList,
  OrderDetail,
  ExchangeParamsType,
  ExchangeList,
  ReturnParamsType,
  ReturnList,
  GiftOrderListPropsForAdmin,
  GiftOrderList,
  GiftOrderListParams,
  ReturnData,
  ExchangeData,
  CancelList,
} from "type/order";
import requestToLoveEgg from "utils/requestToLoveEgg";

// 주문 목록 조회
const getGeneralOrderList = ({
  status,
  keyword,
  dateType,
  startDate,
  endDate,
  page,
  size,
}: GeneralOrderListProps) => {
  return requestToLoveEgg({
    method: "GET",
    url: "/api/v1/orders",
    params: {
      status: status,
      search: keyword,
      dateType: dateType,
      startDate: startDate,
      endDate: endDate,
      page: page,
      size: size,
    },
  });
};

export const useGeneralOrderList = (
  params: GeneralOrderListProps
): UseQueryResult<AxiosResponse<GeneralOrderList>, Error> =>
  useQuery(["useGeneralOrderList", params], () => getGeneralOrderList(params), {
    // retry: 3,
    keepPreviousData: true,
  });

// 주문 상세 조회
const getOrderDetail = (orderId: string) => {
  return requestToLoveEgg({
    method: "GET",
    url: `/api/v1/orders/${orderId}`,
  });
};

export const useOrderDetail = (
  orderId: string
): UseQueryResult<AxiosResponse<OrderDetail>, Error> =>
  useQuery(["useOrderDetail"], () => getOrderDetail(orderId), {
    // retry: 3,
    keepPreviousData: true,
  });

// 교환 관리 목록 조회
const getExchangeList = ({
  status,
  search,
  dateType,
  startDate,
  endDate,
  page,
  size,
}: ExchangeParamsType) => {
  return requestToLoveEgg({
    method: "GET",
    url: `/api/v1/exchanges`,
    params: {
      status: status,
      search: search,
      dateType: dateType,
      startDate: startDate,
      endDate: endDate,
      page: page,
      size: size,
    },
  });
};

export const useExchangeList = (
  params: ExchangeParamsType
): UseQueryResult<AxiosResponse<ExchangeList>, Error> =>
  useQuery(["useExchangeList", params], () => getExchangeList(params), {
    // retry: 3,
    keepPreviousData: true,
  });

// 반품 관리 목록 조회
const getReturnList = ({
  status,
  search,
  dateType,
  startDate,
  endDate,
  page,
  size,
}: ReturnParamsType) => {
  return requestToLoveEgg({
    method: "GET",
    url: `/api/v1/returns`,
    params: {
      status: status,
      search: search,
      dateType: dateType,
      startDate: startDate,
      endDate: endDate,
      page: page,
      size: size,
    },
  });
};

export const useReturnList = (
  params: ReturnParamsType
): UseQueryResult<AxiosResponse<ReturnList>, Error> =>
  useQuery(["useReturnList", params], () => getReturnList(params), {
    // retry: 3,
    keepPreviousData: true,
  });

// 선물 대기 목록 조회
const getGiftOrderList = ({
  keyword,
  dateType,
  startDate,
  endDate,
  status,
  page,
  size,
}: GiftOrderListParams) => {
  return requestToLoveEgg({
    method: "GET",
    url: "/api/v1/gifts",
    params: {
      status: status,
      search: keyword,
      dateType: dateType,
      startDate: startDate,
      endDate: endDate,
      page: page,
      size: size,
    },
  });
};

export const useGiftOrderList = (
  params?: GiftOrderListParams
): UseQueryResult<AxiosResponse<GiftOrderList>, Error> =>
  useQuery(
    ["useGiftOrderList", params],
    () => getGiftOrderList(params as GiftOrderListParams),
    {
      // retry: 3,
      keepPreviousData: true,
    }
  );

// 주문 처리 내역 조회
const getOrderProcessHistory = ({
  orderNo,
  orderItemId,
}: {
  orderNo: string;
  orderItemId: string;
}) => {
  return requestToLoveEgg({
    method: "GET",
    url: `/api/v1/orders/${orderNo}/processing/${orderItemId}`,
  });
};

export const useOrderProcessHistory = (params: {
  orderNo: string;
  orderItemId: string;
}): UseQueryResult<AxiosResponse, Error> =>
  useQuery(
    ["useOrderProcessHistoryForAdmin", params],
    () => getOrderProcessHistory(params),
    {
      // retry: 3,
      keepPreviousData: true,
    }
  );

// 교환 관리 목록 조회
const getReturnDetail = (claimNo: string) => {
  return requestToLoveEgg({
    method: "GET",
    url: `/api/v1/returns/${claimNo}`,
  });
};

export const useReturnDetail = (
  claimNo: string
): UseQueryResult<AxiosResponse<ReturnData>, Error> =>
  useQuery(["useReturnListForAdmin"], () => getReturnDetail(claimNo), {
    // retry: 3,
    keepPreviousData: true,
  });

// 교환 관리 상세 조회
const getExchangeDetail = (claimNo: string) => {
  return requestToLoveEgg({
    method: "GET",
    url: `/api/v1/exchanges/${claimNo}`,
  });
};

export const useExchangeDetail = (
  claimNo: string
): UseQueryResult<AxiosResponse<ExchangeData>, Error> =>
  useQuery(["useExchangeDetail"], () => getExchangeDetail(claimNo), {
    // retry: 3,
    keepPreviousData: true,
  });

// 주문 취소 내역 조회
const getCancelList = ({
  companyId,
  search,
  startDate,
  endDate,
  page,
  size,
}: {
  companyId?: string;
  search?: string;
  startDate?: string;
  endDate?: string;
  page?: number;
  size?: number;
}) => {
  return requestToLoveEgg({
    method: "GET",
    url: `/api/v1/orders/cancel`,
    params: {
      companyId: companyId,
      search: search,
      startDate: startDate,
      endDate: endDate,
      page: page,
      size: size,
    },
  });
};

export const useCancelList = (params: {
  search?: string;
  startDate?: string;
  endDate?: string;
  page?: number;
  size?: number;
}): UseQueryResult<AxiosResponse<CancelList>, Error> =>
  useQuery(["useCancelList", params], () => getCancelList(params), {
    // retry: 3,
    keepPreviousData: true,
  });
