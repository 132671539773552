import React from "react";

import { ReactComponent as Dashb } from "assets/dashBoard.svg";
import { ReactComponent as Review } from "assets/review.svg";
import { ReactComponent as Prod } from "assets/prod.svg";
import { ReactComponent as Card } from "assets/card.svg";
import { ReactComponent as Ask } from "assets/ask.svg";
import { ReactComponent as Chart } from "assets/chart.svg";
import { ReactComponent as Store } from "assets/store.svg";
import { ReactComponent as StoreBoard } from "assets/storeBoard.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const Dashboard = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const userInfo = JSON.parse(localStorage.getItem("userInfo") as string);
  const authority = userInfo?.authority;
  const status = userInfo?.status;

  // ROLE_COMMERCE_ADMIN
  // ROLE_USER

  const USER = "ROLE_USER"
    ? [
        {
          id: 0,
          icon: <Dashb />,
          title: "대시보드",
        },
        {
          id: 1,
          icon: <Prod />,
          title: "상품 관리",
          subNav: [
            { subTitle: "전체 상품", nav: "/product/all" },
            { subTitle: "상품 등록", nav: "/product/add" },
          ],
        },
        {
          id: 2,
          icon: <Card />,
          title: "주문 관리",
          subNav: [
            { subTitle: "일반 주문", nav: "/order" },
            { subTitle: "선물 대기 목록", nav: "/order/gift" },
            { subTitle: "교환 관리", nav: "/order/exchange" },
            { subTitle: "반품 관리", nav: "/order/return" },
            { subTitle: "취소 관리", nav: "/order/cancel" },
          ],
        },
        {
          id: 3,
          icon: <Chart />,
          title: "매출/정산 관리",
          subNav: [
            { subTitle: "매출현황", nav: "/" },
            { subTitle: "정산현황", nav: "/" },
            { subTitle: "결제내역 조회", nav: "/" },
            { subTitle: "정산 관리", nav: "/" },
          ],
        },
        {
          id: 4,
          icon: <Review />,
          title: "리뷰 관리",
          subNav: [
            { subTitle: "리뷰 관리", nav: "/" },
            { subTitle: "고객 문의", nav: "/" },
          ],
        },
        {
          id: 5,
          icon: <Store />,
          title: "상점 관리",
          subNav: [
            { subTitle: "상점 설정", nav: "/store/setting" },
            { subTitle: "계정 설정", nav: "/account/setting" },
          ],
        },
        {
          id: 6,
          icon: <Ask />,
          title: "관리자 문의",
          subNav: [
            { subTitle: "마켓 공지사항", nav: "/" },
            { subTitle: "관리자 문의", nav: "/" },
          ],
        },
      ]
    : "";

  const ADMIN = [
    {
      id: 0,
      icon: <Dashb />,
      title: "대시보드",
    },
    {
      id: 1,
      icon: <Store />,
      title: "상점관리",
      subNav: [
        { subTitle: "전체 상점", nav: "/store/all" },
        { subTitle: "입점 신청", nav: "/store/entry" },
        { subTitle: "정보 변경 이력", nav: "/store/history" },
      ],
    },
    {
      id: 2,
      icon: <Prod />,
      title: "상품 관리",
      subNav: [
        { subTitle: "전체 상품", nav: "/admin/product/all" },
        { subTitle: "상품 등록", nav: "/" },
        { subTitle: "상품 일괄등록(TBD)", nav: "/" },
      ],
    },
    {
      id: 3,
      icon: <Card />,
      title: "주문 관리",
      subNav: [
        { subTitle: "일반 주문", nav: "/admin/order" },
        { subTitle: "선물 대기 목록", nav: "/admin/order/gift" },
        { subTitle: "교환 관리", nav: "/admin/order/exchange" },
        { subTitle: "반품 관리", nav: "/admin/order/return" },
        { subTitle: "취소 관리", nav: "/admin/order/cancel" },
      ],
    },
    {
      id: 4,
      icon: <Chart />,
      title: "매출/정산 관리",
      subNav: [
        { subTitle: "전체 매출현황", nav: "/" },
        { subTitle: "결제내역 조회", nav: "/" },
        { subTitle: "상점별 정산현황", nav: "/" },
        { subTitle: "정산 내역", nav: "/" },
      ],
    },
    {
      id: 5,
      icon: <StoreBoard />,
      title: "커머스 관리",
      subNav: [
        { subTitle: "공지사항", nav: "/" },
        { subTitle: "카테고리 편집", nav: "/" },
        { subTitle: "광고관리", nav: "/" },
      ],
    },
  ];

  const dashboard = authority === "ROLE_COMMERCE_ADMIN" ? ADMIN : USER;
  return (
    <div className="min-w-[200px] w-[220px] flex flex-col gap-2.5 h-full py-10 px-4">
      {dashboard &&
        dashboard?.map(
          (
            item: {
              subNav?: { subTitle: string; nav: string }[];
              title: string;
              icon: JSX.Element;
              id: number;
            },
            i
          ) => {
            if (
              authority === "ROLE_USER" &&
              status === "WAITING" &&
              item.id < 5
            ) {
              <div key={item.id} className="group">
                <div className="flex items-center gap-2 group-hover:bg-Gray-20 px-5 py-2.5">
                  <div>{React.cloneElement(item.icon)}</div>
                  <div className="text-Gray-90 SubH1">{item.title}</div>
                </div>
                {item?.subNav?.map((subItem, idx) => {
                  return (
                    <div
                      onClick={() => {
                        if(subItem.nav === "/") {
                          toast.info("아직 준비중인 기능입니다")
                        } else {
                          navigate(`${subItem.nav}`);
                        }
                      }}
                      key={idx}
                      className={`py-2 pl-[52px] SubH2 cursor-pointer ${
                        pathname === subItem.nav
                          ? "text-Blue-100"
                          : "hover:text-Gray-60 text-Gray-80"
                      }`}
                    >
                      {subItem.subTitle}
                    </div>
                  );
                })}
              </div>;
            } else {
              return (
                <div key={i} className="group">
                  <div className="flex items-center gap-2 group-hover:bg-Gray-20 px-5 py-2.5">
                    <div>{React.cloneElement(item.icon)}</div>
                    <div className="text-Gray-90 SubH1">{item.title}</div>
                  </div>
                  {item?.subNav?.map((subItem, idx) => {
                    return (
                      <div
                        onClick={() => {
                          if(subItem.nav === "/") {
                            toast.info("아직 준비중인 기능입니다")
                          } else { 
                            navigate(`${subItem.nav}`);
                          }
                        }}
                        key={idx}
                        className={`py-2 pl-[52px] SubH2 cursor-pointer ${
                          pathname === subItem.nav
                            ? "text-Blue-100"
                            : "hover:text-Gray-60 text-Gray-80"
                        }`}
                      >
                        {subItem.subTitle}
                      </div>
                    );
                  })}
                </div>
              );
            }
          }
        )}
    </div>
  );
};

export default Dashboard;
