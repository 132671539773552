import React from "react";
import { Outlet, Navigate } from "react-router-dom";

const UserRouter = () => {

    const userInfo = JSON.parse(localStorage.getItem("userInfo") as string);
    const auth = userInfo?.authority;
    const status = userInfo?.status;

    return auth === "ROLE_USER" ? <Outlet />
        : <Navigate to={auth === "ROLE_USER" && status === "EXAMINATION" ? "/store/check"
            : auth === "ROLE_USER" && status === "WAITING" ? "/store/setting"
                        : auth === "ROLE_COMMERCE_ADMIN" && status === "" ? "/store/all" : "/"} />;

};

export default UserRouter;