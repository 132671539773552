export const ALLSTORE = [
  {
    Header: "카테고리",
  },
  {
    Header: "상점ID",
  },
  {
    Header: "상점명",
  },
  {
    Header: "상태",
  },
  {
    Header: "상점 주소",
  },
  {
    Header: "담당자 이름",
  },
  {
    Header: "담당자 이메일",
  },
  {
    Header: "입점일",
  },
  {
    Header: "",
  },
];

export const ENTRYSTORE = [
  {
    Header: "카테고리",
  },
  {
    Header: "상점명",
  },
  {
    Header: "상태",
  },
  {
    Header: "상점 주소",
  },
  {
    Header: "담당자 이름",
  },
  {
    Header: "담당자 이메일",
  },
  {
    Header: "입점 신청일",
  },
];

export const STOREINFOHISTORY = [
  {
    Header: "일시",
  },
  {
    Header: "상점명",
  },
  {
    Header: "상태",
  },
  {
    Header: "변경 항목",
  },
  {
    Header: "최종 편집자",
  },
  {
    Header: "",
  },
];

export const ALLPRODUCT = [
  {
    Header: "카테고리",
  },
  {
    Header: "판매 상태",
  },
  {
    Header: "플랫폼 상품 코드",
  },
  {
    Header: "판매자 관리코드",
  },
  {
    Header: "상품",
  },
  {
    Header: "옵션정보",
  },
  {
    Header: "재고",
  },
  {
    Header: "가격",
  },
  {
    Header: "수정일 / 등록일",
  },
  {
    Header: "정보 변경 이력",
  },
  {
    Header: "",
  },
];
export const ALLPRODUCTADMIN = [
  {
    Header: "상점명",
  },
  {
    Header: "카테고리",
  },
  {
    Header: "판매 상태",
  },
  {
    Header: "플랫폼 상품 코드",
  },
  {
    Header: "상품",
  },
  {
    Header: "옵션정보",
  },
  {
    Header: "재고",
  },
  {
    Header: "가격",
  },
  {
    Header: "수정일 / 등록일",
  },
  {
    Header: "정보 변경 이력",
  },
  {
    Header: "",
  },
];

export const GENERAL_ORDER_ORDER_CHECK_FOR_ADMIN = [
  {
    Header: "주문번호",
  },
  {
    Header: "주문일",
  },
  {
    Header: "주문한 사람",
  },
  {
    Header: "상점명",
  },
  {
    Header: "상품",
  },
  {
    Header: "옵션 정보",
  },
  {
    Header: "수량",
  },
  {
    Header: "출고처리일",
  },
  {
    Header: "택배사",
  },
  {
    Header: "송장번호",
  },
  {
    Header: "받는사람",
  },
  {
    Header: "배송지 정보",
  },
];

export const GENERAL_ORDER_DELIVERY_IN_PROGRESS_FOR_ADMIN = [
  {
    Header: "주문번호",
  },
  {
    Header: "주문일",
  },
  {
    Header: "주문한 사람",
  },
  {
    Header: "상점명",
  },
  {
    Header: "상품",
  },
  {
    Header: "옵션 정보",
  },
  {
    Header: "수량",
  },
  {
    Header: "배송시작일",
  },
  {
    Header: "택배사",
  },
  {
    Header: "송장번호",
  },
  {
    Header: "받는사람",
  },
  {
    Header: "배송지 정보",
  },
];

export const GENERAL_ORDER_DELIVERY_COMPLETE_FOR_ADMIN = [
  {
    Header: "주문번호",
  },
  {
    Header: "주문일",
  },
  {
    Header: "주문한 사람",
  },
  {
    Header: "상점명",
  },
  {
    Header: "상품",
  },
  {
    Header: "옵션 정보",
  },
  {
    Header: "수량",
  },
  {
    Header: "배송완료일",
  },
  {
    Header: "택배사",
  },
  {
    Header: "송장번호",
  },
  {
    Header: "받는사람",
  },
  {
    Header: "배송지 정보",
  },
];

export const GENERAL_ORDER_ORDER_CHECK = [
  {
    Header: "주문번호",
  },
  {
    Header: "주문일",
  },
  {
    Header: "주문한 사람",
  },
  {
    Header: "상품",
  },
  {
    Header: "옵션 정보",
  },
  {
    Header: "수량",
  },
  {
    Header: "출고처리일",
  },
  {
    Header: "택배사",
  },
  {
    Header: "송장번호",
  },
  {
    Header: "받는사람",
  },
  {
    Header: "배송지 정보",
  },
];

export const GENERAL_ORDER_DELIVERY_IN_PROGRESS = [
  {
    Header: "주문번호",
  },
  {
    Header: "주문일",
  },
  {
    Header: "주문한 사람",
  },
  {
    Header: "상점명",
  },
  {
    Header: "상품",
  },
  {
    Header: "옵션 정보",
  },
  {
    Header: "수량",
  },
  {
    Header: "배송시작일",
  },
  {
    Header: "택배사",
  },
  {
    Header: "송장번호",
  },
  {
    Header: "받는사람",
  },
  {
    Header: "배송지 정보",
  },
];

export const GENERAL_ORDER_DELIVERY_COMPLETE = [
  {
    Header: "주문번호",
  },
  {
    Header: "주문일",
  },
  {
    Header: "주문한 사람",
  },
  {
    Header: "상점명",
  },
  {
    Header: "상품",
  },
  {
    Header: "옵션 정보",
  },
  {
    Header: "수량",
  },
  {
    Header: "배송완료일",
  },
  {
    Header: "택배사",
  },
  {
    Header: "송장번호",
  },
  {
    Header: "받는사람",
  },
  {
    Header: "배송지 정보",
  },
];

export const GENERALORDER = [
  {
    Header: "주문번호",
  },
  {
    Header: "상품",
  },
  {
    Header: "옵션 정보",
  },
  {
    Header: "수량",
  },
  {
    Header: "상품",
  },
  {
    Header: "출고처리일",
  },
  {
    Header: "택배사",
  },
  {
    Header: "송장번호",
  },
  {
    Header: "받는사람",
  },
  {
    Header: "배송지 정보",
  },
  {
    Header: "주문일",
  },
  {
    Header: "주문한 사람",
  },
];

export const GIFTORDER = [
  {
    Header: "주문번호",
  },
  {
    Header: "주문일",
  },
  {
    Header: "주문한 사람",
  },
  {
    Header: "상품",
  },
  {
    Header: "옵션 정보",
  },
  {
    Header: "수량",
  },
  {
    Header: "상태",
  },
  {
    Header: "선물 만료일",
  },
  {
    Header: "받는 사람",
  },
  {
    Header: "받는 사람 연락처",
  },
];

export const EXCHANGEORDER = [
  {
    Header: "주문번호",
  },
  {
    Header: "CS 접수번호",
  },
  {
    Header: "상품",
  },
  {
    Header: "옵션 정보",
  },
  {
    Header: "수량",
  },
  {
    Header: "클레임 상태",
  },
  {
    Header: "교환 신청일",
  },
  {
    Header: "교환 접수일",
  },
  {
    Header: "입고 택배사",
  },
  {
    Header: "입고 송장번호",
  },
  {
    Header: "교환 사유",
  },
  {
    Header: "수거 방법",
  },
];

export const RETURNORDER = [
  {
    Header: "주문번호",
  },
  {
    Header: "CS 접수번호",
  },
  {
    Header: "상품",
  },
  {
    Header: "옵션 정보",
  },
  {
    Header: "수량",
  },
  {
    Header: "클레임 상태",
  },
  {
    Header: "반품 신청일",
  },
  {
    Header: "반품 접수일",
  },
  {
    Header: "입고 택배사",
  },
  {
    Header: "입고 송장번호",
  },
  {
    Header: "반품 사유",
  },
  {
    Header: "반품 방법",
  },
];

export const CANCEL_ORDER_FOR_ADMIN = [
  {
    Header: "상점명",
  },
  {
    Header: "취소일",
  },
  {
    Header: "주문일",
  },
  {
    Header: "주문번호",
  },
  {
    Header: "상품",
  },
  {
    Header: "옵션정보",
  },
  {
    Header: "수량",
  },
  {
    Header: "가격",
  },
  {
    Header: "주문자(닉네임)",
  },
];

export const CANCEL_ORDER = [
  {
    Header: "취소일",
  },
  {
    Header: "주문일",
  },
  {
    Header: "주문번호",
  },
  {
    Header: "상품",
  },
  {
    Header: "옵션정보",
  },
  {
    Header: "수량",
  },
  {
    Header: "가격",
  },
  {
    Header: "주문자(닉네임)",
  },
];
