import ButtonFilledPrimary from "components/Button/ButtonFilledPrimary";
import { title } from "process";
import React, { Dispatch, SetStateAction, useEffect } from "react";

interface Props {
  setCloseState: Dispatch<SetStateAction<boolean>>;
  children?: React.ReactNode;
  onClick?: () => void;
  title?: string;
}

const ConfirmPopup = ({ setCloseState, children, title, onClick }: Props) => {
  
  useEffect(() => {
    document.body.style.cssText = `
          position: fixed; 
          top: -${window.scrollY}px;
          overflow-y: scroll;
          width: 100%;`;
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = "";
      window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
    };
  }, []);

  return (
    <div className="fixed top-0 left-0 right-0 w-full h-full z-[1000] bg-Gray-100/25">
      <div
        className={`fixed border left-2/4 top-2/4 -translate-x-2/4 -translate-y-2/4 flex flex-col justify-between bg-Gray-10 rounded-2xl border-Gray-40 min-h-[428px] min-w-[800px] w-[60%] max-w-[1080px] max-h-[70%]`}
      >
        <div className="px-10 text-left py-7 H4 text-Gray-100">{title}</div>
        <div className="flex-1 px-10 max-h-[400px] overflow-y-auto">{children}</div>
        <div className="p-5 pb-7">
          <ButtonFilledPrimary
            onClick={() => {
              if(onClick) {
                onClick()
              }
              setCloseState(false);
            }}
            size={"lg"}
            className="mx-auto"
            btnText={"확인"}
          />
        </div>
      </div>
    </div>
  );
};

export default ConfirmPopup;
