import { AxiosResponse } from "axios";
import { UseQueryResult, useQuery } from "react-query";
import { DeliveryCompany } from "type/deliveryCompany";
import requestToLoveEgg from "utils/requestToLoveEgg";

const getDeliveryCompanyListApi = () => {
    // 배송사 목록 조회
    return requestToLoveEgg({
        method: "GET",
        url: "/api/v1/deliveries"
    })
};

export const useDeliveryCompanyListApi = (): UseQueryResult<
    AxiosResponse<DeliveryCompany>,
    Error
> =>
    useQuery(['useDeliveryCompanyListApi'], () => getDeliveryCompanyListApi(), {
        // retry: 3,
        keepPreviousData: true,
    });