import React, { Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";
import { Content } from "type/order";
import Table from "./Table";
import { GENERAL_ORDER_ORDER_CHECK } from "./TableData";

interface Props {
  tableList: Content[];
  selectedTrList: Dispatch<SetStateAction<any>>;
  selectedList: string[];
}

const OrderCheck = ({ tableList, selectedTrList, selectedList }: Props) => {
  const navigate = useNavigate();

  const onChangeCheckBox = (
    e: React.ChangeEvent<HTMLInputElement>,
    orderNo: string
  ) => {
    const isChecked = e.target.checked;
    selectedTrList((selectedList: string[]) => {
      if (isChecked) {
        return [...selectedList, orderNo];
      } else {
        return selectedList.filter((x) => x !== orderNo);
      }
    });
  };

  const handleAllCheck = (checked: boolean) => {
    if (checked) {
      const orderNoArray: string[] = [];
      tableList.forEach((el) => orderNoArray.push(el.orderNo));
      selectedTrList(orderNoArray);
    } else {
      selectedTrList([]);
    }
  };

  return (
    <>
      {tableList?.length !== 0 ? (
        <Table
          onChange={(e) => {
            handleAllCheck(e.target.checked);
          }}
          checked={tableList?.length === selectedList?.length ? true : false}
          COLUMNS={GENERAL_ORDER_ORDER_CHECK}
          checkBox
        >
          {tableList?.map((item, i) => {
            return (
              <tr key={item.orderNo} className="last:border-none border-y Body4 border-Gray-40">
                <td className="tableTd">
                  <input
                    checked={selectedList.includes(item.orderNo)}
                    onChange={(e) => {
                      onChangeCheckBox(e, item.orderNo);
                    }}
                    type="checkbox"
                    name=""
                    id=""
                  />
                </td>
                <td
                  onClick={() => {
                    navigate(`/order/${item.orderNo}`);
                  }}
                  className="cursor-pointer tableTd text-Blue-100 min-w-[120px]"
                >
                  {item.orderNo}
                </td>
                <td className="tableTd min-w-[100px]">{item.regDt}</td>
                <td className="tableTd min-w-[90px] textEll max-w-[100px]">{item.buyerName}</td>
                <td className="min-w-[200px]">
                  {item.orderItemList.map((k, i) => (
                    <div key={i} className="flex items-center tableTd">
                      <img
                        className="w-10 h-10 mr-2 rounded"
                        src={k.itemImagePath}
                        alt=""
                      />
                      <div className="max-w-[180px] textEll">{k.itemTitle}</div>
                    </div>
                  ))}
                </td>
                <td className="max-w-[160px]">
                  {item.orderItemList.map((k, i) => (
                    <div key={i} className="flex items-center h-[72px] tableTd">
                      {k.optionStr ? k.optionStr : "-"}
                    </div>
                  ))}
                </td>
                <td className="min-w-[100px]">
                  {item.orderItemList.map((k, i) => (
                    <div key={i} className="flex items-center h-[72px] tableTd">
                      {k.count}
                    </div>
                  ))}
                </td>
                <td className="tableTd min-w-[100px]">
                  {item.deliveryStartDate ? item.deliveryStartDate : "-"}
                </td>
                <td className="tableTd min-w-[120px]">{item.courierName}</td>
                <td className="tableTd min-w-[120px]">
                  {item.invoiceNumber ? item.invoiceNumber : "-"}
                </td>
                <td className="tableTd min-w-[50px]">{item.receiverName}</td>
                <td className="tableTd min-w-[200px]">{item.receiverAddr}</td>
              </tr>
            );
          })}
        </Table>
      ) : (
        <div className="h-[500px] flex flex-col items-center justify-center Body4">
          들어온 주문이 없어요
        </div>
      )}
    </>
  );
};

export default OrderCheck;
