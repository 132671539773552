import { useGiftOrderDetailForAdmin } from "api/order/Admin";
import ButtonFilledPrimary from "components/Button/ButtonFilledPrimary";
import ButtonLinedTertiary from "components/Button/ButtonLinedTertiary";
import PageHeader from "components/Header/PageHeader";
import NewStoreForm from "components/Store/NewStoreForm";
import NewStoreWrap from "components/Store/NewStoreWrap";
import ProdDetail from "components/Wrap/ProdDetail";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PriceComma } from "utils/priceComma";

const GiftOrderDetail = () => {
  const navigate = useNavigate();
  const param = useParams();

  const orderId = param.orderId as string;

  const { data } = useGiftOrderDetailForAdmin(orderId);

  const giftDetailData = data?.data.ResultData;

  return (
    <div className="contentBox">
      <PageHeader
        noBorderB
        pageList={["주문 관리", "선물 대기 목록", "선물 상세"]}
        title={`선물 상세 내역`}
      />
      <NewStoreWrap noGap title="주문 정보">
        <NewStoreForm itemCenter title="주문일시">
          <div className="Body3 text-Gray-90">{giftDetailData?.regDt}</div>
        </NewStoreForm>
        <NewStoreForm itemCenter title="주문자 (닉네임)">
          <div className="Body3 text-Gray-90">
          {giftDetailData?.purchaserName} ({giftDetailData?.purchaserNickName})
          </div>
        </NewStoreForm>
        <NewStoreForm itemCenter title="연락처">
          <div className="Body3 text-Gray-90">
            {giftDetailData?.purchaserMobileNo}
          </div>
        </NewStoreForm>
        <NewStoreForm itemCenter title="결제 OS">
          <div className="Body3 text-Gray-90">
            {giftDetailData?.purchaseDevice ? giftDetailData?.purchaseDevice : "-"}
          </div>
        </NewStoreForm>
      </NewStoreWrap>
      <ProdDetail title={"선물 상품"} />
      <div className="overflow-scroll max-h-[600px] min-w-[1020px] visibleScroll mb-[60px]">
        <table className="w-full border-y whitespace-nowrap border-Gray-40">
          <thead>
            <tr>
              <th className="SubH2 py-2.5 bg-Gray-20 border-Gray-20 border-b px-3 text-left">
                주문번호: {giftDetailData?.gifts.orderNo}
              </th>
              <th className="SubH2 py-2.5 bg-Gray-20 border-Gray-20 border-b px-3 text-left">
                옵션정보
              </th>
              <th className="SubH2 py-2.5 bg-Gray-20 border-Gray-20 border-b px-3 text-center">
                수량
              </th>
              <th className="SubH2 py-2.5 bg-Gray-20 border-Gray-20 border-b px-3 text-center">
                가격
              </th>
              <th className="SubH2 py-2.5 bg-Gray-20 border-Gray-20 border-b px-3 text-center">
                상태
              </th>
              <th className="SubH2 py-2.5 bg-Gray-20 border-Gray-20 border-b px-3 text-left">
                배송정보
              </th>
              <th className="SubH2 py-2.5 bg-Gray-20 border-Gray-20 border-b px-3 text-center">
                주문 처리 이력
              </th>
              <th className="SubH2 py-2.5 bg-Gray-20 border-Gray-20 border-b px-3 text-left"></th>
            </tr>
          </thead>
          <tbody>
            {giftDetailData?.gifts.giftItems.map((o, i) => {
              return (
                <tr key={o.orderItemId} className="Body4">
                  <td className="tableTd w-[838px]">
                    <div className="flex items-center gap-2">
                      <img
                        className="rounded w-11 h-11"
                        src={o.thumbnail}
                        alt=""
                      />
                      <div>{o.title}</div>
                    </div>
                  </td>
                  <td className="tableTd">{o.optionStr ? o.optionStr : "-"}</td>
                  <td className="text-center tableTd">{o.count}</td>
                  <td className="text-center tableTd">
                    {PriceComma(o.price)}원
                  </td>
                  <td className="text-center tableTd">
                    {o.itemStatus === "DELIVERY_INPUT_READY" ? (
                      <div className="text-Blue-100">수락대기중</div>
                    ) : o.itemStatus === "EXPIRATION" ? (
                      <div className="text-Red100">기간만료</div>
                    ) : o.itemStatus === "REFUSAL" ? (
                      <div className="text-Red100">선물거절</div>
                    ) : o.itemStatus === "CANCEL" ? (
                      <div className="text-Red100">선물취소</div>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td className="tableTd">
                    <div>{o.courierName}</div>
                    <div>{o.invoiceNumber ? o.invoiceNumber : "-"}</div>
                  </td>
                  <td className="text-center cursor-pointer tableTd text-Gray-60">
                    이력보기
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <NewStoreWrap noGap title="선물 정보">
        <NewStoreForm itemCenter title="선물 만료일">
          <div className="Body3 text-Gray-90">
            {giftDetailData?.deliveryInfo.expiryDate}
          </div>
        </NewStoreForm>
        <NewStoreForm itemCenter title="수령인 정보">
          <div className="Body3 text-Gray-90">
            {giftDetailData?.deliveryInfo.receiverName}
          </div>
        </NewStoreForm>
        <NewStoreForm itemCenter title="수령인 연락처">
          <div className="Body3 text-Gray-90">
            {giftDetailData?.deliveryInfo.receiverMobileNo}
          </div>
        </NewStoreForm>
      </NewStoreWrap>
      <div className="fixed bottom-0 left-0 bg-Gray-10/70 backdrop-blur-[12px] z-[102] w-full py-5 border-t border-Gray-40">
        <div className="flex items-center pl-[260px] pr-[160px] justify-between max-w-[1920px] mx-auto min-w-[1440px]">
          <div className="Body2">선물 대기 목록 {">"} 선물 상세</div>
          <ButtonFilledPrimary
            onClick={() => {
              navigate(-1);
            }}
            size={"lg"}
            btnText={"확인"}
          />
        </div>
      </div>
    </div>
  );
};

export default GiftOrderDetail;
