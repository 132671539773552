import React, { Dispatch, SetStateAction, useEffect } from "react";
import ButtonFilledPrimary from "components/Button/ButtonFilledPrimary";
import Slider from "react-slick";

import { ReactComponent as Next } from "assets/NextArrow.svg";
import { ReactComponent as Prev } from "assets/PrevArrow.svg";

interface Props {
  imageList?: string[];
  setCloseState?: Dispatch<SetStateAction<boolean>>;
}

const PopupImageView = ({ imageList, setCloseState }: Props) => {

  const setting = {
    slidesToShow: 1,
    slidesToScroll: 1,
    centerPadding: '0px',
    nextArrow: <Next/>, // 화살표 버튼을 커스텀해서 사용
    prevArrow: <Prev/>,
  };

  useEffect(() => {
    document.body.style.cssText = `
          position: fixed; 
          top: -${window.scrollY}px;
          overflow-y: scroll;
          width: 100%;`;
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = "";
      window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
    };
  }, []);

  return (
    <div className="fixed top-0 left-0 right-0 w-full h-full z-[1000] bg-Gray-100/25">
      <div className="fixed w-[560px] h-[502px] bg-Gray-10 rounded-2xl gap-5 flex flex-col left-2/4 top-2/4 -translate-x-2/4 -translate-y-2/4">
        <div className="px-10 py-7 H4 text-Gray-100">상세 이미지</div>
        <div className="grow w-[280px] mx-auto">
          <Slider {...setting}>
            {imageList?.map((image, i) => {
              return (
                <div key={i}>
                  <img src={image} className="w-[280px] mx-auto h-[280px]" alt="" />
                </div>
              );
            })}
          </Slider>
        </div>
        <div className="py-5">
          <ButtonFilledPrimary
            onClick={() => {
              setCloseState && setCloseState(false);
            }}
            className="mx-auto"
            size={"lg"}
            btnText={"확인"}
          />
        </div>
      </div>
    </div>
  );
};

export default PopupImageView;
