import React from 'react';

const Footer = () => {
    return (
        <div className='w-full h-[220px] pt-[120px] pb-[136px] text-Gray-60 Body5 pr-[60px] text-right'>
            © 2023 LOVEEGG Inc. All Rights Reserved.
        </div>
    );
};

export default Footer;