import axios from "axios";
import requestToLoveEgg from "./requestToLoveEgg";

export const onSilentRefresh = () => {
    requestToLoveEgg
        .post('v1/silent-refresh')
        .then((res) => {
            if (res.data.ResultCode === 0) {
                window.localStorage.removeItem('userInfo');
                window.location.href = '/';
                return;
            }
            const userInfoTxt = window.localStorage.getItem('userInfo');
            let userInfo = userInfoTxt ? JSON.parse(userInfoTxt) : null;
            if (userInfo && userInfo.id) {
                userInfo.accessToken = res.data.ResultData.accessToken
                userInfo.accessTokenExpiresIn = parseInt(
                    res.data.ResultData.accessTokenExpiresIn
                );
            } else {
                userInfo = {
                    accessToken: res.data.ResultData.accessToken,
                    accessTokenExpiresIn: parseInt(
                        res.data.ResultData.accessTokenExpiresIn,
                    ),

                };
            }
            window.localStorage.setItem('userInfo', JSON.stringify(userInfo));
        })
    .catch((err) => {
        localStorage.removeItem('userInfo');
        window.location.href = '/';
    });
};
