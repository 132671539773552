import React, { useState } from "react";

import { useExchangeDetail } from "api/order/User";

import { ReactComponent as Equal } from "assets/icEqual.svg";
import { ReactComponent as MinusGray } from "assets/MinusGray.svg";
import { ReactComponent as Plus } from "assets/icPlus.svg";
import { ReactComponent as Warning } from "assets/Warning.svg";

import ButtonFilledSecondary from "components/Button/ButtonFilledSecondary";
import ButtonLinedTertiary from "components/Button/ButtonLinedTertiary";
import PageHeader from "components/Header/PageHeader";
import NewStoreForm from "components/Store/NewStoreForm";
import NewStoreWrap from "components/Store/NewStoreWrap";
import ProdDetail from "components/Wrap/ProdDetail";
import { useNavigate, useParams } from "react-router-dom";
import { PriceComma } from "utils/priceComma";
import { toast } from "react-toastify";
import requestToLoveEgg from "utils/requestToLoveEgg";
import Dropdown from "components/Dropdown/Dropdown";
import TextFieldInput from "components/Input/TextFieldInput";
import TextLimitHorizontalInput from "components/Input/TextLimitHorizontalInput";
import ModalPopup from "components/Modal/ModalPopup";
import { useDeliveryCompanyListApi } from "api/deliveryCompany";

const ExchangeOrderDetail = () => {
  const params = useParams();

  const navigate = useNavigate();
  const [cancelModal, setCancelModal] = useState(false);
  const [invoiceModal, setInvoiceModal] = useState(false);
  const [receiveInvoiceModal, setReceiveInvoiceModal] = useState(false);
  const [updateReturnModal, setUpdateReturnModal] = useState(false);

  const [invoiceText, setInvoiceText] = useState("");

  const [selectedDelivery, setSelectedDelivery] = useState<{
    cd: string;
    cdNm: string;
  }>({ cd: "", cdNm: "" });
  const [selectedReceiveDelivery, setSelectedReceiveDelivery] = useState<{
    cd: string;
    cdNm: string;
  }>({ cd: "", cdNm: "" });
  const [inVoiceNo, setInvoiceNo] = useState("");
  const [receiveinVoiceNo, setReceiveInvoiceNo] = useState("");
  const [preInvoice, setPreInvoice] = useState({
    courierName: "",
    invoice: "",
  });
  const [cancelReason, setCancelReason] = useState("");

  const { data, refetch } = useExchangeDetail(params.claimNo!);

  const res = data?.data.ResultData;

  const checkExchange = () => {
    // 교환 접수
    requestToLoveEgg({
      method: "POST",
      url: `/api/v1/exchanges/${params.claimNo}/check`,
    }).then((res) => {
      if (res.data.ResultCode === 1) {
        toast.success(
          "`교환 접수`로 상태 변경했습니다.\n[교환 관리 > 교환 접수]에서 확인 가능합니다."
        );
        refetch();
      }
    });
  };
  const cancelExchange = () => {
    // 교환 취소
    requestToLoveEgg({
      method: "POST",
      url: `/api/v1/exchanges/${params.claimNo}/cancel`,
      data: {
        reason: cancelReason,
      },
    }).then((res) => {
      if (res.data.ResultCode === 1) {
        toast.success(
          "교환 요청이 거부되었습니다.\n[일반주문>배송완료] 메뉴에서 확인 가능합니다"
        );
        navigate("/order/exchange", { replace: true });
      }
    });
  };
  const changeReturn = () => {
    // 반품으로 변경
    requestToLoveEgg({
      method: "POST",
      url: `/api/v1/exchanges/${params.claimNo}/update-return`,
    }).then((res) => {
      if (res.data.ResultCode === 1) {
        navigate("/order/exchange", { replace: true });
        toast.success(
          "반품으로 변경 완료했습니다.\n[일반주문>반품 관리] 메뉴에서 확인 가능합니다."
        );
      }
    });
  };
  const receive = () => {
    // 상품 입고
    requestToLoveEgg({
      method: "POST",
      url: `/api/v1/exchanges/${params.claimNo}/receive`,
    }).then((res) => {
      if (res.data.ResultCode === 1) {
        toast.success(
          "`상품 입고`로 상태 변경했습니다.\n[교환 관리 > 상품 입고]에서 확인 가능합니다."
        );
        refetch();
      }
    });
  };
  const invoiceCollect = () => {
    // 입고 송장 번호 입력
    requestToLoveEgg({
      method: "POST",
      url: `/api/v1/claim/${params.claimNo}/collect-info`,
      data: {
        invoiceNumber: receiveinVoiceNo,
        courierName: selectedReceiveDelivery.cdNm,
      },
    }).then((res) => {
      if (res.data.ResultCode === 1) {
        setReceiveInvoiceModal(false);
        toast.success(`택배사, 입고 송장번호 입력 완료되었습니다:)`);
        setReceiveInvoiceNo("");
        setSelectedReceiveDelivery({ cd: "", cdNm: "" });
        refetch();
      }
    });
  };

  const invoice = () => {
    // 출고 송장 번호 입력
    requestToLoveEgg({
      method: "POST",
      url: `/api/v1/exchanges/${params.claimNo}/invoice-number`,
      data: {
        invoiceNumber: inVoiceNo,
        courierName: selectedDelivery.cdNm,
      },
    }).then((res) => {
      if (res.data.ResultCode === 1) {
        setInvoiceModal(false);
        toast.success(`택배사, 송장번호 ${invoiceText} 완료되었습니다:)`);
        setInvoiceNo("");
        setSelectedDelivery({ cd: "", cdNm: "" });
        refetch();
      }
    });
  };

  const { data: deliveryData } = useDeliveryCompanyListApi();

  const deliveryList = deliveryData?.data.ResultData;

  return (
    <div className="contentBox">
      {receiveInvoiceModal && (
        <ModalPopup
          onClick={() => {
            invoiceCollect();
          }}
          onClickCancelButton={() => {
            setReceiveInvoiceNo("");
            setSelectedReceiveDelivery({ cd: "", cdNm: "" });
          }}
          cancalButton
          setCloseState={setReceiveInvoiceModal}
          type={"md"}
          buttonText={"적용하기"}
        >
          <div className="H4">송장번호 입력</div>
          <div className="my-4 text-Gray-90 Body4">
            CS 접수번호 : {params?.claimNo}
          </div>
          <div className="mt-4 Body2 break-keep">
            송장번호를 입력하면 배송중 단계로 넘어갑니다. 택배사와 송장번호를
            정확하게 입력해 주세요. (구매고객에게는{" "}
            <span className="text-Blue-100">배송중</span>으로 보여집니다.)
          </div>
          <Dropdown
            innerText={
              selectedReceiveDelivery.cdNm
                ? selectedReceiveDelivery.cdNm
                : "선택"
            }
            width="w-full"
            className="mt-6"
          >
            {deliveryList?.map((d, i) => {
              return (
                <li
                  key={i}
                  onClick={() => {
                    setSelectedReceiveDelivery({ cd: d.cd, cdNm: d.cdNm });
                  }}
                  className="dropdownWrap"
                >
                  <div className="dropdownLi">{d.cdNm}</div>
                </li>
              );
            })}
          </Dropdown>
          <TextFieldInput
            inputValue={receiveinVoiceNo}
            onChange={(e) => {
              const { value } = e.target;
              setReceiveInvoiceNo(value.replace(/[^0-9]/g, ""));
            }}
            className="w-full mt-4"
          />
        </ModalPopup>
      )}
      {invoiceModal && (
        <ModalPopup
          onClickCancelButton={() => {
            setInvoiceNo("");
            setSelectedDelivery({ cd: "", cdNm: "" });
            setPreInvoice({ courierName: "", invoice: "" });
          }}
          type={"md"}
          cancalButton
          onClick={() => {
            invoice();
          }}
          setCloseState={setInvoiceModal}
          buttonText={"적용하기"}
        >
          <div className="H4">송장번호 {invoiceText}</div>
          <div className="my-4 text-Gray-90 Body4">
            CS 접수번호 : {params?.claimNo}
          </div>
          {invoiceText === "수정" ? (
            <>
              <div className="Body4 text-Gray-90">
                <div className="mb-4">
                  기존 송장번호 : {preInvoice.courierName} {preInvoice.invoice}
                </div>
                <div>수정할 택배사와 송장번호를 정확하게 입력해 주세요.</div>
              </div>
            </>
          ) : (
            <div className="mt-4 Body2 break-keep">
              송장번호를 입력하면 배송중 단계로 넘어갑니다. 택배사와 송장번호를
              정확하게 입력해 주세요. (구매고객에게는{" "}
              <span className="text-Blue-100">배송중</span>으로 보여집니다.)
            </div>
          )}
          <Dropdown
            innerText={selectedDelivery.cdNm ? selectedDelivery.cdNm : "선택"}
            width="w-full"
            className="mt-6"
          >
            {deliveryList?.map((d, i) => {
              return (
                <li
                  key={i}
                  onClick={() => {
                    setSelectedDelivery({ cd: d.cd, cdNm: d.cdNm });
                  }}
                  className="dropdownWrap"
                >
                  <div className="dropdownLi">{d.cdNm}</div>
                </li>
              );
            })}
          </Dropdown>
          <TextFieldInput
            inputValue={inVoiceNo}
            onChange={(e) => {
              const { value } = e.target;
              setInvoiceNo(value.replace(/[^0-9]/g, ""));
            }}
            className="w-full mt-4"
          />
        </ModalPopup>
      )}
      {updateReturnModal && (
        <ModalPopup
          cancalButton
          setCloseState={setUpdateReturnModal}
          type={"md"}
          onClick={() => {
            changeReturn();
          }}
          buttonText={"변경하기"}
        >
          <div className="H4 text-Gray-100">
          구매자와 상의하셨나요?
          </div>
          <div className="my-4 whitespace-pre-wrap Body4 text-Gray-90">
          {"반품으로 변경하기 전에 구매자에게 미리 알려주세요.\n 변경 시 되돌릴 수 없습니다."}
          </div>
          <div className="flex flex-col w-full gap-5 px-4 py-5 rounded-lg bg-Gray-20 Body4">
            <div>
              <div className="text-Blue-100">{res?.originItem.title}</div>
              <div className="flex items-center">
                {res?.originItem.optionGroups.length !== 0 ? res?.originItem.optionGroups.map((o, i) => {
                  if (res?.originItem.optionGroups.length === i + 1) {
                    return (
                      <div key={i} className="flex flex-col justify-center">
                        {o.options[0].title}{" "}
                        {o.options[0].addPrice === 0
                          ? ""
                          : `(+ ${PriceComma(o.options[0].addPrice)})`}
                      </div>
                    );
                  } else {
                    return (
                      <div key={i} className="flex flex-col justify-center">
                        {o.options[0].title}{" "}
                        {o.options[0].addPrice === 0
                          ? ""
                          : `(+ ${PriceComma(o.options[0].addPrice)})`}{" "}
                        /{" "}
                      </div>
                    );
                  }
                }) : <div className="tableTd">
                -
              </div>}
              </div>
            </div>
            <div>
              {res?.originItem.status === "EXCHANGE_APPLY" ? (
                <>
                  <div className="text-Gray-80">현재 : 교환 신청</div>
                  <div className="mt-1 SubH2 text-Gray-90">
                    변경 : 반품 신청
                  </div>
                </>
              ) : res?.originItem.status === "EXCHANGE_CHECK" ? (
                <>
                  <div className="text-Gray-80">현재 : 교환 접수</div>
                  <div className="mt-1 SubH2 text-Gray-90">
                    변경 : 반품 접수
                  </div>
                </>
              ) : res?.originItem.status === "EXCHANGE_RECEIVE" ? (
                <>
                  <div className="text-Gray-80">현재 : 교환 상품입고</div>
                  <div className="mt-1 SubH2 text-Gray-90">
                    변경 : 반품 처리
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </ModalPopup>
      )}
      {cancelModal && (
        <ModalPopup
          setCloseState={setCancelModal}
          disabled={cancelReason === ""}
          onClick={() => {
            cancelExchange();
          }}
          type={"md"}
          cancalButton
          buttonText={"교환 거부하기"}
        >
          <div className="H4 text-Gray-100">교환거부</div>
          <div className="my-4 Body4 text-Gray-90">
            교환 거부 시 고객의 상품 상태는 배송완료로 돌아갑니다. 거부 시
            되돌릴 수 없습니다.{" "}
          </div>
          <div className="Body4 text-Gray-90">
            교환 거부하실 경우 사유를 작성해주세요.
          </div>
          <TextLimitHorizontalInput
            className="w-full mb-4 mt-9"
            onChange={(e) => {
              setCancelReason(e.target.value);
            }}
            placeholder="취소 사유를 상세하게 작성해주세요"
            currentLength={cancelReason?.length}
            row={7}
            maxLength={200}
          ></TextLimitHorizontalInput>
          <div className="flex items-center">
            <Warning className="mr-2.5" />
            <div className="flex-1 whitespace-pre-wrap Body5 text-Blue-100">
              {
                "지금 작성하는 거부 사유 내용은 고객에게 보여지는\n내용이니 신중하게작성해주세요. (10자 이상)"
              }
            </div>
          </div>
        </ModalPopup>
      )}
      <PageHeader
        noBorderB
        pageList={["주문 관리", "교환 관리", "교환 상세"]}
        title={`교환 상세`}
      />
      <NewStoreWrap noGap title="교환 접수">
        <NewStoreForm itemCenter title="CS 접수 번호">
          <div className="Body3 text-Gray-90">{res?.claimNo}</div>
        </NewStoreForm>
        <NewStoreForm itemCenter title="주문번호">
          <div className="Body3 text-Gray-90">{res?.originOrderNo}</div>
        </NewStoreForm>
        <div className="my-4 border-b border-Gray-40" />
        <NewStoreForm itemCenter title="교환신청일시">
          <div className="Body3 text-Gray-90">{res?.regDt}</div>
        </NewStoreForm>
        <NewStoreForm itemCenter title="주문자 (닉네임)">
          <div className="Body3 text-Gray-90">{res?.nickName}</div>
        </NewStoreForm>
        <NewStoreForm itemCenter title="연락처">
          <div className="Body3 text-Gray-90">{res?.mobileNo}</div>
        </NewStoreForm>
      </NewStoreWrap>
      <ProdDetail title={"교환 상품 정보"}>
        <div className="flex items-center gap-1">
          {res?.originItem.status === "EXCHANGE_APPLY" ? (
            <>
              <ButtonFilledSecondary
                className="h-[38px]"
                size={"md"}
                onClick={() => {
                  checkExchange();
                }}
                btnText={"교환 접수"}
              />
              <ButtonLinedTertiary
                className="h-[38px]"
                onClick={() => {
                  setCancelModal(true);
                }}
                size={"md"}
                btnText={"교환 거부"}
              />
              <ButtonLinedTertiary
                className="h-[38px]"
                onClick={() => {
                  setUpdateReturnModal(true);
                }}
                size={"md"}
                btnText={"반품으로 변경"}
              />
            </>
          ) : res?.originItem.status === "EXCHANGE_CHECK" ? (
            <>
              <ButtonFilledSecondary
                className="h-[38px]"
                size={"md"}
                onClick={() => {
                  receive();
                }}
                btnText={"입고 완료"}
              />
              <ButtonLinedTertiary
                className="h-[38px]"
                onClick={() => {
                  setCancelModal(true);
                }}
                size={"md"}
                btnText={"교환 거부"}
              />
              <ButtonLinedTertiary
                className="h-[38px]"
                onClick={() => {
                  setUpdateReturnModal(true);
                }}
                size={"md"}
                btnText={"반품으로 변경"}
              />
            </>
          ) : res?.originItem.status === "EXCHANGE_RECEIVE" ? (
            <>
              <ButtonFilledSecondary
                className="h-[38px]"
                size={"md"}
                onClick={() => {
                  setInvoiceModal(true);
                  setInvoiceText("입력");
                }}
                btnText={"교환 송장번호 입력"}
              />
              <ButtonLinedTertiary
                className="h-[38px]"
                onClick={() => {
                  setCancelModal(true);
                }}
                size={"md"}
                btnText={"교환 거부"}
              />
              <ButtonLinedTertiary
                className="h-[38px]"
                onClick={() => {
                  setUpdateReturnModal(true);
                }}
                size={"md"}
                btnText={"반품으로 변경"}
              />
            </>
          ) : (
            ""
          )}
        </div>
      </ProdDetail>
      <div className="overflow-scroll max-h-[600px] min-w-[1020px] visibleScroll mb-[60px]">
        <table className="w-full border-y whitespace-nowrap border-Gray-40">
          <thead>
            <tr>
              <th className="SubH2 py-2.5 bg-Gray-20 border-Gray-20 border-b px-3 text-left">
                상품정보
              </th>
              <th className="SubH2 py-2.5 bg-Gray-20 border-Gray-20 border-b px-3 text-left">
                옵션정보
              </th>
              <th className="SubH2 py-2.5 bg-Gray-20 border-Gray-20 border-b px-3 text-center">
                수량
              </th>
              <th className="SubH2 py-2.5 bg-Gray-20 border-Gray-20 border-b px-3 text-center">
                가격
              </th>
              <th className="SubH2 py-2.5 bg-Gray-20 border-Gray-20 border-b px-3 text-center">
                쿠폰 할인가
              </th>
              <th className="SubH2 py-2.5 bg-Gray-20 border-Gray-20 border-b px-3 text-center">
                결제
              </th>
              <th className="SubH2 py-2.5 bg-Gray-20 border-Gray-20 border-b px-3 text-center">
                상태
              </th>
              <th className="SubH2 py-2.5 bg-Gray-20 border-Gray-20 border-b px-3 text-center">
                주문 처리 이력
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="Body4">
              <td className="tableTd w-[838px]">
                <div className="mb-1 text-Red100 SubH3">교환 입고 상품</div>
                <div className="flex items-center gap-2">
                  <img
                    className="rounded w-11 h-11"
                    src={res?.originItem?.imgPath}
                    alt=""
                  />
                  <div>{res?.originItem.title}</div>
                </div>
              </td>
              <td className="flex tableTd min-h-[100.5px] flex-wrap w-[160px] break-all">
                {res?.originItem.optionGroups.length !== 0 ? res?.originItem.optionGroups.map((o, i) => {
                  if (res?.originItem.optionGroups.length === i + 1) {
                    return (
                      <div key={i} className="flex flex-col justify-center">
                        {o.options[0].title}{" "}
                        {o.options[0].addPrice === 0
                          ? ""
                          : `(+ ${PriceComma(o.options[0].addPrice)})`}
                      </div>
                    );
                  } else {
                    return (
                      <div key={i} className="flex flex-col justify-center">
                        {o.options[0].title}{" "}
                        {o.options[0].addPrice === 0
                          ? ""
                          : `(+ ${PriceComma(o.options[0].addPrice)})`}{" "}
                        /{" "}
                      </div>
                    );
                  }
                }) : <div className="tableTd">
                -
              </div>}
              </td>
              <td className="text-center tableTd">{res?.originItem.count}</td>
              <td className="tableTd">
                {PriceComma(res?.originItem.price!)}원
              </td>
              <td className="tableTd">
                {PriceComma(res?.originItem.couponPrice!)}원
              </td>
              <td className="tableTd">
                {PriceComma(
                  res?.originItem.price! - res?.originItem.couponPrice!
                )}
                원
              </td>
              <td className="text-center tableTd">
                {res?.originItem.status === "EXCHANGE_APPLY"
                  ? "교환신청"
                  : res?.originItem.status === "EXCHANGE_CHECK"
                  ? "교환접수"
                  : res?.originItem.status === "EXCHANGE_RECEIVE"
                  ? "교환상품입고"
                  : res?.originItem.status === "EXCHANGE_SEND"
                  ? "교환상품발송"
                  : res?.originItem.status === "EXCHANGE_COMPLETE"
                  ? "교환완료"
                  : "-"}
              </td>
              <td className="text-center cursor-pointer tableTd text-Gray-60">
                이력보기
              </td>
            </tr>
            <tr className="Body4">
              <td className="tableTd w-[838px]">
                <div className="mb-1 text-Gray-80 SubH3">교환 출고 상품</div>
                <div className="flex items-center gap-2">
                  <img
                    className="rounded w-11 h-11"
                    src={res?.exchangeItem?.imgPath}
                    alt=""
                  />
                  <div>{res?.exchangeItem.title}</div>
                </div>
              </td>
              <td className="flex tableTd min-h-[100.5px] flex-wrap w-[160px] break-all">
                {res?.exchangeItem.optionGroups.length !== 0 ?res?.exchangeItem.optionGroups.map((o, i) => {
                  if (res?.exchangeItem.optionGroups.length === i + 1) {
                    return (
                      <div key={i} className="flex flex-col justify-center">
                        {o.options[0].title}{" "}
                        {o.options[0].addPrice === 0
                          ? ""
                          : `(+ ${PriceComma(o.options[0].addPrice)})`}
                      </div>
                    );
                  } else {
                    return (
                      <div key={i} className="flex flex-col justify-center">
                        {o.options[0].title}{" "}
                        {o.options[0].addPrice === 0
                          ? ""
                          : `(+ ${PriceComma(o.options[0].addPrice)})`}{" "}
                        /{" "}
                      </div>
                    );
                  }
                }) : <div className="tableTd">
                -
              </div>}
              </td>
              <td className="text-center tableTd">{res?.exchangeItem.count}</td>
              <td className="tableTd">
                {PriceComma(res?.exchangeItem.price!)}원
              </td>
              <td className="tableTd">
                {PriceComma(res?.exchangeItem.couponPrice!)}원
              </td>
              <td className="tableTd">
                {PriceComma(
                  res?.exchangeItem.price! - res?.exchangeItem.couponPrice!
                )}
                원
              </td>
              <td className="tableTd">
                {res?.exchangeItem.status === "DELIVERY_IN_PROGRESS"
                  ? "배송중"
                  : res?.exchangeItem.status === "DELIVERY_COMPLETE"
                  ? "배송완료"
                  : "-"}
              </td>
              <td className="text-center cursor-pointer tableTd text-Gray-60">
                이력보기
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <NewStoreWrap title={"교환 입고/출고 배송 정보"}>
        <NewStoreForm itemCenter title="상품 입고 방법">
          <div className="Body3 text-Gray-90">
            {res?.collectionType === "COMPANY" ? "업체수거" : "직접수거"}
          </div>
        </NewStoreForm>
        <NewStoreForm itemCenter title="입고 상품 송장번호">
          <div className="Body3 text-Gray-90">
            {!res?.returnInvoiceCourierName &&
            !res?.returnInvoiceNumber &&
            res?.originItem.status === "EXCHANGE_CHECK" ? (
              <ButtonLinedTertiary
                onClick={() => {
                  setReceiveInvoiceModal(true);
                }}
                size={"sm"}
                btnText={"송장번호 입력"}
              />
            ) : (
              <>
                <div>{res?.returnInvoiceCourierName}</div>
                <div>{res?.returnInvoiceNumber}</div>
              </>
            )}
          </div>
        </NewStoreForm>
        <NewStoreForm itemCenter title="교환/반품지">
          <div className="Body3 text-Gray-90">
            <div>
              {res?.returnExchangePlaceZip
                ? `[${res?.returnExchangePlaceZip}]`
                : ""}
            </div>
            <div>
              {res?.returnExchangePlaceAddr}{" "}
              {res?.returnExchangePlaceDetailAddr
                ? `,${res?.returnExchangePlaceDetailAddr}`
                : ""}
            </div>
          </div>
        </NewStoreForm>
        <NewStoreForm itemCenter title="출고 상품 송장번호">
          <div className="Body3 text-Gray-90">
            {res?.exchangeInvoiceNumber && res?.exchangeInvoiceCourierName ? (
              <div className="flex items-center gap-2 Body4 text-Gray-90">
                <div>{res?.exchangeInvoiceCourierName}</div>
                <div>{res?.exchangeInvoiceNumber}</div>
                <ButtonLinedTertiary
                  onClick={() => {
                    setInvoiceText("수정");
                    setPreInvoice({
                      courierName: res?.exchangeInvoiceCourierName,
                      invoice: res?.exchangeInvoiceNumber,
                    });
                    setInvoiceModal(true);
                  }}
                  size={"sm"}
                  btnText={"송장번호 수정"}
                />
              </div>
            ) : (
              "-"
            )}
          </div>
        </NewStoreForm>
        <div className="my-4 border-b border-Gray-40" />
        <NewStoreForm itemCenter title="수령인 정보">
          <div className="Body3 text-Gray-90">{res?.receiverName}</div>
        </NewStoreForm>
        <NewStoreForm itemCenter title="수령인 연락처">
          <div className="Body3 text-Gray-90">{res?.receiverMobileNo}</div>
        </NewStoreForm>
        <NewStoreForm itemCenter title="배송지">
          <div className="Body3 text-Gray-90">
            <div>{res?.receiverZip ? `[${res?.receiverZip}]` : ""}</div>
            <div>
              {res?.receiverAddress}{" "}
              {res?.receiverDetailAddress
                ? `,${res?.receiverDetailAddress}`
                : ""}
            </div>
          </div>
        </NewStoreForm>
        <NewStoreForm itemCenter title="배송 요청 사항">
          <div className="Body3 text-Gray-90">
            {res?.receiverRequestMessage ? res?.receiverRequestMessage : "-"}
          </div>
        </NewStoreForm>
      </NewStoreWrap>
      <NewStoreWrap noGap title={"판매자 예상 정산 정보"}>
        <div className="flex items-center gap-5">
          <div className="flex-1">
            <div className="Body2 text-Gray-80">상품 판매가 (입고상품)</div>
            <div className="H4 text-Gray-90">
              {PriceComma(res?.calculateInfo.price!)}원
            </div>
          </div>
          <MinusGray />
          <div className="flex-1">
            <div className="Body2 text-Gray-80">
              수수료 ({res?.calculateInfo.fee}%)
            </div>
            <div className="H4 text-Gray-90">
              {PriceComma(
                Math.round(
                  res?.calculateInfo.price! * (res?.calculateInfo.fee! * 0.01)
                )
              )}
              원
            </div>
          </div>
          <Plus />
          <div className="flex-1">
            <div className="Body2 text-Gray-80">배송비</div>
            <div className="H4 text-Gray-90">
              {PriceComma(
                res?.calculateInfo.deliveryPrice! +
                  res?.calculateInfo.additionalDeliveryPrice!
              )}
              원
            </div>
          </div>
          <Plus />
          <div className="flex-1">
            <div className="Body2 text-Gray-80">조정금액</div>
            <div className="H4 text-Gray-90">
              {PriceComma(res?.calculateInfo.adjustmentAmount!)}원
            </div>
          </div>
          <Equal />
          <div className="flex-1">
            <div className="Body2 text-Gray-80">예상정산금</div>
            <div className="H4 text-Gray-90">
              {PriceComma(
                Math.round(
                  res?.calculateInfo.price! -
                    res?.calculateInfo.price! * (res?.calculateInfo.fee! * 0.01)
                ) +
                  (res?.calculateInfo.deliveryPrice! +
                    res?.calculateInfo.additionalDeliveryPrice!) +
                  res?.calculateInfo.adjustmentAmount!
              )}
            </div>
          </div>
        </div>
        <div className="my-5 border-b border-Gray-40" />
        <div className="flex items-center gap-5">
          <div className="flex-1">
            <div className="Body2 text-Gray-80">상품 판매가 (출고상품)</div>
            <div className="H4 text-Gray-90">
              {PriceComma(res?.calculateInfo.newPrice!)}원
            </div>
          </div>
          <MinusGray />
          <div className="flex-1">
            <div className="Body2 text-Gray-80">
              수수료({res?.calculateInfo.fee}%)
            </div>
            <div className="H4 text-Gray-90">
              {PriceComma(
                Math.round(
                  res?.calculateInfo.newPrice! *
                    (res?.calculateInfo.fee! * 0.01)
                )
              )}
              원
            </div>
          </div>
          <Plus />
          <div className="flex-1">
            <div className="Body2 text-Gray-80">배송비</div>
            <div className="H4 text-Gray-90">
              {PriceComma(
                res?.calculateInfo.newDeliveryPrice! +
                  res?.calculateInfo.newAdditionalDeliveryPrice!
              )}
              원
            </div>
          </div>
          <Plus />
          <div className="flex-1">
            <div className="Body2 text-Gray-80">조정금액</div>
            <div className="H4 text-Gray-90">
              {PriceComma(res?.calculateInfo.newAdjustmentAmount!)}원
            </div>
          </div>
          <Equal />
          <div className="flex-1">
            <div className="Body2 text-Gray-80">예상정산금</div>
            <div className="text-Blue-100 H2">
              0
              {/* {PriceComma(
                Math.round(
                  
                    res?.calculateInfo.newPrice! -

                    (res?.calculateInfo.newPrice! *
                      (res?.calculateInfo.fee! * 0.01))) +

                  (res?.calculateInfo.newDeliveryPrice! +
                    res?.calculateInfo.newAdditionalDeliveryPrice!) +

                  (res?.calculateInfo.newAdjustmentAmount!)
              )} */}
              원
            </div>
          </div>
        </div>
      </NewStoreWrap>
    </div>
  );
};

export default ExchangeOrderDetail;
