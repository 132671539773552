import { useUserStoreInfoApi } from "api/store";
import PageHeader from "components/Header/PageHeader";
import DeliveryInfo from "components/StoreSetting/DeliveryInfo";
import OperationSetting from "components/StoreSetting/OperationSetting";
import StoreInfo from "components/StoreSetting/StoreInfo";
import React, { useState } from "react";
import { NewUserStoreInfoData } from "type/store";

const StoreDetail = () => {
  const [currentId, setCurrentId] = useState<number>(0);

  const userInfo = JSON.parse(localStorage.getItem("userInfo") as string);
  const authority = userInfo?.authority;

  const { data: SData } = useUserStoreInfoApi();
  const storeData = SData?.data.ResultData as NewUserStoreInfoData;
  return (
    <div className="contentBox">
      <PageHeader
        companyName={storeData?.companyName}
        setCurrnetId={setCurrentId}
        tabList={[
          { id: 0, title: "기본정보" },
          { id: 1, title: "배송정보" },
          { id: 2, title: "운영설정" },
        ]}
        status={storeData?.status}
        pageList={["상점관리", "전체 상점", "상점 상세"]}
        title={``}
      />
      {currentId === 0 ? (
        <StoreInfo authority={authority} status={storeData?.status} />
      ) : currentId === 1 ? (
        <DeliveryInfo authority={authority} />
      ) : currentId === 2 ? (
        <OperationSetting authority={authority} />
      ) : (
        ""
      )}
    </div>
  );
};
export default StoreDetail;
