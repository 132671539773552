import React, { useEffect, useState } from "react";

import { ReactComponent as Logo } from "assets/Logo.svg";
import { ReactComponent as Deco } from "assets/loginDecoTextImg.svg";
import { ReactComponent as Test } from "assets/loginTestImg.svg";
import CheckBox from "components/CheckBox/CheckBox";
import ButtonFilledPrimary from "components/Button/ButtonFilledPrimary";
import ButtonLinedTertiary from "components/Button/ButtonLinedTertiary";
import { useNavigate } from "react-router-dom";
import requestToLoveEgg from "utils/requestToLoveEgg";
import TextFieldInput from "components/Input/TextFieldInput";
import { useCookies } from "react-cookie";

const Login = () => {
  const navigate = useNavigate();
  const [id, setId] = useState("");
  const [pw, setPw] = useState("");

  const [cookies, setCookie, removeCookie] = useCookies(["rememberId"]);
  const [isRemember, setIsRemember] = useState(false);

  useEffect(() => {
    if (cookies.rememberId !== undefined) {
      setId(cookies.rememberId);
      setIsRemember(true);
    } else {
      setId("");
      setIsRemember(false);
    }
  }, []);

  const login = () => {
    requestToLoveEgg({
      method: "POST",
      url: "/v1/login",
      data: {
        id: id,
        password: pw,
      },
    }).then((res) => {
      const v = res.data.ResultData;
      if (res.data.ResultCode === 1) {
        if (v.status === "REJECTED") {
          return alert("입점 신청이 거절된 계정입니다. 담당자 정보에 작성한 이메일 보관함을 확인해 주세요. 문의사항이 있는 경우 러브에그로 문의해 주세요:)");
        }
        if (isRemember) {
          const threeMonthsLater = new Date();
          threeMonthsLater.setMonth(threeMonthsLater.getMonth() + 3);
          setCookie("rememberId", id, {
            maxAge: threeMonthsLater.getTime() - Date.now(),
          });
        } else {
          removeCookie("rememberId");
          setIsRemember(false);
        }
        const userInfo = {
          authority: v.authority,
          brandName: v.brandName,
          companyId: v.companyId,
          companyName: v.companyName,
          id: v.id,
          name: v.name,
          status: v.status,
          accessToken: res.headers["access-token"],
          accessTokenExpiresIn: parseInt(
            res.headers["access-token-expires-in"]
          ),
        };
        window.localStorage.setItem("userInfo", JSON.stringify(userInfo));
        if (v.status === "EXAMINATION" && v.authority === "ROLE_USER") {
          navigate("/store/check", { replace: true });
        }
        if (v.status === "WAITING" && v.authority === "ROLE_USER") {
          navigate("/store/setting", { replace: true });
        } else {
          navigate("/store/all", { replace: true });
        }
      } else {
        alert("로그인 실패");
      }
    });
  };

  const onLoginEnter = (e: any) => {
    if (e.key === "Enter") {
      login();
    }
  };
  return (
    <div className="w-full h-full bg-Blue-50">
      <Test className="fixed bottom-0 left-2/4 -translate-x-2/4" />
      <div className="max-w-[464px] px-7 w-full fixed top-2/4 left-2/4 bg-Gray-10 -translate-x-2/4 -translate-y-2/4 h-[537px] rounded-2xl shadow-[0_4px_28px_0px_rgba(0,0,0,0.08)] flex flex-col items-start justify-around">
        <div className="flex items-center mx-auto">
          <Logo width={100} height={91} />
          <div className="ml-4">
            <div className="H1 text-Gray-100">파트너 센터</div>
            <Deco />
          </div>
        </div>
        <div className="flex flex-col gap-[10px] w-full">
          <TextFieldInput
            onKeyPress={onLoginEnter}
            inputValue={id}
            onChange={(e) => setId(e.target.value.replace(/\b/g, ""))}
            errorMsg="5글자 이상의 영문, 숫자 형식으로 입력해주세요."
            className="w-full"
            placeholder="아이디"
          />
          <TextFieldInput
            onKeyPress={onLoginEnter}
            onChange={(e) => setPw(e.target.value)}
            type="password"
            errorMsg="올바르지 않은 비밀번호 입니다."
            className="w-full"
            placeholder="비밀번호"
          />
          <div className="flex items-center justify-between">
            <CheckBox
              checked={isRemember}
              onChange={(e) => {
                setIsRemember(e.target.checked);
              }}
              text="아이디 저장"
            />
            {/* <div className="flex items-center cursor-pointer SubH2 text-Gray-80">
              <div>아이디</div>
              <div className="mx-1">/</div>
              <div>비밀번호 찾기</div>
            </div> */}
          </div>
        </div>
        <div className="w-full">
          <ButtonFilledPrimary
            onClick={() => {
              login();
            }}
            className="w-full mb-4"
            size={"lg"}
            btnText={"로그인"}
          />
          <ButtonLinedTertiary
            onClick={() => {
              navigate("/store/new");
            }}
            className="w-full"
            size={"lg"}
            btnText={"입점 신청하기"}
          />
        </div>
      </div>
    </div>
  );
};

export default Login;
