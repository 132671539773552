import { useDeliveryCompanyListApi } from "api/deliveryCompany";
import ButtonLinedTertiary from "components/Button/ButtonLinedTertiary";
import Dropdown from "components/Dropdown/Dropdown";
import TextFieldInput from "components/Input/TextFieldInput";
import ModalPopup from "components/Modal/ModalPopup";
import React, { useState } from "react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { Content } from "type/order";
import requestToLoveEgg from "utils/requestToLoveEgg";
import Table from "./Table";
import { GENERAL_ORDER_ORDER_CHECK } from "./TableData";

interface Props {
  tableList: Content[];
}

const DeliveryReady = ({ tableList }: Props) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [selectedDelivery, setSelectedDelivery] = useState<{
    cd: string;
    cdNm: string;
  }>({ cd: "", cdNm: "" });
  const [inVoice, setInvoice] = useState(false);
  const [inVoiceNo, setInvoiceNo] = useState("");
  const [orderNo, setOrderNo] = useState("");

  const { data } = useDeliveryCompanyListApi();

  const deliveryList = data?.data.ResultData;

  return (
    <>
      {inVoice && (
        <ModalPopup
          onClickCancelButton={() => {
            setInvoiceNo("");
            setSelectedDelivery({ cd: "", cdNm: "" });
          }}
          onClick={() => {
            requestToLoveEgg({
              method: "POST",
              url: `/api/v1/orders/${orderNo}/invoice-number`,
              data: {
                invoiceNumber: inVoiceNo,
                courierName: selectedDelivery.cdNm,
              },
            }).then((res) => {
              setInvoiceNo("");
              setSelectedDelivery({ cd: "", cdNm: "" });
              if (res.data.ResultCode === 1) {
                queryClient.invalidateQueries({
                  queryKey: ["useGeneralOrderList"],
                });
                setInvoice(false);
              } else {
                alert("에러");
              }
            });
          }}
          type={"md"}
          cancalButton
          setCloseState={setInvoice}
          buttonText={"적용하기"}
        >
          <div className="H4">송장번호 입력</div>
          <div className="my-4 Body4">주문번호: {orderNo}</div>
          <div className="Body2 break-keep">
            송장번호를 입력하면 배송중 단계로 넘어갑니다. 택배사와 송장번호를
            정확하게 입력해 주세요. (구매고객에게는{" "}
            <span className="text-Blue-100">배송중</span>으로 보여집니다.)
          </div>
          <Dropdown
            innerText={selectedDelivery.cdNm ? selectedDelivery.cdNm : "선택"}
            width="w-full"
            className="mt-6"
          >
            {deliveryList?.map((d, i) => {
              return (
                <li
                  key={i}
                  onClick={() => {
                    setSelectedDelivery({ cd: d.cd, cdNm: d.cdNm });
                  }}
                  className="dropdownWrap"
                >
                  <div className="dropdownLi">{d.cdNm}</div>
                </li>
              );
            })}
          </Dropdown>
          <TextFieldInput
            inputValue={inVoiceNo}
            onChange={(e) => {
              const { value } = e.target;
              setInvoiceNo(value.replace(/[^0-9]/g, ""));
            }}
            className="w-full mt-4"
          />
        </ModalPopup>
      )}
      {tableList?.length !== 0 ? (
        <Table COLUMNS={GENERAL_ORDER_ORDER_CHECK}>
          {tableList?.map((item, i) => {
            return (
              <tr
                key={item.orderNo}
                className="last:border-none border-y Body4 border-Gray-40"
              >
                <td
                  onClick={() => {
                    navigate(`/order/${item.orderNo}`);
                  }}
                  className="cursor-pointer tableTd text-Blue-100 min-w-[120px]"
                >
                  {item.orderNo}
                </td>

                <td className="tableTd min-w-[100px]">{item.regDt}</td>
                <td className="tableTd min-w-[90px] textEll max-w-[100px]">
                  {item.buyerName}
                </td>
                <td className="tableTdmin-w-[120px] ">{item.companyName}</td>
                <td className="min-w-[200px]">
                  {item.orderItemList.map((k, i) => (
                    <div key={i} className="flex items-center tableTd">
                      <img
                        className="w-10 h-10 mr-2 rounded"
                        src={k.itemImagePath}
                        alt=""
                      />
                      <div className="max-w-[180px] textEll">{k.itemTitle}</div>
                    </div>
                  ))}
                </td>
                <td className="max-w-[160px]">
                  {item.orderItemList.map((k, i) => (
                    <div key={i} className="flex items-center h-[72px] tableTd">
                      {k.optionStr ? k.optionStr : "-"}
                    </div>
                  ))}
                </td>
                <td className="min-w-[100px]">
                  {item.orderItemList.map((k, i) => (
                    <div key={i} className="flex items-center h-[72px] tableTd">
                      {k.count}
                    </div>
                  ))}
                </td>
                <td className="tableTd min-w-[100px]">
                  {item.deliveryStartDate ? item.deliveryStartDate : "-"}
                </td>
                <td className="tableTd min-w-[120px]">{item.courierName}</td>
                <td className="tableTd min-w-[120px]">
                  <ButtonLinedTertiary
                    onClick={() => {
                      deliveryList?.map((d) => {
                        if (d.cdNm === item.courierName) {
                          setSelectedDelivery({ cd: d.cd, cdNm: d.cdNm });
                        }
                      });

                      setInvoice(true);
                      setOrderNo(item.orderNo);
                    }}
                    size={"sm"}
                    btnText={"송장번호 입력"}
                  />
                </td>
                <td className="tableTd min-w-[50px]">{item.receiverName}</td>
                <td className="tableTd min-w-[50px]">{item.receiverAddr}</td>
              </tr>
            );
          })}
        </Table>
      ) : (
        <div className="h-[500px] flex flex-col items-center justify-center Body4">
          배송 준비 중인 주문이 없어요
        </div>
      )}
    </>
  );
};

export default DeliveryReady;
