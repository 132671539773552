import React, { useEffect, useRef, useState } from "react";

import ButtonFilledSecondary from "components/Button/ButtonFilledSecondary";
import ButtonLinedTertiary from "components/Button/ButtonLinedTertiary";
import Dropdown from "components/Dropdown/Dropdown";
import PageHeader from "components/Header/PageHeader";
import SearchInput from "components/Input/SearchInput";
import AdminDeliveryComplete from "components/Table/AdminDeliveryComplete";
import AdminDeliveryInProgress from "components/Table/AdminDeliveryInProgress";
import AdminDeliveryReady from "components/Table/AdminDeliveryReady";
import AdminOrderCheck from "components/Table/AdminOrderCheck";
import { Content, ExcelDownForAdmin } from "type/order";
import DatePicker from "react-datepicker";
import { dateFormat } from "utils/dateFormat";
import { ReactComponent as Refresh } from "assets/refresh.svg";
import { ReactComponent as Download } from "assets/download.svg";
import { ReactComponent as Close } from "assets/close.svg";
import ModalPopup from "components/Modal/ModalPopup";
import PopupSelect from "components/Modal/PopupSelect";
import ButtonFilledTertiary from "components/Button/ButtonFilledTertiary";
import useDebounce from "hook/useDebounce";
import requestToLoveEgg from "utils/requestToLoveEgg";
import { useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { useGeneralOrderListForAdmin } from "api/order/Admin";
import Pagination from "components/Pagination/Pagination";
import { useStoreList } from "api/store";

const GeneralOrder = () => {
  const queryClient = useQueryClient();
  const uploadRef = useRef<HTMLInputElement>(null);

  const [currentPage, setCurrentPage] = useState(1);
  let pageItemLength = 10;
  const [storeInfo, setStoreInfo] = useState<{
    storeName: string;
    storeId: number;
  }>({
    storeName: "",
    storeId: 0,
  });
  const [excelFile, setExcelFile] = useState<File | null>(null);
  const [selectedTr, setSelectedTr] = useState<string[]>([]);
  const [startDate, setStartDate] = useState<null | Date>(null);
  const [endDate, setEndDate] = useState<null | Date>(null);
  const [filter, setFilter] = useState<
    {
      name: string;
      type: "ORDER" | "ORDER_CONFIRM" | "DELIVERY_START" | "DELIVERY_COMPLETE";
    }[]
  >([{ name: "주문일", type: "ORDER" }]);

  const [selectedFilter, setSelectedFilter] = useState<{
    name: string;
    type: "ORDER" | "ORDER_CONFIRM" | "DELIVERY_START" | "DELIVERY_COMPLETE";
  }>({ name: "주문일", type: "ORDER" });

  const [selectedStatus, setSelectedStatus] = useState<{
    name: string;
    type:
      | "ALL"
      | "ORDER_CHECK"
      | "DELIVERY_READY"
      | "DELIVERY_IN_PROGRESS"
      | "DELIVERY_COMPLETE";
  }>({ name: "상품 준비중", type: "ORDER_CHECK" });

  const [selectedDate, setSelectedDate] = useState<{
    startDate: Date | string;
    endDate: Date | string;
  }>({ startDate: "", endDate: "" });

  const [search, setSearch] = useState<string>("");

  const [excelDownModal, setExcelDownModal] = useState(false);
  const [orderCheck, setOrderCheck] = useState(false);
  const [uploadInvoice, setUploadInvoice] = useState(false);
  const [invoiceType, setInvoiceType] = useState("");

  const debouncedSearchText = useDebounce(search, 500);

  const { data, refetch } = useGeneralOrderListForAdmin({
    companyId: storeInfo.storeId,
    status: selectedStatus.type,
    dateType: selectedFilter.type,
    startDate: selectedDate.startDate,
    endDate: selectedDate.endDate,
    keyword: debouncedSearchText,
    page: currentPage - 1,
    size: pageItemLength,
  });

  const list = data?.data.ResultData.content as Content[];

  const lengthData = data?.data.ResultData;

  const orderStatus: {
    id: number;
    name: string;
    length: number;
    filterList: {
      name: string;
      type: "ORDER" | "ORDER_CONFIRM" | "DELIVERY_START" | "DELIVERY_COMPLETE";
    }[];
    type:
      | "ALL"
      | "ORDER_CHECK"
      | "DELIVERY_READY"
      | "DELIVERY_IN_PROGRESS"
      | "DELIVERY_COMPLETE";
    rightBorder: boolean;
  }[] = [
    {
      id: 0,
      name: "상품 준비중",
      length: lengthData?.productReadyCount as number,
      filterList: [{ name: "주문일", type: "ORDER" }],
      type: "ORDER_CHECK",
      rightBorder: true,
    },
    {
      id: 1,
      name: "배송 준비중",
      length: lengthData?.deliveryReadyCount as number,
      filterList: [
        { name: "주문일", type: "ORDER" },
        { name: "출고처리일", type: "ORDER_CONFIRM" },
      ],
      type: "DELIVERY_READY",
      rightBorder: true,
    },
    {
      id: 2,
      name: "배송중",
      length: lengthData?.deliveryInProgressCount as number,
      filterList: [
        { name: "주문일", type: "ORDER" },
        { name: "출고처리일", type: "ORDER_CONFIRM" },
        { name: "배송시작일", type: "DELIVERY_START" },
      ],
      type: "DELIVERY_IN_PROGRESS",
      rightBorder: true,
    },
    {
      id: 3,
      name: "배송 완료 (지난 30일)",
      length: lengthData?.deliveryCompleteCount as number,
      filterList: [
        { name: "주문일", type: "ORDER" },
        { name: "출고처리일", type: "ORDER_CONFIRM" },
        { name: "배송시작일", type: "DELIVERY_START" },
        { name: "배송완료일", type: "DELIVERY_COMPLETE" },
      ],
      type: "DELIVERY_COMPLETE",
      rightBorder: false,
    },
  ];

  const handleExcelDown = ({
    companyId,
    status,
    keyword,
    dateType,
    startDate,
    endDate,
  }: ExcelDownForAdmin) => {
    requestToLoveEgg({
      method: "GET",
      url: `/api/admin/v1/orders/download`,
      responseType: "blob",
      headers: { responseType: "arraybuffer" },
      params: {
        companyId: companyId,
        status: status,
        search: keyword,
        dateType: dateType,
        startDate: startDate,
        endDate: endDate,
      },
    }).then((res) => {
      setExcelDownModal(false);
      toast.success("다운로드 완료했습니다 :)");
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      const contentDisposition = res.headers["content-disposition"];
      let fileName = "unknown";
      if (contentDisposition) {
        const [fileNameMatch] = contentDisposition
          .split(";")
          .filter((str: string) => str.includes("filename"));
        if (fileNameMatch) [, fileName] = fileNameMatch.split("=");
        fileName = decodeURI(fileName);
        link.href = url;
        link.setAttribute("download", `${fileName}`);
        link.style.cssText = "display:none";
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    });
  };

  const handleExceluUpload = () => {
    const formData = new FormData();

    formData.append("file", excelFile as File);

    requestToLoveEgg({
      method: "POST",
      url: `/api/admin/v1/orders/invoice-number/update`,
      data: formData,
      headers: {
        "Content-Type": `multipart/form-data`,
      },
    }).then((res) => {
      if (res.data.ResultCode === 1) {
        queryClient.invalidateQueries({
          queryKey: ["useGeneralOrderListForAdmin"],
        });
      }
    });
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [
    selectedStatus.type,
    selectedFilter.type,
    selectedDate.startDate,
    selectedDate.endDate,
    debouncedSearchText,
  ]);

  const { data: storeList } = useStoreList();

  const newStoreList = [
    { brandName: "전체", commerceName: "전체", companyId: 0 },
    ...(storeList?.data?.ResultData !== undefined
      ? storeList?.data?.ResultData
      : []),
  ];

  return (
    <div className="contentBox">
      {excelDownModal && (
        <ModalPopup
          onClick={() => {
            handleExcelDown({
              status: selectedStatus.type,
              dateType: selectedFilter.type,
              startDate: selectedDate.startDate,
              endDate: selectedDate.endDate,
              keyword: debouncedSearchText,
            });
          }}
          type={"md"}
          cancalButton
          setCloseState={setExcelDownModal}
          buttonText={"다운로드"}
        >
          <div className="H4">엑셀 다운로드</div>
          <div className="mt-4 Body2 text-Gray-80 break-keep">
            <span className="text-Red100">
              개인정보가 포함된 주문정보 다운로드
            </span>
            는 업무 처리를 위해 불가피한 경우에 한하여 이용해야하며, 이용 목적
            달성 시 반드시 <span className="text-Red100">파기</span>해야합니다.
          </div>
        </ModalPopup>
      )}
      {orderCheck && (
        <ModalPopup
          onClick={() => {
            requestToLoveEgg({
              method: "POST",
              url: "/api/admin/v1/orders/check",
              data: {
                orderNoList: selectedTr,
              },
            }).then((res) => {
              if (res.data.ResultCode === 1) {
                refetch();
                setOrderCheck(false);
                setSelectedTr([]);
                toast.success(
                  "‘배송 준비중'으로 상태 변경했습니다.\n[배송 준비중]에서 확인 가능합니다."
                );
              } else {
                alert("에러");
              }
            });
          }}
          setCloseState={setOrderCheck}
          type={"md"}
          buttonText={"발주 확인"}
          cancalButton
        >
          <div className="H4">
            선택한 {selectedTr.length}건의 주문을 [발주 확인] 처리할까요?
          </div>
          <div className="mt-4 break-keep Body2">
            발주 확인 후에는 배송준비중 단계로 넘어갑니다. (구매고객에게{" "}
            <span className="text-Blue-100">배송준비중</span>으로 보여집니다.)
          </div>
        </ModalPopup>
      )}
      {uploadInvoice && (
        <PopupSelect
          disabled={excelFile === null}
          onClickCancelButton={() => {
            setExcelFile(null);
          }}
          buttonText="적용하기"
          onClick={() => {
            handleExceluUpload();
          }}
          title={`송장번호 일괄 ${invoiceType}`}
          setCloseState={setUploadInvoice}
        >
          <div className="mb-10">
            {excelFile?.name ? (
              <div className="flex items-center justify-between w-full px-4 py-2 border rounded Body4 text-Gray-90 bg-Gray-20 border-Gray-40">
                <div>{excelFile.name}</div>
                <Close
                  onClick={() => {
                    setExcelFile(null);
                  }}
                />
              </div>
            ) : (
              <>
                <ButtonFilledTertiary
                  onClick={() => {
                    uploadRef.current?.click();
                  }}
                  size={"md"}
                  btnText={"엑셀 파일 업로드"}
                ></ButtonFilledTertiary>
                <input
                  ref={uploadRef}
                  onChange={(e) => {
                    if (e.target.files) {
                      setExcelFile(e.target.files[0] as File);
                    }
                  }}
                  type="file"
                  accept=".xlsx"
                  className="hidden"
                ></input>
              </>
            )}
          </div>
          <ul className="flex flex-col gap-1 ml-4 list-disc Body4">
            <li>
              <span className="text-Blue-100">
                일괄 업로드 시에는 택배사 변경 불가합니다.
              </span>{" "}
              택배사 변경은 개별 주문번호에 있는 ‘송장번호 입력’을 이용해
              주세요.
            </li>
            <li>
              송장번호를 등록하려는 주문을 '엑셀다운로드' 기능을 통해 다운받아
              택배사, 송장번호, 배송시작일을 입력하신 후 다시 업로드 하시면 일괄
              등록됩니다.
            </li>
            <li>
              업로드 하실 엑셀파일에는 상품주문번호, 배송시작일, 택배사,
              송장번호 네 개 열이 반드시 있어야 합니다. 네 개 필수값만 있으면
              정상 처리 진행되며, 다른 열의 항목은 수정해도 등록되지 않습니다.
            </li>
            <li>
              파일업로드 후 반드시 '적용하기' 버튼을 통해 일괄등록을 완료해
              주세요.
            </li>
            <li>
              배송시작일은 엑셀을 업로드 하는 당일 날짜를 입력해주세요. 입력시
              하이픈(-)이나 공백 없이 입력해야 합니다.(예:20160125)
            </li>
            <li>
              엑셀 다운로드를 통해 받으신 파일의 형식과 동일한 확장자(xls)
              파일이어야 하며, 시트명은 수정하지 마시고, 파일명은 영문으로
              등록해주세요
            </li>
          </ul>
        </PopupSelect>
      )}
      <PageHeader
        noBorderB
        pageList={["주문 관리", "일반 주문"]}
        title={`일반 주문`}
      />
      <div className="flex items-center h-20 border border-Gray-40 rounded-2xl min-w-[1020px]">
        {orderStatus.map((item) => {
          return (
            <div
              className={`flex items-center justify-center w-full ${
                item.rightBorder && "border-r-2 border-Gray-40"
              }`}
              key={item.id}
            >
              <div
                onClick={() => {
                  setCurrentPage(1);
                  setSelectedTr([]);
                  setSelectedFilter({ name: "주문일", type: "ORDER" });
                  setSelectedStatus({ name: item.name, type: item.type });
                  setFilter(item.filterList);
                }}
                className={`flex flex-col text-center cursor-pointer`}
              >
                <div
                  className={`Body4 ${
                    selectedStatus.name === item.name &&
                    selectedStatus.type === item.type
                      ? "text-Blue-100"
                      : "text-Gray-80"
                  }`}
                >
                  {item.name}
                </div>
                <div
                  className={`H2 hover:underline ${
                    selectedStatus.name === item.name &&
                    selectedStatus.type === item.type
                      ? "text-Blue-100 underline"
                      : "text-Gray-100"
                  }`}
                >
                  {item.length}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="flex flex-col gap-5 pb-10 border-b border-Gray-40 min-w-[1020px] mt-10">
        <div className="flex items-center">
          <div className="py-2.5 min-w-[132px] text-Gray-90 SubH2 mr-6">
            상점
          </div>
          <Dropdown
            width="w-[311px]"
            innerText={
              storeInfo.storeName !== "" ? storeInfo.storeName : "전체"
            }
          >
            {newStoreList?.map((s, i) => {
              return (
                <li
                  key={i}
                  onClick={() => {
                    setCurrentPage(1);
                    setStoreInfo({
                      storeId: s.companyId,
                      storeName: s.commerceName,
                    });
                  }}
                  className="dropdownWrap"
                >
                  <div className="dropdownLi">{s.commerceName}</div>
                </li>
              );
            })}
          </Dropdown>
        </div>
        <div className="flex items-center">
          <div className="py-2.5 min-w-[132px] text-Gray-90 SubH2 mr-6">
            조회기간
          </div>
          <div className="flex items-center gap-2">
            <Dropdown
              innerText={selectedFilter.name ? selectedFilter.name : ""}
              width="w-[177px]"
            >
              {filter.map((k, i) => {
                return (
                  <li
                    onClick={() => {
                      setCurrentPage(1);
                      setSelectedFilter({ name: k.name, type: k.type });
                      refetch();
                    }}
                    key={i}
                    className="dropdownWrap"
                  >
                    <div className="dropdownLi">{k.name}</div>
                  </li>
                );
              })}
            </Dropdown>
            <div className="flex items-center">
              <DatePicker
                placeholderText="시작일"
                className="flex items-center px-3 py-2 h-[52px] border border-Gray-40 Body4 text-Gray-90 rounded-lg"
                selected={startDate}
                onChange={(date: Date) => {
                  setStartDate(date);
                  setEndDate(null);
                  setSelectedDate({
                    ...selectedDate,
                    startDate: dateFormat(date).replaceAll(".", ""),
                    endDate: "",
                  });
                }}
                selectsStart
                dateFormat="yyyy.MM.dd"
                startDate={startDate}
                endDate={endDate}
              />
              <div className="px-2">~</div>
              <DatePicker
                placeholderText="종료일"
                disabled={!startDate}
                className="flex items-center px-3 py-2 h-[52px] border border-Gray-40 Body4 text-Gray-90 rounded-lg"
                selected={endDate}
                onChange={(date: Date) => {
                  setCurrentPage(1);
                  setEndDate(date);
                  setSelectedDate({
                    ...selectedDate,
                    endDate: dateFormat(date).replaceAll(".", ""),
                  });
                }}
                selectsEnd
                dateFormat="yyyy.MM.dd"
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
              />
              {startDate && endDate && (
                <div
                  className="flex items-center ml-4 cursor-pointer SubH2"
                  onClick={() => {
                    setCurrentPage(1)
                    setSelectedDate({ startDate: "", endDate: "" });
                    setStartDate(null);
                    setEndDate(null);
                    setSelectedFilter({ name: "주문일", type: "ORDER" });
                  }}
                >
                  <Refresh className="mr-2" />
                  <div>초기화</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between py-4 min-w-[1020px]">
        <div className="flex items-center gap-4">
          <ButtonLinedTertiary
            onClick={() => {
              setExcelDownModal(true);
            }}
            icon={<Download />}
            className="h-[38px]"
            size={"sm"}
            btnText={"엑셀 다운로드"}
          />
          {selectedStatus.type === "ORDER_CHECK" ? (
            <ButtonFilledSecondary
              onClick={() => {
                setOrderCheck(true);
              }}
              className="h-[38px]"
              disabled={selectedTr.length === 0}
              size={"md"}
              btnText={"발주 확인"}
            />
          ) : selectedStatus.type === "DELIVERY_READY" ? (
            <ButtonFilledSecondary
              onClick={() => {
                setUploadInvoice(true);
                setInvoiceType("업로드");
              }}
              className="h-[38px]"
              size={"md"}
              btnText={"송장번호 일괄 업로드"}
            />
          ) : selectedStatus.type === "DELIVERY_IN_PROGRESS" ? (
            <ButtonFilledSecondary
              onClick={() => {
                setUploadInvoice(true);
                setInvoiceType("수정");
              }}
              className="h-[38px]"
              size={"md"}
              btnText={"송장번호 일괄 수정"}
            />
          ) : (
            ""
          )}
        </div>
        <SearchInput
          inputValue={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          placeholder="주문번호/상품명/송장번호/구매자명 등으로 검색"
          className="w-[350px] h-12"
        />
      </div>
      {selectedStatus.type === "ORDER_CHECK" ? (
        <AdminOrderCheck
          selectedList={selectedTr}
          selectedTrList={setSelectedTr}
          tableList={list}
        />
      ) : selectedStatus.type === "DELIVERY_READY" ? (
        <AdminDeliveryReady tableList={list} />
      ) : selectedStatus.type === "DELIVERY_IN_PROGRESS" ? (
        <AdminDeliveryInProgress tableList={list} />
      ) : selectedStatus.type === "DELIVERY_COMPLETE" ? (
        <AdminDeliveryComplete tableList={list} />
      ) : (
        ""
      )}
      <Pagination
        className="mt-4"
        itemsCount={lengthData?.totalElements!}
        pageSize={pageItemLength}
        currPage={currentPage}
        setList={setCurrentPage}
      />
    </div>
  );
};

export default GeneralOrder;
