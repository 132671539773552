import React from 'react';

interface TextAreaProps {
    placeholder?: string;
    className?: string;
    inputValue?: string;
    maxLength?: number;
    currentLength?: number;
    height?: string;
    disabled?: boolean;
    row?: number;
    onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const TextLimitHorizontalInput = ({ inputValue, height, className, row, placeholder, maxLength, onChange, currentLength, disabled }: TextAreaProps) => {
    return (
        <div className={`${disabled ? "bg-Gray-20" : "bg-Gray-10"} border border-Gray-40 flex items-end rounded-lg flex-col pt-2.5 px-3 pb-2.5 ${className ? className : "w-full"}`}>
            <textarea rows={row} disabled={disabled} value={inputValue} className={`Body3 border-none w-full resize-none mb-5`} onChange={onChange} maxLength={maxLength} placeholder={placeholder}/>
                {maxLength &&
                <div className='flex items-center Body4'>
                    <div className='text-Gray-80'>{currentLength}</div>
                    <div className='mx-[2px] text-Gray-60'>/</div>
                    <div className='text-Gray-60'>{maxLength}</div>
                </div>
            }
        </div>
    );
};

export default TextLimitHorizontalInput;