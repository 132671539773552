import { AxiosResponse } from "axios";
import { UseQueryResult, useQuery } from "react-query";
import { StoreList, StoreProductDetail, StoreProductList } from "type/product";
import {
  AllStoreList,
  CategoryListApi,
  DeliveryData,
  NewUserStoreInfo,
  OperationData,
  StoreChangeHistory,
  StoreEntryList,
} from "type/store";
import requestToLoveEgg from "utils/requestToLoveEgg";

const getCategoryListApi = () => {
  // 카테고리 목록 조회
  return requestToLoveEgg({
    method: "GET",
    url: "/v1/categories",
  });
};

export const useCategoryListApi = (): UseQueryResult<
  AxiosResponse<CategoryListApi>,
  Error
> =>
  useQuery(["useTabListQuery"], () => getCategoryListApi(), {
    // retry: 3,
    keepPreviousData: true,
  });

const getUserStoreInfoApi = (companyId?: string) => {
  // 입점 신청 상세 조회
  return requestToLoveEgg({
    method: "GET",
    url: "api/v1/join-apply/detail",
    params: { companyId: companyId },
  });
};

export const useUserStoreInfoApi = (
  companyId?: string
): UseQueryResult<AxiosResponse<NewUserStoreInfo>, Error> =>
  useQuery(["useUserStoreInfoApi"], () => getUserStoreInfoApi(companyId), {
    // retry: 3,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

const getUserDeliveryInfoApi = (companyId?: string) => {
  // 상점 배송 정보 조회
  return requestToLoveEgg({
    method: "GET",
    url: `api/v1/join-apply/detail/delivery`,
    params: { companyId: companyId },
  });
};

export const useUserDeliveryInfoApi = (
  companyId?: string
): UseQueryResult<AxiosResponse<DeliveryData>, Error> =>
  useQuery(
    ["useUserDeliveryInfoApi"],
    () => getUserDeliveryInfoApi(companyId),
    {
      // retry: 3,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

const getUserOperationInfoApi = (companyId?: string) => {
  // 상점 운영 설정 정보 조회
  return requestToLoveEgg({
    method: "GET",
    url: "api/v1/join-apply/detail/operation",
    params: { companyId: companyId },
  });
};

export const useUserOperationInfoApi = (
  companyId?: string
): UseQueryResult<AxiosResponse<OperationData>, Error> =>
  useQuery(
    ["useUserOperationInfoApi"],
    () => getUserOperationInfoApi(companyId),
    {
      // retry: 3,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

const getAllStoreListApi = async ({
  status,
  category,
  page,
  size,
}: {
  status?: string;
  category?: number;
  page?: number;
  size?: number;
}) => {
  // 상점 운영 설정 정보 조회
  const API = await requestToLoveEgg({
    method: "GET",
    url: "/api/admin/v1/commerce",
    params: {
      status: status,
      category: category,
      page: page,
      size: size,
    },
  });
  return await API;
};

export const useAllStoreListApi = (params: {
  status?: string;
  category?: number;
  page?: number;
  size?: number;
}): UseQueryResult<AxiosResponse<AllStoreList>, Error> =>
  useQuery(["useAllStoreListAPi", params], () => getAllStoreListApi(params), {
    // retry: 3,
    keepPreviousData: true,
  });

const getStoreChangeHistoryApi = async ({
  startDate,
  endDate,
  page,
  size,
}: {
  startDate?: string | Date;
  endDate?: string | Date;
  page?: number;
  size?: number;
}) => {
  // 상점 정보 변경 이력 조회
  const API = await requestToLoveEgg({
    method: "GET",
    url: "/api/admin/v1/commerce/log",
    params: {
      startDate: startDate,
      endDate: endDate,
      page: page,
      size: size,
    },
  });
  return await API;
};

export const useStoreChangeHistoryApi = (params: {
  startDate?: string | Date;
  endDate?: string | Date;
  page?: number;
  size?: number;
}): UseQueryResult<AxiosResponse<StoreChangeHistory>, Error> =>
  useQuery(
    ["useStoreChangeHistoryApi", params],
    () => getStoreChangeHistoryApi(params),
    {
      // retry: 3,
      keepPreviousData: true,
    }
  );

const getStoreEntryListApi = async () => {
  // 입점 신청 목록 조회
  const API = await requestToLoveEgg({
    method: "GET",
    url: "/api/admin/v1/join-apply",
  });
  return await API;
};

export const useStoreEntryListApi = (): UseQueryResult<
  AxiosResponse<StoreEntryList>,
  Error
> =>
  useQuery(["useStoreEntryListApi"], () => getStoreEntryListApi(), {
    // retry: 3,
    keepPreviousData: true,
  });

const getStoreProductList = async ({
  page,
  size,
  categoryId,
  status,
}: {
  page: number;
  size: number;
  categoryId: number;
  status:
    | string
    | "ON_SALE"
    | "SUSPENSION_OF_SALE"
    | "OUT_OF_STOCK"
    | "END_OF_SALE";
}) => {
  // 상품 목록 조회
  const API = await requestToLoveEgg({
    method: "GET",
    url: "/api/v1/items",
    params: {
      categoryId: categoryId,
      status: status,
      page: page,
      size: size,
    },
  });
  return await API;
};

export const useStoreProductList = (params: {
  page: number;
  size: number;
  categoryId: number;
  status:
    | string
    | "ON_SALE"
    | "SUSPENSION_OF_SALE"
    | "OUT_OF_STOCK"
    | "END_OF_SALE";
}): UseQueryResult<AxiosResponse<StoreProductList>, Error> =>
  useQuery(["useStoreProductList", params], () => getStoreProductList(params), {
    // retry: 3,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

const getStoreProductDetail = async (itemId: string, companyId?: number) => {
  // 상품 상세 조회
  const API = await requestToLoveEgg({
    method: "GET",
    url: `/api/v1/items/${itemId}`,
    params: {
      companyId: companyId,
    },
  });
  return await API;
};

export const useStoreProductDetail = (
  itemId: string,
  companyId?: number
): UseQueryResult<AxiosResponse<StoreProductDetail>, Error> =>
  useQuery(
    ["useStoreProductDetail"],
    () => getStoreProductDetail(itemId, companyId),
    {
      // retry: 3,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
const getStoreList = async (search?: string) => {
  // 상점명 검색
  const API = await requestToLoveEgg({
    method: "GET",
    url: `/api/admin/v1/commerce/company-search`,
    params: search,
  });
  return await API;
};

export const useStoreList = (
  search?: string
): UseQueryResult<AxiosResponse<StoreList>, Error> =>
  useQuery(["useStoreList"], () => getStoreList(search), {
    // retry: 3,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

const getStoreProductListForAdmin = async ({
  page,
  size,
  companyId,
  categoryId,
  status,
}: {
  page: number;
  size: number;
  companyId?: number;
  categoryId: number;
  status:
    | string
    | "ON_SALE"
    | "SUSPENSION_OF_SALE"
    | "OUT_OF_STOCK"
    | "END_OF_SALE";
}) => {
  // 상품 목록 조회
  const API = await requestToLoveEgg({
    method: "GET",
    url: "/api/admin/v1/items",
    params: {
      companyId: companyId,
      categoryId: categoryId,
      page: page,
      size: size,
      status: status,
    },
  });
  return await API;
};

export const useStoreProductListForAdmin = (params: {
  page: number;
  size: number;
  companyId: number;
  categoryId: number;
  status:
    | string
    | "ON_SALE"
    | "SUSPENSION_OF_SALE"
    | "OUT_OF_STOCK"
    | "END_OF_SALE";
}): UseQueryResult<AxiosResponse<StoreProductList>, Error> =>
  useQuery(
    ["useStoreProductListForAdmin", params],
    () => getStoreProductListForAdmin(params),
    {
      // retry: 3,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

const getCopyItem = ({
  companyId,
  itemId,
}: {
  companyId?: number;
  itemId: string;
}) => {
  return requestToLoveEgg({
    method: "POST",
    url: `/api/v1/items/${itemId}/copy`,
    params: {
      companyId: companyId,
    },
  });
};

export const useCopyItem = (params: {
  companyId?: number;
  itemId: string;
}): UseQueryResult<AxiosResponse<any>, Error> =>
  useQuery(["useCopyItem", params], () => getCopyItem(params), {
    refetchOnWindowFocus: false,
    enabled: false,
  });
