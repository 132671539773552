'use client'
import React, { ReactComponentElement } from "react";

interface LinedTertiaryBtnProps {
    size: "sm" | "md" | "lg";
    icon?: ReactComponentElement<any>;
    btnText: string;
    disabled?: boolean;
    className?: string;
    onClick?: () => void;
}

const ButtonLinedTertiary = ({
    size,
    icon,
    btnText,
    disabled,
    className,
    onClick,
}: LinedTertiaryBtnProps) => {
    return (
        <button
            onClick={onClick}
            disabled={disabled}
            className={`rounded-lg bg-Gray-10 px-3 flex border border-Gray-40 items-center justify-center cursor-pointer hover:bg-Gray-20 hover:border-Gray-80 text-Gray-100 disabled:bg-Gray-40 disabled:border-none disabled:text-Gray-60 disabled:cursor-default
            ${size === "lg"
                    ? "min-h-[48px] h-14 min-w-[160px] SubH1"
                    : size === "md"
                        ? "min-h-[38px] max-h-[52px] SubH2"
                        : size === "sm" ? "Body4 h-8  !px-2" : ""
                } ${className}
                `}
        >
            {icon && icon}
            <div className={`${icon && "ml-2"}`}>{btnText}</div>
        </button>
    );
};

export default ButtonLinedTertiary;