import React, { useState } from "react";

import PageHeader from "components/Header/PageHeader";
import DeliveryInfo from "components/StoreSetting/DeliveryInfo";
import OperationSetting from "components/StoreSetting/OperationSetting";
import StoreInfo from "components/StoreSetting/StoreInfo";
import { useUserStoreInfoApi } from "api/store";
import { NewUserStoreInfoData } from "type/store";

const SettingStore = () => {
  const [currentId, setCurrnetId] = useState(0);

  const userInfo = JSON.parse(localStorage.getItem("userInfo") as string);
  const authority = userInfo?.authority;

  const { data: SData } = useUserStoreInfoApi();
  const storeData = SData?.data.ResultData as NewUserStoreInfoData;

  return (
    <div className="contentBox">
      <PageHeader
        status={storeData?.status}
        setCurrnetId={setCurrnetId}
        tabList={[
          { id: 0, title: "기본정보" },
          { id: 1, title: "배송정보" },
          { id: 2, title: "운영설정" },
        ]}
        pageList={["상점 관리", "상점 설정"]}
        title={`상점 설정`}
      />
      {currentId === 0 ? (
        <StoreInfo authority={authority} status={storeData?.status} />
      ) : currentId === 1 ? (
        <DeliveryInfo />
      ) : currentId === 2 ? (
        <OperationSetting authority={authority} />
      ) : (
        ""
      )}
    </div>
  );
};

export default SettingStore;
