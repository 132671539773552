import React, { useState } from "react";
import Dropdown from "components/Dropdown/Dropdown";
import PageHeader from "components/Header/PageHeader";
import SearchInput from "components/Input/SearchInput";
import Table from "components/Table/Table";
import { GIFTORDER } from "components/Table/TableData";
import useDebounce from "hook/useDebounce";
import DatePicker from "react-datepicker";
import { ReactComponent as Refresh } from "assets/refresh.svg";
import { useNavigate } from "react-router-dom";
import { dateFormat } from "utils/dateFormat";
import { useGiftOrderList } from "api/order/User";
import Pagination from "components/Pagination/Pagination";

const GiftOrder = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  let pageItemLength = 10;
  const [startDate, setStartDate] = useState<null | Date>(null);
  const [endDate, setEndDate] = useState<null | Date>(null);

  const [search, setSearch] = useState<string>("");
  const [selectedStatus, setSelectedStatus] = useState<{
    name: string;
    type: "" | "DELIVERY_INPUT_READY" | "EXPIRATION" | "REFUSAL" | "CANCEL";
  }>({
    type: "",
    name: "전체",
  });

  const [selectedDateType, setSelectedDateType] = useState<{
    name: string;
    type: "ORDER" | "EXPIRATION";
  }>({
    type: "ORDER",
    name: "주문일",
  });
  const [selectedDate, setSelectedDate] = useState<{
    startDate: string;
    endDate: string;
  }>({ startDate: "", endDate: "" });

  const status: {
    id: number;
    name: string;
    type: "" | "DELIVERY_INPUT_READY" | "EXPIRATION" | "REFUSAL" | "CANCEL";
  }[] = [
    { id: 0, type: "", name: "전체" },
    { id: 1, type: "DELIVERY_INPUT_READY", name: "수락대기중" },
    { id: 2, type: "EXPIRATION", name: "기간만료" },
    { id: 3, type: "REFUSAL", name: "선물거절" },
    { id: 4, type: "CANCEL", name: "선물취소" },
  ];

  const dateType: {
    id: number;
    name: string;
    type: "ORDER" | "EXPIRATION";
  }[] = [
    { id: 0, type: "ORDER", name: "주문일" },
    { id: 1, type: "EXPIRATION", name: "선물 만료일" },
  ];
  const debouncedSearchText = useDebounce(search, 500);

  const { data } = useGiftOrderList({
    dateType: selectedDateType.type,
    status: selectedStatus.type,
    startDate: selectedDate.startDate,
    keyword: debouncedSearchText,
    endDate: selectedDate.endDate,
    page: currentPage - 1,
    size: pageItemLength,
  });

  const giftList = data?.data.ResultData.content;

  return (
    <div className="contentBox">
      <PageHeader
        noBorderB
        pageList={["주문 관리", "선물 수락 대기"]}
        title={`선물 대기 목록`}
      />
      <div className="flex flex-col gap-5 pb-10 border-b border-Gray-40 min-w-[1020px]">
        <div className="flex items-center">
          <div className="w-[132px] SubH2 mr-6">선물 상태</div>
          <Dropdown innerText={selectedStatus.name} width="w-[311px]">
            {status.map((item, i) => {
              return (
                <li
                  key={item.id}
                  onClick={() => {
                    setCurrentPage(1);
                    setSelectedStatus({
                      type: item.type,
                      name: item.name,
                    });
                  }}
                  className="dropdownWrap"
                >
                  <div className="dropdownLi">{item.name}</div>
                </li>
              );
            })}
          </Dropdown>
        </div>
        <div className="flex items-center">
          <div className="w-[132px] SubH2 mr-6">조회 기간</div>
          <div className="flex items-center gap-2">
            <Dropdown innerText={selectedDateType.name} width="w-[177px]">
              {dateType.map((item) => {
                return (
                  <li
                    key={item.id}
                    onClick={() => {
                      setCurrentPage(1);
                      setSelectedDateType({
                        type: item.type,
                        name: item.name,
                      });
                    }}
                    className="dropdownWrap"
                  >
                    <div className="dropdownLi">{item.name}</div>
                  </li>
                );
              })}
            </Dropdown>
            <DatePicker
              className="flex items-center px-3 py-2 h-[52px] border border-Gray-40 Body4 text-Gray-90 rounded-lg"
              selected={startDate}
              placeholderText="시작일"
              onChange={(date: Date) => {
                setStartDate(date);
                setEndDate(null);
                setSelectedDate({
                  ...selectedDate,
                  startDate: dateFormat(date).replaceAll(".", ""),
                  endDate: "",
                });
              }}
              selectsStart
              dateFormat="yyyy.MM.dd"
              startDate={startDate}
              endDate={endDate}
            />
            <div className="px-2">~</div>
            <DatePicker
              className="flex items-center px-3 py-2 h-[52px] border border-Gray-40 Body4 text-Gray-90 rounded-lg"
              placeholderText="종료일"
              disabled={!startDate}
              selected={endDate}
              onChange={(date: Date) => {
                setCurrentPage(1);
                setEndDate(date);
                setSelectedDate({
                  ...selectedDate,
                  endDate: dateFormat(date).replaceAll(".", ""),
                });
              }}
              selectsEnd
              dateFormat="yyyy.MM.dd"
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
            />
            {startDate && endDate && (
              <div
                className="flex items-center ml-4 cursor-pointer SubH2"
                onClick={() => {
                  setCurrentPage(1);
                  setSelectedDate({ startDate: "", endDate: "" });
                  setStartDate(null);
                  setEndDate(null);
                }}
              >
                <Refresh className="mr-2" />
                <div>초기화</div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="flex items-end justify-between py-4 min-w-[1020px]">
        <div className="SubH2" />
        <SearchInput
          inputValue={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          placeholder="주문번호/상품명/송장번호/구매자명 등으로 검색"
          className="w-[350px] h-12"
        />
      </div>
      {giftList?.length !== 0 ? (
        <Table COLUMNS={GIFTORDER}>
          {giftList?.map((g, idx) => {
            return (
              <tr key={g.orderNo} className="Body4">
                <td className="tableTd">{g.orderNo}</td>
                <td className="tableTd">{g.regDt}</td>
                <td className="tableTd textEll max-w-[100px]">{g.buyerName}</td>
                <td className="min-w-[200px]">
                  {g.giftItemList.map((item, i) => {
                    return (
                      <div key={i} className="flex items-center tableTd">
                        <img
                          className="w-10 h-10 mr-2 rounded"
                          src={item.itemImagePath}
                          alt=""
                        />
                        <div className="max-w-[180px] textEll">
                          {item.itemTitle}
                        </div>
                      </div>
                    );
                  })}
                </td>
                <td className="max-w-[160px]">
                  {g.giftItemList.map((item, i) => {
                    return (
                      <div
                        key={i}
                        className="flex items-center h-[72px] tableTd"
                      >
                        {item.optionStr ? item.optionStr : "-"}
                      </div>
                    );
                  })}
                </td>
                <td className="min-w-[100px]">
                  {g.giftItemList.map((item, i) => (
                    <div key={i} className="flex items-center h-[72px] tableTd">
                      {item.count}
                    </div>
                  ))}
                </td>
                <td
                  className={`tableTd ${
                    g.status !== "DELIVERY_INPUT_READY"
                      ? "text-Red100"
                      : "text-Gray-90"
                  }`}
                >
                  {g.status === "DELIVERY_INPUT_READY"
                    ? "수락대기중"
                    : g.status === "EXPIRATION"
                    ? "기간만료"
                    : g.status === "REFUSAL"
                    ? "선물거절"
                    : g.status === "CANCEL"
                    ? "선물취소"
                    : ""}
                </td>
                <td className="tableTd">{g.expiryDate}</td>
                <td className="tableTd textEll max-w-[100px]">{g.receiverName}</td>
                <td className="tableTd">{g.receiverMobileNo}</td>
              </tr>
            );
          })}
        </Table>
      ) : (
        <div className="h-[500px] flex flex-col items-center justify-center Body4">
          대기 중인 선물이 없어요
        </div>
      )}
      <Pagination
        className="mt-4"
        itemsCount={data?.data.ResultData?.totalElements!}
        pageSize={pageItemLength}
        currPage={currentPage}
        setList={setCurrentPage}
      />
    </div>
  );
};

export default GiftOrder;
