import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { ReactComponent as Refresh } from "assets/refresh.svg";
import { useExchangeList } from "api/order/User";
import Dropdown from "components/Dropdown/Dropdown";
import PageHeader from "components/Header/PageHeader";
import Table from "components/Table/Table";
import { EXCHANGEORDER } from "components/Table/TableData";
import useDebounce from "hook/useDebounce";
import { dateFormat } from "utils/dateFormat";
import { useNavigate } from "react-router-dom";
import Pagination from "components/Pagination/Pagination";

const ExchangeOrder = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  let pageItemLength = 10;
  const [selectedTr, setSelectedTr] = useState<string[]>([]);
  const [startDate, setStartDate] = useState<null | Date>(null);
  const [endDate, setEndDate] = useState<null | Date>(null);
  const [search, setSearch] = useState<string>("");

  const [selectedDate, setSelectedDate] = useState<{
    startDate: Date | string;
    endDate: Date | string;
  }>({ startDate: "", endDate: "" });

  const [selectedFilter, setSelectedFilter] = useState<{
    name: string;
    type:
      | "EXCHANGE_APPLY"
      | "EXCHANGE_CHECK"
      | "EXCHANGE_RECEIVE"
      | "EXCHANGE_SEND"
      | "EXCHANGE_COMPLETE";
  }>({ name: "교환 신청", type: "EXCHANGE_APPLY" });

  const [selectedStatus, setSelectedStatus] = useState<{
    name: string;
    type:
      | "EXCHANGE_APPLY"
      | "EXCHANGE_CHECK"
      | "EXCHANGE_RECEIVE"
      | "EXCHANGE_SEND"
      | "EXCHANGE_COMPLETE";
  }>({ name: "교환 신청", type: "EXCHANGE_APPLY" });

  const [filter, setFilter] = useState<
    {
      name: string;
      type:
        | "EXCHANGE_APPLY"
        | "EXCHANGE_CHECK"
        | "EXCHANGE_RECEIVE"
        | "EXCHANGE_SEND"
        | "EXCHANGE_COMPLETE";
    }[]
  >([{ name: "교환 신청일", type: "EXCHANGE_APPLY" }]);

  const debouncedSearchText = useDebounce(search, 500);

  const { data, refetch } = useExchangeList({
    status: selectedStatus.type,
    dateType: selectedFilter.type,
    startDate: selectedDate.startDate,
    endDate: selectedDate.endDate,
    search: debouncedSearchText,
    page: currentPage - 1,
    size: pageItemLength,
  });

  const exchangeLengthData = data?.data.ResultData;

  const orderStatus: {
    id: number;
    name: string;
    length: number;
    filterList: {
      name: string;
      type:
        | "EXCHANGE_APPLY"
        | "EXCHANGE_CHECK"
        | "EXCHANGE_RECEIVE"
        | "EXCHANGE_SEND"
        | "EXCHANGE_COMPLETE";
    }[];
    type:
      | "EXCHANGE_APPLY"
      | "EXCHANGE_CHECK"
      | "EXCHANGE_RECEIVE"
      | "EXCHANGE_SEND"
      | "EXCHANGE_COMPLETE";
    rightBorder: boolean;
  }[] = [
    {
      id: 0,
      name: "교환 신청",
      length: exchangeLengthData?.applyCount!,
      filterList: [{ name: "교환 신청일", type: "EXCHANGE_APPLY" }],
      type: "EXCHANGE_APPLY",
      rightBorder: true,
    },
    {
      id: 1,
      name: "교환 접수",
      length: exchangeLengthData?.checkCount!,
      filterList: [
        { name: "교환 신청일", type: "EXCHANGE_APPLY" },
        { name: "교환 접수일", type: "EXCHANGE_CHECK" },
      ],
      type: "EXCHANGE_CHECK",
      rightBorder: true,
    },
    {
      id: 2,
      name: "상품 입고",
      length: exchangeLengthData?.receiveCount!,
      filterList: [
        { name: "교환 신청일", type: "EXCHANGE_APPLY" },
        { name: "교환 접수일", type: "EXCHANGE_CHECK" },
        { name: "입고 완료일", type: "EXCHANGE_RECEIVE" },
      ],
      type: "EXCHANGE_RECEIVE",
      rightBorder: true,
    },
    {
      id: 3,
      name: "교환 배송",
      length: exchangeLengthData?.deliveryStartCount!,
      filterList: [
        { name: "교환 신청일", type: "EXCHANGE_APPLY" },
        { name: "교환 접수일", type: "EXCHANGE_CHECK" },
        { name: "입고 완료일", type: "EXCHANGE_RECEIVE" },
        { name: "배송 시작일", type: "EXCHANGE_SEND" },
      ],
      type: "EXCHANGE_SEND",
      rightBorder: true,
    },
    {
      id: 4,
      name: "교환 완료",
      length: exchangeLengthData?.completeCount!,
      filterList: [
        { name: "교환 신청일", type: "EXCHANGE_APPLY" },
        { name: "교환 접수일", type: "EXCHANGE_CHECK" },
        { name: "입고 완료일", type: "EXCHANGE_RECEIVE" },
        { name: "배송 시작일", type: "EXCHANGE_SEND" },
        { name: "배송 완료일", type: "EXCHANGE_COMPLETE" },
      ],
      type: "EXCHANGE_COMPLETE",
      rightBorder: false,
    },
  ];

  return (
    <div className="contentBox">
      <PageHeader pageList={["주문 관리", "교환 관리"]} title={`교환 관리`} />
      <div className="flex items-center h-20 border border-Gray-40 rounded-2xl min-w-[1020px]">
        {orderStatus.map((item) => {
          return (
            <div
              className={`flex items-center justify-center w-full ${
                item.rightBorder && "border-r-2 border-Gray-40"
              }`}
              key={item.id}
            >
              <div
                onClick={() => {
                  setCurrentPage(1);
                  setSelectedTr([]);
                  setSelectedFilter({
                    name: "교환 신청일",
                    type: "EXCHANGE_APPLY",
                  });
                  setSelectedStatus({ name: item.name, type: item.type });
                  setFilter(item.filterList);
                }}
                className={`flex flex-col text-center cursor-pointer`}
              >
                <div
                  className={`Body4 ${
                    selectedStatus.name === item.name &&
                    selectedStatus.type === item.type
                      ? "text-Blue-100"
                      : "text-Gray-80"
                  }`}
                >
                  {item.name}
                </div>
                <div
                  className={`H2 hover:underline ${
                    selectedStatus.name === item.name &&
                    selectedStatus.type === item.type
                      ? "text-Blue-100 underline"
                      : "text-Gray-100"
                  }`}
                >
                  {item.length}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="flex items-center py-10 border-b border-Gray-90 min-w-[1020px]">
        <div className="mr-6 SubH2 text-Gray-90">조회기간</div>
        <div className="flex items-center gap-2">
          <Dropdown
            innerText={selectedFilter.name ? selectedFilter.name : ""}
            width="w-[177px]"
          >
            {filter.map((k, i) => {
              return (
                <li
                  onClick={() => {
                    setCurrentPage(1);
                    setSelectedFilter({ name: k.name, type: k.type });
                    refetch();
                  }}
                  key={i}
                  className="dropdownWrap"
                >
                  <div className="dropdownLi">{k.name}</div>
                </li>
              );
            })}
          </Dropdown>
          <div className="flex items-center">
            <DatePicker
              className="flex items-center px-3 py-2 h-[52px] border border-Gray-40 Body4 text-Gray-90 rounded-lg"
              placeholderText="시작일"
              selected={startDate}
              onChange={(date: Date) => {
                setStartDate(date);
                setEndDate(null);
                setSelectedDate({
                  ...selectedDate,
                  startDate: dateFormat(date).replaceAll(".", ""),
                  endDate: "",
                });
              }}
              selectsStart
              dateFormat="yyyy.MM.dd"
              startDate={startDate}
              endDate={endDate}
            />
            <div className="px-2">~</div>
            <DatePicker
              className="flex items-center px-3 py-2 h-[52px] border border-Gray-40 Body4 text-Gray-90 rounded-lg"
              placeholderText="종료일"
              disabled={!startDate}
              selected={endDate}
              onChange={(date: Date) => {
                setCurrentPage(1);
                setEndDate(date);
                setSelectedDate({
                  ...selectedDate,
                  endDate: dateFormat(date).replaceAll(".", ""),
                });
              }}
              selectsEnd
              dateFormat="yyyy.MM.dd"
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
            />
            {startDate && endDate && (
              <div
                className="flex items-center ml-4 cursor-pointer SubH2"
                onClick={() => {
                  setCurrentPage(1);
                  setSelectedDate({ startDate: "", endDate: "" });
                  setStartDate(null);
                  setEndDate(null);
                  setSelectedFilter({
                    name: "교환 신청일",
                    type: "EXCHANGE_APPLY",
                  });
                }}
              >
                <Refresh className="mr-2" />
                <div>초기화</div>
              </div>
            )}
          </div>
        </div>
      </div>
      {data?.data.ResultData.content.length !== 0 ? (
        <Table COLUMNS={EXCHANGEORDER}>
          {data?.data.ResultData.content.map((item, i) => {
            return (
              <tr
                key={item.claimNo}
                className="last:border-none border-y Body4 border-Gray-40"
              >
                <td className="tableTd">{item.originOrderNo}</td>
                <td
                  onClick={() => {
                    navigate(`/order/exchange/${item.claimNo}`);
                  }}
                  className="cursor-pointer tableTd text-Blue-100"
                >
                  {item.claimNo}
                </td>
                <td className="min-w-[200px]">
                  <div className="flex items-center tableTd">
                    <img
                      className="w-10 h-10 mr-2 rounded"
                      src={item.itemImagePath}
                      alt=""
                    />
                    <div className="max-w-[180px] textEll">
                      {item.itemTitle}
                    </div>
                  </div>
                </td>
                <td className="tableTd max-w-[120px]">
                  {item.optionStr ? item.optionStr : "-"}
                </td>
                <td className="tableTd">{item.count}</td>
                <td className="text-center tableTd">
                  {item.claimStatus === "EXCHANGE_APPLY"
                    ? "교환신청"
                    : item.claimStatus === "EXCHANGE_CHECK"
                    ? "교환접수"
                    : item.claimStatus === "EXCHANGE_RECEIVE"
                    ? "상품입고"
                    : item.claimStatus === "EXCHANGE_SEND"
                    ? "교환배송"
                    : item.claimStatus === "EXCHANGE_COMPLETE"
                    ? "교환완료"
                    : ""}
                </td>
                <td className="tableTd">{item.regDt}</td>
                <td className="tableTd">{item.checkDt}</td>
                <td className="tableTd">
                  {item.returnCourierName ? item.returnCourierName : "-"}
                </td>
                <td className="tableTd">
                  {item.returnInvoiceNumber ? item.returnInvoiceNumber : "-"}
                </td>
                <td className="tableTd">
                  {item.reason === "CHANGE_OF_HEART"
                    ? "단순변심"
                    : item.reason === "WRONG_ORDER"
                    ? "잘못주문"
                    : item.reason === "DELIVERY_OMITTED"
                    ? "배송누락"
                    : item.reason === "PRODUCT_DEFECT"
                    ? "상품불량"
                    : ""}
                </td>
                <td className="tableTd">
                  {item.collectionType === "COMPANY"
                    ? "업체수거"
                    : item.collectionType === "USER"
                    ? "직접수거"
                    : ""}
                </td>
              </tr>
            );
          })}
        </Table>
      ) : (
        <div className="h-[500px] flex flex-col items-center justify-center Body4">
          해당 상태의 상품이 없어요
        </div>
      )}
      <Pagination
        className="mt-4"
        itemsCount={data?.data.ResultData?.totalElements!}
        pageSize={pageItemLength}
        currPage={currentPage}
        setList={setCurrentPage}
      />
    </div>
  );
};

export default ExchangeOrder;
