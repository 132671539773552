import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import "react-datepicker/dist/react-datepicker.css";
import { QueryClient, QueryClientProvider } from 'react-query';
import { RecoilRoot } from 'recoil';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ReactComponent as GreenCheck } from "assets/greenCheck.svg";
import { ReactComponent as RedCheck } from "assets/redCheck.svg";
import { ReactComponent as BlueInfo } from "assets/blueInfo.svg";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const queryClient = new QueryClient();

root.render(
  <BrowserRouter>
    <RecoilRoot>
      <QueryClientProvider client={queryClient}>
        <ToastContainer
        hideProgressBar
        pauseOnHover
        closeOnClick
        theme='light'
        autoClose={2000}
        icon={({ type }:{type:string}) => {
          if (type === "success") return <GreenCheck/>;
          if (type === "error") return <RedCheck/>;
          if (type === "info") return <BlueInfo/>;
      }}
        />
        <App />
      </QueryClientProvider>
    </RecoilRoot>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
