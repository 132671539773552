import { useUserOperationInfoApi } from "api/store";
import ButtonFilledPrimary from "components/Button/ButtonFilledPrimary";
import ButtonLinedTertiary from "components/Button/ButtonLinedTertiary";
import ImgUploadBtn from "components/Button/ImgUploadBtn";
import Dropdown from "components/Dropdown/Dropdown";
import TextFieldInput from "components/Input/TextFieldInput";
import TextLimitHorizontalInput from "components/Input/TextLimitHorizontalInput";
import NewStoreForm from "components/Store/NewStoreForm";
import NewStoreWrap from "components/Store/NewStoreWrap";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { OperationResultData } from "type/store";
import { ReactComponent as Plus } from "assets/Plus.svg";

import { ReactComponent as ImageDeleteBtn } from "assets/imgDeleteBtn.svg";
import requestToLoveEgg from "utils/requestToLoveEgg";

interface Props {
  OperationData?: OperationResultData;
  authority: string;
}

const OperationSetting = ({ OperationData, authority }: Props) => {
  const params = useParams();
  const [isDeletedLogo, setIsDeletedLogo] = useState<string>("");
  const [logoFile, setLogoFile] = useState<File>();
  const [edit, setEdit] = useState(false);
  const [hour, setHour] = useState<string[]>([]);
  const [minute, setMinute] = useState<string[]>([]);

  const [customerCenterStartTimeHour, setCustomerCenterStartTimeHour] =
    useState("");
  const [customerCenterStartTimeMinute, setCustomerCenterStartTimeMinute] =
    useState("");
  const [customerCenterEndTimeHour, setCustomerCenterEndTimeHour] =
    useState("");
  const [customerCenterEndTimeMinute, setCustomerCenterEndTimeMinute] =
    useState("");

  const [breakStartTimeHour, setBreakStartTimeHour] = useState("");
  const [breakStartTimeMinute, setBreakStartTimeMinute] = useState("");
  const [breakEndTimeHour, setBreakEndTimeHour] = useState("");
  const [breakEndTimeMinute, setBreakEndTimeMinute] = useState("");

  const { data: OData, refetch } = useUserOperationInfoApi(
    authority === "ROLE_COMMERCE_ADMIN" ? params.id : ""
  );
  const operation = OData?.data.ResultData as OperationResultData;

  const [operationData, setOperationData] = useState<{
    companyId?: number | null;
    breakEndTime: string;
    breakStartTime: string;
    customerCenterEndTime: string;
    customerCenterNum: string;
    customerCenterStartTime: string;
    serviceNotice: string;
    deleteLogoFile: string;
    logoPath: string | any;
  }>({
    companyId: null,
    customerCenterStartTime: "",
    customerCenterEndTime: "",
    customerCenterNum: "",
    breakStartTime: "",
    breakEndTime: "",
    serviceNotice: "",
    deleteLogoFile: "",
    logoPath: "",
  });

  useEffect(() => {
    let h = [];
    for (let i = 0; i < 24; i++) {
      h.push(String(i).padStart(2, "0"));
    }
    let m = [];
    for (let i = 0; i < 60; i++) {
      m.push(String(i).padStart(2, "0"));
    }
    setHour(h);
    setMinute(m);
  }, []);

  useEffect(() => {
    setOperationData({
      ...operationData,
      companyId: authority === "ROLE_COMMERCE_ADMIN" ? Number(params.id) : null,
      customerCenterStartTime: operation?.customerCenterStartTime,
      customerCenterEndTime: operation?.customerCenterEndTime,
      customerCenterNum: operation?.customerCenterNum,
      breakStartTime: operation?.breakStartTime,
      breakEndTime: operation?.breakEndTime,
      serviceNotice: operation?.serviceNotice,
      logoPath: operation?.logoPath,
      deleteLogoFile: isDeletedLogo,
    });
  }, [OData]);

  const onChangeLogo = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const logo = e.target.files[0];
    setLogoFile(e.target.files[0]);

    const reader = new FileReader();
    reader.readAsDataURL(logo);
    reader.onloadend = () => {
      setOperationData({
        ...operationData,
        logoPath: reader.result,
      });
    };
  };

  const deleteImage = () => {
    setOperationData({
      ...operationData,
      logoPath: "",
    });
  };

  const handleOperationInfo = () => {
    const formData = new FormData();
    formData.append(
      "data",
      new Blob([JSON.stringify(operationData)], { type: "application/json" })
    );
    if (operationData.logoPath) {
      formData.append("logoFile", logoFile as File);
    }

    requestToLoveEgg({
      method: "POST",
      data: formData,
      headers: {
        "Content-Type": `multipart/form-data`,
      },
      url: `/api/v1/join-apply/operation`,
    }).then((res) => {
      setEdit(false);
      refetch();
    });
  };

  const start = operationData.customerCenterStartTime?.split(":");
  const end = operationData.customerCenterEndTime?.split(":");
  const breakStart = operationData.breakStartTime?.split(":");
  const breakEnd = operationData.breakEndTime?.split(":");

  const operationStart = operation?.customerCenterStartTime?.split(":");
  const operationEnd = operation?.customerCenterEndTime?.split(":");
  const operationBreakstart = operation?.breakStartTime?.split(":");
  const operationBreakEnd = operation?.breakEndTime?.split(":");

  useEffect(() => {
    setOperationData({
      ...operationData,
      customerCenterStartTime: `${
        customerCenterStartTimeHour
          ? customerCenterStartTimeHour
          : operationStart?.[0] !== undefined
          ? operationStart?.[0]
          : "00"
      }:${
        customerCenterStartTimeMinute
          ? customerCenterStartTimeMinute
          : operationStart?.[1] !== undefined
          ? operationStart?.[1]
          : "00"
      }`,
    });
  }, [customerCenterStartTimeHour, customerCenterStartTimeMinute]);

  useEffect(() => {
    setOperationData({
      ...operationData,
      customerCenterEndTime: `${
        customerCenterEndTimeHour
          ? customerCenterEndTimeHour
          : operationEnd?.[0] !== undefined
          ? operationEnd?.[0]
          : "00"
      }:${
        customerCenterEndTimeMinute
          ? customerCenterEndTimeMinute
          : operationEnd?.[1] !== undefined
          ? operationEnd?.[1]
          : "00"
      }`,
    });
  }, [customerCenterEndTimeHour, customerCenterEndTimeMinute]);

  useEffect(() => {
    setOperationData({
      ...operationData,
      breakStartTime: `${
        breakStartTimeHour
          ? breakStartTimeHour
          : operationBreakstart?.[0] !== undefined
          ? operationBreakstart?.[0]
          : "00"
      }:${
        breakStartTimeMinute
          ? breakStartTimeMinute
          : operationBreakstart?.[1] !== undefined
          ? operationBreakstart?.[1]
          : "00"
      }`,
    });
  }, [breakStartTimeHour, breakStartTimeMinute]);

  useEffect(() => {
    setOperationData({
      ...operationData,
      breakEndTime: `${
        breakEndTimeHour
          ? breakEndTimeHour
          : operationBreakEnd?.[0] !== undefined
          ? operationBreakEnd?.[0]
          : "00"
      }:${
        breakEndTimeMinute
          ? breakEndTimeMinute
          : operationBreakEnd?.[1] !== undefined
          ? operationBreakEnd?.[1]
          : "00"
      }`,
    });
  }, [breakEndTimeHour, breakEndTimeMinute]);

  useEffect(() => {
    if (operationData.logoPath === "") {
      setIsDeletedLogo("Y");
    } else {
      setIsDeletedLogo("N");
    }
  }, [isDeletedLogo, operationData.logoPath]);

  const validCheck =
    operationData.customerCenterNum !== "" &&
    operationData.customerCenterStartTime !== "" &&
    operationData.customerCenterStartTime !== "00:00" &&
    operationData.customerCenterEndTime !== "" &&
    operationData.customerCenterEndTime !== "00:00";

  return (
    <div>
      <NewStoreWrap title="상점 정보">
        <NewStoreForm itemCenter title="로고">
          {!edit && operationData.logoPath !== "" ? (
            <img
              className="rounded-lg w-[120px] h-[120px] border border-Gray-40"
              src={operationData?.logoPath}
              alt=""
            />
          ) : edit && operationData.logoPath !== "" ? (
            <div className="relative">
              <img
                className="rounded-lg w-[120px] h-[120px] border border-Gray-40"
                src={operationData?.logoPath}
                alt=""
              />
              <ImageDeleteBtn
                onClick={() => {
                  deleteImage();
                }}
                className="absolute z-50 cursor-pointer top-2 right-2"
              />
            </div>
          ) : !edit && operationData.logoPath === "" ? (
            <div>-</div>
          ) : (
            <ImgUploadBtn id="Logo" onChange={(e) => onChangeLogo(e)} />
          )}
        </NewStoreForm>
        <NewStoreForm
          className="flex items-center gap-2"
          itemCenter
          required
          title="고객센터 운영시간"
        >
          {!edit ? (
            <div className="flex items-center gap-2">
              <TextFieldInput
                disabled
                className="w-[108px]"
                inputValue={
                  operationData.customerCenterStartTime
                    ? operationData.customerCenterStartTime
                    : "--:--"
                }
              />
              <div>~</div>
              <TextFieldInput
                disabled
                className="w-[108px]"
                inputValue={
                  operationData.customerCenterEndTime
                    ? operationData.customerCenterEndTime
                    : "--:--"
                }
              />
            </div>
          ) : (
            <>
              <Dropdown
                width="w-20"
                innerText={start !== undefined ? start[0] : "--"}
              >
                {hour.map((h, i) => {
                  return (
                    <li
                      onClick={() => {
                        setCustomerCenterStartTimeHour(h);
                      }}
                      key={i}
                      className="dropdownWrap"
                    >
                      <div className="dropdownLi">{h}</div>
                    </li>
                  );
                })}
              </Dropdown>
              <div>시</div>
              <Dropdown
                width="w-20"
                innerText={start !== undefined ? start[1] : "--"}
              >
                {minute.map((m, i) => {
                  return (
                    <li
                      onClick={() => {
                        setCustomerCenterStartTimeMinute(m);
                      }}
                      key={i}
                      className="dropdownWrap"
                    >
                      <div className="dropdownLi">{m}</div>
                    </li>
                  );
                })}
              </Dropdown>
              <div>분</div>
              <div className="mx-2">~</div>
              <Dropdown
                width="w-20"
                innerText={end !== undefined ? end[0] : "--"}
              >
                {hour.map((h, i) => {
                  return (
                    <li
                      onClick={() => {
                        setCustomerCenterEndTimeHour(h);
                      }}
                      key={i}
                      className="dropdownWrap"
                    >
                      <div className="dropdownLi">{h}</div>
                    </li>
                  );
                })}
              </Dropdown>
              <div>시</div>
              <Dropdown
                width="w-20"
                innerText={end !== undefined ? end[1] : "--"}
              >
                {minute.map((m, i) => {
                  return (
                    <li
                      onClick={() => {
                        setCustomerCenterEndTimeMinute(m);
                      }}
                      key={i}
                      className="dropdownWrap"
                    >
                      <div className="dropdownLi">{m}</div>
                    </li>
                  );
                })}
              </Dropdown>
              <div>분</div>
            </>
          )}
        </NewStoreForm>
        <NewStoreForm
          className="flex items-center gap-2"
          itemCenter
          title="휴게시간"
        >
          {!edit ? (
            <div className="flex items-center gap-2">
              <TextFieldInput
                disabled
                className="w-[108px]"
                inputValue={
                  operationData.breakStartTime
                    ? operationData.breakStartTime
                    : "--:--"
                }
              />
              <div>~</div>
              <TextFieldInput
                disabled
                className="w-[108px]"
                inputValue={
                  operationData.breakEndTime
                    ? operationData.breakEndTime
                    : "--:--"
                }
              />
            </div>
          ) : (
            <>
              <Dropdown
                width="w-20"
                innerText={breakStart !== undefined ? breakStart[0] : "--"}
              >
                {hour.map((h, i) => {
                  return (
                    <li
                      onClick={() => {
                        setBreakStartTimeHour(h);
                      }}
                      key={i}
                      className="dropdownWrap"
                    >
                      <div className="dropdownLi">{h}</div>
                    </li>
                  );
                })}
              </Dropdown>
              <div>시</div>
              <Dropdown
                width="w-20"
                innerText={breakStart !== undefined ? breakStart[1] : "--"}
              >
                {minute.map((m, i) => {
                  return (
                    <li
                      onClick={() => {
                        setBreakStartTimeMinute(m);
                      }}
                      key={i}
                      className="dropdownWrap"
                    >
                      <div className="dropdownLi">{m}</div>
                    </li>
                  );
                })}
              </Dropdown>
              <div>분</div>
              <div className="mx-2">~</div>
              <Dropdown
                width="w-20"
                innerText={breakEnd !== undefined ? breakEnd[0] : "--"}
              >
                {hour.map((h, i) => {
                  return (
                    <li
                      onClick={() => {
                        setBreakEndTimeHour(h);
                      }}
                      key={i}
                      className="dropdownWrap"
                    >
                      <div className="dropdownLi">{h}</div>
                    </li>
                  );
                })}
              </Dropdown>
              <div>시</div>
              <Dropdown
                width="w-20"
                innerText={breakEnd !== undefined ? breakEnd[1] : "--"}
              >
                {minute.map((m, i) => {
                  return (
                    <li
                      onClick={() => {
                        setBreakEndTimeMinute(m);
                      }}
                      key={i}
                      className="dropdownWrap"
                    >
                      <div className="dropdownLi">{m}</div>
                    </li>
                  );
                })}
              </Dropdown>
              <div>분</div>
            </>
          )}
        </NewStoreForm>
        <NewStoreForm required title="고객센터 연락처">
          <TextFieldInput
            disabled={!edit}
            maxLength={20}
            inputValue={operationData.customerCenterNum}
            onChange={(e) => {
              setOperationData({
                ...operationData,
                customerCenterNum: e.target.value.replace(/[^0-9-]{0,16}/g, ""),
              });
            }}
            className="w-[476px]"
            placeholder="예) 000-1234-5678"
          />
        </NewStoreForm>
        <NewStoreForm title="A/S 유의사항">
          <TextLimitHorizontalInput
            row={8}
            disabled={!edit}
            inputValue={
              operationData?.serviceNotice ? operationData?.serviceNotice : ""
            }
            currentLength={operationData.serviceNotice.length}
            onChange={(e) => {
              setOperationData({
                ...operationData,
                serviceNotice: e.target.value,
              });
            }}
            maxLength={300}
            className="w-[803px]"
            placeholder="A/S 관련해서 알려야하는 내용이 있다면 작성해주세요"
          />
        </NewStoreForm>
      </NewStoreWrap>
      <div className="fixed bottom-0 left-0 bg-Gray-10/70 backdrop-blur-[12px] z-[102] w-full py-5 border-t border-Gray-40">
        <div className="flex items-center pl-[260px] pr-[160px] justify-between max-w-[1920px] mx-auto min-w-[1440px]">
          <div className="Body2">
            상점 관리 {">"} 상점 설정 {">"} 운영설정{" "}
          </div>
          {edit ? (
            <div className="flex items-center gap-4">
              <ButtonLinedTertiary
                onClick={() => {
                  setOperationData({
                    ...operationData,
                    logoPath: "",
                  });
                  refetch();
                  setEdit(false);
                }}
                size={"lg"}
                btnText={"취소"}
              />
              <ButtonFilledPrimary
                onClick={() => {
                  handleOperationInfo();
                }}
                disabled={!validCheck}
                size={"lg"}
                btnText={"수정 완료"}
              />
            </div>
          ) : (
            <div>
              <ButtonLinedTertiary
                onClick={() => setEdit(true)}
                size={"lg"}
                btnText={"운영설정 수정"}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OperationSetting;
