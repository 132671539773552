import React from "react";

interface Props {
  COLUMNS: { Header: string }[];
  children?: React.ReactNode;
  checkBox?: boolean;
  checked?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const Table = ({ COLUMNS, children, checkBox, checked, onChange }: Props) => {
  return (
    <div className="overflow-scroll border-y border-Gray-40 min-h-[600px] max-h-[600px] min-w-[1020px] visibleScroll">
      <table className="w-full whitespace-nowrap">
        <thead>
          <tr>
            {checkBox && (
              <th className="SubH2 static top-0 z-[100] py-2.5 bg-Gray-20 border-Gray-20 border-b px-3">
                <input
                  onChange={onChange}
                  checked={checked}
                  type="checkbox"
                  name=""
                  id=""
                />
              </th>
            )}
            {COLUMNS?.map((item: any, idx: number) => {
              return (
                <th
                  className="SubH2 static top-0 z-[100] py-2.5 bg-Gray-20 border-Gray-20 border-b px-3 text-left"
                  key={idx}
                >
                  {item.Header}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </table>
    </div>
  );
};

export default Table;
