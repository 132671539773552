import Header from "./Header/Header";
import Dashboard from "./Dashboard/Dashboard";

import { Outlet } from "react-router-dom";
import Footer from "./Footer/Footer";

import { ReactComponent as Top } from "assets/screenTop.svg";
import { useState, useEffect } from "react";

const Layout = () => {
  const [position, setPosition] = useState(0);
  function onScroll() {
    setPosition(window.scrollY);
  }
  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);
  return (
    <div className="max-w-[1920px] mx-auto">
      <Header />
      <div className="flex">
        <Dashboard />
        <Outlet />
      </div>
      {position > 400 && (
        <Top
          onClick={() => {
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }}
          className="fixed right-[60px] bottom-[140px] cursor-pointer"
        />
      )}
      <Footer />
    </div>
  );
};

export default Layout;
