import React, { useState } from "react";
import _ from "lodash";
import { ReactComponent as PaginationLeft } from "assets/paginationLeft.svg";
import { ReactComponent as PaginationRight } from "assets/paginationRight.svg";

function Pagination(props: {
  itemsCount: number;
  pageSize: number;
  currPage: number;
  className?: string;
  setList: any;
}) {
  const { itemsCount, pageSize, currPage, setList, className } = props; // 각각 아이템 개수, 한 페이지에 보여줄 아이템 개수, 최근 페이지, 페이지 클릭시 호출 함수
  const pageCount = Math.ceil(itemsCount / pageSize); // 몇 페이지가 필요한지 계산

  const [paginationNum, setPaginationNum] = useState(1);
  const paginationSize = 10;

  if (itemsCount === 0) return null; // 1페이지 뿐이라면 페이지 수를 보여주지 않음

  const pages = _.range(1, pageCount + 1); // 마지막 페이지에 보여줄 컨텐츠를 위해 +1, https://lodash.com/docs/#range 참고

  const nextOnClick = () => {
    if (Math.ceil(pageCount/paginationSize) === paginationNum) {
      return;
    } else {
      setPaginationNum(paginationNum + 1);
    }
  };
  const prevClick = () => {
    if (paginationNum === 1) {
      return;
    } else {
    setPaginationNum(paginationNum - 1);
    }
  };
  const setPage = (page: number) => {
      setList(page);
  };

  return (
    <div
      className={`flex items-center justify-center min-w-[1020px] Body4 ${className}`}
    >
      <div className="mr-2.5" onClick={prevClick}>
        <PaginationLeft
          className={`${
            paginationNum === 1 ? "stroke-Gray-40" : "stroke-Gray-90"
          } cursor-pointer`}
        />
      </div>
      {pages
        .slice(
          (paginationNum - 1) * paginationSize,
          (paginationNum - 1) * paginationSize + paginationSize
        )
        .map((page, idx) => (
          <div
            key={idx}
            className={`w-9 h-9 flex flex-col items-center justify-center ${
              currPage === page ? "text-Blue-100 underline" : " cursor-pointer"
            }`}
            onClick={() => {
              setPage(page);
            }}
          >
            {page}
          </div>
        ))}
      <div className="ml-2.5" onClick={nextOnClick}>
        <PaginationRight
          className={`${
            Math.ceil(pageCount/paginationSize) === paginationNum ? "stroke-Gray-40" : "stroke-Gray-90"
          }  cursor-pointer`}
        />
      </div>
    </div>
  );
}

export default Pagination;
