import React from 'react';

interface Props {
    children?: React.ReactNode;
    title: string;
    noMb?: boolean;
    noGap?: boolean;
}

const ProdDetail = ({ children, title, noMb, noGap }: Props) => {
    return (
        <div className='flex items-center py-3 border-b border-Gray-100 min-w-[1020px]'>
            <div className={`SubH1 ${children && "mr-4"}`}>{title}</div>
            {children}
        </div>
    );
};

export default ProdDetail;