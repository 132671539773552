import React from 'react';

interface Props {
    placeholder?: string;
    className?: string;
    inputOnButton?: string;
    type?: string;
    disabled?: boolean;
    maxLength?:number;
    error?: boolean;
    errorMsg?: string;
    name?: string;
    value?:string;
    inputValue?:string;
    defaultValue?:string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

const TextFieldInput = ({ placeholder, onKeyPress, maxLength, name, inputValue,defaultValue, type, disabled, className, inputOnButton, onChange, error, errorMsg }: Props) => {
    return (
        <div className={`relative`}>
            <input name={name} maxLength={maxLength} onKeyPress={onKeyPress} value={inputValue} defaultValue={defaultValue} type={type} disabled={disabled} onChange={onChange} placeholder={placeholder} className={`border ${error ? "border-red" : " border-Gray-40"} Body3 rounded-lg py-[10px] px-3 h-[52px] ${className ? className : "w-full"}`} />
            {inputOnButton &&
                <div className='absolute right-3 top-[14px] text-Gray-60 SubH2'>{inputOnButton}</div>
            }
            {(error && errorMsg) &&
                <div className='mt-2 SubH3 text-red'>
                    {errorMsg}
                </div>
            }
        </div>
    );
};

export default TextFieldInput;