import CheckBox from 'components/CheckBox/CheckBox';
import Footer from 'components/Footer/Footer';
import Header from 'components/Header/Header';
import NewStoreForm from 'components/Store/NewStoreForm';
import NewStoreWrap from 'components/Store/NewStoreWrap';
import { ReactComponent as Logo } from 'assets/Logo.svg'
import React from 'react';
import { useUserStoreInfoApi } from 'api/store';
import TextFieldInput from 'components/Input/TextFieldInput';

const CheckStore = () => {
    const { data: userData } = useUserStoreInfoApi();

    const data = userData?.data.ResultData;

    if (data) {
        return (
            <div>
                <Header />
                <div className='min-w-[770px] px-[60px] max-w-[1073px] w-full mx-auto pt-[56px]'>
                    <div className='flex items-center gap-4'>
                        <Logo width={100} height={81} />
                        <div className='text-Gray-100 H1'>입점 신청 (심사중)</div>
                    </div>
                    <div className='p-5 bg-Gray-20 max-w-[626px] my-10 rounded-lg'>
                        <div className='mb-4 SubH1 text-Gray-100'>신청 전 확인해주세요.</div>
                        <div className='flex flex-col gap-1 text-Gray-90 Body4'>
                            <div>· 현재 법인, 과세 사업자인 경우에만 신청 가능합니다.</div>
                            <div>· 배송/CS/상품등록 등 판매 및 사후관리가 불가능한 업체는 입점 불가합니다.</div>
                            <div>· 고객센터와 별도 메일을 통한 문의 및 접수는 불가합니다.</div>
                            <div>· 입점 결과는 기재하신 담당자 연락처 또는 이메일로 회신드리오니, 정확한 정보를 입력해주세요.</div>
                            <div>· 단순 입점 방식에 대한 문의는 답변 드리기 어렵습니다.</div>
                        </div>
                    </div>

                    <NewStoreWrap title="판매자 정보">
                        <NewStoreForm required title="회사명">
                            <TextFieldInput inputValue={data.companyName} disabled className='w-[476px]' placeholder="예시) 주식회사 러브에그" />
                            <div className='mt-2 text-Blue-70 Body4'>
                                사업자 등록증의 법인명 또는 상호명을 입력해 주세요.
                            </div>
                        </NewStoreForm>
                        <NewStoreForm required title="사업자 등록 정보" className='flex items-center'>
                            <TextFieldInput disabled inputValue={data.companyRegNo?.replace(/^(\d{0,3})(\d{0,2})(\d{0,5})$/g, "$1-$2-$3")} className='w-[270px]' placeholder="123" />
                        </NewStoreForm>
                        <NewStoreForm className='flex flex-col items-start gap-2.5' required title="사업자 소재지">
                            <TextFieldInput inputValue={data.zip} disabled className='w-[358px]' placeholder="우편번호" />
                            <TextFieldInput inputValue={data.address} disabled className='w-[476px]' />
                            <TextFieldInput inputValue={data.detailAddress} disabled className='w-[476px]' placeholder="" />
                        </NewStoreForm>
                        <NewStoreForm required title="대표자">
                            <TextFieldInput inputValue={data.reqName} disabled className='w-[476px]' placeholder="대표자 이름 입력" />
                        </NewStoreForm>
                        <NewStoreForm required title="대표 판매 카테고리">
                            <TextFieldInput inputValue={data.mainCategoryName} disabled className='w-[476px]' />
                        </NewStoreForm>
                        {data.subCategoryName1 &&
                            <NewStoreForm required title="추가 카테고리1">
                                <TextFieldInput inputValue={data.subCategoryName1} disabled className='w-[476px]' />
                            </NewStoreForm>
                        }
                        {data.subCategoryName2 &&
                            <NewStoreForm required title="추가 카테고리2">
                                <TextFieldInput inputValue={data.subCategoryName2} disabled className='w-[476px]' />
                            </NewStoreForm>
                        }
                        <NewStoreForm title="브랜드명">
                            <TextFieldInput inputValue={data.brandName} disabled className='w-[476px]'/>
                        </NewStoreForm>
                        <NewStoreForm itemCenter title="공식 홈페이지 URL">
                            <div className='underline cursor-pointer Body3 text-Blue-100' onClick={() => {
                                if (data.mainUrl.startsWith("https://")) {
                                    window.open(`${data.mainUrl}`)
                                } else if (data.mainUrl.startsWith("http://")) {
                                    window.open(`${data.mainUrl}`)
                                } else {
                                    window.open(`https://${data.mainUrl}`)
                                }
                            }}>
                                {data.mainUrl ? data.mainUrl : "-"}
                            </div>
                        </NewStoreForm>
                        <NewStoreForm itemCenter subTitle='(자사몰 제외)' title="대표 판매채널 URL">
                            <div className='underline cursor-pointer Body3 text-Blue-100' onClick={() => {
                                if (data.saleUrl.startsWith("https://")) {
                                    window.open(`${data.saleUrl}`)
                                }
                                else if (data.saleUrl.startsWith("http://")) {
                                    window.open(`${data.saleUrl}`)
                                } else {
                                    window.open(`https://${data.saleUrl}`)
                                }
                            }}>
                                {data.saleUrl ? data.saleUrl : "-"}
                            </div>
                        </NewStoreForm>
                    </NewStoreWrap>

                    <NewStoreWrap title="계정 정보">
                        <NewStoreForm title="판매자 로그인 ID" required>
                            <TextFieldInput inputValue={data.id} disabled className='w-[386px]' placeholder="사용할 아이디 입력" />
                            <div className='mt-2 whitespace-pre-wrap text-Blue-70 Body4'>
                                {`5글자 이상의 영문, 숫자 입력해 주세요. (최대 12글자)\n로그인 ID는 입점 신청 후  변경 불가하니 신중하게 정해주세요.`}
                            </div>
                        </NewStoreForm>
                        <NewStoreForm title="비밀번호" required>
                            <TextFieldInput inputValue='************' disabled className='w-[476px]' type='password' placeholder="비밀번호 입력" />
                            <div className='whitespace-pre-wrap'>
                                <div className='my-2 text-Blue-70 Body4'>
                                    영문, 숫자, 특수문자 조합으로 입력해 주세요. (최소 12글자)
                                </div>
                            </div>
                        </NewStoreForm>
                    </NewStoreWrap>

                    <NewStoreWrap title="담당자 정보" noMb>
                        <NewStoreForm title="이름" required >
                            <TextFieldInput inputValue={data.username} disabled className='w-[367px]' placeholder="이름 입력" />
                        </NewStoreForm>
                        <NewStoreForm title="이메일" required className='flex items-center'>
                            <TextFieldInput inputValue={data.email?.split('@')[0]} disabled className='w-[270px]' placeholder="연락받을 이메일" />
                            <div className='mx-2 Body4 text-Gray-90'>@</div>
                            <TextFieldInput inputValue={data.email?.split('@')[1]} disabled className='w-[175px]' />
                        </NewStoreForm>
                        <NewStoreForm title="연락처" required className='flex items-center'>
                            <TextFieldInput inputValue={data.mobileNo?.replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3")} disabled className='w-[270px]' placeholder="010" />
                        </NewStoreForm>
                    </NewStoreWrap>
                    <div className='mt-4 Body4 text-Blue-100'>
                        ※ 연락받을 수 있는 정보로 입력해 주세요. 담당자 이메일로 심사결과  알려드립니다.
                    </div>
                    <div className='py-5 px-6 flex flex-col items-start min-w-[650px] my-10 gap-5 border border-Gray-40 rounded-lg'>
                        <div className='SubH1 text-Gray-100'>약관 동의</div>
                        <div className='flex flex-col items-start gap-4'>
                            <CheckBox checked text='입점 이후 신청 사실과 다른 정보, 상품 판매 등으로 인한 문제 발생 시 통보없이 판매중지될 수 있으며, 이 후 법적 조치에 동의합니다. (필수)' />
                            <CheckBox checked text='개인정보 수집 동의(필수)' />
                            <CheckBox checked text='판매약관 동의(필수)' />
                        </div>
                        <div className='Body5 text-Gray-80'>※ 제출 이후 입점 절차를 더 이상 진행하지 않는 경우, 본 내용은 14일후에 파기합니다.</div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    } else return <div></div>
};

export default CheckStore;



