import React, { useContext, useEffect, useState } from "react";
import { Outlet, Navigate } from "react-router-dom";

const GuestRouter = () => {

    const userInfo = JSON.parse(localStorage.getItem("userInfo") as string);
    const status = userInfo?.status;
    const auth = userInfo?.authority;

    return auth === undefined && status === undefined ? <Outlet />
        : <Navigate to={auth === "ROLE_USER" && status === "EXAMINATION" ? "/store/check"
            : auth === "ROLE_USER" && status === "WAITING" ? "/store/setting"
            : auth === "ROLE_USER" && status === "INACTIVE" ? "/product/all"
                    : auth === "ROLE_USER" && status === "ACTIVE" ? "/product/all"
                : auth === "ROLE_COMMERCE_ADMIN" && status === "" ? "/store/all" : "/"} />;

};

export default GuestRouter;