import { AxiosResponse } from "axios";
import { UseQueryResult, useQuery } from "react-query";
import {
  GeneralOrderListPropsForAdmin,
  GeneralOrderList,
  GiftOrderList,
  GiftOrderListPropsForAdmin,
  OrderDetailAdmin,
  GiftDetail,
  ExchangeList,
  ExchangeParamsType,
  ReturnList,
  ReturnParamsType,
  ReturnData,
  ExchangeData,
  CancelList,
} from "type/order";
import requestToLoveEgg from "utils/requestToLoveEgg";

// 주문 목록 조회 (어드민)
const getGeneralOrderListForAdmin = ({
  companyId,
  status,
  keyword,
  dateType,
  startDate,
  endDate,
  page,
  size
}: GeneralOrderListPropsForAdmin) => {
  return requestToLoveEgg({
    method: "GET",
    url: "/api/admin/v1/orders",
    params: {
      companyId: companyId,
      status: status,
      search: keyword,
      dateType: dateType,
      startDate: startDate,
      endDate: endDate,
      page: page,
      size: size,
    },
  });
};

export const useGeneralOrderListForAdmin = (
  params: GeneralOrderListPropsForAdmin
): UseQueryResult<AxiosResponse<GeneralOrderList>, Error> =>
  useQuery(
    ["useGeneralOrderListForAdmin", params],
    () => getGeneralOrderListForAdmin(params),
    {
      // retry: 3,
      keepPreviousData: true,
    }
  );

// 선물 대기 목록 조회 (어드민)
const getGiftOrderListForAdmin = ({
  companyId,
  dateType,
  keyword,
  startDate,
  endDate,
  status,
  size,
  page
}: GiftOrderListPropsForAdmin) => {
  return requestToLoveEgg({
    method: "GET",
    url: "/api/admin/v1/gifts",
    params: {
      companyId: companyId,
      status: status,
      search: keyword,
      dateType: dateType,
      startDate: startDate,
      endDate: endDate,
      size: size,
      page: page,
    },
  });
};

export const useGiftOrderListForAdmin = (
  params?: GiftOrderListPropsForAdmin
): UseQueryResult<AxiosResponse<GiftOrderList>, Error> =>
  useQuery(
    ["useGiftOrderListForAdmin", params],
    () => getGiftOrderListForAdmin(params as GiftOrderListPropsForAdmin),
    {
      // retry: 3,
      keepPreviousData: true,
    }
  );

// 주문 상세 조회 (어드민)
const getOrderDetailForAdmin = (orderId: string) => {
  return requestToLoveEgg({
    method: "GET",
    url: `/api/admin/v1/orders/${orderId}`,
  });
};

export const useOrderDetailForAdmin = (
  orderId: string
): UseQueryResult<AxiosResponse<OrderDetailAdmin>, Error> =>
  useQuery(["useOrderDetailForAdmin"], () => getOrderDetailForAdmin(orderId), {
    // retry: 3,
    keepPreviousData: true,
  });

// 선물 대기 상세 조회 (어드민)
const getGiftOrderDetailForAdmin = (orderId: string) => {
  return requestToLoveEgg({
    method: "GET",
    url: `/api/admin/v1/gifts/${orderId}`,
  });
};

export const useGiftOrderDetailForAdmin = (
  orderId: string
): UseQueryResult<AxiosResponse<GiftDetail>, Error> =>
  useQuery(
    ["useGiftOrderDetailForAdmin"],
    () => getGiftOrderDetailForAdmin(orderId),
    {
      // retry: 3,
      keepPreviousData: true,
    }
  );

// 교환 관리 목록 조회
const getExchangeListForAdmin = ({
  companyId,
  status,
  search,
  dateType,
  startDate,
  endDate,
  page,
  size
}: ExchangeParamsType) => {
  return requestToLoveEgg({
    method: "GET",
    url: `/api/admin/v1/exchanges`,
    params: {
      companyId: companyId,
      status: status,
      search: search,
      dateType: dateType,
      startDate: startDate,
      endDate: endDate,
      page: page,
      size: size,
    },
  });
};

export const useExchangeListForAdmin = (
  params: ExchangeParamsType
): UseQueryResult<AxiosResponse<ExchangeList>, Error> =>
  useQuery(
    ["useExchangeListForAdmin", params],
    () => getExchangeListForAdmin(params),
    {
      // retry: 3,
      keepPreviousData: true,
    }
  );

// 반품 관리 목록 조회
const getReturnListForAdmin = ({
  companyId,
  status,
  search,
  dateType,
  startDate,
  endDate,
}: ReturnParamsType) => {
  return requestToLoveEgg({
    method: "GET",
    url: `/api/admin/v1/returns`,
    params: {
      companyId: companyId,
      status: status,
      search: search,
      dateType: dateType,
      startDate: startDate,
      endDate: endDate,
    },
  });
};

export const useReturnListForAdmin = (
  params: ReturnParamsType
): UseQueryResult<AxiosResponse<ReturnList>, Error> =>
  useQuery(
    ["useReturnListForAdmin", params],
    () => getReturnListForAdmin(params),
    {
      // retry: 3,
      keepPreviousData: true,
    }
  );

const getOrderProcessHistoryForAdmin = ({
  orderNo,
  orderItemId,
}: {
  orderNo: string;
  orderItemId: string;
}) => {
  return requestToLoveEgg({
    method: "GET",
    url: `/api/admin/v1/orders/${orderNo}/processing/${orderItemId}`,
  });
};

export const useOrderProcessHistoryForAdmin = (params: {
  orderNo: string;
  orderItemId: string;
}): UseQueryResult<AxiosResponse, Error> =>
  useQuery(
    ["useOrderProcessHistoryForAdmin", params],
    () => getOrderProcessHistoryForAdmin(params),
    {
      // retry: 3,
      keepPreviousData: true,
    }
  );

// 반품 관리 상세 조회
const getReturnDetailForAdmin = (claimNo: string) => {
  return requestToLoveEgg({
    method: "GET",
    url: `/api/admin/v1/returns/${claimNo}`,
  });
};

export const useReturnDetailForAdmin = (
  claimNo: string
): UseQueryResult<AxiosResponse<ReturnData>, Error> =>
  useQuery(["useReturnListForAdmin"], () => getReturnDetailForAdmin(claimNo), {
    // retry: 3,
    keepPreviousData: true,
  });

// 교환 관리 상세 조회
const getExchangeDetailForAdmin = (claimNo: string) => {
  return requestToLoveEgg({
    method: "GET",
    url: `/api/admin/v1/exchanges/${claimNo}`,
  });
};

export const useExchangeDetailForAdmin = (
  claimNo: string
): UseQueryResult<AxiosResponse<ExchangeData>, Error> =>
  useQuery(
    ["useExchangeDetailForAdmin"],
    () => getExchangeDetailForAdmin(claimNo),
    {
      // retry: 3,
      keepPreviousData: true,
    }
  );

// 주문 취소 내역 조회
const getCancelListForAdmin = ({
  companyId,
  search,
  startDate,
  endDate,
  page,
  size,
}: {
  companyId?: number;
  search?: string;
  startDate?: string;
  endDate?: string;
  page?: number;
  size?: number;
}) => {
  return requestToLoveEgg({
    method: "GET",
    url: `/api/admin/v1/orders/cancel`,
    params: {
      companyId: companyId,
      search: search,
      startDate: startDate,
      endDate: endDate,
      page: page,
      size: size,
    },
  });
};

export const useCancelListForAdmin = (params: {
  companyId?: number;
  search?: string;
  startDate?: string;
  endDate?: string;
  page?: number;
  size?: number;
}): UseQueryResult<AxiosResponse<CancelList>, Error> =>
  useQuery(["useCancelListForAdmin",params], () => getCancelListForAdmin(params), {
    // retry: 3,
    keepPreviousData: true,
  });
