import React from 'react';
import { ReactComponent as Plus } from 'assets/Plus.svg'

interface Props {
    onChange?:(e:React.ChangeEvent<HTMLInputElement>) => void;
    maxLength?:string;
    currentLength?:number;
    multiple?: boolean;
    accept?: string;
    id:string;
    disabled?:boolean
}

const ImgUploadBtn = ({onChange, maxLength, disabled, currentLength, multiple,id}:Props) => {
    
    return (
        <label htmlFor={id} className='min-w-[120px] w-[120px] cursor-pointer h-[120px] border border-dashed border-Gray-40 flex flex-col gap-2 items-center justify-center px-2 py-2.5'>
            <input multiple={multiple} accept=".gif, .jpg, .png" id={id} onChange={onChange} className='hidden' type="file"></input>
            <Plus />
            <div className='text-center Body4'>
                <div>이미지 등록</div>
                <div className='text-Gray-80'>( <span className={`${currentLength !== 0 ? "text-Blue-100" : "text-Gray-80"}`}>{currentLength ? currentLength : 0}</span> / {maxLength? maxLength : 1} )</div>
            </div>
        </label>
    );
};

export default ImgUploadBtn;