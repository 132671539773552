import React, { useState } from "react";
import { useStoreChangeHistoryApi } from "api/store";
import ButtonLinedTertiary from "components/Button/ButtonLinedTertiary";
import PageHeader from "components/Header/PageHeader";
import Table from "components/Table/Table";
import { STOREINFOHISTORY } from "components/Table/TableData";
import { ReactComponent as Refresh } from "assets/refresh.svg";
import { dateFormat } from "utils/dateFormat";
import DatePicker from "react-datepicker";
import Pagination from "components/Pagination/Pagination";

const StoreInfoHistory = () => {
  const [currentPage, setCurrentPage] = useState(1);
  let pageItemLength = 10;
  const [selectedDate, setSelectedDate] = useState<{
    startDate: Date | string;
    endDate: Date | string;
  }>({ startDate: "", endDate: "" });
  const [startDate, setStartDate] = useState<null | Date>(null);
  const [endDate, setEndDate] = useState<null | Date>(null);

  const { data: history } = useStoreChangeHistoryApi({
    startDate: selectedDate.startDate,
    endDate: selectedDate.endDate,
    page: currentPage - 1,
    size: pageItemLength,
  });

  const storeHistory = history?.data.ResultData.content;
  return (
    <div className="contentBox">
      <PageHeader
        noBorderB
        pageList={["상점관리", "상점 정보 변경 이력"]}
        title={`상점 정보 변경 이력`}
      />
      <div className="border-t flex items-center py-10 border-b border-Gray-40 min-w-[1020px]">
        <div className="mr-6 SubH2 text-Gray-90">조회기간</div>
        <div className="flex items-center gap-2">
          <div className="flex items-center">
            <DatePicker
              className="flex items-center px-3 py-2 h-[52px] border border-Gray-40 Body4 text-Gray-90 rounded-lg"
              selected={startDate}
              placeholderText="시작일"
              onChange={(date: Date) => {
                setStartDate(date);
                setEndDate(null);
                setSelectedDate({
                  ...selectedDate,
                  startDate: dateFormat(date).replaceAll(".", ""),
                  endDate: "",
                });
              }}
              selectsStart
              dateFormat="yyyy.MM.dd"
              startDate={startDate}
              endDate={endDate}
            />
            <div className="px-2">~</div>
            <DatePicker
              className="flex items-center px-3 py-2 h-[52px] border border-Gray-40 Body4 text-Gray-90 rounded-lg"
              placeholderText="종료일"
              selected={endDate}
              disabled={!startDate}
              onChange={(date: Date) => {
                setCurrentPage(1);
                setEndDate(date);
                setSelectedDate({
                  ...selectedDate,
                  endDate: dateFormat(date).replaceAll(".", ""),
                });
              }}
              selectsEnd
              dateFormat="yyyy.MM.dd"
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
            />
            {startDate && endDate && (
              <div
                className="flex items-center ml-4 cursor-pointer SubH2"
                onClick={() => {
                  setCurrentPage(1);
                  setSelectedDate({ startDate: "", endDate: "" });
                  setStartDate(null);
                  setEndDate(null);
                }}
              >
                <Refresh className="mr-2" />
                <div>초기화</div>
              </div>
            )}
          </div>
        </div>
      </div>
      {storeHistory?.length !== 0 ? (
        <Table COLUMNS={STOREINFOHISTORY}>
          {storeHistory?.map((item, i) => {
            return (
              <tr className="Body4 text-Gray-90" key={i}>
                <td className="tableTd">{item.regDt}</td>
                <td className="tableTd">{item.commerceName}</td>
                <td className="tableTd">
                  {item.commerceStatus === "ACTIVE" ? (
                    <div className="flex items-center gap-1">
                      <div className="w-1.5 h-1.5 rounded-full bg-Green100" />
                      <div>활성</div>
                    </div>
                  ) : item.commerceStatus === "INACTIVE" ? (
                    <div className="flex items-center gap-1">
                      <div className="w-1.5 h-1.5 rounded-full bg-Gray-40" />
                      <div>비활성</div>
                    </div>
                  ) : item.commerceStatus === "WAITING" ? (
                    <div className="flex items-center gap-1">
                      <div className="w-1.5 h-1.5 rounded-full bg-[#FAD619]" />
                      <div>대기중</div>
                    </div>
                  ) : (
                    ""
                  )}
                </td>
                <td className="w-full tableTd">
                  <div className="flex items-center gap-1 ">
                    <div className="text-Gray-90 SubH2 min-w-[57px]">
                      [{item.changeTitle}]
                    </div>
                    <div className="Body4">{item.changeSubTitle}</div>
                  </div>
                </td>
                <td className="tableTd">{item.editor}</td>
                <td className="tableTd">
                  <ButtonLinedTertiary
                    className="w-[67px]"
                    size={"sm"}
                    btnText={"상세보기"}
                  />
                </td>
              </tr>
            );
          })}
        </Table>
      ) : (
        <div className="flex flex-col items-center justify-center w-full h-[500px] Body4 text-Gray-90">
          정보 변경한 이력이 없어요
        </div>
      )}
      <Pagination
        className="mt-4"
        itemsCount={history?.data.ResultData.totalElements!}
        pageSize={pageItemLength}
        currPage={currentPage}
        setList={setCurrentPage}
      />
    </div>
  );
};

export default StoreInfoHistory;
