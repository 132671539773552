import PageHeader from 'components/Header/PageHeader';
import Table from 'components/Table/Table';
import { ENTRYSTORE } from 'components/Table/TableData';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import requestToLoveEgg from 'utils/requestToLoveEgg';

const EntryStore = () => {
    const navigate = useNavigate();

    const [entrystore, setEntryStore] = useState<{
        address: string
        brandName: string
        categoryName: string
        companyId: number;
        companyName: string
        detailAddress: string
        email: string
        name: string
        regDt: string
        status: string
    }[]>([])

    const entryStore = () => {
        requestToLoveEgg({
            method: "GET",
            url: "/api/admin/v1/join-apply",
        })
            .then((res) => {
                setEntryStore(res.data.ResultData.content
                )
            })
    }

    useEffect(() => {
        entryStore();
    }, [])



    return (
        <div className='contentBox'>
            <PageHeader pageList={["상점관리", "입점 신청"]} title={`입점 신청 (${entrystore?.length})`} />
            {entrystore.length !== 0
                ?
                <Table COLUMNS={ENTRYSTORE} >
                    {entrystore.map((item, i) => {
                        return (
                            <tr key={i} className='Body4 text-Gray-90'>
                                <td className='tableTd'>{item.categoryName}</td>
                                <td onClick={() => {
                                    navigate(`/store/request/${item.companyId}`)
                                }} className='underline cursor-pointer tableTd'>{item.companyName}</td>
                                <td className='tableTd'>{item.status === "REJECTED" ?
                                    <div className='text-red'>입점 거부</div>
                                    : item.status === "EXAMINATION" ?
                                        <div className="flex items-center gap-1">
                                            <div className='w-1.5 h-1.5 rounded-full bg-red' />
                                            <div>심사중</div>
                                        </div>
                                        :
                                        item.status === "WAITING" ?
                                            <div className="flex items-center gap-1">
                                                <div className='w-1.5 h-1.5 rounded-full bg-[#FAD619]' />
                                                <div>대기중</div>
                                            </div>
                                            : "'"
                                }</td>
                                <td className='w-full tableTd'>{item.address}{item.detailAddress ? `, ${item.detailAddress}` : ""}</td>
                                <td className='tableTd'>{item.name}</td>
                                <td className='tableTd'>{item.email}</td>
                                <td className='tableTd'>{item.regDt}</td>
                            </tr>
                        )
                    })
                    }
                </Table>
                :
                <div className='flex flex-col items-center justify-center w-full h-[500px] Body4 text-Gray-90'>
                    입점한 업체가 없어요
                </div>
            }
        </div>
    );
};

export default EntryStore;