import React from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';

const Header = () => {
    const navigate = useNavigate()
    const userInfo = JSON.parse(localStorage.getItem("userInfo") as string);
    const authority = userInfo?.authority;
    return (
        <div className='flex items-center justify-between pl-9 pr-11 py-2.5 min-w-[1280px]'>
            <div className='flex items-end gap-2'>
                <div className='H2 text-Gray-100'>레빠마켓</div>
                <div className='SubH2 text-Blue-70'>{authority === "ROLE_USER" ? "파트너센터" : authority === "ROLE_COMMERCE_ADMIN" ? "통합 관리자" : ""}</div>
            </div>
            <div className='flex items-center gap-4'>
                <div className='flex items-center gap-1'>
                    <div className='SubH1 text-Gray-90'>{userInfo?.companyName}</div>
                    <div className='Body2 text-Gray-90'>님</div>
                </div>
                <div onClick={() => {
                    localStorage.removeItem("userInfo");
                    window.location.reload();
                }} className='SubH1 px-4 py-2.5 text-Gray-60 cursor-pointer'>로그아웃</div>
            </div>
        </div>
    );
};

export default Header;